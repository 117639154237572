import React, { useState, useEffect } from 'react';

const validateTel = (value) => {
    // Limpa todos os caracteres não numéricos
    const cleanedValue = value.replace(/\D/g, '').substring(0, 11);

    // Valida se o valor limpo tem entre 11 e 16 caracteres (incluindo DDD e número)
    const isValidLength = cleanedValue.length === 11;

    // Formata o número de telefone
    const formattedValue = cleanedValue
        .replace(/^(\d{2})(\d{1})/, '($1) $2 ')
        .replace(/(\d{4})(\d{4})$/, '$1-$2')
        .trim();

    // Valida o formato usando uma expressão regular
    const telPattern = /^\(\d{2}\) \d{1} \d{4}-\d{4}$/;
    const isValidFormat = telPattern.test(formattedValue);

    // Retorna a validação e o valor formatado
    return { isValid: isValidLength && isValidFormat, formattedValue };
};


const validateEmail = (value) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return { isValid: emailPattern.test(value), formattedValue: value };
};

const validateSite = (value) => {
    const sitePattern = /^[a-zA-Z0-9-]{2,26}\.[a-zA-Z]{2,3}(?:\.[a-zA-Z]{2,3})?$/;
    return { isValid: sitePattern.test(value), formattedValue: value };
};

const validateInstagram = (value) => {
    const instagramPattern = /^[a-zA-Z0-9._]{1,30}$/;
    return { isValid: instagramPattern.test(value), formattedValue: value };
};

const validateMonetario = (value) => {
    if (!value) return { isValid: false, formattedValue: '' };

    // Remove qualquer caractere que não seja número
    const cleanedValue = value.replace(/\D/g, '');

    // Se o valor tiver menos de 3 dígitos, padStart para garantir que os centavos sejam considerados
    const intValue = cleanedValue.padStart(3, '0');

    // Separa os números em inteiros e decimais
    const integerPart = intValue.slice(0, -2).replace(/^0+/, '') || '0'; // Remove zeros à esquerda
    const decimalPart = intValue.slice(-2); // Últimos dois dígitos

    // Formata a parte inteira com pontos
    const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    // Constrói o valor final
    const formattedValue = `${formattedInteger},${decimalPart}`;

    // Verifica se o valor é válido (nesse caso, vamos considerar qualquer valor não vazio como válido)
    const isValid = cleanedValue.length > 0 && parseInt(cleanedValue) > 0;

    return { isValid, formattedValue };
};

const validatePorcentagem = (value) => {
    if (!value) return { isValid: false, formattedValue: '' };

    // Remove qualquer caractere que não seja número
    const cleanedValue = value.replace(/\D/g, '');

    // Se o valor tiver menos de 3 dígitos, padStart para garantir que os centavos sejam considerados
    const intValue = cleanedValue.padStart(3, '0');

    // Separa os números em inteiros e decimais
    const integerPart = intValue.slice(0, -1).replace(/^0+/, '') || '0'; // Remove zeros à esquerda
    const decimalPart = intValue.slice(-1); // Últimos dois dígitos

    const formattedValue = `${integerPart}.${decimalPart}`;

    // Verifica se o valor é válido (nesse caso, vamos considerar qualquer valor não vazio como válido)
    const isValid = cleanedValue.length > 0 && parseInt(cleanedValue) > 0;

    return { isValid, formattedValue };
};



const validateNumerico = (value) => {
    const cleanedValue = value.replace(/\D/g, '');
    return { isValid: !isNaN(cleanedValue), formattedValue: cleanedValue };
};


const TextInput = ({ label, value, onChange, isRequired, validacao, tipoValidacao, paginaQuestao }) => {
    const [displayValue, setDisplayValue] = useState(value);
    const [error, setError] = useState('');

    useEffect(() => {
        if (value !== displayValue) {
            const { isValid, formattedValue } = validateValue(tipoValidacao, value);
            setDisplayValue(formattedValue);
            setError(isValid ? '' : 'Valor inválido');
        }
    }, [value, tipoValidacao]);

    const [formatedPlaceHolder, setFormatedPlaceHolder] = useState("Digite aqui...")
    const validateValue = (type, value) => {
        switch (type) {
            case 'tel':
                setFormatedPlaceHolder('(XX) 9 XXXX-XXXX');
                return validateTel(value);
            case 'email':
                setFormatedPlaceHolder('exemplo@dominio.xxx');
                return validateEmail(value);
            case 'site':
                setFormatedPlaceHolder('dominio.xxx.xx');
                return validateSite(value);
            case 'instagram':
                setFormatedPlaceHolder('@perfil');
                return validateInstagram(value);
            case 'monetario':
                setFormatedPlaceHolder('R$ XX.XXX,00');
                return validateMonetario(value);
            case 'porcentagem':
                setFormatedPlaceHolder('XX.X%');
                return validatePorcentagem(value);
            case 'numerico':
                setFormatedPlaceHolder('Apenas dígitos...');
                return validateNumerico(value);
            default:
                setFormatedPlaceHolder("Digite aqui...");
                return { isValid: true, formattedValue: value };
        }
    };

    useEffect(() => {
        const { isValid, formattedValue } = validateValue(tipoValidacao, value);

        const finalValidity = (!isRequired && !formattedValue.length) || (formattedValue.length && isValid);

        setDisplayValue(formattedValue);
        setError(finalValidity ? '' : 'Valor inválido');
        onChange(formattedValue, finalValidity);
    }, [paginaQuestao]);

    const handleChange = (e) => {
        const { isValid, formattedValue } = validateValue(tipoValidacao, e.target.value);

        const finalValidity = (!isRequired && !formattedValue.length) || (formattedValue.length && isValid);

        setDisplayValue(formattedValue);
        setError(finalValidity ? '' : 'Valor inválido');
        onChange(formattedValue, finalValidity);
    };


    return (
        <div className="space-y-2">
            {/*<label className={`block text-xl ${isRequired ? 'after:content-["*"] after:text-orange-500' : ''}`}>
                {label}
            </label>*/}
            <input
                type="text"
                className={`w-full p-4 text-xl bg-transparent border-b-4 border-neutral-800 ${error ? 'border-b-red-500' : (displayValue ? 'border-b-orange-500' : 'border-b-neutral-900')} focus:border-neutral-800 focus:border-b-orange-500 focus:ring-0 placeholder:text-neutral-500`}
                placeholder={formatedPlaceHolder}
                value={displayValue}
                onInput={handleChange}
                required={isRequired}
            />
            {error && <div className="text-red-500 text-sm">{error}</div>}
        </div>
    );
};

export default TextInput;
