import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

const ModalCriarEditarPlano = ({ plan_id = null, mode, closeModal, plano, setPlano, planoErrors, setPlanoErrors, metadata, setMetadata, disabledAll, enableSend, handleSendData }) => {
    const toMoney = (number) => {
        return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(number)
    }

    const setMetadataInPlan = (plan_base) => {
        const obj = { plano: plan_base }

        setPlano(prevPlano => ({
            ...prevPlano,
            ['metadata']: obj
        }));
        setMetadata(plan_base)
    }

    const handleInputChange = (e) => {
        let { name, value } = e.target;

        if (name === 'price') {
            value = value.replace(/\D/g, ''); // Remove non-digit characters
            setPlano(prevPlano => ({
                ...prevPlano,
                items: prevPlano.items.map((item, index) =>
                    index === 0
                        ? {
                            ...item,
                            pricing_scheme: {
                                ...item.pricing_scheme,
                                price: Number(value / 100) // Ensure the value is a number
                            }
                        }
                        : item
                )
            }));
        } else {
            setPlano(prevPlano => ({
                ...prevPlano,
                [name]: value
            }));
        }
    };

    return (
        <div className="fixed top-0 left-0 w-screen min-h-screen flex justify-center align-middle items-center z-40">
            <div className="w-full min-h-screen bg-neutral-950 opacity-95" onClick={() => closeModal()}></div>
            <div className="absolute w-11/12 md:w-1/2 rounded-lg bg-neutral-800 p-5 text-neutral-300 overflow-y-auto max-h-screen">
                <div className="border-b border-neutral-700 py-5 space-y-2 flex justify-between items-center">
                    <img src='/main_logo.png' className='h-7' />
                    <button className="text-4xl hover:text-neutral-200 active:text-neutral-50" onClick={() => closeModal()}>&times;</button>
                </div>
                <div className="py-5 flex flex-col gap-5 w-2/3 m-auto">
                    <div className='flex flex-col w-full gap-2'>
                        <label htmlFor="name" className='text-xs md:text-sm'>Nome</label>
                        <input type="text" id="name" name="name" value={plano.name} onChange={handleInputChange} required placeholder="Indira um nome identificador do plano" autoFocus className='w-full text-sm p-2 rounded-md bg-neutral-700 focus:bg-neutral-700 focus:outline-none focus:border-none focus:shadow-none focus:ring-1 focus:ring-orange-500' />
                        {planoErrors.name && <p className='text-red-500 text-xs md:text-sm pt-1 pb-2'>{planoErrors.name}</p>}
                    </div>
                    <div className='flex flex-col w-full gap-2'>
                        <label htmlFor="description" className='text-xs md:text-sm'>Descrição:</label>
                        <input type="text" id="description" name="description" value={plano.description} onChange={handleInputChange} required placeholder="Insira uma descrição detalhada" className='w-full text-sm p-2 rounded-md bg-neutral-700 focus:bg-neutral-700 focus:outline-none focus:border-none focus:shadow-none focus:ring-1 focus:ring-orange-500' />
                        {planoErrors.description && <p className='text-red-500 text-xs md:text-sm pt-1 pb-2'>{planoErrors.description}</p>}
                    </div>
                    <div className='flex flex-col w-full gap-2'>
                        <label htmlFor="price" className='text-xs md:text-sm'>Preço:</label>
                        <input type="text" id="price" name="price" value={toMoney(plano.items[0].pricing_scheme.price)} onChange={handleInputChange} required placeholder="Insira o preço do plano" className='w-full text-sm p-2 rounded-md bg-neutral-700 focus:bg-neutral-700 focus:outline-none focus:border-none focus:shadow-none focus:ring-1 focus:ring-orange-500' />
                        {planoErrors.price && <p className='text-red-500 text-xs md:text-sm pt-1 pb-2'>{planoErrors.price}</p>}
                    </div>
                    <div className='flex flex-col w-full gap-2'>
                        <label htmlFor="plano-base" className='text-xs md:text-sm'>Escolha um Plano Base</label>
                        <div className="w-full flex flex-row gap-3">
                            <button className={`w-1/3 p-1 rounded-lg ${metadata === 'aviao' ? 'bg-yellow-500 cursor-default' : 'bg-orange-500 hover:bg-orange-700 active:bg-orange-900'}`} onClick={() => setMetadataInPlan('aviao')}>Plano Avião</button>
                            <button className={`w-1/3 p-1 rounded-lg ${metadata === 'jato' ? 'bg-yellow-500 cursor-default' : 'bg-orange-500 hover:bg-orange-700 active:bg-orange-900'}`} onClick={() => setMetadataInPlan('jato')} >Plano Jato</button>
                            <button className={`w-1/3 p-1 rounded-lg ${metadata === 'foguete' ? 'bg-yellow-500 cursor-default' : 'bg-orange-500 hover:bg-orange-700 active:bg-orange-900'}`} onClick={() => setMetadataInPlan('foguete')} >Plano Foguete</button>
                        </div>
                    </div>
                </div>
                <div className="border-t border-neutral-700 py-5 flex flex-row justify-end items-center align-middle gap-x-3 select-none">

                    <button className="py-2 px-3 md:py-3 md:px-4 rounded-md text-xs md:text-sm bg-neutral-600 hover:bg-neutral-700 active:bg-neutral-800" disabled={disabledAll} onClick={() => closeModal()}>Cancelar</button>
                    <button className="py-2 px-3 md:py-3 md:px-4  rounded-md text-xs font-semibold md:text-sm bg-orange-600 hover:bg-orange-700 active:bg-orange-500 disabled:bg-neutral-800" disabled={disabledAll || !enableSend} onClick={() => handleSendData()}>{mode === 'create' ? 'Criar Plano' : 'Editar Plano'}</button>

                </div>
            </div>
        </div>
    )
}
export default ModalCriarEditarPlano