import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';

const TabelaPacotes = ({ planos }) => {
    const [disableSend, setDisableSend] = useState(true)
    const [novoPacote, setNovoPacote] = useState({
        nome: "",
        planos: ["", "", ""],
    })

    const [pacotes, setPacotes] = useState([])
    const [loading, setLoading] = useState(true)
    const [planosAviao, setPlanosAviao] = useState([])
    const [planosJato, setPlanosJato] = useState([])
    const [planosFoguete, setPlanosFoguete] = useState([])

    useEffect(() => {
        let isMounted = true;

        const fetchPacotes = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/pagarme/listar-pacotes`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                });

                if (isMounted) {
                    if (response.data?.dados) {
                        setPacotes(response.data.dados);
                    } else {
                        console.error("Erro ao buscar pacotes: ", response);
                        toast.error("Erro ao listar pacotes de planos");
                    }
                    setLoading(false);
                }
            } catch (error) {
                if (isMounted) {
                    console.error("Erro ao buscar pacotes:", error);
                    toast.error("ERROR: Erro ao listar pacotes de planos");
                    setLoading(false);
                }
            }
        };

        if (planos) fetchPacotes();

        return () => {
            isMounted = false;
        };
    }, [planos]);


    useEffect(() => {
        const dividirPlanos = () => {
            const plano_aviao = [];
            const plano_jato = [];
            const plano_foguete = [];

            console.log("PLANOZ: ", planos)
            planos.forEach(plano => {
                const plano_tipo = plano?.metadata?.plano
                if (!plano_tipo) return;

                switch (plano_tipo.toLowerCase()) {
                    case 'aviao':
                        plano_aviao.push(plano);
                        break;
                    case 'jato':
                        plano_jato.push(plano);
                        break;
                    case 'foguete':
                        plano_foguete.push(plano);
                        break;
                    default:
                        console.error(`Plano desconhecido: ${plano.metadata.plano}`);
                }
            });

            setPlanosAviao(plano_aviao);
            setPlanosJato(plano_jato);
            setPlanosFoguete(plano_foguete);
        };

        if (planos.length) dividirPlanos();

    }, [planos])

    useEffect(() => {
        const { nome, planos } = novoPacote;

        const hasAllPlans = planos && planos.length >= 3 && planos.slice(0, 3).every(plan => plan);
        setDisableSend(!(nome && hasAllPlans));
    }, [novoPacote]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === 'nome') {
            setNovoPacote(prevNovoPacote => ({
                ...prevNovoPacote,
                nome: value
            }));
        } else {
            let posicao;
            switch (name) {
                case 'plano_aviao': posicao = 0; break;
                case 'plano_jato': posicao = 1; break;
                case 'plano_foguete': posicao = 2; break;
                default: return;
            }
            setNovoPacote(prevNovoPacote => {
                const novosPlanos = [...prevNovoPacote.planos];
                novosPlanos[posicao] = value;
                return {
                    ...prevNovoPacote,
                    planos: novosPlanos
                };
            });
        }
    };

    const createPackage = async () => {
        if (!novoPacote.nome) {
            toast.error("Insira o nome do pacote");
            return;
        }
        if (!novoPacote.planos[0] || !novoPacote.planos[1] || !novoPacote.planos[2]) {
            toast.error("É preciso selecionar um plano de cada tipo");
            return;
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/pagarme/criar-pacote`, novoPacote, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });
            console.log("RESULT: ", response);
            if (response.data) {
                setPacotes(prevPacotes => [...prevPacotes, response.data]);
                toast.success("Pacote criado com sucesso");
                resetForm();
            } else {
                console.error("Erro ao criar pacotes: ", response);
                toast.error("Erro ao criar o novo pacote: " + response.message);
            }
        } catch (error) {
            console.error("Erro ao criar pacotes:", error);
            toast.error("ERROR: Erro ao criar o novo pacote: " + error.message);
        }
    };

    const resetForm = () => {
        setNovoPacote({
            nome: '',
            planos: ['', '', '']
        })
    }

    const deletePackage = async (pacoteId) => {
        try {
            const response = await axios.delete(`${process.env.REACT_APP_BACKEND_BASE_URL}/pagarme/deletar-pacote/${pacoteId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });
            if (response.data) {
                setPacotes(prevPacotes => prevPacotes.filter(pacote => pacote._id !== pacoteId));
                toast.success("Pacote excluído com sucesso");
            } else {
                console.error("Erro ao excluir pacote: ", response);
                toast.error("Erro ao excluir o pacote: " + response.message);
            }
        } catch (error) {
            console.error("Erro ao excluir pacote:", error);
            toast.error("ERROR: Erro ao excluir o pacote: " + error.message);
        }
    };


    const copyLink = (plano_id) => {
        const link = `${window.location.origin}/assine-simbium/${plano_id}`;

        navigator.clipboard.writeText(link)
            .then(() => {
                console.log("Link copiado para a área de transferência com sucesso!");
                toast.success("Link copiado para a área de transferência!");
            })
            .catch(err => {
                console.error("Falha ao copiar o link para a área de transferência: ", err);
                toast.error("Falha ao copiar o link para a área de transferência");
            });
    };

    return (
        <div className="rounded-lg bg-neutral-900cl select-[#EA580C]">
            <div className="p-3 bg-orange-600 rounded-t-lg">Pacotes de Planos</div>
            <table className="w-full" >
                <thead>
                    <tr>
                        <th className='bg-neutral-900'>Nome</th>
                        <th className='bg-neutral-900'>Plano Avião</th>
                        <th className='bg-neutral-900'>Plano Jato</th>
                        <th className='bg-neutral-900'>Plano Foguete</th>
                        <th colSpan="2" className='bg-neutral-900 text-center'>Controles</th>
                    </tr>
                </thead>
                <tbody className="bg-neutral-800">
                    {pacotes?.length ? pacotes.map((pacote, index) => (
                        <tr key={index} className="even:bg-neutral-700">
                            <td>{pacote.nome}</td>
                            <td>{pacote.planos[0]}</td>
                            <td>{pacote.planos[1]}</td>
                            <td>{pacote.planos[2]}</td>
                            <td className='text-center'>
                                <button onClick={() => deletePackage(pacote._id)}>Excluir</button>
                            </td>
                            <td className='text-center'>
                                <button onClick={() => copyLink(pacote._id)}>Copiar Link</button>
                            </td>
                        </tr>
                    )) : (
                        <tr>
                            <td colSpan="6" className='text-center'>
                                {!loading ? "Não há pacotes cadastrados." : <span className='w-full h-5 animate-pulse'>Carregando pacotes...</span>}
                            </td>
                        </tr>
                    )}
                    <tr>
                        <td>
                            <input
                                type="text"
                                id="nome"
                                name="nome"
                                value={novoPacote.nome}
                                onChange={handleInputChange}
                                placeholder="Insira o nome do novo pacote"
                                className='w-full text-sm p-2 rounded-md bg-neutral-700 focus:bg-neutral-700 focus:outline-none focus:border-none focus:shadow-none focus:ring-1 focus:ring-orange-500'
                            />
                        </td>
                        <td>
                            <select
                                name="plano_aviao"
                                className='w-full text-sm p-2 rounded-md bg-neutral-700 focus:bg-neutral-700 focus:outline-none focus:border-none focus:shadow-none focus:ring-1 focus:ring-orange-500'
                                value={novoPacote.planos[0]}
                                onChange={handleInputChange}
                            >
                                <option value="" disabled>Escolha um plano do tipo AVIÃO</option>
                                {planosAviao.map((aviao, index_aviao) => (
                                    <option key={index_aviao} value={aviao.id}>{aviao.name}</option>
                                ))}
                            </select>
                        </td>
                        <td>
                            <select
                                name="plano_jato"
                                className='w-full text-sm p-2 rounded-md bg-neutral-700 focus:bg-neutral-700 focus:outline-none focus:border-none focus:shadow-none focus:ring-1 focus:ring-orange-500'
                                value={novoPacote.planos[1]}
                                onChange={handleInputChange}
                            >
                                <option value="" disabled>Escolha um plano do tipo JATO</option>
                                {planosJato.map((jato, index_jato) => (
                                    <option key={index_jato} value={jato.id}>{jato.name}</option>
                                ))}
                            </select>
                        </td>
                        <td>
                            <select
                                name="plano_foguete"
                                className='w-full text-sm p-2 rounded-md bg-neutral-700 focus:bg-neutral-700 focus:outline-none focus:border-none focus:shadow-none focus:ring-1 focus:ring-orange-500'
                                value={novoPacote.planos[2]}
                                onChange={handleInputChange}
                            >
                                <option value="" disabled>Escolha um plano do tipo FOGUETE</option>
                                {planosFoguete.map((foguete, index_foguete) => (
                                    <option key={index_foguete} value={foguete.id}>{foguete.name}</option>
                                ))}
                            </select>
                        </td>
                        <td colSpan="2" className="text-center">
                            <button onClick={() => createPackage()} disabled={disableSend}>Criar Pacote</button>
                        </td>
                    </tr>
                </tbody>
            </table >
        </div >
    )
}

export default TabelaPacotes