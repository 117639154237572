import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation, useHistory } from 'react-router-dom'
import axios from 'axios';
import { toast } from 'react-toastify';
import ModalConsultoresHome from './modal';  // Corrigido o nome do componente aqui
import { usuarioAtual } from '../../../features/usuario/usuarioAtual';  // Importando o objeto corretamente

const ColaboradoresHome = (props) => {
    const [popupColaboradorVisible, setPopupColaboradorVisible] = useState(false);
    const [colaboradores, setColaboradores] = useState([]);
    const [nomeNovoColaborador, setNomeNovoColaborador] = useState('');
    const [emailNovoColaborador, setEmailNovoColaborador] = useState('');
    const [loading, setLoading] = useState('');

    const api = axios.create({
        baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
    });

    const fetchColaboradores = async () => {
        console.log("FECTH")
        try {
            const response = await api.get('/usuarios/meus-colaboradores', {
                params: { usuarioID: props.usuarioAtual._id },
            });
            if (response.data) {
                setColaboradores(response.data);
            }
        } catch (error) {
            handleRequestError(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchColaboradores();
    }, []);

    const deletarColaborador = async (colaboradorEmail, colaboradorNome) => {
        if (!window.confirm(`Tem certeza que deseja excluir o usuário ${colaboradorNome}? Esta ação não pode ser desfeita!`)) {
            return;
        }

        try {
            const response = await api.delete(`/usuarios/deletar-colaborador/${colaboradorEmail}`);

            handleSuccessResponse(response, 'Colaborador excluído com sucesso!', () => {
                const updatedUsuario = { ...props.usuarioAtual, creditos: props.usuarioAtual.creditos + 80 };
                fetchColaboradores();
                // Atualizar usuário aqui se necessário
            });
        } catch (error) {
            handleRequestError(error);
        }
    };

    const handleSuccessResponse = (response, successMessage, callback) => {
        console.log("SUCESS")
        if (response.data && response.data.message) {
            toast.success(successMessage);
            callback();
        }
    };

    const handleRequestError = (error) => {
        console.log("fail: ", error)
        if (error.response) {
            toast.error(error.response);
        }
    };

    return (
        <div className='w-full md:w-1/2'>
            <section className='w-full bg-neutral-800 rounded-lg'>
                <div className='p-3 md:p-5 h-fit max-h-[300px] lg:max-h-[520px] overflow-y-auto'>
                    <h3 className='text-neutral-300 text-md md:text-xl'>Meus Colaboradores</h3>
                    <div className={`${props.usuarioAtual.creditos <= 1 ? 'text-red-500' : 'text-neutral-400'} text-center py-3`}>
                        Você possui {props.usuarioAtual.creditos} crédito(s) para adicionar colaboradores.
                        {props.usuarioAtual.ref.plano && props.usuarioAtual.ref.plano !== process.env.REACT_APP_PLANO_AVIAO && <button className='p-0 m-0 animate-pulse text-neutral-400 hover:text-orange-500 active:text-orange-600'>&nbsp;Comprar mais créditos!</button>}
                    </div>
                    <ul className="w-full divide-y divide-neutral-900">
                        {colaboradores.length ? (
                            colaboradores.map((colaborador, index) => (
                                <li key={index} className='py-3 flex justify-between gap-3 group'>
                                    <span className='flex flex-row items-center gap-3 truncate'>
                                        <span className='rounded-full max-w-10 max-h-10 bg-neutral-900 overflow-hidden'><img className='object-cover object-center' src={colaborador.foto_perfil || "https://thumbs.dreamstime.com/b/linha-%C3%ADcone-do-preto-avatar-perfil-de-usu%C3%A1rio-121102131.jpg"} /></span>
                                        <span className='truncate'>
                                            <p className='text-md md:text-xl text-neutral-200'>{colaborador.nome}</p>
                                            <a href={`mailto:${colaborador.email}`} className='text-xs md:text-sm text-neutral-500 hover:text-orange-500'>{colaborador.email}</a>
                                        </span>
                                    </span>
                                    <span className=''>
                                        <p className=' text-neutral-400 text-xs md:text-sm text-right'>{colaborador.acesso}</p>
                                        <p className=' text-xs text-right cursor-pointer select-none text-red-500 hover:text-red-600 active:text-700' onClick={() => deletarColaborador(colaborador.email, colaborador.nome)}>Remover</p>
                                    </span>
                                </li>
                            ))
                        ) : (
                            <li className='text-neutral-500 text-center'>
                                Nenhum colaborador adicionado
                            </li>
                        )}
                    </ul>
                </div>
                {props.usuarioAtual.ref.plano && props.usuarioAtual.ref.plano === process.env.REACT_APP_PLANO_AVIAO ?
                    <div className='rounded-b-lg p-3 w-full bg-neutral-700 text-neutral-400 select-none text-center hover:bg-neutral-600'>Exclusivo para <strong>Assinantes Anuais</strong></div>
                    :
                    <div className='rounded-b-lg p-3 w-full cursor-pointer bg-neutral-700 text-neutral-400 select-none text-center hover:bg-orange-500 hover:text-white active:bg-orange-700 active:text-white' onClick={() => setPopupColaboradorVisible(true)}>Adicionar Colaborador</div>
                }
            </section>
            {popupColaboradorVisible && (
                <ModalConsultoresHome
                    usuarioAtual={props.usuarioAtual}
                    nomeNovoColaborador={nomeNovoColaborador}
                    emailNovoColaborador={emailNovoColaborador}
                    setnomeNovoColaborador={setNomeNovoColaborador}
                    setemailNovoColaborador={setEmailNovoColaborador}
                    setPopupColaboradorVisible={setPopupColaboradorVisible}
                    handleRequestError={handleRequestError}
                    handleSuccessResponse={handleSuccessResponse}
                    fetchColaboradores={fetchColaboradores}
                />
            )}
        </div>
    )
}

export default ColaboradoresHome;
