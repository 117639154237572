import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ZipCodeInput from '../form/zip-code-input';

const ModalCompraFormularioEnderecoCobranca = ({ usuarioAtual, billingAddress, setBillingAddress, billingAddressErrors, setBillingAddressErrors }) => {

    const estados = { AC: 'Acre', AL: 'Alagoas', AM: 'Amazonas', AP: 'Amapá', BA: 'Bahia', CE: 'Ceará', DF: 'Distrito Federal', ES: 'Espírito Santo', GO: 'Goiás', MA: 'Maranhão', MT: 'Mato Grosso', MS: 'Mato Grosso do Sul', MG: 'Minas Gerais', PA: 'Pará', PB: 'Paraíba', PR: 'Paraná', PE: 'Pernambuco', PI: 'Piauí', RJ: 'Rio de Janeiro', RN: 'Rio Grande do Norte', RS: 'Rio Grande do Sul', RO: 'Rondônia', RR: 'Roraima', SC: 'Santa Catarina', SP: 'São Paulo', SE: 'Sergipe', TO: 'Tocantins' };
    const estados_list = ['AC', 'AL', 'AM', 'AP', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO'];

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === 'zip_code') handleZipCode(value)
        // Atualiza o estado do billingAddress
        setBillingAddress(prevBillingAddress => ({
            ...prevBillingAddress,
            [name]: value
        }));
    };

    return (
        <div className='w-full mt-5 space-y-5'>
            <h2 className='font-semibold uppercase text-xl'>Endereço de Cobrança</h2>
            <div className='flex flex-col w-full gap-2'>
                <label htmlFor="zip_code" className='text-xs md:text-sm'>CEP:</label>
                <ZipCodeInput
                    billingAddress={billingAddress}
                    billingAddressErrors={billingAddressErrors}
                    setBillingAddress={setBillingAddress}
                    setBillingAddressErrors={setBillingAddressErrors}
                />
                {billingAddressErrors.zip_code && <p className='text-red-500 text-xs md:text-sm pt-1 pb-2'>{billingAddressErrors.zip_code}</p>}
            </div>
            <div className='flex flex-col w-full gap-2'>
                <label htmlFor="street" className='text-xs md:text-sm'>Rua:</label>
                <input type="text" id="street" name="street" value={billingAddress.street} onChange={handleInputChange} required className='w-full text-sm p-2 rounded-md bg-neutral-700 focus:bg-neutral-700 focus:outline-none focus:border-none focus:shadow-none focus:ring-1 focus:ring-orange-500' />
                {billingAddressErrors.street && <p className='text-red-500 text-xs md:text-sm pt-1 pb-2'>{billingAddressErrors.street}</p>}
            </div>
            <div className='flex flex-col lg:flex-row gap-5'>
                <div className='flex flex-col w-full lg:w-1/2 gap-2'>
                    <label htmlFor="number" className='text-xs md:text-sm'>Número:</label>
                    <input type="text" id="number" name="number" value={billingAddress.number} onChange={handleInputChange} required className='w-full text-sm p-2 rounded-md bg-neutral-700 focus:bg-neutral-700 focus:outline-none focus:border-none focus:shadow-none focus:ring-1 focus:ring-orange-500' />
                    {billingAddressErrors.number && <p className='text-red-500 text-xs md:text-sm pt-1 pb-2'>{billingAddressErrors.number}</p>}
                </div>
                <div className='flex flex-col w-full lg:w-1/2 gap-2'>
                    <label htmlFor="complement" className='text-xs md:text-sm'>Complemento:</label>
                    <input type="text" id="complement" name="complement" value={billingAddress.complement} onChange={handleInputChange} required className='w-full text-sm p-2 rounded-md bg-neutral-700 focus:bg-neutral-700 focus:outline-none focus:border-none focus:shadow-none focus:ring-1 focus:ring-orange-500' />
                    {billingAddressErrors.complement && <p className='text-red-500 text-xs md:text-sm pt-1 pb-2'>{billingAddressErrors.complement}</p>}
                </div>
            </div>
            <div className='flex flex-col w-full gap-2'>
                <label htmlFor="neighborhood" className='text-xs md:text-sm'>Bairro:</label>
                <input type="text" id="neighborhood" name="neighborhood" value={billingAddress.neighborhood} onChange={handleInputChange} required className='w-full text-sm p-2 rounded-md bg-neutral-700 focus:bg-neutral-700 focus:outline-none focus:border-none focus:shadow-none focus:ring-1 focus:ring-orange-500' />
                {billingAddressErrors.neighborhood && <p className='text-red-500 text-xs md:text-sm pt-1 pb-2'>{billingAddressErrors.neighborhood}</p>}
            </div>
            <div className='flex flex-col lg:flex-row gap-5'>
                <div className='flex flex-col w-full lg:w-1/2 gap-2'>
                    <label htmlFor="state" className='text-xs md:text-sm'>Estado:</label>
                    <select
                        id="state"
                        name="state"
                        onChange={handleInputChange}
                        value={billingAddress.state}
                        required
                        className='w-full text-sm p-2 rounded-md bg-neutral-700 focus:bg-neutral-700 focus:outline-none focus:border-none focus:shadow-none focus:ring-1 focus:ring-orange-500'
                    >
                        <option value="" disabled>Escolha um estado</option>
                        {estados_list.map((estado, index) => (
                            <option key={index} value={estado}>{estados[estado]}</option>
                        ))}
                    </select>
                    {billingAddressErrors.state && <p className='text-red-500 text-xs md:text-sm pt-1 pb-2'>{billingAddressErrors.state}</p>}
                </div>
                <div className='flex flex-col w-full lg:w-1/2 gap-2'>
                    <label htmlFor="city" className='text-xs md:text-sm'>Cidade:</label>
                    <input type="text" id="city" name="city" value={billingAddress.city} onChange={handleInputChange} required className='w-full text-sm p-2 rounded-md bg-neutral-700 focus:bg-neutral-700 focus:outline-none focus:border-none focus:shadow-none focus:ring-1 focus:ring-orange-500' />
                    {billingAddressErrors.city && <p className='text-red-500 text-xs md:text-sm pt-1 pb-2'>{billingAddressErrors.city}</p>}
                </div>
            </div>
        </div>
    );
}

export default ModalCompraFormularioEnderecoCobranca;
