import React, { useState, useEffect } from 'react';
const RadioGroup = ({ label, options, selectedOption, onChange, isRequired, paginaQuestao }) => {
    const [error, setError] = useState('');
    const handleInput = (value) => {
        const isValid = !isRequired || value

        setError(isValid ? '' : 'Selecione uma opção');
        onChange(value, isValid);
    }

    useEffect(() => {
        const isValid = !isRequired || selectedOption

        setError(isValid ? '' : 'Selecione uma opção');
        onChange(selectedOption, isValid);
    }, [paginaQuestao])

    return (
        <div className="space-y-2">
            {/*<p className="text-2xl text-orange-500">{label}</p>*/}
            <div className="">
                {options && options.map((option) => (
                    <label
                        key={option.codigo}
                        className={`block p-3 bg-neutral-700 cursor-pointer 
                            ${selectedOption === option.codigo ? 'bg-orange-500 text-white' : 'bg-neutral-800 hover:bg-neutral-900 active:bg-neutral-950 text-neutral-200'} 
                         transition-colors duration-200`}
                        onClick={() => handleInput(option.codigo)}
                    >
                        <span>{option.descricao}</span>
                    </label>
                ))}
            </div>
            {error && <div className="text-red-500 text-sm">{error}</div>}
        </div>
    )
};

export default RadioGroup;
