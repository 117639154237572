import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify'
import styles from './live.module.css';
import axios from 'axios'


const TabelaLive = (props) => {
    const date = new Date()
    date.setHours(3, 0, 0, 0)
    const ano = date.getFullYear()
    let mes = date.getMonth();
    if (mes < 10) {
        mes = `0${mes + 1}`
    }

    let dia = date.getDate()
    if (dia < 10) {
        dia = `0${dia}`
    }

    const [data, setData] = useState({
        data: new Date(),
        nome: '',
        link: '',
        professores: [],
        tag: ''
    });

    const [professoresList, setProfessoresList] = useState([]);

    const removerLive = async () => {
        try {

            const result = await axios.delete(`${process.env.REACT_APP_BACKEND_BASE_URL}/lives`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })

            toast.success("Live deletada com sucesso!")

        } catch (err) {

            toast.error("Nenhuma live encontrada")
        }
    }

    const getProfessorNome = (professorId) => {
        if (professorId) {
            const professor = professoresList.find(professor => professor._id === professorId)
            if (professor && professor.nome) {
                return professor.nome
            }

        }

        return 'Daniel Rabi';
    }

    const getProfessorFoto = (professorId) => {
        if (professorId) {
            const professor = professoresList.find(professor => professor._id === professorId)
            if (professor && professor.foto_perfil) {
                return professor.foto_perfil
            }

        }

        return "https://atigni.b-cdn.net/path/348319829_1209679629690528_3100790813138095354_n.jpg"
    }

    const removeProfessor = (professorId) => {
        const newProfessores = data.professores.filter(professor => professor._id !== professorId)
        setData({ ...data, professores: newProfessores })
    };

    const formatarDataLive = (data) => {
        if (data) {
            const date = new Date(data)
            const options = { day: 'numeric', month: 'long', hour: 'numeric', minute: 'numeric' };
            const formattedDate = new Intl.DateTimeFormat('pt-BR', options).format(date);
            return formattedDate;
        }
    };

    const anunciarLive = async () => {
        axios.post(process.env.REACT_APP_BACKEND_BASE_URL + "/lives", data, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
        }).then((res) => {
            toast.success('Live enviada com sucesso!');
            setData({
                data: new Date(),
                nome: '',
                professores: [],
                link: '',
                tag: ''
            });

        }).catch((error) => {
            alert("Houve um erro ao enviar a Live.");
        });
    };

    const getProfessores = async () => {

        try {

            const result = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/usuarios/professores?consultores=true`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            });

            setProfessoresList(result.data)

        } catch (err) {


        }

    };

    useEffect(() => {
        getProfessores();
    }, [])

    return (
        <div className={styles['tabela-aulas']}>
            <div className={styles['container']}>
                <span className={styles['text']}>Live</span>
            </div>
            <div className={styles['container01']}>
                <div className={styles['form']}>
                    <div className={styles['container02']}>
                        <span className={styles['text01']}>
                            <span>Data </span>
                        </span>
                        <input
                            type="datetime-local"
                            className={styles['input-dark']}
                            value={data.data}
                            onChange={(e) => setData({ ...data, data: e.target.value })}
                        />
                    </div>

                    <div className={styles['container04']}>
                        <span className={styles['text05']}>Nome</span>
                        <input
                            type="text"
                            className={styles['input-dark']}
                            value={data.nome}
                            placeholder="Os 5 Pilares da Prospecção B2B"
                            onChange={(e) => setData({ ...data, nome: e.target.value })}
                        />
                    </div>
                    <div className={styles['container05']}>
                        <span className={styles['text06']}>Link</span>
                        <input
                            type="text"
                            className={styles['input-dark']}
                            placeholder="https://linkdasualive.com"
                            value={data.link}
                            onChange={(e) => setData({ ...data, link: e.target.value })}
                        />
                    </div>
                    <div className={styles['container05']}>
                        <span className={styles['text06']}>Tag</span>
                        <input
                            type="text"
                            className={styles['input-dark']}
                            value={data.tag}
                            placeholder='Convidado do mês'
                            onChange={(e) => setData({ ...data, tag: e.target.value })}
                        />
                    </div>
                    <div className={styles['container06']}>
                        <span className={styles['text07']}>Professores</span>
                        <select
                            value=""
                            className={styles['dropdown']}
                            onChange={(e) => {
                                setData((prevData) => ({
                                    ...prevData,
                                    professores: [...prevData.professores, e.target.value]
                                }));
                            }}
                        >
                            <option value="" disabled>Selecione os professores</option>
                            {professoresList.length > 0 && (
                                professoresList.map((professor, index) => (
                                    <option key={index} value={professor._id} disabled={data.professores.includes(professor._id)}>{professor.nome}</option>
                                ))
                            )}

                        </select>
                        <div className={styles['tags']}>
                            {data.professores.length > 0 && (
                                data.professores.map((professor, index) => (
                                    <div key={index} className={styles['tag']}
                                        onClick={() => removeProfessor(professor._id)}
                                    >
                                        <span className={styles['label6']}>{getProfessorNome(professor)}</span>
                                        <svg
                                            viewBox="0 0 804.5714285714286 1024"
                                            className={styles['icon4']}
                                        >
                                            <path d="M741.714 755.429c0 14.286-5.714 28.571-16 38.857l-77.714 77.714c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-168-168-168 168c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-77.714-77.714c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l168-168-168-168c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l77.714-77.714c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l168 168 168-168c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l77.714 77.714c10.286 10.286 16 24.571 16 38.857s-5.714 28.571-16 38.857l-168 168 168 168c10.286 10.286 16 24.571 16 38.857z"></path>
                                        </svg>
                                    </div>
                                ))
                            )}
                        </div>
                    </div>
                    <button
                        disabled={data?.tag === '' || data?.data === '' || data?.nome === '' || data?.link === '' || (data?.professores.length === 0 || data?.professores.some(professor => professor._id === '1234x'))}
                        className={styles['btn-add']}
                        onClick={anunciarLive}
                    >

                        <svg className={styles['icon']} viewBox="0 0 24 24" stroke="#fff" strokeWidth="2" strokeLinecap='round' strokeLinejoin='round' fill='none'>
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <path d="M12 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0"></path>
                            <path d="M12 12m-5 0a5 5 0 1 0 10 0a5 5 0 1 0 -10 0"></path>
                            <path d="M15.9 20.11l0 .01"></path>
                            <path d="M19.04 17.61l0 .01"></path>
                            <path d="M20.77 14l0 .01"></path>
                            <path d="M20.77 10l0 .01"></path>
                            <path d="M19.04 6.39l0 .01"></path>
                            <path d="M15.9 3.89l0 .01"></path>
                            <path d="M12 3l0 .01"></path>
                            <path d="M8.1 3.89l0 .01"></path>
                            <path d="M4.96 6.39l0 .01"></path>
                            <path d="M3.23 10l0 .01"></path>
                            <path d="M3.23 14l0 .01"></path>
                            <path d="M4.96 17.61l0 .01"></path>
                            <path d="M8.1 20.11l0 .01"></path>
                            <path d="M12 21l0 .01"></path>
                        </svg>

                        <span className={styles['text08']}>Anunciar</span>
                    </button>
                </div>
                <div className={styles['container07']}>
                    <button className={styles["remove-live"]} onClick={removerLive}>Deletar última live</button>
                    <a href={data.link} target="_blank" className={styles["home-g-aula-mensal"]}>
                        <div className={styles["home-aula-mensal"]}>
                            <div className={styles["home-container16"]}>
                                <span className={styles["home-text33"]}>
                                    <span>{data.tag || "Nenhuma tag inserida"}</span>
                                </span>
                                <span className={styles["home-text36"]}>
                                    <span>{data.nome || "Nenhum nome inserido"}</span>
                                </span>
                                <span className={styles["home-text39"]}>
                                    <span>{formatarDataLive(data.data) || formatarDataLive(new Date())}</span>
                                </span>
                            </div>
                            <div className={styles["home-container17"]}
                                style={data.professores.length <= 1 ? {
                                    display: 'flex',
                                    marginRight: '1rem'
                                } : {}}
                            >
                                {data.professores.length ? data.professores.map((professor, index) => (
                                    <img
                                        key={index}
                                        alt="image213872"
                                        src={getProfessorFoto(professor._id)}
                                        className={styles["home-image212"]}
                                    />
                                )) :
                                    <p className='text-white'>Selecione um professor</p>
                                }
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    )
};

export default TabelaLive;
