import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import styles from './comunidade.module.css';
import axios from 'axios';

const TabelaComunidade = (props) => {

    const [data, setData] = useState([]);
    const [dataFlag, setDataFlag] = useState(false)

    const [item, setItem] = useState({
        titulo: '',
        descricao: '',
        foto: ''
    });

    const [postFotoUrl, setPostFotoUrl] = useState('')
    const [publicFotoUrl, setPublicFotoUrl] = useState('')
    const [postFotoFile, setPostFotoFile] = useState('')

    const [processando, setProcessando] = useState(false);

    useEffect(() => {
        if (dataFlag) {
            salvarAlteracoes()
        }
        setDataFlag(true)
    }, [data])

    const removerItem = (index) => {
        if (processando) return;
        const newData = [...data];
        newData.splice(index, 1);
        setData(newData);
    };

    const adicionarBloco = async () => {
        if (item.foto) {
            const upload_result = await uploadOnGoogleStorage();
            if (!upload_result) {
                toast.error("Erro ao fazer upload da foto!")
                return;
            }
        }
        setData([...data, { ...item, foto: publicFotoUrl }]);
        setItem({
            titulo: '',
            descricao: '',
            foto: ''
        });
        document.getElementById("file-input").value = '';
    };

    const uploadFile = async (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            setProcessando(true);
            setPostFotoFile(selectedFile);

            // Gerar uma URL temporária local para exibir a imagem enquanto não é feito o upload real
            const tempUrl = URL.createObjectURL(selectedFile);
            setItem({ ...item, foto: tempUrl });

            try {
                // Fazer a requisição para obter a URL assinada
                const response = await axios.post(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}/config/upload-url`,
                    {
                        fileName: selectedFile.name,
                        fileType: selectedFile.type
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('token')}`
                        }
                    }
                );

                const { uploadUrl, publicUrl } = response.data;
                setPostFotoUrl(uploadUrl);
                setPublicFotoUrl(publicUrl);

                // Aqui, a URL pública ainda não estará disponível porque o upload não foi feito
                // Ela será atualizada com a URL correta após o upload
                setItem({ ...item, foto: tempUrl }); // Exibir a URL temporária

            } catch (error) {
                toast.error('Erro ao fazer o upload da imagem.');
            } finally {
                setProcessando(false);
            }
        }
    };


    const uploadOnGoogleStorage = async () => {
        try {
            // Fazer o upload do arquivo para o Google Cloud Storage usando a URL assinada
            await axios.put(postFotoUrl, postFotoFile, {
                headers: {
                    'Content-Type': postFotoFile.type
                }
            });
            return true;
        } catch (error) {
            toast.error('Erro ao fazer o upload da imagem.');
            return false
        }
    };


    const salvarAlteracoes = async () => {
        setProcessando(true);
        try {
            const configData = {
                blocos_comunidade: data
            };

            const response = await axios.post(process.env.REACT_APP_BACKEND_BASE_URL + '/config', configData, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            if (response.status === 200) {
                toast.success('Alterações salvas com sucesso!');
            } else {
                toast.error('Erro ao salvar alterações.');
            }
        } catch (error) {
            toast.error('Erro ao salvar alterações.');
        } finally {
            setProcessando(false);
        }
    };

    useEffect(() => {
        let isMounted = true;

        const getBlocos = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/config`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });

                if (response.data && response.data.blocos_comunidade && isMounted) {
                    setDataFlag(false)
                    setData(response.data.blocos_comunidade);
                }
            } catch (err) {
                toast.error("Erro ao recuperar comunidades do Banco de Dados!")
                console.log("ERRO: ", err);
            }
        };

        getBlocos();

        return () => {
            isMounted = false;
        };
    }, []);

    return (
        <div className={styles['tabela-aulas']}>
            <div className={styles['container']}>
                <span className={styles['text']}>Comunidade</span>
            </div>
            <div className={styles['container01']}>
                <div className={styles['form']}>
                    <div className={styles['container04']}>
                        <span className={styles['text05']}>Título</span>
                        <input
                            type="text"
                            className={`${styles['input-dark']} disabled:opacity-50`}
                            disabled={processando}
                            value={item.titulo}
                            onChange={(e) => setItem({ ...item, titulo: e.target.value })}
                        />
                    </div>
                    <div className={styles['container05']}>
                        <span className={styles['text06']}>Descrição</span>
                        <input
                            type="text"
                            className={`${styles['input-dark']} disabled:opacity-50`}
                            disabled={processando}
                            value={item.descricao}
                            onChange={(e) => setItem({ ...item, descricao: e.target.value })}
                        />
                    </div>
                    <div className={styles['container3']} onClick={() => document.getElementById("file-input").click()}>
                        <span className={styles['label1']}>Foto</span>
                        <div className={`${styles['container4']} ${processando ? 'opacity-50' : ''}`}>
                            <img
                                alt="image"
                                src={item.foto ? item.foto : '../default-img.svg'}
                                className={styles['image']}
                            />
                            <div className={styles['fechar-popup1']} id="upload-file">
                                <label>
                                    <svg viewBox="0 0 804.5714285714286 1024" className={styles['icon2']}>
                                        <path d="M804.571 420.571v109.714c0 30.286-24.571 54.857-54.857 54.857h-237.714v237.714c0 30.286-24.571 54.857-54.857 54.857h-109.714c-30.286 0-54.857-24.571-54.857-54.857v-237.714h-237.714c-30.286 0-54.857-24.571-54.857-54.857v-109.714c0-30.286 24.571-54.857 54.857-54.857h237.714v-237.714c0-30.286 24.571-54.857 54.857-54.857h109.714c30.286 0 54.857 24.571 54.857 54.857v237.714h237.714c30.286 0 54.857 24.571 54.857 54.857z"></path>
                                    </svg>
                                </label>
                                <input
                                    id="file-input"
                                    type="file"
                                    style={{ display: 'none' }}
                                    disabled={processando}
                                    accept="image/jpeg, image/png, image/gif"
                                    onChange={uploadFile}
                                />
                            </div>
                        </div>
                    </div>
                    <button className={`${styles['btn-add']} disabled:opacity-50`} disabled={processando} onClick={adicionarBloco}>
                        <span className={`${styles['text08']}`}>Adicionar Bloco</span>
                    </button>
                </div>
                <div className={styles['container07']}>
                    <button className={`${styles["salvar-alteracoes"]} disabled:opacity-50`} disabled={processando} onClick={salvarAlteracoes}>Salvar alterações</button>
                    <div className="home-comunidade" style={{ width: '100%' }}>
                        <div className="home-container19">
                            {data.map((item, index) => (
                                <div key={index} className="home-container20">
                                    <svg xmlns="http://www.w3.org/2000/svg" className={`${styles['excluir-item']} ${processando ? 'opacity-50' : ''}`} viewBox="0 0 24 24" onClick={() => removerItem(index)}>
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <path d="M18 6l-12 12"></path>
                                        <path d="M6 6l12 12"></path>
                                    </svg>
                                    <img
                                        alt="image163873"
                                        src={item.foto}
                                        className="home-image16"
                                    />
                                    <span className="home-text43">{item.titulo}</span>
                                    <span className="home-text44">{item.descricao}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TabelaComunidade;
