const ModalDeletarPlano = ({ plan_id = null, closeModal }) => {
    return (
        <div>
            <div className="fixed top-0 left-0 w-screen min-h-screen flex justify-center align-middle items-center z-40">
                <div className="w-full min-h-screen bg-neutral-950 opacity-95" onClick={() => closeModal()}></div>
                <div className="absolute w-11/12 md:w-1/2 rounded-lg bg-neutral-800 p-5 text-neutral-300 overflow-y-auto max-h-screen">
                    <div className="border-b border-neutral-700 py-5 space-y-2 flex justify-between items-center">
                        <img src='/main_logo.png' className='h-7' />
                        <button className="text-4xl hover:text-neutral-200 active:text-neutral-50" onClick={() => closeModal()}>&times;</button>
                    </div>
                    <div className="py-5 flex flex-col md:flex-row md:divide-x divide-neutral-700">

                        dipk
                    </div>
                    <div className="border-t border-neutral-700 py-5 flex flex-row justify-end items-center align-middle gap-x-3 select-none">

                        <button className="py-2 px-3 md:py-3 md:px-4 rounded-md text-xs md:text-sm bg-neutral-600 hover:bg-neutral-700 active:bg-neutral-800" onClick={() => closeModal()}>Cancelar</button>
                        <button className="py-2 px-3 md:py-3 md:px-4  rounded-md text-xs font-semibold md:text-sm bg-orange-600 hover:bg-orange-700 active:bg-orange-500 disabled:bg-neutral-800">{mode === 'create' ? 'Criar Plano' : 'Editar Plano'}</button>

                    </div>
                </div>
            </div>
        </div>
    )
}
export default ModalDeletarPlano