//features/usuario/usuarioAtual.js
import { createSlice } from '@reduxjs/toolkit';

const usuarioAtualSlice = createSlice({
  name: 'usuarioAtual',
  initialState: null,
  reducers: {
    setUsuario: (state,action) => {
      return action.payload
    }
  },
});

export const { setUsuario } = usuarioAtualSlice.actions;
export default usuarioAtualSlice.reducer;
