import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Helmet } from 'react-helmet'
import { useSelector, useDispatch } from 'react-redux';
import Header from '../components/header'
import Menu from '../components/menu';
import Footer from '../components/footer'
import Aside from '../components/aside'
import PopupAgendamento from '../components/popups/add_consultoria'
import './meus-cursos.css'


const MeusCursos = (props) => {
  const [cursos, setCursos] = useState([]);
  const [cursoInicial, setCursoInicial] = useState(0);
  const [agendamentoPopupVisible, setAgendamentoPopupVisible] = useState(false);

  const usuarioAtual = useSelector(state => state.usuarioAtual);
  const [showMenu, setShowMenu] = useState(window.innerWidth >= 1024);

  useEffect(() => {
    let isMounted = true

    const fetchCursos = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_BACKEND_BASE_URL + '/cursos?incMeusCursos=true', {
          params: {
            quantidade: 1,
            aPartirDe: 0,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        });

        if (isMounted) setCursos(response.data);
      } catch (error) {
      }
    };

    fetchCursos();

    return () => {
      isMounted = false
    }
  }, []);

  return (
    <div>
      <Header usuarioAtual={usuarioAtual} showMenu={showMenu} setShowMenu={setShowMenu} />
      <div className="meus-cursos-container">
        <Helmet>
          <title>Meus Treinamentos - Simbium</title>
          <meta
            property="og:title"
            content="Meus Treinamentos - Simbium"
          />
        </Helmet>
        <div className="meus-cursos-container1">
          {/*{window.outerWidth > 991 && (
            <Aside agendarConsultoria={() => setPopupAgendamentoVisible(true)}></Aside>
          )}*/}
          <div className="meus-cursos-conteudo l-cont">
            <div className="meus-cursos-meus-cursos">
              <div className="meus-cursos-container2">
                <svg viewBox="0 0 1024 1024" className="meus-cursos-icon">
                  <path d="M512 128l470 256v342h-86v-296l-384 210-470-256zM214 562l298 164 298-164v172l-298 162-298-162v-172z"></path>
                </svg>
                <span className="meus-cursos-text">Meus Treinamentos</span>
              </div>
              <div className="meus-cursos-container3">
                {cursos.cursos && (
                  cursos.cursos.map((curso, index) => (
                    <Link key={index} to={`/curso/${curso.slug}`} className="meus-cursos-card">
                      <div className="meus-cursos-capa">
                        <div className="meus-cursos-info-curso">
                          <span className="meus-cursos-text1">
                            {curso.nome}
                          </span>
                          <span className="meus-cursos-text2">{curso.aulas} aulas</span>
                        </div>
                        <img
                          alt="image"
                          src={curso.capa}
                          className="meus-cursos-image"
                        />
                      </div>
                      <div className="meus-cursos-progresso">
                        <div className="meus-cursos-container4">
                          <span className="meus-cursos-text3">Seu progresso</span>
                        </div>
                        <div className="meus-cursos-container5">
                          <div className="meus-cursos-container6">
                            <div className="meus-cursos-container7">
                              <div className="meus-cursos-container8"
                                style={{
                                  width: `${curso.progresso}%`,
                                  borderRadius: curso.progresso === 100 ? '10px' : '10px 0 0 10px'
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
        {agendamentoPopupVisible && (
          <PopupAgendamento erro={(e) => { toast.error(e) }} sucesso={() => toast.success("Consultoria agendada com sucesso!")} fecharPopup={() => setAgendamentoPopupVisible(false)} />
        )}
      </div>
      <Footer></Footer>
    </div>
  )
}

export default MeusCursos
