import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation, Link } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import { Chart } from 'chart.js/auto';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

import { setCurso } from '../features/curso/cursoAtual';
import Header from '../components/header';
import Menu from '../components/menu';
import Footer from '../components/footer';
import Aside from '../components/aside';
import ListaCursos from '../components/lista-cursos';
import PopupAgendamento from '../components/popups/add_consultoria';
import PopupHistoricoComissoes from '../components/popups/historico_comissoes';
import PopupShorts from '../components/popups/shorts';
import ListaAulasRecentes from '../components/lista-aulas-recentes';

const HomeClean = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const usuarioAtual = useSelector(state => state.usuarioAtual);
  const user = useSelector(state => state.usuarioAtual);
  const [showMenu, setShowMenu] = useState(window.innerWidth >= 1024);
  // States
  const [agendamentoPopupVisible, setAgendamentoPopupVisible] = useState(false);
  const [popupComissoesVisible, setPopupComissoesVisible] = useState(false);
  const [video, setVideo] = useState(props.videoFrame || '');
  const [isFrameLoaded, setFrameLoaded] = useState(false);
  const [menu, setMenu] = useState([
    {
      titulo: 'Destrave seu Negócio',
      descricao: 'Conteúdos e benefícios exclusivos para você destravar e ir além',
      opcoes: [
        {
          titulo: 'Automações',
          img_url: '/home-clean/clean-backgrounds/chave-mestra.png',
          link: '/master/chave-mestra',
          em_breve: false,
        },
        {
          titulo: 'Treinamento para Colaboradores',
          img_url: '/home-clean/clean-backgrounds/treinamento-para-colaboradores.png',
          link: '/master/treinamento-colaboradores',
          em_breve: false,
        },
      ],
    },
    {
      titulo: 'Seu negócio em outro nível',
      descricao: 'Temos ainda mais para te oferecer. Conteúdos de outro nível que vão levar o seu negócio à outro patamar.',
      opcoes: [
        {
          titulo: 'Conteúdo de Especialistas',
          img_url: '/home-clean/clean-backgrounds/conteudo-especialistas.png',
          link: '/master/conteudos-especialistas',
          em_breve: true,
        },
        {
          titulo: 'Sugestões de Leitura',
          img_url: '/home-clean/clean-backgrounds/be-a-ba-automacao.png',
          link: '/em-breve',
          em_breve: true,
        },
        {
          titulo: 'IA da Simbium',
          img_url: '/home-clean/clean-backgrounds/ia.png',
          link: '/ia',
          em_breve: true,
        },
        {
          titulo: 'Clube de Benefícios',
          img_url: '/home-clean/clean-backgrounds/clube-beneficios.png',
          link: '/em-breve',
          em_breve: true,
        },
      ],
    },
  ])
  const [marketingMetrics, setMarketingMetrics] = useState({});
  const [salesMetrics, setSalesMetrics] = useState({});
  const [managementMetrics, setManagementMetrics] = useState({});
  const [popupColaboradorVisible, setPopupColaboradorVisible] = useState(false);
  const [colaboradores, setColaboradores] = useState([]);
  const [nomeNovoColaborador, setNomeNovoColaborador] = useState('');
  const [emailNovoColaborador, setEmailNovoColaborador] = useState('');
  const [stepAgendamento, setStepAgendamento] = useState(1);
  const [tituloAgendamento, setTituloAgendamento] = useState('');
  const [descricaoAgendamento, setDescricaoAgendamento] = useState('');
  const [professores, setProfessores] = useState([]);
  const [selectedProfessor, setSelectedProfessor] = useState('');
  const [horariosDisponiveis, setHorariosDisponiveis] = useState([]);
  const [horarioSelecionado, setHorarioSelecionado] = useState('');
  const [agendamentoLoading, setAgendamentoLoading] = useState('');
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [pacotesVisible, setPacotesVisible] = useState(false);
  const [popupAgendamentoVisible, setPopupAgendamentoVisible] = useState(false);
  const [filtroPesquisa, setFiltroPesquisa] = useState('');
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [popupShortsVisible, setPopupShortsVisible] = useState([]);
  const [tags, setTags] = useState([]);
  const [shorts, setShorts] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [shouldLoadMore, setShouldLoadMore] = useState(false);
  const [showOpcoes, setShowOpcoes] = useState(true);
  const [loading, setLoading] = useState(false);

  // Refs
  const containerRef = useRef(null);
  const isMounted = useRef(true);
  const shortsBoxRef = useRef(null);
  const dropdownRef = useRef(null);

  // Utilidades
  const fetchData = async (pagina = currentPage, endpoint = process.env.REACT_APP_BACKEND_BASE_URL + '/aulas', params = {}) => {
    if (loading) return;

    setLoading(true);

    const filteredTags = tags.filter(t => t.checked).map(t => t.nome);

    const defaultParams = {
      skip: 0,
      limit: 5,
      shorts: true,
      busca: null,
      tags: null
    };

    try {
      const response = await axios.get(endpoint, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        },
        params: { ...defaultParams, ...params }
      });
      setShorts(response.data.aulas);
      setCurrentPage(0);
      setShouldLoadMore(false);

    } catch (error) {
      setShouldLoadMore(false);
    }

    setLoading(false);
  };


  // Handlers e Hooks
  useEffect(() => {
    // Video Boas Vindas
    const fetchVideoBoasVindas = async () => {
      try {
        const response = await fetchData(`${process.env.REACT_APP_BACKEND_BASE_URL}/config`);
        if (response && response.boas_vindas && response.boas_vindas.url) {
          setVideo(response.boas_vindas.url);
        }
      } catch (error) {
        props.fecharPopup();
      }
    };

    if (location.pathname.includes('/admin')) {
      props.fecharPopup();
    }

    fetchVideoBoasVindas();

    return () => {
      isMounted.current = false;
    };
  }, [location.pathname]);

  useEffect(() => {
    if (!containerRef.current || isFrameLoaded) return;

    const iframeElement = containerRef.current.querySelector('iframe');
    if (!iframeElement) return;

    const handleLoad = () => {
      setFrameLoaded(true);
    };

    iframeElement.addEventListener('load', handleLoad);

    return () => {
      iframeElement.removeEventListener('load', handleLoad);
    };
  }, [video, isFrameLoaded]);

  useEffect(() => {
    const opcoesDados = JSON.parse(localStorage.getItem('opcoesDados'));

    if (!opcoesDados) {
      setShowOpcoes(true)
      return
    }

    const expirationDate = new Date(opcoesDados.date).getTime();
    const currentDate = new Date().getTime();

    if (expirationDate < currentDate) {
      setShowOpcoes(true);
    }

  }, []);


  const closeOptions = () => {
    setShowOpcoes(false);

    const expirationDate = new Date();
    expirationDate.setHours(23, 59, 59, 59);

    const opcoesDados = {
      date: expirationDate
    };

    localStorage.setItem('opcoesDados', JSON.stringify(opcoesDados));
  };



  useEffect(() => {
    // Fetch Main Data
    const fetchMainData = async () => {
      try {
        const exampleMarketingData = {
          cost: 5000,
          ctr: 0.05,
          conversionRate: 0.1,
          cpa: 50,
          cpl: 20,
          roas: 2.5,
        };
        const exampleSalesData = {
          cac: 100,
          roi: 150,
          meetingsScheduled: 50,
          meetingsHeld: 40,
          meetingsClosed: 20,
          conversionRate: 0.5,
          salesBySalesperson: [
            { name: 'John', percentage: 40 },
            { name: 'Emma', percentage: 60 },
          ],
          totalRevenue: 10000,
        };
        const exampleManagementData = {
          ltv: 500,
          roi: 0.3,
          netProfit: 3000,
        };

        setMarketingMetrics(exampleMarketingData);
        setSalesMetrics(exampleSalesData);
        setManagementMetrics(exampleManagementData);
      } catch (error) {
        console.error('Error fetching main data:', error);
      }
    };

    fetchMainData();
  }, []);

  const fetchColaboradores = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/usuarios/meus-colaboradores`,
        {
          params: {
            usuarioID: usuarioAtual._id,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );

      if (response.data) {
        setColaboradores(response.data); // Assumindo que response.data é o array de colaboradores
      }
    } catch (error) {
      // Verificar se o componente ainda está montado antes de atualizar o estado
      if (!isMounted.current) {
        return;
      }
      setLoading(false);
    }
  };



  useEffect(() => {
    fetchColaboradores();
  }, []);

  const adicionarColaborador = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/usuarios/adicionar-colaborador`,
        {
          nome: nomeNovoColaborador,
          email: emailNovoColaborador,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );

      if (response.data && response.data.message) {
        toast.success(response.data.message);
        setPopupColaboradorVisible(false);
        setNomeNovoColaborador('');
        setEmailNovoColaborador('');

        const usuario = {
          ...usuarioAtual,
          creditos: usuarioAtual.creditos - 80,
        };

        // dispatch(setUsuario(usuario));
        fetchColaboradores();

        if (
          !usuarioAtual.onboarding ||
          !usuarioAtual.onboarding.etapas ||
          !usuarioAtual.onboarding.etapas.includes('adicionar-time')
        ) {
          onboardingColaboradores();
        }
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message)
        toast.error(error.response.data.message);
    }
  };

  const deletarColaborador = async (colaboradorEmail, colaboradorNome) => {
    if (!confirm("Tem certeza que deseja excluir o usuário " + colaboradorNome + "? Esta ação não pode ser desfeita!")) return;
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/usuarios/deletar-colaborador/${colaboradorEmail}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );

      if (response.data && response.data.message) {
        toast.success(response.data.message);


        const usuario = {
          ...usuarioAtual,
          creditos: usuarioAtual.creditos + 80,
        };

        // dispatch(setUsuario(usuario));

        // Atualizar o estado ou recarregar a lista de colaboradores após a exclusão
        fetchColaboradores();
        // setColaboradores(colaboradores.filter(colaborador => colaborador.email !== colaboradorEmail));
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      }
    }
  };

  useEffect(() => {
    const fetchProfessores = async () => {
      console.log("VAMOS PEGAR OS CONSULTS")
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/usuarios/professores?consultores=true`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        );
        setProfessores(response.data);
        console.log(professores)
      } catch (error) {
        // Handle error
      }
    };

    fetchProfessores();
  }, []);

  const handleProfessorChange = (professorId) => {
    if (selectedProfessor === professorId) {
      setSelectedProfessor('')
    } else {
      setSelectedProfessor(professorId);
      setStepAgendamento(2)
    }

    if (professorId === '') {
      setHorariosDisponiveis([], '')
    } else {
      getHorariosDisponiveis(selectedDate, professorId)
    }
  };

  const handleDateChange = date => {
    setSelectedDate(date);
    setHorarioSelecionado('')
    if (selectedProfessor !== '') {
      getHorariosDisponiveis(date, selectedProfessor)
    }
  };

  const handleHorarioChange = (newHorario) => {
    if (newHorario !== '') {
      setHorarioSelecionado(newHorario);
      setStepAgendamento(3)
    }
  }

  const formatSelectedDate = date => {
    const daysOfWeek = [
      'Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira',
      'Quinta-feira', 'Sexta-feira', 'Sábado'
    ];
    const months = [
      'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
      'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
    ];

    const day = daysOfWeek[date.getDay()];
    const dayOfMonth = date.getDate();
    const month = months[date.getMonth()];

    return `${day}, ${dayOfMonth} de ${month}`;
  };

  const formattedDate = formatSelectedDate(selectedDate);

  const professorTemHorarioDisponivel = date => {
    if (!selectedProfessor) return false;
    const professor = professores.find(p => p._id === selectedProfessor);
    if (!professor) return false;
    const dayOfWeek = date.getDay();
    const weekdays = ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado'];
    const disponibilidade = professor.horariosDisponiveis.find(h => h.diaSemana === weekdays[dayOfWeek]);
    return !!disponibilidade && disponibilidade.horarios.length > 0;
  };


  const getHorariosDisponiveis = async (date, professorId) => {
    const data = new Date(date)
    const dia = data.getDate();
    const mes = data.getMonth() + 1;
    const ano = data.getFullYear();

    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/usuarios/horarios-disponiveis?dia=${dia}&mes=${mes}&ano=${ano}&professorId=${professorId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      });
      setHorariosDisponiveis(response.data)
    } catch (err) {
    }
  }

  const agendarConsultoria = async () => {
    const data = new Date(selectedDate)
    const dia = data.getDate();
    const mes = data.getMonth();
    const ano = data.getFullYear();
    const hora = horarioSelecionado.split(":")[0]
    const minuto = horarioSelecionado.split(":")[1]
    const professorId = selectedProfessor
    const titulo = tituloAgendamento;
    const descricao = descricaoAgendamento;

    try {
      setAgendamentoLoading(true)
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/agendamentos`, {
        data: new Date(ano, mes, dia, hora, minuto, 0, 0).toISOString(),
        professor: professorId,
        titulo,
        descricao
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      })

      setAgendamentoLoading(false)
      props.fecharPopup()
      props.sucesso()
      dispatch(setUsuario({
        ...usuarioAtual,
        consultorias: {
          ...usuarioAtual.consultorias,
          cota: usuarioAtual.consultorias.cota - 1
        }
      }));

      if (!usuarioAtual.onboarding || !usuarioAtual.onboarding.etapas || !usuarioAtual.onboarding.etapas.includes("agendar-consultoria")) {
        onboardingConsultoria()
      }

    } catch (err) {
      setAgendamentoLoading(false)
      props.fecharPopup()
      if (err && err.response && err.response.data && err.response.data.error) {
        props.erro(err.response.data.error)
      } else {
        props.erro("Algo deu errado")
      }
    }
  }

  const handleClickShort = async short => {
    const currentIndex = shorts.findIndex(s => s._id === short._id);
    const prevIndex = currentIndex > 0 ? currentIndex - 1 : null;
    const nextIndex = currentIndex < 5 ? currentIndex + 1 : null;

    const popupShorts = [
      prevIndex !== null ? shorts[prevIndex] : '',
      short,
      nextIndex !== null ? shorts[nextIndex] : '',
    ].filter(Boolean);

    setPopupShortsVisible(popupShorts);
  };

  const handleOutsideClick = event => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  useEffect(() => {
    if (filtroPesquisa || tags.length > 0) {
      fetchData(0);
    }
  }, [filtroPesquisa, tags]);

  useEffect(() => {
    const fetchUniqueTags = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_BACKEND_BASE_URL + '/aulas/tags-shorts', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        });

        if (response.data && response.data.length > 0) {
          const newTags = response.data.map(nt => ({ nome: nt, checked: false }));
          setTags(newTags);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchUniqueTags();
  }, []);



  return (
    <div>
      <Header></Header>
      <div className="gap-1 w-full flex flex-col min-h-screen overflow-y-hidden items-start bg-neutral-900">
        <Helmet>
          <title>Simbium</title>
          <meta property="og:title" content="Simbium" />
        </Helmet>

        <section className="w-full mt-[100px] bg-gradient-to-t from-neutral-900 to-transparent space-y-5">

          <section className='w-11/12 m-auto flex flex-col md:flex-row justify-between space-y-5 md:space-y-0 md:space-x-5'>
            {/* Métricas de Marketing */}
            <div className="bg-neutral-800 text-white shadow-xl rounded-lg p-0 w-full md:w-1/3 overflow-hidden">
              <div className='my-3 mx-5 lg:m-6'>
                <h2 className="text-lg lg:text-2xl font-semibold mb-1 lg:mb-4">Métricas de Marketing</h2>
                <p className="text-2xl lg:text-6xl font-bold mb-1 lg:mb-4">R$ {marketingMetrics.cost}</p>
                <div>
                  <p className="text-sm lg:text-lg mb-2">Custo com Marketing</p>
                  <div className="flex justify-between">
                    <div>
                      <p className="text-sm lg:text-lg font-semibold">CTR</p>
                      <p className="text-lg lg:text-2xl font-bold">{(marketingMetrics.ctr * 100).toFixed(2)}%</p>
                    </div>
                    <div>
                      <p className="text-sm lg:text-lg font-semibold">ROAS</p>
                      <p className="text-lg lg:text-2xl font-bold">{marketingMetrics.roas}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='text-center text-orange-500 hover:bg-neutral-900 active:bg-neutral-700 select-none cursor-pointer p-1 lg:p-3 text-sm lg:text-lg'>Atualizar dados</div>
            </div>



            {/* Métricas de Vendas */}
            <div className="bg-neutral-800 text-white shadow-xl rounded-lg w-full md:w-1/3 overflow-hidden">
              <div className='m-6'>
                <h2 className="text-2xl font-semibold mb-4">Métricas de Vendas</h2>
                <p className="text-6xl font-bold mb-4">R$ {salesMetrics.totalRevenue}</p>
                <p className="text-lg mb-2">Faturamento Total</p>
                <div className="flex justify-between">
                  <div>
                    <p className="text-lg font-semibold">CAC</p>
                    <p className="text-2xl font-bold">R$ {salesMetrics.cac}</p>
                  </div>
                  <div>
                    <p className="text-lg font-semibold">ROI</p>
                    <p className="text-2xl font-bold">{salesMetrics.roi}%</p>
                  </div>
                </div>
              </div>
              <div className='text-center bg-neutral-950 text-orange-500 hover:bg-neutral-900 active:bg-neutral-700 select-none cursor-pointer p-3 text-lg'>Atualizar dados</div>
            </div>

            {/* Métricas de Gestão */}
            <div className="bg-neutral-800 text-white shadow-xl rounded-lg w-full md:w-1/3 overflow-hidden">
              <div className='m-6'>
                <h2 className="text-2xl font-semibold mb-4">Métricas de Gerência</h2>
                <p className="text-6xl font-bold mb-4">R$ {managementMetrics.netProfit}</p>
                <p className="text-lg mb-2">Lucro Líquido</p>
                <div className="flex justify-between">
                  <div>
                    <p className="text-lg font-semibold">LTV</p>
                    <p className="text-2xl font-bold">R$ {managementMetrics.ltv}</p>
                  </div>
                  <div>
                    <p className="text-lg font-semibold">ROI</p>
                    <p className="text-2xl font-bold">{managementMetrics.roi}%</p>
                  </div>
                </div>
              </div>
              <div className='text-center bg-neutral-950 text-orange-500 hover:bg-neutral-900 active:bg-neutral-700 select-none cursor-pointer p-3 text-lg'>Atualizar dados</div>
            </div>
          </section>
          <section className='w-11/12 m-auto flex flex-col xl:flex-row justify-between space-y-5 xl:space-y-0 xl:space-x-5'>
            {/*<section className='w-full xl:w-1/2'>
              <div className="meus-colaboradores-colaboradores">
                <div className="meus-colaboradores-container06">
                  <div className="meus-colaboradores-container07">
                    <div className="meus-colaboradores-container08">
                      <span className="meus-colaboradores-text09">Nome</span>
                      <span className="meus-colaboradores-text10">E-mail</span>
                      <span className="meus-colaboradores-text11">Acesso</span>
                      <span className="meus-colaboradores-header-deletar">Deletar</span>
                    </div>
                    <div className="meus-colaboradores-infos">
                      {colaboradores.length > 0 ? (
                        colaboradores.map((colaborador, index) => (
                          <div key={index} className="meus-colaboradores-container09">
                            <span className="meus-colaboradores-text12">
                              {colaborador.nome}
                            </span>
                            <span className="meus-colaboradores-text13">
                              {colaborador.email}
                            </span>
                            <span className="meus-colaboradores-text14">
                              {colaborador.acesso}
                            </span>
                            <span onClick={() => deletarColaborador(colaborador.email, colaborador.nome)} className="meus-colaboradores-body-deletar">
                              Deletar
                            </span>
                          </div>

                        ))

                      ) : (

                        <div className="meus-colaboradores-infos1">
                          <span className="meus-colaboradores-text15">
                            Nenhum colaborador adicionado
                          </span>
                        </div>

                      )}

                    </div>
                  </div>
                  <p className='w-full text-center text-white bg-neutral-950 rounded-b-xl py-1 hover:bg-orange-800 active:bg-orange-600 cursor-pointer select-none' onClick={() => setPopupColaboradorVisible(true)}>Adicionar Novo Colaborador</p>
                </div>

              </div>
                    </section>*/}
            <section className='w-full xl:w-1/2 overflow-auto'>
              <table className="w-full">
                <thead>
                  <tr>
                    <th className="text-white font-semibold">Nome</th>
                    <th className="text-white font-semibold">E-mail</th>
                    <th className="text-white font-semibold">Acesso</th>
                    <th className="text-white font-semibold">Deletar</th>
                  </tr>
                </thead>
                <tbody>
                  {colaboradores.length > 0 ? (
                    colaboradores.map((colaborador, index) => (
                      <tr key={index}>
                        <td className="text-white">{colaborador.nome}</td>
                        <td className="text-white">{colaborador.email}</td>
                        <td className="text-white">{colaborador.acesso}</td>
                        <td className="text-red-500 cursor-pointer" onClick={() => deletarColaborador(colaborador.email, colaborador.nome)}>Deletar</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="4" className="text-center text-white">Nenhum colaborador adicionado</td>
                    </tr>
                  )}
                </tbody>
              </table>
              <p className='w-full text-center text-white bg-neutral-950 my-5 lg:my-0 lg:rounded-b-xl py-1 hover:bg-orange-800 active:bg-orange-600 cursor-pointer select-none' onClick={() => setPopupColaboradorVisible(true)}>Adicionar Novo Colaborador</p>
            </section>

            <section className='w-full xl:w-1/2'>
              {usuarioAtual.consultorias && usuarioAtual.consultorias.cota && usuarioAtual.consultorias.cota > 0
                && usuarioAtual.consultorias.expiracao && new Date(usuarioAtual.consultorias.expiracao) > new Date() && !pacotesVisible ? (
                <div id="agendamento" style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', gap: '30px' }}>

                  {stepAgendamento === 1 && (
                    <div className="home-container43 bg-neutral-950 p-5 rounded-lg h-full">
                      <h3 className="title">Selecione qual time: Marketing, Vendas ou Gestão.</h3>
                      <div className="professores h-full">
                        {professores.length > 0 ? (
                          professores.map((professor, index) => (
                            <div key={index} className="professor"
                              style={selectedProfessor === professor._id ? { border: '5px solid #E86817' } : {}}
                              onClick={() => { handleProfessorChange(professor._id) }}
                            >
                              <img className='w-full rounded-md hover:border-2 boder-orange-900' src={professor.foto_perfil ? professor.foto_perfil : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png'} />
                              <div className="dados">
                                <h4>{professor.nome}</h4>
                                {professor.area && (
                                  <h5>{professor.area}</h5>
                                )}
                              </div>
                            </div>
                          ))
                        ) :
                          <p className='text-gray-500 h-full'>Não há consultores cadastrados no momento!</p>
                        }
                      </div>
                    </div>
                  )}
                  {stepAgendamento === 2 && (
                    <div className=" bg-neutral-950 p-5 rounded-lg h-full flex flex-col gap-5">
                      <span className="home-text87">Escolha a melhor Data e Horário</span>
                      <div className="home-container52">
                        <Calendar
                          value={selectedDate}
                          onChange={handleDateChange}
                          minDate={new Date()}
                          tileClassName={({ date, view }) => {
                            if (view === 'month' && professorTemHorarioDisponivel(date)) {
                              return 'day-with-availability';
                            }
                          }}
                        />
                        <div className="home-data">
                          <span className="home-text88">{formattedDate}</span>
                          <div className="home-horrios">
                            {horariosDisponiveis.length > 0 ? (
                              horariosDisponiveis.map((h, index) => (
                                <span
                                  key={index}
                                  className={`home-text89 ${horarioSelecionado === h ? 'active' : ''}`}
                                  onClick={() => handleHorarioChange(h)}
                                >
                                  {h}
                                </span>
                              ))
                            ) : (
                              selectedProfessor ? (
                                <span className="text-less">Nenhum horário disponível para a data selecionada</span>
                              ) : (
                                <span className="text-less">Selecione um professor para ver os horários</span>)
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {stepAgendamento === 3 && (
                    <div style={{ width: '100%' }}>
                      <div className="agendamento-detalhes bg-neutral-950 p-5 rounded-lg h-full">
                        <h3>Agendamento:</h3>
                        <div className="data-agendamento">
                          <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-calendar-time" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <path d="M11.795 21h-6.795a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v4"></path>
                            <path d="M18 18m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0"></path>
                            <path d="M15 3v4"></path>
                            <path d="M7 3v4"></path>
                            <path d="M3 11h16"></path>
                            <path d="M18 16.496v1.504l1 1"></path>
                          </svg>
                          <div className="data-selecionada">
                            <span>{selectedDate.toLocaleDateString('pt-br')}</span>
                            <span>às {horarioSelecionado}h</span>
                          </div>
                          <svg style={{ cursor: 'pointer' }} onClick={() => setStepAgendamento(2)} xmlns="http://www.w3.org/2000/svg" className="ionicon" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M364.13 125.25L87 403l-23 45 44.99-23 277.76-277.13-22.62-22.62zM420.69 68.69l-22.62 22.62 22.62 22.63 22.62-22.63a16 16 0 000-22.62h0a16 16 0 00-22.62 0z" /></svg>
                        </div>

                        <div className="info-adicional">
                          <h4>Você quer ajuda sobre:</h4>
                          <input type="text" value={tituloAgendamento} onChange={(e) => setTituloAgendamento(e.target.value)} />
                          <textarea value={descricaoAgendamento} onChange={(e) => setDescricaoAgendamento(e.target.value)}></textarea>
                        </div>

                        {selectedDate && selectedProfessor && horarioSelecionado && (
                          <button className={`w-full bg-orange-500 text-white hover:bg-orange-700 active:bg-orange-900 p-2 rounded-lg ${agendamentoLoading ? 'loading' : ''}`} onClick={agendarConsultoria}>{agendamentoLoading ? 'Aguarde...' : 'Concluir Agendamento'}</button>
                        )}
                      </div>
                    </div>
                  )}
                  {stepAgendamento > 1 &&
                    <div className='w-full text-center text-white hover:text-orange-500 active:text-orange:800 cursor-pointer' onClick={() => { setStepAgendamento(stepAgendamento > 1 ? stepAgendamento - 1 : 1); setSelectedProfessor(''); }}>Voltar à etapa anterior</div>
                  }
                </div>
              ) : (<div style={{ display: 'none' }}></div>)}
            </section>
          </section>

          {/**SHORTS */}


          <section className='w-11/12 mx-auto pt-10 space-y-10'>
            <div className='my-5'>
              <div className='flex flex-row items-center align-middle space-x-5'>
                <svg viewBox="0 0 1024 1024" className="h-9 fill-current text-white">
                  <path d="M406.286 644.571l276.571-142.857-276.571-144.571v287.429zM512 152c215.429 0 358.286 10.286 358.286 10.286 20 2.286 64 2.286 102.857 43.429 0 0 31.429 30.857 40.571 101.714 10.857 82.857 10.286 165.714 10.286 165.714v77.714s0.571 82.857-10.286 165.714c-9.143 70.286-40.571 101.714-40.571 101.714-38.857 40.571-82.857 40.571-102.857 42.857 0 0-142.857 10.857-358.286 10.857v0c-266.286-2.286-348-10.286-348-10.286-22.857-4-74.286-2.857-113.143-43.429 0 0-31.429-31.429-40.571-101.714-10.857-82.857-10.286-165.714-10.286-165.714v-77.714s-0.571-82.857 10.286-165.714c9.143-70.857 40.571-101.714 40.571-101.714 38.857-41.143 82.857-41.143 102.857-43.429 0 0 142.857-10.286 358.286-10.286v0z"></path>
                </svg>
                <h2 className='text-2xl md:text-4xl md:mb-2 text-white'>Shorts</h2>
              </div>
              <p className='text-sm md:text-lg text-gray-400'>Aulas direto ao ponto em vídeos de até 90 segundos para te ajudar a destravar assuntos específicos!</p>
            </div>
            <div className="w-full grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-3">
              {shorts && shorts.length > 0 && shorts.map((short) => (
                <div className="border-2 border-transparent hover:border-orange-500 hover:scale-105 transition-all rounded-md cursor-pointer overflow-hidden" key={short._id} onClick={() => handleClickShort(short)}>
                  <img src={short.capa} />
                </div>
              ))}
            </div>
            <div className='w-full text-center'>
              <a href='/shorts' className='text-neutral-500 hover:text-orange-500 mx-auto text-center px-5 py-2 rounded-full border border-neutral-500 hover:border-orange-500 mt-10'>Ver mais</a>
            </div>
          </section>
          {/**FIM SHORTS */}




          <section className="w-full flex flex-col pb-20">
            {menu.map((secao, index_secao) => (
              <div className='w-11/12 mx-auto my-5' key={index_secao}>
                <div className='my-5'>
                  <h2 className='text-2xl md:text-4xl md:mb-2 text-white'>{secao.titulo}</h2>
                  <p className='text-sm md:text-lg text-gray-400'>{secao.descricao}</p>
                </div>
                <div className='grid grid-cols-2 gap-2 sm:grid-cols-3 sm:gap-3 md:grid-cols-4 md:gap-4 2xl:grid-cols-5 2xl:gap-5 mt-7'>
                  {secao.opcoes.map((pagina, index_pagina) => (
                    <div className={`p-2 h-[60vw] sm:h-[45vw] md:h-[30vw] 2xl:h-[25vw] relative bg-gray-800 rounded-lg overflow-hidden transform transition-transform duration-100 ease-in-out ${pagina.em_breve ? 'grayscale cursor-default' : 'cursor-pointer'} hover:scale-105 hover:border-orange-500 border-2 border-gray-800 bg-cover bg-no-repeat bg-center`} style={{ backgroundImage: `url('${pagina.img_url}')` }} key={index_pagina} title={pagina.titulo} onClick={() => {
                      if (pagina.link === '') {
                        setAgendamentoPopupVisible(true);
                      } else if (pagina.link.includes('http')) {
                        window.open(pagina.link, '_blank');
                      } else if (!pagina.em_breve) {
                        history.push(pagina.link);
                      } else {
                        console.log('Em breve');
                      }
                    }}>
                      <div className={`absolute inset-0 bg-black opacity-70 z-0`}></div>
                      <div className='border-2 border-gray-200 h-full m-auto p-2 relative text-gray-200 text-center font-extrabold flex items-center justify-center text-md md:text-sm lg:text-3xl'>{pagina.titulo}</div>
                      <span className={`w-full ${pagina.em_breve ? 'p-2' : ''} text-center bg-orange-500 absolute bottom-0 inset-x-0 text-white text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl`}>{pagina.em_breve ? usuarioAtual.trial ? 'Em breve (conteúdo exclusivo)' : 'Em breve' : ''}</span>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </section>

          <section className="flex flex-col items-center space-y-10 m-5 p-5 md:m-0 bg-neutral-950 py-14 rounded-lg" id="indicacao">
            <div className="flex gap-8 items-center flex-row">
              <svg viewBox="0 0 877.7142857142857 1024" className="fill-white w-10 h-10">
                <path d="M530.286 774.857v-409.143h-182.857v409.143c0 20 16.571 29.714 36.571 29.714h109.714c20 0 36.571-9.714 36.571-29.714zM269.714 292.571h111.429l-72-92c-6.286-7.429-20-17.714-39.429-17.714-30.286 0-54.857 24.571-54.857 54.857s24.571 54.857 54.857 54.857zM662.857 237.714c0-30.286-24.571-54.857-54.857-54.857-19.429 0-33.143 10.286-39.429 17.714l-71.429 92h110.857c30.286 0 54.857-24.571 54.857-54.857zM877.714 384v182.857c0 10.286-8 18.286-18.286 18.286h-54.857v237.714c0 30.286-24.571 54.857-54.857 54.857h-621.714c-30.286 0-54.857-24.571-54.857-54.857v-237.714h-54.857c-10.286 0-18.286-8-18.286-18.286v-182.857c0-10.286 8-18.286 18.286-18.286h251.429c-70.857 0-128-57.143-128-128s57.143-128 128-128c38.286 0 73.714 16 96 44l73.143 94.286 73.143-94.286c22.286-28 57.714-44 96-44 70.857 0 128 57.143 128 128s-57.143 128-128 128h251.429c10.286 0 18.286 8 18.286 18.286z"></path>
              </svg>
              <span className="text-white text-xl sm:text-2xl 2xl:text-4xl">Indique e Ganhe</span>
            </div>
            <div className="flex flex-col gap-30 items-center justify-center">
              <div className="flex flex-col gap-15 items-center justify-center">
                <div className="flex flex-row items-center justify-center">
                  <img alt="image244711" src="/external/image244711-bwb-200h.png" className="w-24" />
                  <div className="flex flex-col items-center justify-center align-middle">
                    <span className="text-orange-500 text-4xl">{user.creditos}</span>
                    <span className="text-white">Seus créditos</span>
                  </div>
                </div>
                {false && (
                  <span className="text-white text-sm">
                    <span className="text-gray-600">Próximo prêmio:</span>
                    <span className="text-orange-500"> 100 créditos</span>
                  </span>
                )}
              </div>
              <span className="text-white text-lg text-center mt-10">
                Indique nossa plataforma para seus amigos com o link abaixo e garanta presentes exclusivos.
              </span>
              <div className="flex flex-col gap-15 items-center justify-center">
                <div className="flex flex-row divide-x-2 border-2 my-5 border-dashed border-gray-500 p-2 rounded-lg">
                  <span className="text-white text-md break-all p-5">
                    {`${window.location.origin}/indicacao/${user._id}`}
                  </span>
                  <button className="text-white text-sm p-5" onClick={() => copyToClipboard(`${window.location.origin}/indicacao/${user._id}`)}>Copiar link</button>
                </div>
                <span className="text-white text-sm text-center">
                  <span className="text-gray-600">Cada indicação =</span>
                  <span className="text-orange-500"> 1 crédito e mais <strong>R$ 500,00</strong>*</span>
                  <p style={{ marginTop: '8px', fontSize: '13px', color: "#9e9e9e" }}>*Premiação em dinheiro <strong>APENAS</strong> se a pessoa que você indicou <strong>se tornar um assinante</strong>*</p>
                </span>
              </div>
            </div>
            <div className="flex flex-row gap-12 md:gap-24 items-center justify-center">
              <div className="flex gap-3 cursor-pointer transition duration-300 items-center p-3 rounded-full bg-stone-500 font-bold" onClick={() => { if (usuarioAtual.totalComissoes > 0) { setPopupComissoesVisible(true) } }} style={usuarioAtual.totalComissoes > 0 ? { background: '#0cda07' } : {}}>
                <svg className="fill-white w-5 h-5" style={usuarioAtual.totalComissoes > 0 ? { fill: '#fff' } : {}} viewBox="0 0 512 512"><path d="M341.28 22.78L254 123.75l63.156-33.656 4.438-2.375 4.437 2.405 18.314 9.938-3.063-77.282zM129.814 46.563l24.375 130.407 88.718-47.282-113.094-83.125zM321.53 108.97L83.345 235.905 192.156 293.5l237.47-125.75-108.095-58.78zm42.75 44.686l25.595 13.03-197.313 103.94-66.875-35.688 25.344-13.407 41.532 22.19 171.72-90.064zm92.19 18.22l9.092 24.03 26.22-22.656-35.313-1.375zm-15.783 11.155L199.47 310.78l-5.782 83.376L465.75 249.344l-25.063-66.313zm-334.562 19.564L16.562 226.78l36.876 19.564 6.093-16.28 1.25-3.314 3.126-1.656 42.22-22.5zm-34.438 48.312l-28.812 77 131.844 70.156 6.155-89.375-109.188-57.78zm335.344 50.875l-174.25 92.75 54.907 103.44L325.5 358.093l142.47 26.625-60.94-82.94zm-300.717 81.064l-19.438 44.812L144 402.876l-37.688-20.032z" /></svg>
                <span className="text-white text-sm">Comissões em Dinheiro <em style={usuarioAtual.totalComissoes > 0 ? { fontSize: '14px', color: 'rgb(8 127 10)' } : { fontSize: '14px', color: '#9e9e9e' }}>(R$ {usuarioAtual.totalComissoes ? usuarioAtual.totalComissoes.toLocaleString('pt-br') : '0'},00)</em></span>
              </div>
            </div>
          </section>
        </section>


        {/** POPUPS */}
        {popupColaboradorVisible && (
          <div className="meus-colaboradores-popup-agendamento">
            <div className="meus-colaboradores-conteudo1">
              <div className="meus-colaboradores-container11">
                <Link to="/" className="meus-colaboradores-navlink">
                  <img
                    alt="image"
                    src="/image9-200h.png"
                    className="meus-colaboradores-logo"
                  />
                </Link>
                <span className="meus-colaboradores-text19">
                  Adicionar colaborador
                </span>
              </div>
              <div className="meus-colaboradores-inputs">
                <div className="meus-colaboradores-container12">
                  <svg
                    viewBox="0 0 731.4285714285713 1024"
                    className="meus-colaboradores-icon2"
                  >
                    <path d="M731.429 799.429c0 83.429-54.857 151.429-121.714 151.429h-488c-66.857 0-121.714-68-121.714-151.429 0-150.286 37.143-324 186.857-324 46.286 45.143 109.143 73.143 178.857 73.143s132.571-28 178.857-73.143c149.714 0 186.857 173.714 186.857 324zM585.143 292.571c0 121.143-98.286 219.429-219.429 219.429s-219.429-98.286-219.429-219.429 98.286-219.429 219.429-219.429 219.429 98.286 219.429 219.429z"></path>
                  </svg>
                  <input
                    type="text"
                    placeholder="Nome"
                    value={nomeNovoColaborador}
                    onChange={(e) => setNomeNovoColaborador(e.target.value)}
                    className="meus-colaboradores-textinput input"
                  />
                </div>
                <div className="meus-colaboradores-container13">
                  <svg viewBox="0 0 1024 1024" className="meus-colaboradores-icon4">
                    <path d="M854 342v-86l-342 214-342-214v86l342 212zM854 170q34 0 59 26t25 60v512q0 34-25 60t-59 26h-684q-34 0-59-26t-25-60v-512q0-34 25-60t59-26h684z"></path>
                  </svg>
                  <input
                    type="text"
                    placeholder="Email"
                    value={emailNovoColaborador}
                    onChange={(e) => setEmailNovoColaborador(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        adicionarColaborador()
                      }
                    }}
                    className="meus-colaboradores-textinput1 input"
                  />
                </div>
              </div>
              <div className="meus-colaboradores-container14">
                <div className="meus-colaboradores-b-entrar" onClick={() => { adicionarColaborador() }}>
                  <svg
                    viewBox="0 0 804.5714285714286 1024"
                    className="meus-colaboradores-icon6"
                  >
                    <path d="M804.571 420.571v109.714c0 30.286-24.571 54.857-54.857 54.857h-237.714v237.714c0 30.286-24.571 54.857-54.857 54.857h-109.714c-30.286 0-54.857-24.571-54.857-54.857v-237.714h-237.714c-30.286 0-54.857-24.571-54.857-54.857v-109.714c0-30.286 24.571-54.857 54.857-54.857h237.714v-237.714c0-30.286 24.571-54.857 54.857-54.857h109.714c30.286 0 54.857 24.571 54.857 54.857v237.714h237.714c30.286 0 54.857 24.571 54.857 54.857z"></path>
                  </svg>
                  <span className="meus-colaboradores-text20">Adicionar</span>
                </div>
                <span className="meus-colaboradores-text21">
                  <span onClick={() => setPopupColaboradorVisible(false)}>
                    Cancelar
                  </span>
                </span>
              </div>
            </div>
          </div>
        )}
        {
          agendamentoPopupVisible && (
            <PopupAgendamento erro={(e) => { toast.error(e) }} sucesso={() => toast.success("Consultoria agendada com sucesso!")} fecharPopup={() => setAgendamentoPopupVisible(false)} />
          )
        }
        {popupShortsVisible && popupShortsVisible.length > 0 && (
          <PopupShorts
            video={popupShortsVisible}
            handleClickShort={(e) => {
              handleClickShort(e)
            }}
            fecharPopup={() => setPopupShortsVisible([])}
          />
        )}
        {
          popupComissoesVisible && (
            <PopupHistoricoComissoes indicacoes={usuarioAtual.indicacoes} totalComissoes={usuarioAtual.totalComissoes} fecharPopup={() => setPopupComissoesVisible(false)} />
          )
        }
        {/** FIM POPUPS */}
      </div >
      <Footer></Footer>
    </div >
  )
}

export default HomeClean
