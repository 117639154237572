import { CheckIcon } from "@heroicons/react/24/outline";
import React, { useState, useEffect } from 'react';

const RadioList = ({ value, onChange, isRequired, paginaQuestao }) => {
    const sizes = ["w-20 h-20", "w-14 h-14", "w-10 h-10", "w-14 h-14", "w-20 h-20"];
    const [error, setError] = useState('');

    const handleInput = (val) => {
        const isValid = !isRequired || val

        setError(isValid ? '' : 'Selecione uma opção');
        onChange(val, isValid);
    }

    useEffect(() => {
        const isValid = !isRequired || value
        console.log("REQUIRED? ", isRequired)
        console.log("VALUE ", value)
        setError(isValid ? '' : 'Selecione uma opção');
        onChange(value, isValid);
    }, [paginaQuestao])

    return (
        <div className="flex justify-center space-x-4 items-center">
            <p className="text-xs text-neutral-600 text-right">Absolutamente não</p>
            {[1, 2, 3, 4, 5].map((weight, index) => (
                <label key={index} className="flex flex-col items-center space-y-2 cursor-pointer">
                    <input
                        type="radio"
                        id={`radio${index + 1}`}
                        name="radioList"
                        className="hidden"
                        checked={value === weight}
                        onChange={() => handleInput(weight)}
                    />
                    <div
                        className={`text-white font-bold ${sizes[index]} ring-1 rounded-full flex items-center justify-center ${value === weight ? "bg-orange-500 ring-orange-500" : "ring-neutral-600 hover:bg-neutral-600 active:bg-neutral-700"}`}
                    >
                        {value === weight &&
                            <CheckIcon className="w-4 h-4" />
                        }
                    </div>
                </label>
            ))}
            <p className="text-xs text-neutral-600">Absolutamente sim</p>
        </div>
    );
};

export default RadioList;
