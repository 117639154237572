import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import axios from 'axios'
import styles from './aula.module.css'

import PopupAdminAula from '../popups/add_aula';
import PopupRemoverItem from '../popups/remover_item'


const TabelaAulas = (props) => {

  const [aulas, setAulas] = useState([])
  const [popupVisible, setPopupVisible] = useState(false)
  const [itemPopup, setItemPopup] = useState(null)
  const [popupRemoveVisible, setPopupRemoveVisible] = useState(false)
  const [page, setPages] = useState(1);
  const [temporaryPositions, setTemporaryPositions] = useState({});
  const [tipoAulas, setTipoAulas] = useState('shorts');
  const [pesquisaAula, setPesquisaAula] = useState('')

  const fecharPopup = () => {
    setItemPopup(null)
    setPopupVisible(false)
    setPopupRemoveVisible(false)
  }

  const updatePosition = async (aulaId, posicao) => {
    try {
      const r = await axios.post(process.env.REACT_APP_BACKEND_BASE_URL + '/aulas', {
        _id: aulaId,
        posicao
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      })

      if (aulas && aulas.aulas && aulas.aulas.length > 0) {
        let newOrderAulas = aulas.aulas;
        let aulaChange = newOrderAulas.findIndex(aula => aula._id === aulaId);
        newOrderAulas[aulaChange].posicao = posicao;
        newOrderAulas = newOrderAulas.sort(function (a, b) { return a.posicao - b.posicao });
        let a = aulas;
        a.aulas = newOrderAulas;
        setAulas(a)
        if (page > 1) setPages(1)
      }

      toast.success("Posição alterada com sucesso");
    } catch (err) {
    }
  }


  const fetchAulas = async (cInicial) => {
    try {
      const aulas = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/aulas`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        },
        params: {
          [tipoAulas]: true,
          busca: pesquisaAula && pesquisaAula !== '' ? pesquisaAula : null,
          limit: 20,
          skip: cInicial ? (cInicial - 1) * 20 : 0,
          all: true
        }
      })
      setAulas(aulas.data)
    } catch (err) {
    }
  };

  useEffect(() => {
    fetchAulas(page)

  }, [page])

  useEffect(() => {
    setPages(1)
    fetchAulas(1)
  }, [pesquisaAula, tipoAulas])

  return (
    <div className={styles['tabela-aulas']}>
      {popupRemoveVisible && <PopupRemoverItem itemPopup={itemPopup} tipo="aula" buscarAulasModulo={() => fetchAulas(0)} removido={() => { toast.success('Short removida!') }} fecharPopup={fecharPopup} />}
      {popupVisible && <PopupAdminAula itemPopup={itemPopup} fecharPopup={fecharPopup} buscarAulasModulos={() => fetchAulas(page)} sucesso={() => { toast.success("Short salva com sucesso!") }} />}
      <div className={styles['container']}>
        <span className={styles['text']}>Shorts</span>
        <div className={styles['btn-add-aula']} onClick={() => setPopupVisible(true)}>
          <svg viewBox="0 0 804.5714285714286 1024" className={styles['icon']}>
            <path d="M804.571 420.571v109.714c0 30.286-24.571 54.857-54.857 54.857h-237.714v237.714c0 30.286-24.571 54.857-54.857 54.857h-109.714c-30.286 0-54.857-24.571-54.857-54.857v-237.714h-237.714c-30.286 0-54.857-24.571-54.857-54.857v-109.714c0-30.286 24.571-54.857 54.857-54.857h237.714v-237.714c0-30.286 24.571-54.857 54.857-54.857h109.714c30.286 0 54.857 24.571 54.857 54.857v237.714h237.714c30.286 0 54.857 24.571 54.857 54.857z"></path>
          </svg>
          <span className={styles['text01']}>Short</span>
        </div>
      </div>
      <div className={styles["filtros"]}>
        <div className={styles["filtro"]}>
          <label>Pesquise por uma aula</label>
          <input type="text" className="input-dark" value={pesquisaAula} onChange={(e) => setPesquisaAula(e.target.value)} />
        </div>
        <div className={styles["filtro"]}>
          <label>Tipo de Short</label>
          <select className='dropdown' value={tipoAulas} onChange={(e) => setTipoAulas(e.target.value)}>
            <option value="shorts">Shorts</option>
            <option value="inspiracionais">Inspiracionais</option>
          </select>
        </div>
      </div>
      {aulas && aulas.aulas && aulas.aulas.length > 0 ? (
        <div className={styles['not-empty']}>
          <div className={styles['table']}>
            <div className={styles['container1']}>
              <div className={styles['container2']}></div>
              <span className={styles['text02']}>Short</span>
              <span className={styles['text03']}>Posição</span>
            </div>
            <div className={styles['infos']}>
              {aulas.aulas.map((aula, index) => (
                <div key={index} className={styles['container4']}>
                  <svg
                    onClick={() => {
                      setItemPopup(aula)
                      setPopupVisible(true)
                    }}
                    viewBox="0 0 865.7188571428571 1024"
                    className={styles['icon2']}
                  >
                    <path d="M207.429 877.714l52-52-134.286-134.286-52 52v61.143h73.143v73.143h61.143zM506.286 347.429c0-7.429-5.143-12.571-12.571-12.571-3.429 0-6.857 1.143-9.714 4l-309.714 309.714c-2.857 2.857-4 6.286-4 9.714 0 7.429 5.143 12.571 12.571 12.571 3.429 0 6.857-1.143 9.714-4l309.714-309.714c2.857-2.857 4-6.286 4-9.714zM475.429 237.714l237.714 237.714-475.429 475.429h-237.714v-237.714zM865.714 292.571c0 19.429-8 38.286-21.143 51.429l-94.857 94.857-237.714-237.714 94.857-94.286c13.143-13.714 32-21.714 51.429-21.714s38.286 8 52 21.714l134.286 133.714c13.143 13.714 21.143 32.571 21.143 52z"></path>
                  </svg>
                  <div className={styles['container5']}>
                    <img
                      alt="image"
                      src={aula.capa ? aula.capa : '/default-img.svg'}
                      className={styles['image']}
                    />
                    <span className={styles['text05']}>
                      <span className={styles['text06']}>
                        {aula.nome}
                      </span>
                    </span>
                  </div>
                  <span className={styles['text08']}>  <input
                    type="text"
                    value={temporaryPositions[aula._id] || aula.posicao}
                    onChange={(event) => {
                      const newPosition = event.target.value;
                      setTemporaryPositions((prevPositions) => ({
                        ...prevPositions,
                        [aula._id]: newPosition,
                      }));
                    }}
                    onBlur={async () => {
                      const newPosition = temporaryPositions[aula._id];
                      if (newPosition !== undefined) {
                        const updatedAulas = {
                          ...aulas,
                          aulas: aulas.aulas.map((aulaItem) =>
                            aulaItem._id === aula._id ? { ...aulaItem, posicao: newPosition } : aulaItem
                          ),
                        };

                        setAulas(updatedAulas);

                        await updatePosition(aula._id, newPosition);

                        setTemporaryPositions((prevPositions) => ({
                          ...prevPositions,
                          [aula._id]: undefined,
                        }));

                      }
                    }}
                  /></span>
                  <svg
                    onClick={() => {
                      setItemPopup(aula)
                      setPopupRemoveVisible(true)
                    }}
                    viewBox="0 0 804.5714285714286 1024"
                    className={styles['icon4']}
                  >
                    <path d="M292.571 786.286v-402.286c0-10.286-8-18.286-18.286-18.286h-36.571c-10.286 0-18.286 8-18.286 18.286v402.286c0 10.286 8 18.286 18.286 18.286h36.571c10.286 0 18.286-8 18.286-18.286zM438.857 786.286v-402.286c0-10.286-8-18.286-18.286-18.286h-36.571c-10.286 0-18.286 8-18.286 18.286v402.286c0 10.286 8 18.286 18.286 18.286h36.571c10.286 0 18.286-8 18.286-18.286zM585.143 786.286v-402.286c0-10.286-8-18.286-18.286-18.286h-36.571c-10.286 0-18.286 8-18.286 18.286v402.286c0 10.286 8 18.286 18.286 18.286h36.571c10.286 0 18.286-8 18.286-18.286zM274.286 219.429h256l-27.429-66.857c-1.714-2.286-6.857-5.714-9.714-6.286h-181.143c-3.429 0.571-8 4-9.714 6.286zM804.571 237.714v36.571c0 10.286-8 18.286-18.286 18.286h-54.857v541.714c0 62.857-41.143 116.571-91.429 116.571h-475.429c-50.286 0-91.429-51.429-91.429-114.286v-544h-54.857c-10.286 0-18.286-8-18.286-18.286v-36.571c0-10.286 8-18.286 18.286-18.286h176.571l40-95.429c11.429-28 45.714-50.857 76-50.857h182.857c30.286 0 64.571 22.857 76 50.857l40 95.429h176.571c10.286 0 18.286 8 18.286 18.286z"></path>
                  </svg>
                </div>
              ))}
            </div>
          </div>
          {aulas.totalAulas > 20 && (
            <div className={styles['paginao']}>
              <span className={styles['text10']} onClick={() => {
                if (page > 1) {
                  setPages(page - 1)
                } else {
                  setPages(aulas.totalPages)
                }
              }}>
                <svg
                  viewBox="0 0 768 1024" className="admin-cursos-icon02">
                  <path d="M669.143 172l-303.429 303.429 303.429 303.429c14.286 14.286 14.286 37.143 0 51.429l-94.857 94.857c-14.286 14.286-37.143 14.286-51.429 0l-424-424c-14.286-14.286-14.286-37.143 0-51.429l424-424c14.286-14.286 37.143-14.286 51.429 0l94.857 94.857c14.286 14.286 14.286 37.143 0 51.429z"></path>
                </svg>
              </span>
              <span className={styles['text11']}>{page} {aulas && aulas.totalPages ? 'de ' + aulas.totalPages : ''}</span>
              <span className={styles['text10']} onClick={() => {
                if (page === aulas.totalPages) {
                  setPages(1)
                } else {
                  setPages(page + 1)
                }
              }}>
                <svg
                  viewBox="0 0 694.8571428571428 1024"
                  className="admin-cursos-icon04"
                >
                  <path d="M632.571 501.143l-424 424c-14.286 14.286-37.143 14.286-51.429 0l-94.857-94.857c-14.286-14.286-14.286-37.143 0-51.429l303.429-303.429-303.429-303.429c-14.286-14.286-14.286-37.143 0-51.429l94.857-94.857c14.286-14.286 37.143-14.286 51.429 0l424 424c14.286 14.286 14.286 37.143 0 51.429z"></path>
                </svg>
              </span>
            </div>
          )}
        </div>
      ) : (<p style={{ color: '#999' }}>Nenhuma aula encontrada</p>)}
    </div>
  )
}

export default TabelaAulas