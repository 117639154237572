// store.js
import { configureStore } from '@reduxjs/toolkit';
import usuarioAtualReducer from './features/usuario/usuarioAtual';
import cursoAtualReducer from './features/curso/cursoAtual';
import moduloAtualReducer from './features/modulo/moduloAtual';

const store = configureStore({
  reducer: {
    usuarioAtual: usuarioAtualReducer,
    cursoAtual: cursoAtualReducer,
    moduloAtual: moduloAtualReducer
  },
});

export default store;