import React, { useState, useEffect } from 'react';

const TextArea = ({ label, value, onChange, isRequired, paginaQuestao }) => {
    const [charCount, setCharCount] = useState(value.length || 0);
    const charLimit = 300;
    const [error, setError] = useState('');

    const handleChange = (e) => {
        const isValid = !isRequired || e.target.value;

        setError(isValid ? '' : 'Selecione uma opção');
        if (e.target.value.length <= charLimit) {
            setCharCount(e.target.value.length);
            onChange(e.target.value, isValid);
        }
    };

    useEffect(() => {
        const isValid = !isRequired || value;

        setError(isValid ? '' : 'Selecione uma opção');
        if (value.length <= charLimit) {
            setCharCount(value.length);
            onChange(value, isValid);
        }
    }, [paginaQuestao])
    return (
        <div>
            {/*<p className={`text-2xl text-orange-500 ${isRequired ? 'required' : ''}`}>{label}</p>*/}
            <div className="input-group text-group">
                <textarea
                    className={`w-full p-4 text-lg bg-transparent border-b-4 border-neutral-800 ${value ? 'border-b-orange-500' : 'border-b-neutral-900'} focus:border-neutral-800 focus:border-b-orange-500 focus:ring-0 placeholder:text-neutral-500`}
                    placeholder="Digite aqui"
                    rows="4"
                    value={value}
                    onChange={handleChange}
                    required={isRequired}
                ></textarea>
                <div className="text-right text-sm text-neutral-500">
                    {charCount}/{charLimit} caracteres
                </div>
            </div>
        </div>
    );
};

export default TextArea;
