import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation, Link } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import Footer from '../../components/footer';
import Header from '../../components/header';
import Menu from '../../components/menu';

import IndiqueGanheHome from './indique-e-ganhe/main';
import PainelBIHome from './painel-bi/main';
import DiagnosticForm from './diagnostic-form/main';
import HomeMobile from './home-mobile/main';

import PopupAgendamento from '../../components/popups/add_consultoria';
import ShortsHome from './shorts/main';
import ColaboradoresHome from './colaboradores/main';
import ConsultoriaHome from './consultoria/main';
import ConteudosHome from './conteudos/main';
import AutomacoesHome from './automacoes/main';
import TreinamentosHome from './treinamentos/main';

const HomeOfficial = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const usuarioAtual = useSelector(state => state.usuarioAtual);
    const [showMenu, setShowMenu] = useState(window.innerWidth >= 1024);

    console.log(`Bearer ${localStorage.getItem("token")}`)
    return (
        <div className='w-full'>
            <div className='block'>
                <Helmet>
                    <title>Simbium</title>
                    <meta property="og:title" content="Simbium" />
                </Helmet>
                <Header usuarioAtual={usuarioAtual} showMenu={showMenu} setShowMenu={setShowMenu} />
                <div className={`w-full px-5 bg-neutral-900 flex flex-col space-y-10`}>
                    <div className='w-full flex flex-row mt-14 pt-10'>
                        {showMenu &&
                            <div className='w-3/12 mb-5'>
                                <Menu usuarioAtual={usuarioAtual} showMenu={showMenu} setShowMenu={setShowMenu} />
                            </div>
                        }
                        <div className={`${showMenu ? 'w-9/12' : 'w-full'} mb-5 mx-auto`}>
                            <DiagnosticForm usuarioAtual={usuarioAtual} />

                            <section className='flex sm:hidden w-full hover:shadow-orange-500'>
                                <div className='w-11/12 mx-auto relative flex flex-row rounded-xl bg-gradient-to-r from-[#fe631c] to-[#fe941e]' onClick={() => setAgendamentoPopupVisible(true)}>
                                    <div className='w-1/2 m-5'>
                                        <p className='text-xl mb-2 font-bold'>Agende sua consultoria!</p>
                                        <p className='text-xs'>Converse agora com seu supervisor</p>
                                    </div>
                                    <img src="/home-official/agende-consultoria-mood.png" className='w-[150px] absolute bottom-0 right-3' />
                                </div>
                            </section>

                            <AutomacoesHome usuarioAtual={usuarioAtual} />

                            <section className='flex sm:flex-col md:flex-row gap-5 w-11/12 mx-auto'>
                                <ColaboradoresHome usuarioAtual={usuarioAtual} />
                                <ConsultoriaHome usuarioAtual={usuarioAtual} erro={(e) => { toast.error(e) }} sucesso={() => toast.success("Consultoria agendada com sucesso!")} />
                            </section>

                            <ShortsHome usuarioAtual={usuarioAtual} />
                            <TreinamentosHome />

                            <section className='flex sm:hidden w-full hover:shadow-blue-500 pt-10'>
                                <a target="_blank" href={`${process.env.REACT_APP_COMUNIDADE_URL}`} className='w-11/12 mx-auto relative flex flex-row justify-end rounded-xl bg-gradient-to-r from-[#041628] to-blue-900'>
                                    <img src="/home-official/acesse-comunidade.png" className='w-[250px] absolute bottom-0 -left-10' />
                                    <div className='w-1/2 m-5 text-white text-right'>
                                        <p className='text-xl mb-2 font-bold'>Acesse a comunidade!</p>
                                        <p className='text-xs text-blue-200'>Junte-se a nós</p>
                                    </div>
                                </a>
                            </section>

                            <ConteudosHome usuarioAtual={usuarioAtual} />

                            <IndiqueGanheHome usuarioAtual={usuarioAtual} />
                        </div>
                    </div>
                </div>
            </div>
            {/*<div className='block lg:hidden'>
                {showMenu &&
                    <div className='w-full'>
                        <Menu usuarioAtual={usuarioAtual} showMenu={showMenu} setShowMenu={setShowMenu} />
                    </div>
                }
                <HomeMobile usuarioAtual={usuarioAtual} showMenu={showMenu} setShowMenu={setShowMenu} />
            </div>*/}
            <Footer></Footer>
        </div>
    )
}

export default HomeOfficial;
