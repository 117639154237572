import React, { useState, useEffect, useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import axios from 'axios'
// import styles from './master.module.css'
import './master.css'


const MasterCursoAdmin = (props) => {
  const history = useHistory();

  const header_geral = { Authorization: `Bearer ${localStorage.getItem("token")}` }

  const { curso_id } = useParams();
  const [curso, setCurso] = useState(null);
  const [aulas, setAulas] = useState([]);

  const [tituloCurso, setTituloCurso] = useState("");
  const [erroTituloCurso, setErroTituloCurso] = useState("");

  const [descricaoCurso, setDescricaoCurso] = useState("");
  const [erroDescricaoCurso, setErroDescricaoCurso] = useState("");

  const [modoCurso, setModoCurso] = useState(true);
  const [erroModoCurso, setErroModoCurso] = useState("");

  const [imagemCurso, setImagemCurso] = useState("");
  const [erroImagemCurso, setErroImagemCurso] = useState("");

  const [videoPreviaCurso, setVideoPreviaCurso] = useState("");
  const [erroVideoPreviaCurso, setErroVideoPreviaCurso] = useState("");

  const [automacaoCurso, setAutomacaoCurso] = useState(false);
  const [erroAutomacaoCurso, setErroAutomacaoCurso] = useState("");

  const [precoCurso, setPrecoCurso] = useState(0);
  const [erroPrecoCurso, setErroPrecoCurso] = useState("");

  const [linkCompraCurso, setLinkCompraCurso] = useState("");
  const [erroLinkCompraCurso, setErroLinkCompraCurso] = useState("");

  const [funcionalidadesCurso, setFuncionalidadesCurso] = useState([]);
  const [erroFuncionalidadesCurso, setErroFuncionalidadesCurso] = useState("");

  const [funcionalidadeTituloCurso, setFuncionalidadeTituloCurso] = useState("");
  const [erroFuncionalidadeTituloCurso, setErroFuncionalidadeTituloCurso] = useState("");

  const [funcionalidadeDescricaoCurso, setFuncionalidadeDescricaoCurso] = useState("");
  const [erroFuncionalidadeDescricaoCurso, setErroFuncionalidadeDescricaoCurso] = useState("");

  const [materiaisCurso, setMateriaisCurso] = useState("");
  const [erroMateriaisCurso, setErroMateriaisCurso] = useState("");

  const [vantagensCurso, setVantagensCurso] = useState("");
  const [erroVantagensCurso, setErroVantagensCurso] = useState("");

  const [novoNomeMaterialCurso, setNovoNomeMaterialCurso] = useState("")
  const [novoArquivoMaterialCurso, setNovoArquivoMaterialCurso] = useState(null)
  const [novoUrlMaterialCurso, setNovoUrlMaterialCurso] = useState("")
  const [novoTipoMaterialCurso, setNovoTipoMaterialCurso] = useState(0)
  const [erroNovoMaterialCurso, setErroNovoMaterialCurso] = useState("")

  const [novoTituloAula, setNovoTituloAula] = useState("");
  const [erroNovoTituloAula, setErroNovoTituloAula] = useState("");

  const [novaDescricaoAula, setNovaDescricaoAula] = useState("");
  const [erroNovaDescricaoAula, setErroNovaDescricaoAula] = useState("");

  const [accessToken, setAccessToken] = useState("");
  const [bunnyUrlPublicoCapa, setBunnyUrlPublicoCapa] = useState(`${process.env.REACT_APP_BUNNY_FILEHOST}paginas-automaticas/cursos/capas/`);
  const [bunnyUrlPublicoPrevia, setBunnyUrlPublicoPrevia] = useState(`${process.env.REACT_APP_BUNNY_FILEHOST}paginas-automaticas/cursos/previas/`);
  const [alteracoesSalvas, setAlteracoesSalvas] = useState("")
  const [erro, setErro] = useState("")

  const [editRows, setEditRows] = useState(2);
  const [createRows, setCreateRows] = useState(1);
  const [functionRows, setFunctionRows] = useState(1);

  const [processando, setProcessando] = useState(false);

  const stateSetters = {
    edit: { setDescription: setDescricaoCurso, setRows: setEditRows, minRows: 2 },
    create: { setDescription: setNovaDescricaoAula, setRows: setCreateRows, minRows: 1 },
    funcionalidade: { setDescription: setFuncionalidadeDescricaoCurso, setRows: setFunctionRows, minRows: 1 },
  };

  const handleTextChange = (e, mode) => {
    const { setDescription, setRows, minRows } = stateSetters[mode];
    setDescription(e.target.value);
    const numOfLines = e.target.value.split('\n').length;
    setRows(numOfLines > minRows ? numOfLines : minRows);
  };

  useEffect(() => {
    let isMounted = true;
    const fetchCurso = async () => {
      setProcessando(true)
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/master/curso/${curso_id}`, { headers: header_geral });
        if (!isMounted) return
        console.log("CURSO: ", response.data)
        setCurso(response.data);
        setTituloCurso(response.data.titulo)
        setDescricaoCurso(response.data.descricao)
        setModoCurso(response.data.ativo)
        setImagemCurso(response.data.imagem || "")
        setVideoPreviaCurso(response.data.videoPrevia || "")
        setAutomacaoCurso(response.data.automacao)
        setPrecoCurso(response.data.preco || 0)
        setLinkCompraCurso(response.data.linkCompra || "")
        setFuncionalidadesCurso(response.data.funcionalidades)
        setMateriaisCurso(response.data.materiais)
        setVantagensCurso(response.data.vantagens)

      } catch (error) {
        console.error(error);
      } finally {
        setProcessando(false)
      }
    };

    const fetchAulas = async () => {
      setProcessando()
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/master/aulas/${curso_id}`, { headers: header_geral });
        if (isMounted) setAulas(response.data);
      } catch (error) {
        console.error(error);
      } finally {
        setProcessando(false)
      }
    };

    fetchCurso();
    fetchAulas();

    return () => {
      isMounted = false;
    };
  }, [curso_id]);


  const urlValido = (string) => {
    let url;

    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
  }

  function criarSlug(titulo) {
    const mapaAcentos = {
      'á': 'a', 'ã': 'a', 'à': 'a', 'â': 'a', 'é': 'e', 'ê': 'e', 'í': 'i', 'ó': 'o', 'õ': 'o', 'ô': 'o', 'ú': 'u', 'ç': 'c',
      'Á': 'a', 'Ã': 'a', 'À': 'a', 'Â': 'a', 'É': 'e', 'Ê': 'e', 'Í': 'i', 'Ó': 'o', 'Õ': 'o', 'Ô': 'o', 'Ú': 'u', 'Ç': 'c'
    };

    let slug = titulo.toLowerCase();
    slug = slug.replace(/[\s]/g, '-'); // substitui espaços por hífens
    slug = slug.replace(/[\W]/g, function (char) { // substitui caracteres especiais
      return mapaAcentos[char] || char;
    });
    slug = slug.replace(/[^a-z0-9-]/g, ''); // remove caracteres não alfanuméricos e não hífens
    return slug;
  }

  const atualizarCurso = async (file = null, file_type = null) => {
    setProcessando(true)
    setAlteracoesSalvas("Salvando alterações");

    if (!tituloCurso || !tituloCurso.length) {
      setErroTituloCurso("O título é obrigatório!")
      setProcessando(false)
      return
    }
    setErroTituloCurso("")

    setErroDescricaoCurso("")
    setErroModoCurso("")
    setErroImagemCurso("")
    setErroVideoPreviaCurso("")
    setErroAutomacaoCurso("")

    if (automacaoCurso === true && (!precoCurso || parseFloat(precoCurso) < 0)) {
      setErroPrecoCurso("A automação precisa de um preço maior ou igual a 0")
      setProcessando(false)
      return
    }
    setErroPrecoCurso("")

    /*if (automacaoCurso === true && !linkCompraCurso.length) {
      setErroPrecoCurso("A automação precisa de um link de compra")
      setProcessando(false)
      return
    }
    setErroPrecoCurso("")*/

    if (automacaoCurso === true && !funcionalidadesCurso.length) {
      setErroFuncionalidadesCurso("A automação precisa ter pelo menos 01 funcionalidade.")
      setProcessando(false)
      return
    }
    setErroFuncionalidadesCurso("")

    if (materiaisCurso && materiaisCurso.length && !urlValido(materiaisCurso)) {
      setErroMateriaisCurso("A url inserida para o download dos materiais é inválida (deve ser http ou https)")
      setProcessando(false)
      return
    }
    setErroMateriaisCurso("")
    setErroVantagensCurso("")

    try {
      const response = await axios.put(`${process.env.REACT_APP_BACKEND_BASE_URL}/master/curso/${curso._id}`, {
        titulo: tituloCurso,
        codigo: criarSlug(tituloCurso),
        descricao: descricaoCurso,
        emBreve: modoCurso,
        ativo: modoCurso,
        imagem: file_type === 'img' ? file : imagemCurso,
        videoPrevia: file_type === 'video' ? file : videoPreviaCurso,
        automacao: automacaoCurso,
        preco: precoCurso,
        linkCompra: linkCompraCurso,
        funcionalidades: funcionalidadesCurso,
        materiais: materiaisCurso,
        vantagens: vantagensCurso,
      }, { headers: header_geral });

      if (response.status === 200) {
        setAlteracoesSalvas("Alterações salvas com sucesso!");
        setCurso(response.data);

        setTimeout(() => setAlteracoesSalvas(""), 3000)
      } else {
        console.log(response);
        setAlteracoesSalvas("")
      }
    } catch (err) {
      setAlteracoesSalvas("")
      if (err.response && err.response.data) {
        console.error(err.response.data.error);
        toast.error(err.response.data.error)
      } else {
        console.error(err);
      }
    } finally {
      setProcessando(false)
    }
  };

  const alterarOrdem = async (aula_id, direcao) => {
    setProcessando(true)
    try {
      const response = await axios.put(`${process.env.REACT_APP_BACKEND_BASE_URL}/master/aulas/ordem/${aula_id}`, { direcao }, { headers: header_geral });

      if (response.status === 200) {
        console.log('Ordem da aula alterada com sucesso!');

        // Após a alteração da ordem, faça uma nova requisição para buscar todas as aulas
        const responseAulas = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/master/aulas/${curso_id}`, { headers: header_geral });

        if (responseAulas.status === 200) {
          // Atualize o estado do seu componente com as novas aulas
          setAulas(responseAulas.data);
        } else {
          console.error('Erro ao buscar aulas:', responseAulas);
        }

        return response.data;
      } else {
        console.error('Erro ao alterar a ordem da aula:', response);
      }
    } catch (err) {
      console.error('Erro ao alterar a ordem da aula:', err);
    } finally {
      setProcessando(false)
    }
  };



  const uploadCapa = async (event) => {
    setProcessando(true);
    setAlteracoesSalvas("Fazendo upload da CAPA");

    const file = event.target.files[0];
    if (!file) {
      console.error("Nenhum arquivo selecionado");
      event.target.value = null;
      setProcessando(false);
      return;
    }
    const ext = file.name.split('.').pop();
    try {
      // Solicitar a URL assinada do backend
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/master/upload/curso/capa/${curso._id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          params: {
            ext: `.${ext}`,
            contentType: file.type,
          },
        }
      );

      const { uploadUrl, fileName } = response.data;

      // Fazer o upload direto para o Google Cloud Storage usando a URL assinada
      const uploadResponse = await axios.put(uploadUrl, file, {
        headers: {
          'Content-Type': file.type,
        },
      });

      if (uploadResponse.status === 200) {
        const publicUrl = `https://storage.googleapis.com/${process.env.REACT_APP_GOOGLE_CLOUD_BUCKET_NAME}/${fileName}`;
        setImagemCurso(publicUrl);
        await atualizarCurso(publicUrl, 'img')
        toast.success("Upload realizado com sucesso");
        console.log('Upload realizado com sucesso:', publicUrl);
      } else {
        toast.error("Erro na tentativa de upload");
        console.error('Erro no upload:', uploadResponse);
      }
    } catch (error) {
      toast.error("Erro na tentativa de upload");
      console.error('Erro no upload:', error.response ? error.response.data : error.message);
    } finally {
      event.target.value = null;
      setProcessando(false);
    }
  };


  const uploadVideoPrevia = async (event) => {
    setProcessando(true);
    setAlteracoesSalvas("Fazendo upload do VÍDEO DE PRÉVIA");

    const file = event.target.files[0];
    if (!file) {
      console.error("Nenhum vídeo inserido!");
      event.target.value = null;
      setProcessando(false);
      setAlteracoesSalvas("");
      return;
    }

    const maxSizeMB = 5000; // 5 GB em MB
    const fileSizeMB = file.size / (1024 * 1024); // Tamanho do arquivo em MB

    if (fileSizeMB > maxSizeMB) {
      console.log("O arquivo excede o tamanho máximo permitido de 5 GB.");
      setErro("O arquivo excede o tamanho máximo permitido de 5 GB.");
      setTimeout(() => {
        setErro("");
      }, 5000);
      event.target.value = null;
      setProcessando(false);
      setAlteracoesSalvas("");
      return;
    }
    const ext = file.name.split('.').pop();
    try {
      // Solicitar a URL assinada do backend
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/master/upload/curso/previa/${curso._id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          params: {
            ext: `.${ext}`,
            contentType: file.type,
          },
        }
      );

      const { uploadUrl, fileName } = response.data;

      // Fazer o upload direto para o Google Cloud Storage usando a URL assinada
      const uploadResponse = await axios.put(uploadUrl, file, {
        headers: {
          'Content-Type': file.type,
        },
      });

      if (uploadResponse.status === 200) {
        setVideoPreviaCurso("");
        const publicUrl = `https://storage.googleapis.com/${process.env.REACT_APP_GOOGLE_CLOUD_BUCKET_NAME}/${fileName}`;

        //setCurso(cursoAtualizado.data);
        setVideoPreviaCurso(publicUrl);
        await atualizarCurso(publicUrl, 'video')

        toast.success("Upload realizado com sucesso");
        setAlteracoesSalvas("Upload concluído com sucesso");
        console.log('Upload realizado com sucesso:', publicUrl);
      } else {
        setAlteracoesSalvas("Erro ao tentar fazer upload da prévia no servidor");
        toast.error("Erro na tentativa de upload");
        console.error('Erro no upload:', uploadResponse);
      }
    } catch (error) {
      setAlteracoesSalvas("(CATCH) Erro ao tentar fazer upload da prévia no servidor");
      console.error('Erro no upload:', error.response ? error.response.data : error.message);
    } finally {
      event.target.value = null;
      setProcessando(false);
    }
  };


  const handleArquivo = async (event) => {
    setProcessando(true)
    setNovoArquivoMaterialCurso("")
    const file = event.target.files[0]
    const fileType = file.type; // get the file type from the file
    const fileName = file.name.replace(/ /g, "_")
    console.log("FILE TYPE: ", fileType)
    try {
      console.log("Tentando enviar para o bunny")
      const response = await axios.put(`${process.env.REACT_APP_BUNNY_STORAGE_ENDPOINT}paginas-automaticas/cursos/materiais/${curso_id}/${fileName}`, file, {
        headers: {
          'AccessKey': process.env.REACT_APP_BUNNY_TOKEN,
          'Content-Type': fileType // use the file type here
        }
      });
      if (response.status === 201) {
        setNovoArquivoMaterialCurso(fileName)
        console.log("Mateirial Enviado")
      } else {
        console.log('O upload do MATERIAL falhou (try):', response);
      }
    } catch (error) {
      console.error('O upload do MATERIAL falhou (catch):', error);
    } finally {
      setProcessando(false)
    }
  }

  const adicionarMaterial = () => {
    setProcessando(true)
    if (!novoNomeMaterialCurso || !novoNomeMaterialCurso.length) {
      setErroNovoMaterialCurso("Insira o nome do novo material")
      setProcessando(false)
      return;
    }

    if (novoTipoMaterialCurso === 0 && !novoArquivoMaterialCurso) {
      setErroNovoMaterialCurso("Insira o arquivo do novo material")
      setProcessando(false)
      return;
    }

    if (novoTipoMaterialCurso === 1 && (!novoUrlMaterialCurso || !novoUrlMaterialCurso.length)) {
      setErroNovoMaterialCurso("Insira o link do novo material")
      setProcessando(false)
      return;
    }

    // Crie uma nova lista de materiais com os materiais existentes e o novo material
    const novoMaterialLista = [...materiaisCurso, {
      nome: novoNomeMaterialCurso,
      arquivo: novoArquivoMaterialCurso,
      url: novoUrlMaterialCurso,
      tipo: novoTipoMaterialCurso,
    }];

    // Atualize o estado com a nova lista de materiais
    setMateriaisCurso(novoMaterialLista);
    setNovoNomeMaterialCurso("")
    setNovoArquivoMaterialCurso(null)
    setNovoUrlMaterialCurso("")
    setProcessando(false)
  }

  const adicionarAula = async () => {
    setProcessando(true)
    if (!novoTituloAula) {
      setErroNovoTituloAula("O título é obrigatório.");
      setProcessando(false)
      return;
    }
    setErroNovoTituloAula("");

    /*if (!novaDescricaoAula) {
      setErroNovaDescricaoAula("A descrição é obrigatória.");
      return;
    }*/
    setErroNovaDescricaoAula("");

    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/master/aula`, {
        curso_id: curso._id,
        titulo: novoTituloAula,
        codigo: criarSlug(novoTituloAula),
        descricao: novaDescricaoAula,
      }, { headers: header_geral });
      // Atualize a lista de aulas após a criação bem-sucedida de uma nova aula
      setAulas(prevAulas => [...prevAulas, response.data]);
      setNovoTituloAula("");
      setNovaDescricaoAula("");
    } catch (err) {
      if (err.response && err.response.data) {
        console.error(err.response.data);  // Log the server error message
        setErroNovoTituloAula(err.response.data.titulo);
        setErroNovaDescricaoAula(err.response.data.descricao);
      } else {
        console.error(err);
      }
    } finally {
      setProcessando(false)
    }

  };

  const excluirAula = async (aula_id, aula_titulo) => {
    setProcessando(true)
    if (!confirm("Você tem certeza que deseja deletar a aula '" + aula_titulo + "'? Esta ação não pode ser desfeita!")) {
      setProcessando(false)
      return
    }
    try {
      const response = await axios.delete(`${process.env.REACT_APP_BACKEND_BASE_URL}/master/aula/${aula_id}`, { headers: header_geral });
      if (response.status === 200) {
        console.log(response.data.message);
        // Remova a aula da lista após a exclusão bem-sucedida
        setAulas(prevAulas => prevAulas.filter(aula => aula._id !== aula_id));
        toast.success(`Aula "${aula_titulo}" deletada com sucesso!`)
      } else {
        toast.error("Erro ao tentar deletar aula '" + aula_titulo + "'")
        console.log(response)
      }
    } catch (err) {
      toast.error("Erro ao tentar deletar aula '" + aula_titulo + "'")
      console.error(err);
      if (err.response && err.response.data) {
        console.error(err.response.data.error);
        alert(err.response.data.error)
      } else {
        console.log(err)
      }
    } finally {
      setProcessando(false)
    }
  };

  const adicionarFuncionalidadeAutomacao = (valor) => {
    setProcessando(true)
    if (!funcionalidadeTituloCurso.length) {
      setErroFuncionalidadeTituloCurso("A funcionalidade deve ter um título.")
      setProcessando(false)
      return;
    }
    setErroFuncionalidadeTituloCurso("")

    if (!funcionalidadeDescricaoCurso.length) {
      setErroFuncionalidadeDescricaoCurso("A funcionalidade deve ter uma descrição.")
      setProcessando(false)
      return;
    }
    setErroFuncionalidadeDescricaoCurso("")

    setFuncionalidadesCurso([...funcionalidadesCurso, { titulo: funcionalidadeTituloCurso, descricao: funcionalidadeDescricaoCurso }])

    setFuncionalidadeTituloCurso("")
    setFuncionalidadeDescricaoCurso("")
    setProcessando(false)
  }

  const getFormat = (videoUrl) => {
    // Divide a URL pelo ponto e retorna o último item da lista
    const parts = videoUrl.split('.');
    return parts[parts.length - 1];
  }

  return (
    <div className="space-y-5 w-full flex relative flex-col pb-8 justify-start bg-[#212121] rounded-xl overflow-hidden mt-5">
      <div className="w-full">
        {curso &&
          <div>
            <div className='text-white bg-zinc-950 p-8'>
              <p className="text-md text-gray-400 mb-3">Página {'>'} Seção {'>'} Curso</p>
              <h1 className='text-5xl'>{curso.titulo}</h1>
              <p className="text-orange-500 hover:text-orange-600 active:text-orange-700 cursor-pointer mt-3 transition-all duration-100 ease-linear" onClick={() => history.push(`/admin/master-secao/${curso.secao}`)}>Voltar</p>
            </div>

            <div className='px-14'>
              <p className='secaoTitulo'>Editar informações do Curso</p>

              <div className='flex flex-col sm:flex-row items-center gap-5'>
                <div className='flex flex-col w-full mx-auto'>
                  <label htmlFor="tituloCurso" className="text-white">Título</label>
                  <input id="tituloCurso" type="text" value={tituloCurso} onChange={e => setTituloCurso(e.target.value)} className="w-full p-2 bg-gray-800 text-white rounded-md mb-4 disabled:opacity-50" disabled={processando} placeholder="Título" />
                  {erroTituloCurso && <span className="text-red-500">{erroTituloCurso}</span>}

                  <label htmlFor="descricaoCurso" className="text-white">Descrição</label>
                  <textarea id="descricaoCurso"
                    value={descricaoCurso}
                    onChange={e => handleTextChange(e, 'edit')}
                    className="w-full p-2 bg-gray-800 text-white rounded-md mb-4 disabled:opacity-50" disabled={processando}
                    placeholder="Descrição"
                    rows={editRows}
                  />
                  {erroDescricaoCurso && <span className="text-red-500">{erroDescricaoCurso}</span>}

                  <label htmlFor="automacaoCurso" className="flex items-center text-white my-5">
                    <input
                      id="automacaoCurso"
                      type="checkbox"
                      checked={automacaoCurso}
                      onChange={e => setAutomacaoCurso(e.target.checked)}
                      className="mr-2 rounded disabled:opacity-50" disabled={processando}
                    />
                    Esse curso é uma automação?
                  </label>
                  {erroAutomacaoCurso && <span className="text-red-500">{erroAutomacaoCurso}</span>}

                  <div>
                    <label htmlFor="materiaisCurso" className="text-white">{automacaoCurso ? "Link de compra da automação" : "Link para download dos materiais"}</label>
                    <input id="materiaisCurso" type="text" value={materiaisCurso} onChange={e => setMateriaisCurso(e.target.value)} className="w-full p-2 bg-gray-800 text-white rounded-md mb-4 disabled:opacity-50" disabled={processando} placeholder="Link para download dos materiais" />
                    {erroMateriaisCurso && <span className="text-red-500">{erroMateriaisCurso}</span>}
                  </div>

                  {automacaoCurso &&
                    <div>
                      <label htmlFor="precoCurso" className="text-white">Preço da Automação (R$)</label>
                      <input id="precoCurso" type="number" min="0" step="0.01" value={precoCurso} onChange={e => setPrecoCurso(e.target.value)} className="w-full p-2 bg-gray-800 text-white rounded-md mb-4 disabled:opacity-50" disabled={processando} placeholder="Preço da automação" />
                      {erroPrecoCurso && <span className="text-red-500">{erroPrecoCurso}</span>}

                      {/*<label htmlFor="linkCompraCurso" className="text-white">Link de Compra</label>
                      <input id="linkCompraCurso" type="text" value={linkCompraCurso} onChange={e => setLinkCompraCurso(e.target.value)} className="w-full p-2 bg-gray-800 text-white rounded-md mb-4" placeholder="Link de compra da automação" />
                      {erroLinkCompraCurso && <span className="text-red-500">{erroLinkCompraCurso}</span>}*/}

                      <p className='text-white mt-5'>Lista de funcionalidades da Automação</p>
                      <ul className='text-white border-neutral-500 rounded-lg bg-neutral-900 my-5 p-5 divide-y-2 divide-neutral-500'>
                        {funcionalidadesCurso?.length ? funcionalidadesCurso.map((funcionalidade, funcionalidade_index) => {
                          return (
                            <li key={funcionalidade_index} className='py-5 w-11/12 m-auto flex flex-row gap-x-3 justify-between'>
                              <span className='flex flex-row gap-3'>
                                <span className='text-xl'>
                                  <span className='flex flex-col items-center'>
                                    {funcionalidade_index > 0 && <button className='cursor-pointer hover:text-orange-600 active:text-orange-700 disabled:opacity-50' disabled={processando} onClick={(event) => {
                                      let aux = [...funcionalidadesCurso];  // Cria uma cópia do estado atual
                                      aux[funcionalidade_index] = aux[funcionalidade_index - 1];
                                      aux[funcionalidade_index - 1] = funcionalidade;
                                      setFuncionalidadesCurso(aux);  // Atualiza o estado com a nova ordem
                                    }}>▲</button>}
                                    {funcionalidade_index < funcionalidadesCurso.length - 1 && <button className='cursor-pointer hover:text-orange-600 active:text-orange-700 disabled:opacity-50' disabled={processando} onClick={(event) => {
                                      let aux = [...funcionalidadesCurso];  // Cria uma cópia do estado atual
                                      aux[funcionalidade_index] = aux[funcionalidade_index + 1];
                                      aux[funcionalidade_index + 1] = funcionalidade;
                                      setFuncionalidadesCurso(aux);  // Atualiza o estado com a nova ordem
                                    }}>▼</button>}
                                  </span>
                                </span>
                                <span>
                                  <p className='text-xl'>{funcionalidade.titulo}</p>
                                  <p className='text-md text-gray-400 whitespace-pre-wrap'>{funcionalidade.descricao}</p>
                                </span>
                              </span>
                              <span className='cursor-pointer hover:text-orange-600 active:text-orange-700 text-4xl' onClick={(event) => {
                                let aux = [...funcionalidadesCurso];
                                aux.splice(funcionalidade_index, 1);  // Remove o item na posição funcionalidade_index
                                setFuncionalidadesCurso(aux);  // Atualiza o estado com a nova lista
                              }}>
                                &times;
                              </span>
                            </li>
                          )
                        }) :
                          <li>Não há funcionalidades cadastradas até o momento</li>
                        }

                      </ul>
                      {erroFuncionalidadesCurso && <span className="text-red-500 my-5">{erroFuncionalidadesCurso}</span>}

                      <div className='flex flex-col md:flex-row w-full items-center align-middle text-white gap-5 border-2 border-neutral-500 rounded-lg p-5'>
                        <div className='w-full md:w-5/12'>
                          <label htmlFor="funcionalidadeTituloCurso" className="text-white w-full">Titulo da Funcionalidade</label>
                          <input id="funcionalidadeTituloCurso" type="text" value={funcionalidadeTituloCurso} onChange={e => setFuncionalidadeTituloCurso(e.target.value)} className="w-full p-2 bg-gray-800 text-white rounded-md mb-4 disabled:opacity-50" disabled={processando} placeholder="Adicione um titúlo para a nova funcionalidade da automação" />
                          {erroFuncionalidadeTituloCurso && <span className="text-red-500 w-full">{erroFuncionalidadeTituloCurso}</span>}
                        </div>
                        <div className='w-full md:w-6/12'>
                          <label htmlFor="funcionalidadeDescricaoCurso" className="text-white w-full">Descrição da funcionalidade</label>
                          <textarea id="funcionalidadeDescricaoCurso"
                            value={funcionalidadeDescricaoCurso}
                            onChange={e => handleTextChange(e, 'funcionalidade')}
                            className="w-full p-2 bg-gray-800 text-white rounded-md mb-4 disabled:opacity-50" disabled={processando}
                            placeholder="Descreva o que a nova funcionalidade da automação faz"
                            rows={functionRows}
                          />
                          {erroFuncionalidadeDescricaoCurso && <span className="text-red-500 w-full">{erroFuncionalidadeDescricaoCurso}</span>}
                        </div>
                        <div className='w-full md:w-1/12'>
                          <button className='text-6xl w-full hover:text-7xl hover:text-orange-500 active:text-6xl active:text-orange-700 disabled:opacity-50' disabled={processando} onClick={adicionarFuncionalidadeAutomacao}>+</button>
                        </div>
                      </div>

                    </div>
                  }

                  <label htmlFor="modoCurso" className="flex items-center text-white my-5 select-none">

                    <input
                      id="modoCurso"
                      type="checkbox"
                      checked={modoCurso}
                      onChange={e => setModoCurso(e.target.checked)}
                      className="mr-2 rounded disabled:opacity-50"
                      disabled={processando}
                    />
                    {automacaoCurso ? "Esta automação está ativa?" : "Este curso está ativo?"}
                  </label>
                  {erroModoCurso && <span className="text-red-500">{erroModoCurso}</span>}


                  <label htmlFor="videoPreviaCurso" className="text-white">ID do vídeo de prévia</label>
                  <input id="videoPreviaCurso" type="text" value={videoPreviaCurso} onChange={e => setVideoPreviaCurso(e.target.value)} className="w-full p-2 bg-gray-800 text-white rounded-md mb-4 disabled:opacity-50" disabled={processando} placeholder="Nome do vídeo de prévia" />
                  {erroVideoPreviaCurso && <span className="text-red-500">{erroVideoPreviaCurso}</span>}

                  <button className='px-4 py-2 bg-orange-500 text-white rounded hover:bg-gray-700 active:bg-gray-800 disabled:opacity-50' disabled={processando} onClick={atualizarCurso}>Salvar Alterações</button>
                  <p className='text-green-500 my-5 text-center transition-all'>{alteracoesSalvas}</p>
                  <p className='text-red-500 my-5 text-center transition-all'>{erro}</p>
                </div>
              </div>


              <div className='mb-10 w-full flex flex-col items-center sm:flex-row gap-5 min-h-80'>
                <div className='w-full sm:w-1/3 h-full'>
                  <div className="flex flex-col items-center w-full h-full border-dashed border-2 border-orange-600 rounded-lg overflow-hidden">
                    {imagemCurso && imagemCurso.length ?
                      <img id="img-pagina-banner" src={`${imagemCurso}`} alt="Banner" className="w-full h-[400px] object-cover transition-opacity duration-300 ease-in-out" />
                      :
                      <p className='w-full h-[400px] text-gray-500 text-xl text-center p-5 rounded-md my-3' >Nenhuma capa foi selecionada para este curso até o momento!</p>
                    }
                    {erroImagemCurso && <span className="text-red-500">{erroImagemCurso}</span>}
                    <div className='bg-black w-full p-2 text-center'>
                      <input id="bannerInput" type="file" accept=".png,.jpg,.jpeg" onChange={(event) => uploadCapa(event)} className="hidden disabled:opacity-50" disabled={processando} />
                      <div className="text-white text-xl cursor-pointer hover:text-orange-600" onClick={() => document.getElementById('bannerInput').click()}>Trocar Capa</div>
                    </div>
                  </div>
                </div>
                <div className='w-full sm:w-2/3 h-full'>
                  <div className="flex flex-col items-center w-full h-full border-dashed border-2 border-orange-600 rounded-lg overflow-hidden">
                    {videoPreviaCurso ?
                      <video
                        controls
                        className="w-full h-full object-contain transition-opacity duration-300 ease-in-out"
                        preload="auto"
                      >
                        <source
                          src={videoPreviaCurso}
                          type={`video/${getFormat(videoPreviaCurso)}`}
                          className="w-full h-full"
                        />
                        Seu navegador não suporta a reprodução de vídeos.
                      </video>
                      :
                      <p className='w-full h-[400px] text-gray-500 text-xl text-center p-5 rounded-md my-3' >Nenhum vídeo de prévia foi selecionado para este curso até o momento!</p>
                    }
                    {erroVideoPreviaCurso && <span className="text-red-500">{erroVideoPreviaCurso}</span>}
                    <div className='bg-black w-full p-2 text-center'>
                      <input id="videoPreviaInput" type="file" accept=".mp4,.mkv" onChange={(event) => uploadVideoPrevia(event)} className="hidden disabled:opacity-50" disabled={processando} />
                      <div className="text-white text-xl cursor-pointer hover:text-orange-600" onClick={() => document.getElementById('videoPreviaInput').click()}>Trocar Prévia</div>
                    </div>
                  </div>
                </div>

              </div>


              {/* IMPLEMENTAR UPLOAD INDIVIDUAL DE ARQUIVOS E LINKS
              <div className='mb-10 w-full'>
                <div className='w-full sm:w-2/3 m-auto bg-zinc-700 rounded-lg p-5'>
                  <ul className='divide-y'>
                    {(!materiaisCurso || !materiaisCurso.length) ? <li className='py-5 text-slate-100'>Não há MATERIAIS disponibilizados até o momento</li> :
                      materiaisCurso.map((material, index) => {
                        <li key={index} className='py-5 text-slate-100' onClick={() => handleMaterial(material)}>{material.nome}<span className='inline'>&times;</span></li>
                      })
                    }
                  </ul>

                  <div>
                    <div className="relative mt-2 rounded-md shadow-sm w-full">
                      <input type="text" className="float-left block w-3/4 rounded-l-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6" onInput={e => setNovoNomeMaterialCurso(e.target.value)} placeholder="Insira o nome do material" />
                      <select
                        className="block w-1/4 rounded-r-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:max-w-xs sm:text-sm sm:leading-6"
                        onChange={e => setNovoTipoMaterialCurso(Number(e.target.value))}
                      >
                        <option value={0}>Arquivo</option>
                        <option value={1}>Link</option>
                      </select>
                    </div>
                    <div className="relative mt-2 rounded-md shadow-sm w-full">
                      {novoTipoMaterialCurso === 0 ?
                        <div>
                          <input id="novo-material-arquivo" type="file" accept='.jpg, .jpeg, .png, .mkv, .mp4, .pdf' className="hidden" onChange={event => handleArquivo(event)} />
                          <div className="w-full rounded-md border-0 py-1.5 pl-7 pr-20 text-white ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6 hover:bg-white hover:text-black active:bg-zinc-300 select-none cursor-pointer" onClick={() => document.getElementById('novo-material-arquivo').click()}>{novoArquivoMaterialCurso ? novoArquivoMaterialCurso : 'Adicionar Aquivo'}</div>
                        </div>
                        :
                        <input type="text" className="block w-full rounded-l-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6" onInput={e => setNovoUrlMaterialCurso(e.target.value)} placeholder="Insira a URL do link" />
                      }
                    </div>
                    <button className='salvarUpdate my-3 w-full' onClick={adicionarMaterial}>Adicionar Material</button>
                    {erroNovoMaterialCurso && <span className="error-master">{erroNovoMaterialCurso}</span>}
                  </div>
                  
                </div>
              </div>
              */}

              {!automacaoCurso &&
                <div>
                  <p className='secaoTitulo'>Lista de Aulas</p>

                  <table className="tabela">
                    <thead>
                      <tr>
                        {aulas.length > 1 &&
                          <td className='w-fit'>Ordem</td>
                        }
                        <th>Título</th>
                        <th>Descrição</th>
                        {/*<th>Editar</th>*/}
                        <th>Deletar</th>
                      </tr>
                    </thead>
                    <tbody>
                      {aulas.map((aula, index) => (
                        <tr key={aula._id} onClick={() => {
                          localStorage.setItem('/admin/master-aula', aula._id)
                          history.push(`/admin/master-aula/${aula._id}`)
                        }} className='hover:bg-gray-100'>
                          {aulas.length > 1 &&
                            <td className='w-fit text-white text-center'>
                              <span className='flex flex-col items-center'>
                                {index > 0 && <button className='text-center hover:text-orange-600 active:text-orange-700 disabled:opacity-50' disabled={processando} onClick={(event) => {
                                  event.stopPropagation();  // Adicione esta linha
                                  alterarOrdem(aula._id, -1);
                                }}>▲</button>}
                                {index < aulas.length - 1 && <button className='text-center hover:text-orange-600 active:text-orange-700 disabled:opacity-50' disabled={processando} onClick={(event) => {
                                  event.stopPropagation();  // Adicione esta linha
                                  alterarOrdem(aula._id, 1);
                                }}>▼</button>}
                              </span>
                            </td>
                          }
                          <td>{aula.titulo}</td>
                          <td>{aula.descricao}</td>
                          <td>
                            <button className="btnIcone disabled:opacity-50" disabled={processando} onClick={(event) => {
                              event.stopPropagation();  // Adicione esta linha
                              excluirAula(aula._id, aula.titulo);
                            }}>
                              <svg width="30px" height="30px" viewBox="-2.4 -2.4 28.80 28.80" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#a1a1a1" transform="matrix(1, 0, 0, 1, 0, 0)rotate(0)">
                                <g strokeWidth="0">
                                  <path transform="translate(-2.4, -2.4), scale(1.7999999999999998)" fill="#efefefaaaaaa" d="M9.166.33a2.25 2.25 0 00-2.332 0l-5.25 3.182A2.25 2.25 0 00.5 5.436v5.128a2.25 2.25 0 001.084 1.924l5.25 3.182a2.25 2.25 0 002.332 0l5.25-3.182a2.25 2.25 0 001.084-1.924V5.436a2.25 2.25 0 00-1.084-1.924L9.166.33z" strokeWidth="0" />
                                </g>
                                <g strokeLinecap="round" strokeLinejoin="round" />
                                <g>
                                  <path d="M10 12V17" stroke="#efefef" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /> <path d="M14 12V17" stroke="#efefef" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /> <path d="M4 7H20" stroke="#efefef" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /> <path d="M6 10V18C6 19.6569 7.34315 21 9 21H15C16.6569 21 18 19.6569 18 18V10" stroke="#efefef" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /> <path d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z" stroke="#efefef" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </g>
                              </svg>
                            </button>
                          </td>
                        </tr>
                      ))}
                      <tr>
                        {aulas.length > 1 &&
                          <td className='w-fit'></td>
                        }
                        <td>
                          <input type="text" value={novoTituloAula} onChange={e => setNovoTituloAula(e.target.value)} className="input-master disabled:opacity-50" disabled={processando} placeholder="Título" />
                          {erroNovoTituloAula && <span className="error-master">{erroNovoTituloAula}</span>}
                        </td>
                        <td>
                          <textarea
                            value={novaDescricaoAula}
                            onChange={e => handleTextChange(e, 'create')}
                            className="input-master disabled:opacity-50" disabled={processando}
                            placeholder="Descrição"
                            rows={createRows}
                          />
                          {erroNovaDescricaoAula && <span className="error-master">{erroNovaDescricaoAula}</span>}
                        </td>
                        <td>
                          <button className="btnIcone disabled:opacity-50" disabled={processando} onClick={adicionarAula}>
                            <svg width="30px" height="30px" viewBox="-2.4 -2.4 28.80 28.80" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#efefef">
                              <g strokeWidth="0" />
                              <g strokeLinecap="round" strokeLinejoin="round" />
                              <g>
                                <path d="M12.75 9C12.75 8.58579 12.4142 8.25 12 8.25C11.5858 8.25 11.25 8.58579 11.25 9L11.25 11.25H9C8.58579 11.25 8.25 11.5858 8.25 12C8.25 12.4142 8.58579 12.75 9 12.75H11.25V15C11.25 15.4142 11.5858 15.75 12 15.75C12.4142 15.75 12.75 15.4142 12.75 15L12.75 12.75H15C15.4142 12.75 15.75 12.4142 15.75 12C15.75 11.5858 15.4142 11.25 15 11.25H12.75V9Z" fill="#efefef" /> <path fillRule="evenodd" clipRule="evenodd" d="M12.0574 1.25H11.9426C9.63424 1.24999 7.82519 1.24998 6.41371 1.43975C4.96897 1.63399 3.82895 2.03933 2.93414 2.93414C2.03933 3.82895 1.63399 4.96897 1.43975 6.41371C1.24998 7.82519 1.24999 9.63422 1.25 11.9426V12.0574C1.24999 14.3658 1.24998 16.1748 1.43975 17.5863C1.63399 19.031 2.03933 20.1711 2.93414 21.0659C3.82895 21.9607 4.96897 22.366 6.41371 22.5603C7.82519 22.75 9.63423 22.75 11.9426 22.75H12.0574C14.3658 22.75 16.1748 22.75 17.5863 22.5603C19.031 22.366 20.1711 21.9607 21.0659 21.0659C21.9607 20.1711 22.366 19.031 22.5603 17.5863C22.75 16.1748 22.75 14.3658 22.75 12.0574V11.9426C22.75 9.63423 22.75 7.82519 22.5603 6.41371C22.366 4.96897 21.9607 3.82895 21.0659 2.93414C20.1711 2.03933 19.031 1.63399 17.5863 1.43975C16.1748 1.24998 14.3658 1.24999 12.0574 1.25ZM3.9948 3.9948C4.56445 3.42514 5.33517 3.09825 6.61358 2.92637C7.91356 2.75159 9.62177 2.75 12 2.75C14.3782 2.75 16.0864 2.75159 17.3864 2.92637C18.6648 3.09825 19.4355 3.42514 20.0052 3.9948C20.5749 4.56445 20.9018 5.33517 21.0736 6.61358C21.2484 7.91356 21.25 9.62177 21.25 12C21.25 14.3782 21.2484 16.0864 21.0736 17.3864C20.9018 18.6648 20.5749 19.4355 20.0052 20.0052C19.4355 20.5749 18.6648 20.9018 17.3864 21.0736C16.0864 21.2484 14.3782 21.25 12 21.25C9.62177 21.25 7.91356 21.2484 6.61358 21.0736C5.33517 20.9018 4.56445 20.5749 3.9948 20.0052C3.42514 19.4355 3.09825 18.6648 2.92637 17.3864C2.75159 16.0864 2.75 14.3782 2.75 12C2.75 9.62177 2.75159 7.91356 2.92637 6.61358C3.09825 5.33517 3.42514 4.56445 3.9948 3.9948Z" fill="#efefef" />
                              </g>
                            </svg>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              }
            </div>
          </div>
        }
      </div>
    </div>
  )
}

export default MasterCursoAdmin