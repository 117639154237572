import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation, Link } from 'react-router-dom';
import axios from 'axios';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { toast } from 'react-toastify';
import TextInput from './inputs/text-input.js';
import RadioGroup from './inputs/radio-group.js';
import RadioWithOtherOption from './inputs/radio-group-with-other-option.js';
import RadioList from './inputs/radio-list.js';
import TextArea from './inputs/text-area.js';

const DiagnosticForm = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const header_geral = { Authorization: `Bearer ${localStorage.getItem("token")}` };

    const usuarioAtual = useSelector(state => state.usuarioAtual);
    const { onboarding } = usuarioAtual;
    const [novato, setNovato] = useState(true);
    const [secoes, setSecoes] = useState([]);

    const [paginaTipo, setPaginaTipo] = useState(0); // 0: Boas-vindas; 1: Capa de seção; 2: Questão
    const [etapaSecao, setEtapaSecao] = useState(0);
    const [paginaQuestao, setPaginaQuestao] = useState(0);
    const [respostas, setRespostas] = useState([]);

    const [secaoAtual, setSecaoAtual] = useState(null);
    const [questaoAtual, setQuestaoAtual] = useState(null);
    const [qtdSecoes, setQtdSecoes] = useState(0);
    const [qtdQuestoes, setQtdQuestoes] = useState(0);
    const [fimFormulario, setFimFormulario] = useState(false);
    const [isValid, setIsValid] = useState(false);

    useEffect(() => {
        const savedPaginaTipo = localStorage.getItem("paginaTipo");
        const savedEtapaSecao = localStorage.getItem("etapaSecao");
        const savedPaginaQuestao = localStorage.getItem("paginaQuestao");
        const savedRespostas = localStorage.getItem("respostas");
        const savedSecaoAtual = localStorage.getItem("secaoAtual");
        const savedQuestaoAtual = localStorage.getItem("questaoAtual");
        const savedQtdSecoes = localStorage.getItem("qtdSecoes");
        const savedQtdQuestoes = localStorage.getItem("qtdQuestoes");
        const savedFimFormulario = localStorage.getItem("fimFormulario");
        const savedIsValid = localStorage.getItem("isValid");

        if (savedPaginaTipo) setPaginaTipo(parseInt(savedPaginaTipo));
        if (savedEtapaSecao) setEtapaSecao(parseInt(savedEtapaSecao));
        if (savedPaginaQuestao) setPaginaQuestao(parseInt(savedPaginaQuestao));
        if (savedRespostas) setRespostas(JSON.parse(savedRespostas));
        if (savedSecaoAtual) setSecaoAtual(JSON.parse(savedSecaoAtual));
        if (savedQuestaoAtual) setQuestaoAtual(JSON.parse(savedQuestaoAtual));
        if (savedQtdSecoes) setQtdSecoes(parseInt(savedQtdSecoes));
        if (savedQtdQuestoes) setQtdQuestoes(parseInt(savedQtdQuestoes));
        if (savedFimFormulario) setFimFormulario(JSON.parse(savedFimFormulario));
        if (savedIsValid) setIsValid(JSON.parse(savedIsValid));
    }, []);


    useEffect(() => {
        setNovato(!onboarding.etapas.includes('diagnostico'));
    }, [onboarding]);

    useEffect(() => {
        if (secoes.length === 0) return;

        setQtdSecoes(secoes.length);

        const secao_atual = secoes[etapaSecao];
        const questao_atual = secoes[etapaSecao].questoes[paginaQuestao];

        setSecaoAtual(secao_atual);
        setQtdQuestoes(secao_atual?.questoes?.length);

        setQuestaoAtual(questao_atual);

        const ultimaSecao = secoes.length - 1;
        const ultimaQuestao = secao_atual.questoes.length - 1 || 0;

        const fimSecao = etapaSecao === ultimaSecao;
        const fimQuestao = paginaQuestao === ultimaQuestao;

        setFimFormulario(fimSecao && fimQuestao);
    }, [secoes, etapaSecao, paginaQuestao]);

    useEffect(() => {
        let isMounted = true;

        const fetchSecoes = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/formulario-diagnostico/secoes-com-questoes`, { headers: header_geral });

                if (isMounted && response.status === 200) {
                    setSecoes(response.data);
                    console.log("SEÇÕES COM QUESTÕES: ", response.data);
                } else {
                    console.log(response);
                }
            } catch (error) {
                console.error(error);
            }
        };

        if (usuarioAtual && novato) fetchSecoes();

        return () => { isMounted = false; };
    }, [usuarioAtual, novato]);

    useEffect(() => {
        localStorage.setItem("paginaTipo", paginaTipo);
        localStorage.setItem("etapaSecao", etapaSecao);
        localStorage.setItem("paginaQuestao", paginaQuestao);
        localStorage.setItem("respostas", JSON.stringify(respostas));
        localStorage.setItem("secaoAtual", JSON.stringify(secaoAtual));
        localStorage.setItem("questaoAtual", JSON.stringify(questaoAtual));
        localStorage.setItem("qtdSecoes", qtdSecoes);
        localStorage.setItem("qtdQuestoes", qtdQuestoes);
        localStorage.setItem("fimFormulario", fimFormulario);
        localStorage.setItem("isValid", isValid);
    }, [paginaTipo, etapaSecao, paginaQuestao, respostas, secaoAtual, questaoAtual, qtdSecoes, qtdQuestoes, fimFormulario, isValid]);


    const nextSection = () => {
        setPaginaQuestao(0);
        setEtapaSecao(etapaSecao + 1);
        setPaginaTipo(1);
    };

    const previousSection = () => {
        setPaginaQuestao(0);
        setEtapaSecao(etapaSecao - 1);
        setPaginaTipo(1);
    };

    const nextPage = () => {
        if (paginaTipo === 0) {
            setPaginaTipo(1);
        } else if (paginaTipo === 1) {
            setPaginaTipo(2);
        } else if (paginaTipo === 2) {
            const isLastQuestion = secoes[etapaSecao]?.questoes?.length === paginaQuestao + 1;
            if (isLastQuestion) {
                setPaginaQuestao(0);
                setEtapaSecao(etapaSecao + 1);
                setPaginaTipo(1);
            } else {
                setPaginaQuestao(paginaQuestao + 1);
            }
        }
    };

    const previousPage = () => {
        if (paginaTipo === 1) {
            if (etapaSecao === 0 && paginaQuestao === 0) {
                setPaginaTipo(0);
            } else {
                setPaginaQuestao(secoes[etapaSecao - 1]?.questoes?.length - 1 || 0);
                setEtapaSecao(etapaSecao - 1);
                setPaginaTipo(2);
            }
        } else if (paginaTipo === 2) {
            if (paginaQuestao === 0) {
                setPaginaTipo(1);
            } else {
                setPaginaQuestao(paginaQuestao - 1);
            }
        }
    };

    const getTotalQuestoes = () => {
        return secoes.reduce((total, secao) => total + (secao.questoes?.length || 0), 0);
    };

    const updateAnswer = (questaoId, valor, valid = true) => {
        console.log("ID: ", questaoId);
        setRespostas((prevRespostas) => ({
            ...prevRespostas,
            [questaoId]: valor,
        }));
        setIsValid(valid);
    };

    const finishForm = () => {
        console.log("Formulário Finalizado!")
    }

    const resetForm = () => {
        // Resetar todos os estados para seus valores iniciais
        setPaginaTipo(0); // ou o valor inicial apropriado
        setEtapaSecao(0); // ou o valor inicial apropriado
        setPaginaQuestao(0); // ou o valor inicial apropriado
        setRespostas([]); // ou o valor inicial apropriado
        // setSecaoAtual(null); // ou o valor inicial apropriado
        // setQuestaoAtual(null); // ou o valor inicial apropriado
        // setQtdSecoes(0); // ou o valor inicial apropriado
        // setQtdQuestoes(0); // ou o valor inicial apropriado
        // setFimFormulario(false); // ou o valor inicial apropriado

        // Limpar as entradas do localStorage
        localStorage.removeItem("paginaTipo");
        localStorage.removeItem("etapaSecao");
        localStorage.removeItem("paginaQuestao");
        localStorage.removeItem("respostas");
        //localStorage.removeItem("secaoAtual");
        //localStorage.removeItem("questaoAtual");
        //localStorage.removeItem("qtdSecoes");
        //localStorage.removeItem("qtdQuestoes");
        //localStorage.removeItem("fimFormulario");
    };


    return (
        <div
            className="w-11/12 mx-auto space-y-5 text-white">
            {novato &&
                <section className="p-20 bg-neutral-800 rounded-lg border border-neutral-600 bg-center bg-cover bg-no-repeat before:opacity-50">
                    {paginaTipo === 0 ?
                        <div className='space-y-10'>
                            <div className='font-poppins font-semibold space-y-2'>
                                <h3 className='text-xl text-neutral-500'>Formulário Diagnóstico</h3>
                                <h2 className='text-5xl text-white'>Comece por aqui</h2>
                            </div>
                            <div className='text-justify text-lg text-neutral-200 space-y-5'>
                                <p>Bem-vindo(a), aqui você pode dar o primeiro passo na criação de um diagnóstico da sua jornada como empreendedor. Aqui, você responderá algumas perguntas que ajudarão a mapear sua situação atual. Ao concluir, terá acesso a uma visão gráfica do seu progresso, que poderá atualizar sempre que desejar acompanhar sua evolução.</p>
                                <p className='italic text-xs fint-light'>({getTotalQuestoes()} questões | Tempo estimado: {(10 * getTotalQuestoes() / 60).toFixed(2)} minutos)</p>
                            </div>
                            <div className='select-none'>
                                <button type='button' className='bg-orange-500 hover:bg-white active:bg-neutral-50 text-xl text-white hover:text-orange-500 active:text-orange-600 font-poppins font-semibold px-10 py-3 rounded-sm animate-pulse hover:animate-none' onClick={() => nextPage()}>Vamos Lá</button>
                            </div>
                        </div>
                        :
                        paginaTipo === 1 ?

                            <div className='space-y-10'>
                                <div className='font-poppins font-semibold space-y-2'>
                                    <div className='flex justify-between select-none'>
                                        <h3 className='text-xl text-neutral-500'>Seção {etapaSecao + 1}</h3>
                                        <span className='text-neutral-500 flex gap-3 items-center'>
                                            {etapaSecao > 0 && <span className='hover:scale-125 cursor-pointer' onClick={() => previousSection()}><ChevronLeftIcon className="w-4 h-4" /></span>}
                                            <span>{etapaSecao + 1} / {qtdSecoes}</span>
                                            {etapaSecao < (qtdSecoes - 1) && <span className='hover:scale-125 cursor-pointer' onClick={() => nextSection()}><ChevronRightIcon className="w-4 h-4" /></span>}
                                        </span>
                                    </div>
                                    <h2 className='text-5xl text-white'>{secaoAtual.titulo}</h2>
                                </div>
                                <div className='text-justify text-lg text-neutral-200 space-y-5'>
                                    <p>{secaoAtual.descricao}</p>
                                    <p className='text-yellow-500'>{secaoAtual.instrucoes}</p>
                                    <p className='italic text-xs fint-light'>({qtdQuestoes} questões | Tempo estimado: {(10 * qtdQuestoes / 60).toFixed(2)} minutos)</p>
                                </div>
                                <div className='flex justify-between select-none'>
                                    <button type='button' className='bg-white hover:bg-orange-500 active:bg-neutral-50 text-xl text-orange-500 hover:text-white active:text-white font-poppins font-semibold px-10 py-3 rounded-sm' onClick={() => previousPage()}>Voltar</button>
                                    <button type='button' className='bg-orange-500 hover:bg-white active:bg-neutral-50 text-xl text-white hover:text-orange-500 active:text-orange-600 font-poppins font-semibold px-10 py-3 rounded-sm' onClick={() => nextPage()}>Preecher {secaoAtual.titulo}</button>
                                </div>
                            </div>

                            :

                            <div className='space-y-10'>
                                <div className='font-poppins font-semibold space-y-2'>
                                    <div className='flex justify-between select-none'>
                                        <h3 className='text-xl text-neutral-500'>Seção {etapaSecao + 1}: {secaoAtual.titulo}</h3>
                                        <span className='text-neutral-500 flex gap-3 items-center'>
                                            {etapaSecao > 0 && <span className='hover:scale-125 cursor-pointer' onClick={() => previousSection()}><ChevronLeftIcon className="w-4 h-4" /></span>}
                                            <span>{etapaSecao + 1} / {qtdSecoes}</span>
                                            {etapaSecao < (qtdSecoes - 1) && <span className='hover:scale-125 cursor-pointer' onClick={() => nextSection()}><ChevronRightIcon className="w-4 h-4" /></span>}
                                        </span>
                                    </div>
                                    <h2 className={`text-2xl text-white ${!questaoAtual.anulavel ? "after:content-['*'] after:text-orange-500" : ""}`}>{questaoAtual.etiqueta}</h2>
                                </div>
                                <div className='text-justify text-lg text-neutral-200 space-y-5'>
                                    {questaoAtual.tipo_questao === 0 && (
                                        <TextInput
                                            label={questaoAtual.etiqueta}
                                            value={respostas[questaoAtual._id] || ''}
                                            onChange={(value, valid) => updateAnswer(questaoAtual._id, value, valid)}
                                            isRequired={!questaoAtual.anulavel}
                                            validacao={questaoAtual.validacao}
                                            tipoValidacao={questaoAtual.tipo_validacao}
                                            paginaQuestao={questaoAtual._id}
                                        />
                                    )}
                                    {questaoAtual.tipo_questao === 1 && (
                                        <RadioGroup
                                            label={questaoAtual.etiqueta}
                                            options={questaoAtual.opcoes}
                                            selectedOption={respostas[questaoAtual._id] || null}
                                            onChange={(codigo, valid) => updateAnswer(questaoAtual._id, codigo, valid)}
                                            isRequired={!questaoAtual.anulavel}
                                            paginaQuestao={questaoAtual._id}
                                        />
                                    )}
                                    {questaoAtual.tipo_questao === 2 && (
                                        <RadioWithOtherOption
                                            label={questaoAtual.etiqueta}
                                            options={questaoAtual.opcoes}
                                            selectedOption={respostas[questaoAtual._id]?.codigo || ''}
                                            otherValue={respostas[questaoAtual._id]?.other || ''}
                                            onChange={(codigo, valid) => updateAnswer(questaoAtual._id, codigo === 'other' ? { codigo: 'other', other: respostas[questaoAtual._id]?.other || '' } : { codigo }, valid)}
                                            onOtherChange={(value, valid) => updateAnswer(questaoAtual._id, { codigo: 'other', other: value }, valid)}
                                            isRequired={!questaoAtual.anulavel}
                                            paginaQuestao={questaoAtual._id}
                                        />
                                    )}
                                    {questaoAtual.tipo_questao === 3 && (
                                        <RadioList
                                            value={respostas[questaoAtual._id] || null}
                                            onChange={(valor, valid) => updateAnswer(questaoAtual._id, valor, valid)}
                                            isRequired={!questaoAtual.anulavel}
                                            paginaQuestao={questaoAtual._id}
                                        />
                                    )}
                                    {questaoAtual.tipo_questao === 4 &&
                                        <TextArea
                                            label={questaoAtual.etiqueta}
                                            value={respostas[questaoAtual._id] || ''}
                                            onChange={(value, valid) => updateAnswer(questaoAtual._id, value, valid)}
                                            isRequired={!questaoAtual.anulavel}
                                            paginaQuestao={questaoAtual._id}
                                        />
                                    }
                                </div>
                                <div className='flex justify-between items-center select-none'>
                                    <button type='button' className='bg-white hover:bg-orange-500 active:bg-neutral-50 text-xl text-orange-500 hover:text-white active:text-white font-poppins font-semibold px-10 py-3 rounded-sm' onClick={() => previousPage()}>Voltar</button>
                                    <div className='text-right text-neutral-300'>
                                        Questão {paginaQuestao + 1} / {qtdQuestoes}
                                    </div>
                                    {fimFormulario ?
                                        <button type='button' className='bg-yellow-500 hover:bg-white active:bg-neutral-50 text-xl text-white hover:text-yellow-500 active:text-yellow-600 font-poppins font-semibold px-10 py-3 rounded-sm animate-pulse hover:animate-none' onClick={() => finishForm()}>Salvar Dados</button>
                                        :
                                        <div>
                                            {isValid ?
                                                <button type='button' className='bg-orange-500 hover:bg-white active:bg-neutral-50 text-xl text-white hover:text-orange-500 active:text-orange-600 font-poppins font-semibold px-10 py-3 rounded-sm' onClick={() => nextPage()}>Próximo</button>
                                                :
                                                <p>Preecha o campo corretamente</p>
                                            }
                                        </div>
                                    }

                                </div>
                            </div>
                    }
                </section>
            }
            <button type="button" onClick={() => resetForm()}>Resetar formulário</button>
        </div >
    );
};

export default DiagnosticForm;
