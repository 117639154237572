import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setUsuario } from '../features/usuario/usuarioAtual';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';

import Header from '../components/header';
import Menu from '../components/menu';
import Footer from '../components/footer'
import Aside from '../components/aside';
import PopupComprarCreditos from '../components/popups/add_creditos';
import PopupAgendamento from '../components/popups/add_consultoria';
import { useHistory } from 'react-router-dom';

import './meus-colaboradores.css';


const MeusColaboradores = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [popupColaboradorVisible, setPopupColaboradorVisible] = useState(false);
  const [popupCreditosVisible, setPopupCreditosVisible] = useState(false);
  const [popupAgendamentoVisible, setPopupAgendamentoVisible] = useState(false);
  const usuarioAtual = useSelector((state) => state.usuarioAtual);
  const [asideVisible, setAsideVisible] = useState(window.innerWidth > 991 ? true : false);

  const [colaboradores, setColaboradores] = useState([]);
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');

  const [loading, setLoading] = useState(true);

  const isMounted = useRef(true);

  const [showMenu, setShowMenu] = useState(window.innerWidth >= 1024);

  const onboardingColaboradores = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/usuarios/onboarding`,
        {
          etapa: 'adicionar-time',
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );

      if (response.data && response.data.usuario) {
        dispatch(setUsuario(response.data.usuario));
      }
    } catch (err) { }
  };

  async function fetchColaboradores() {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/usuarios/meus-colaboradores`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      // Verificar se o componente ainda está montado antes de atualizar o estado
      if (!isMounted.current) {
        return;
      }

      setColaboradores(response.data);
      setLoading(false);
    } catch (error) {
      // Verificar se o componente ainda está montado antes de atualizar o estado
      if (!isMounted.current) {
        return;
      }

      setLoading(false);
    }
  }

  const adicionarColaborador = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/usuarios/adicionar-colaborador`,
        {
          nome,
          email,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );

      if (response.data && response.data.message) {
        toast.success(response.data.message);
        setPopupColaboradorVisible(false);
        setNome('');
        setEmail('');

        const usuario = {
          ...usuarioAtual,
          creditos: usuarioAtual.creditos - 80,
        };

        dispatch(setUsuario(usuario));
        fetchColaboradores();

        if (
          !usuarioAtual.onboarding ||
          !usuarioAtual.onboarding.etapas ||
          !usuarioAtual.onboarding.etapas.includes('adicionar-time')
        ) {
          onboardingColaboradores();
          console.log('feito');
        }
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message)
        toast.error(error.response.data.message);
    }
  };

  const deletarColaborador = async (colaboradorEmail, colaboradorNome) => {
    if (!confirm("Tem certeza que deseja excluir o usuário " + colaboradorNome + "? Esta ação não pode ser desfeita!")) return;
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/usuarios/deletar-colaborador/${colaboradorEmail}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );

      if (response.data && response.data.message) {
        toast.success(response.data.message);


        const usuario = {
          ...usuarioAtual,
          creditos: usuarioAtual.creditos + 80,
        };

        dispatch(setUsuario(usuario));

        // Atualizar o estado ou recarregar a lista de colaboradores após a exclusão
        fetchColaboradores();
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      }
    }
  };

  useEffect(() => {
    return () => {
      // Define isMounted como false quando o componente é desmontado
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    // Montar o componente
    isMounted.current = true;

    fetchColaboradores();

    // Desmontar o componente
    return () => {
      isMounted.current = false;
    };
  }, []);


  return (
    <div className='w-full'>
      <Helmet>
        <title>Simbium</title>
        <meta property="og:title" content="Simbium" />
      </Helmet>
      <Header usuarioAtual={usuarioAtual} showMenu={showMenu} setShowMenu={setShowMenu} />
      <div className='flex flex-col space-y-10'>
        <div className='lg:p-5 flex flex-row lg:mt-14 pt-5 lg:pt-10 space-x-5'>
          {showMenu &&
            <div className='w-3/12 mb-5'>
              <Menu usuarioAtual={usuarioAtual} showMenu={showMenu} setShowMenu={setShowMenu} />
            </div>
          }
          <div className={`${showMenu ? 'w-9/12' : 'w-11/12'} mb-5 mx-auto`}>
            <div className="flex flex-col gap-8 w-full p-7 bg-[#212121] rounded-lg">
              <div className="flex flex-col gap-4">
                {popupCreditosVisible && (
                  <PopupComprarCreditos fecharPopup={() => setPopupCreditosVisible(false)} />
                )}
                <div className="meus-colaboradores-container04">
                  <svg
                    viewBox="0 0 1024 1024"
                    className="meus-colaboradores-icon"
                  >
                    <path d="M598 256v-86h-172v86h172zM854 256q36 0 60 25t24 61v468q0 36-24 61t-60 25h-684q-36 0-60-25t-24-61v-468q0-36 24-61t60-25h172v-86q0-36 24-60t60-24h172q36 0 60 24t24 60v86h172z"></path>
                  </svg>
                  <span className="meus-colaboradores-text">
                    Meus Colaboradores
                  </span>
                </div>

                {usuarioAtual.ref.plano && usuarioAtual.ref.plano !== process.env.REACT_APP_PLANO_AVIAO ?
                  <span className="meus-colaboradores-text01">
                    <span>
                      {usuarioAtual.ref.plano === process.env.REACT_APP_PLANO_FOGUETE && <p>Você já pode incluir 3 colaboradores sem custo algum!" < br /></p>}Você pode adicionar mais colaboradores com preços especiais clicando em <strong className="meus-colaboradores-comprar-txt" onClick={() => setPopupCreditosVisible(true)} >Comprar</strong>.
                    </span>
                  </span>
                  :
                  <span className="meus-colaboradores-text01">
                    <span>
                      Não é possível comprar créditos ou adicionar Colaboradores no Plano Avião
                    </span>
                  </span>
                }
                <div className="meus-colaboradores-creditos">
                  <span className="meus-colaboradores-text06 text-sm">Você possui <span className='text-yellow-500'>{usuarioAtual.creditos}</span> crédito(s)</span>
                  {usuarioAtual.ref.plano && usuarioAtual.ref.plano !== process.env.REACT_APP_PLANO_AVIAO &&
                    <button className="meus-colaboradores-text07" onClick={() => setPopupCreditosVisible(true)}>Comprar</button>
                  }
                </div>
                <div className="meus-colaboradores-container05">
                  {usuarioAtual.ref.plano && usuarioAtual.ref.plano !== process.env.REACT_APP_PLANO_AVIAO && usuarioAtual.creditos >= 80 ? (
                    <button className="meus-colaboradores-b-continuar-aula" onClick={() => setPopupColaboradorVisible(true)}>
                      <span className="meus-colaboradores-text08">
                        Adicionar Colaborador
                      </span>
                    </button>
                  ) : (
                    <button className="meus-colaboradores-b-continuar-aula" title="Saldo insuficiente!" disabled>
                      <span className="meus-colaboradores-text08">
                        Adicionar Colaborador
                      </span>
                    </button>
                  )}
                  <div className="meus-colaboradores-colaboradores">
                    <div className="meus-colaboradores-container06">
                      <div className="meus-colaboradores-container07">
                        <div className="meus-colaboradores-container08">
                          <span className="meus-colaboradores-text09">Nome</span>
                          <span className="meus-colaboradores-text10">E-mail</span>
                          <span className="meus-colaboradores-text11">Acesso</span>
                          <span className="meus-colaboradores-header-deletar">Deletar</span>
                        </div>
                        <div className="meus-colaboradores-infos">
                          {colaboradores.length > 0 ? (
                            colaboradores.map((colaborador, index) => (
                              <div key={index} className="meus-colaboradores-container09">
                                <span className="meus-colaboradores-text12">
                                  {colaborador.nome}
                                </span>
                                <span className="meus-colaboradores-text13">
                                  {colaborador.email}
                                </span>
                                <span className="meus-colaboradores-text14">
                                  {colaborador.acesso}
                                </span>
                                <span onClick={() => deletarColaborador(colaborador.email, colaborador.nome)} className="meus-colaboradores-body-deletar">
                                  Deletar
                                </span>
                              </div>

                            ))

                          ) : (

                            <div className="meus-colaboradores-infos1">
                              <span className="meus-colaboradores-text15">
                                Nenhum colaborador adicionado
                              </span>
                            </div>

                          )}

                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              {popupColaboradorVisible && (
                <div className="meus-colaboradores-popup-agendamento">
                  <div className="meus-colaboradores-conteudo1">
                    <div className="meus-colaboradores-container11">
                      <Link to="/" className="meus-colaboradores-navlink">
                        <img
                          alt="image"
                          src="/image%209-200h.png"
                          className="meus-colaboradores-logo"
                        />
                      </Link>
                      <span className="meus-colaboradores-text19">
                        Adicionar colaborador
                      </span>
                    </div>
                    <div className="meus-colaboradores-inputs">
                      <div className="meus-colaboradores-container12">
                        <svg
                          viewBox="0 0 731.4285714285713 1024"
                          className="meus-colaboradores-icon2"
                        >
                          <path d="M731.429 799.429c0 83.429-54.857 151.429-121.714 151.429h-488c-66.857 0-121.714-68-121.714-151.429 0-150.286 37.143-324 186.857-324 46.286 45.143 109.143 73.143 178.857 73.143s132.571-28 178.857-73.143c149.714 0 186.857 173.714 186.857 324zM585.143 292.571c0 121.143-98.286 219.429-219.429 219.429s-219.429-98.286-219.429-219.429 98.286-219.429 219.429-219.429 219.429 98.286 219.429 219.429z"></path>
                        </svg>
                        <input
                          type="text"
                          placeholder="Nome"
                          value={nome}
                          onChange={(e) => setNome(e.target.value)}
                          className="meus-colaboradores-textinput input"
                        />
                      </div>
                      <div className="meus-colaboradores-container13">
                        <svg viewBox="0 0 1024 1024" className="meus-colaboradores-icon4">
                          <path d="M854 342v-86l-342 214-342-214v86l342 212zM854 170q34 0 59 26t25 60v512q0 34-25 60t-59 26h-684q-34 0-59-26t-25-60v-512q0-34 25-60t59-26h684z"></path>
                        </svg>
                        <input
                          type="text"
                          placeholder="Email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              adicionarColaborador()
                            }
                          }}
                          className="meus-colaboradores-textinput1 input"
                        />
                      </div>
                    </div>
                    <div className="meus-colaboradores-container14">
                      <div className="meus-colaboradores-b-entrar" onClick={() => { adicionarColaborador() }}>
                        <svg
                          viewBox="0 0 804.5714285714286 1024"
                          className="meus-colaboradores-icon6"
                        >
                          <path d="M804.571 420.571v109.714c0 30.286-24.571 54.857-54.857 54.857h-237.714v237.714c0 30.286-24.571 54.857-54.857 54.857h-109.714c-30.286 0-54.857-24.571-54.857-54.857v-237.714h-237.714c-30.286 0-54.857-24.571-54.857-54.857v-109.714c0-30.286 24.571-54.857 54.857-54.857h237.714v-237.714c0-30.286 24.571-54.857 54.857-54.857h109.714c30.286 0 54.857 24.571 54.857 54.857v237.714h237.714c30.286 0 54.857 24.571 54.857 54.857z"></path>
                        </svg>
                        <span className="meus-colaboradores-text20">Adicionar</span>
                      </div>
                      <span className="meus-colaboradores-text21">
                        <span onClick={() => setPopupColaboradorVisible(false)}>
                          Cancelar
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              )}
              {popupAgendamentoVisible && (
                <PopupAgendamento erro={(e) => { toast.error(e) }} sucesso={() => toast.success("Consultoria agendada com sucesso!")} fecharPopup={() => setPopupAgendamentoVisible(false)} />
              )}

            </div>
          </div>

        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}

export default MeusColaboradores
