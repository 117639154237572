import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ZipCodeInput from '../form/zip-code-input';
import CreditCardNumberInput from '../form/credit-card-number-input';
import DocumentInputPayment from '../form/document-input-payment';

const ModalCompraFormularioPagamento = ({ usuarioAtual, paymentMethod, setPaymentMethod, cardsList, card, setCardsList, setCard, cardErrors, setCardErrors, installments, setInstallments, tarifas, setTarifas, selectedInstallment, setSelectedInstallment, toMoney }) => {

    const [loadingCardList, setLoadingCardList] = useState(false)

    useEffect(() => {
        let isMounted = true;

        const fetchCardsList = async () => {
            setLoadingCardList(true)
            try {
                const consumidorId = usuarioAtual.consumidorId;

                if (!consumidorId) {
                    setLoadingCardList(false)
                    return
                };

                const response = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/pagarme/list-cards/`, {
                    consumidorId: consumidorId,
                    apiKey: process.env.REACT_APP_PAGARME_TOKEN,
                }, {
                    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
                });

                if (isMounted && response.data) {

                    const today = new Date();

                    setCardsList(response.data.data.data.filter(cardItem =>
                        cardItem.type === paymentMethod &&
                        cardItem.status === 'active' &&
                        (cardItem.exp_year > today.getFullYear() ||
                            (cardItem.exp_year === today.getFullYear() && cardItem.exp_month >= (today.getMonth() + 1)))
                    ));

                } else {
                    console.log("Resposta do servidor sem dados válidos: ", response);
                }

            } catch (error) {
                console.log("Erro ao buscar dados do cliente: ", error.message);
            }
            resetForm()
            setLoadingCardList(false)
        };

        if (paymentMethod && paymentMethod !== 'ticket') {
            fetchCardsList()
        }

        return () => {
            isMounted = false;
        };
    }, [paymentMethod])

    const PaymentMethodOption = ({ id, label, value, selectedMethod, setPaymentMethod }) => (
        <label
            htmlFor={id}
            className={`rounded-md border-2 border-neutral-700 w-full flex flex-row items-center gap-3 p-2 cursor-pointer ${selectedMethod === value ? 'bg-orange-600' : ''} hover:bg-orange-500 active:bg-orange-700 select-none`}
        >
            <input
                id={id}
                type="radio"
                name="payment-method"
                value={value}
                onChange={() => setPaymentMethod(value)}
                className='hidden'
            />
            <span className='text-white'>{label}</span>
        </label>
    );

    const [disableCardList, setDisableCardList] = useState(false)
    const [disableForm, setDisableForm] = useState(true)

    const selectCard = async (selectedCard) => {
        setDisableForm(true)
        setDisableCardList(true)
        try {
            const consumidorId = usuarioAtual.consumidorId;
            if (!consumidorId) {
                console.log("Consumidor ID não encontrado");
                return;
            }

            if (!selectedCard) {
                console.log("Nenhum cartão selecionado");
                return;
            }

            const response = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/pagarme/get-card/`, {
                consumidorId: consumidorId,
                cardId: selectedCard.id,
                apiKey: process.env.REACT_APP_PAGARME_TOKEN,
            }, {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
            });

            if (response.data.data) {
                setCard(response.data.data);
                setCard(prevCard => ({
                    ...prevCard,
                    ['cvv']: '',
                }));
            } else {
                console.log("Resposta do servidor sem dados válidos: ", response);
                setDisableForm(false)
            }
        } catch (error) {
            console.log("Erro ao buscar dados do cliente: ", error.message);
            setDisableForm(false)
        }
        setDisableCardList(false)
    };

    const resetForm = () => {
        setCard({
            id: '',
            brand: '',
            exp_month: 0,
            exp_year: 0,
            number: '',
            holder_document: '',
            holder_name: '',
            id: '',
            status: '',
            type: '',
            cvv: '',
        })
        setDisableForm(false)
    }

    useEffect(() => {
        const errors = {};
        const monthCard = card.exp_month;
        const yearCard = card.exp_year;
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth() + 1;
        const currentYear = currentDate.getFullYear();

        if (isNaN(monthCard) || monthCard < 1 || monthCard > 12) {
            errors.exp_month = 'Mês inválido';
        } else if (yearCard < currentYear) {
            errors.exp_year = 'Ano inválido';
        } else if (yearCard === currentYear && monthCard < currentMonth) {
            errors.exp_month = 'Mês inválido';
        } else if (yearCard < currentYear) {
            errors.exp_year = 'Ano inválido';
        }

        setCardErrors(errors);

    }, [card.exp_month, card.exp_year]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        let errors = {};

        // Validação do nome
        if (name === 'holder_name') {
            const check_name = value.trim().replace(/\s{2,}/g, ' ');
            if (!/^[a-zA-Z\s]+$/.test(check_name) || check_name.split(' ').length < 2) {
                errors.holder_name = 'O nome deve conter pelo menos duas palavras separadas por espaço e só pode conter letras.';
            }
        }

        // Validação do CVV
        if (name === 'cvv') {
            if (!/^\d+$/.test(value)) {
                errors.cvv = 'O CVV só pode conter números.';
            }
        }

        // Atualiza os erros se houverem
        setCardErrors(errors);

        // Atualiza o estado do cartão
        setCard(prevCard => ({
            ...prevCard,
            [name]: (name === 'exp_month' || name === 'exp_year') ? (value ? parseInt(value, 10) : 0) : value
        }));


    };



    return (
        <div className='w-full mt-5 space-y-5'>
            <h2 className='font-semibold uppercase text-xl'>Pagamento</h2>
            <div className='flex flex-col w-full gap-2'>
                <div className='flex flex-col md:flex-row md:justify-between items-center'>
                    <h3 className='text-sm md:text-md text-orange-50 mb-2'>Selecione uma forma de pagamento</h3>
                    {paymentMethod !== null && <button className='text-xs my-3 md:text-sm text-left text-orange-400 hover:text-orange-500 active:text-orange-600' onClick={() => { setCardsList([]); resetForm(); setPaymentMethod(null); setSelectedInstallment(0); setTarifas(0) }}>Alterar método</button>}
                </div>

                {(paymentMethod === null || paymentMethod === 'credit') && (
                    <PaymentMethodOption
                        id="payment-method-credit"
                        label="Cartão de Crédito"
                        value="credit"
                        selectedMethod={paymentMethod}
                        setPaymentMethod={setPaymentMethod}
                    />
                )}
                {(paymentMethod === null || paymentMethod === 'debit') && (
                    <PaymentMethodOption
                        id="payment-method-debit"
                        label="Cartão de Débito"
                        value="debit"
                        selectedMethod={paymentMethod}
                        setPaymentMethod={setPaymentMethod}
                    />
                )}
                {/*}
                {(paymentMethod === null || paymentMethod === 'ticket') && (
                    <PaymentMethodOption
                        id="payment-method-ticket"
                        label="Boleto"
                        value="ticket"
                        selectedMethod={paymentMethod}
                        setPaymentMethod={setPaymentMethod}
                    />
                )}*/}
            </div>
            {(paymentMethod === 'credit' || paymentMethod === 'debit') && (
                <div className='py-5 border-y border-neutral-700'>
                    <h3 className='text-sm md:text-md text-orange-50 mb-2'>Selecione um dos seus cartões cadastrados</h3>
                    {loadingCardList && <div className='text-center text-sm text-neutral-500 py-5 flex items-center justify-center gap-5'>
                        <svg class="animate-ping h-5 w-5 mr-3 rounded-full bg-orange-500" viewBox="0 0 24 24">...</svg>
                        Carregando lista de cartões cadastrados
                    </div>}
                    <div className=''>
                        {cardsList.length > 0 ?
                            <ul className='space-y-2'>
                                {cardsList.map((cardItem) => (
                                    <li
                                        key={cardItem.id}
                                        onClick={() => { if (!disableCardList) selectCard(cardItem) }}
                                        className={`flex flex-col text-center md:flex-row md:justify-between border-2 border-neutral-700 p-3 cursor-pointer select-none 
                        ${cardItem.id === card.id ? 'bg-neutral-600' : ''} 
                        ${!disableCardList ? 'hover:bg-neutral-700 active:bg-neutral-900' : 'opacity-50'}`}
                                    >
                                        <span className='text-xs'>{cardItem.brand.toUpperCase()}</span>
                                        <span className='text-xs'>{cardItem.holder_name.toUpperCase()}</span>
                                        <span className='text-xs'>**** **** **** {cardItem.last_four_digits}</span>
                                    </li>
                                ))}
                                <li onClick={() => resetForm()}
                                    className={`flex flex-col text-center md:flex-row md:justify-between border-2 border-neutral-700 p-3 cursor-pointer select-none 
                        ${!card.id ? 'bg-neutral-600' : ''} 
                        ${!disableCardList ? 'hover:bg-neutral-700 active:bg-neutral-900' : 'opacity-50'}`}
                                >
                                    <span className='text-xs'>OUTRO</span>
                                </li>
                            </ul>

                            : (
                                <p className='text-xs text-neutral-400'>Nenhum cartão cadastrado</p>
                            )}
                    </div>
                </div>
            )}

            {paymentMethod && paymentMethod !== 'ticket' && (
                <div className='w-full mt-5 space-y-5'>
                    {disableCardList && <div className='text-center text-sm text-neutral-500 py-5 flex items-center justify-center gap-5'>
                        <svg class="animate-ping h-5 w-5 mr-3 rounded-full bg-orange-500" viewBox="0 0 24 24">...</svg>
                        Carregando dados do cartão selecionado
                    </div>}
                    <h3 className='text-sm md:text-md text-orange-50 mb-2'>Dados do Cartão</h3>
                    {paymentMethod === 'credit' && (
                        <div className='flex flex-col w-full gap-2'>
                            <label htmlFor="installments" className='text-xs md:text-sm'>Selecione a quantidade de parcelas</label>
                            <select
                                id="installment"
                                name="installment"
                                value={selectedInstallment}
                                onChange={(e) => setSelectedInstallment(e.target.value)}
                                required
                                className='w-full text-sm p-2 rounded-md bg-neutral-700 focus:bg-neutral-700 focus:outline-none focus:border-none focus:shadow-none focus:ring-1 focus:ring-orange-500 disabled:opacity-50'
                            >
                                {installments.map((installment, index) => (
                                    <option key={index} value={index}>
                                        {installment.number.toString().padStart(2, '0')} x {toMoney(installment.total)}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )}


                    <div className='flex flex-col lg:flex-row gap-5'>
                        <div className='flex flex-col w-full lg:w-2/3 gap-2'>
                            <label htmlFor="number" className='text-xs md:text-sm'>Número do Cartão</label>
                            <CreditCardNumberInput
                                card={card}
                                setCard={setCard}
                                cardErrors={cardErrors}
                                setCardErrors={setCardErrors}
                                paymentMethod={paymentMethod}
                                disableForm={disableForm}
                            />
                            {cardErrors.number && <p className='text-red-500 text-xs md:text-sm pt-1 pb-2'>{cardErrors.number}</p>}
                        </div>
                        <div className='flex flex-col w-full lg:w-1/3 gap-2'>
                            <label htmlFor="brand" className='text-xs md:text-sm'>Bandeira <span className='text-xs text-orange-700 cursor-help' title="Aceitamos as bandeiras: elo | mastercard | visa | amex | jcb | aura | hipercard | diners | unionpay | discover.">🛈</span></label>
                            <select
                                id="brand"
                                name="brand"
                                value={card.brand}
                                onChange={handleInputChange}
                                disabled={disableForm || card.number.length < 6}
                                required
                                className='w-full text-sm p-2 rounded-md bg-neutral-700 focus:bg-neutral-700 focus:outline-none focus:border-none focus:shadow-none focus:ring-1 focus:ring-orange-500 disabled:opacity-50'
                            >
                                <option value="" disabled>Selecione uma bandeira</option>
                                <option value="elo">Elo</option>
                                <option value="mastercard">Mastercard</option>
                                <option value="visa">Visa</option>
                                <option value="amex">American Express</option>
                                <option value="jcb">JCB</option>
                                <option value="aura">Aura</option>
                                <option value="hipercard">Hipercard</option>
                                <option value="diners">Diners Club</option>
                                <option value="unionpay">UnionPay</option>
                                <option value="discover">Discover</option>
                            </select>

                            {cardErrors.brand && <p className='text-red-500 text-xs md:text-sm pt-1 pb-2'>{cardErrors.brand}</p>}
                        </div>
                    </div>
                    <div className='flex flex-col w-full gap-2'>
                        <label htmlFor="holder_name" className='text-xs md:text-sm'>Nome (como escrito no cartão)</label>
                        <input
                            type="text"
                            id="holder_name"
                            name="holder_name"
                            value={card.holder_name.toUpperCase()}
                            onChange={handleInputChange}
                            disabled={disableForm}
                            required
                            className='w-full text-sm p-2 rounded-md bg-neutral-700 focus:bg-neutral-700 focus:outline-none focus:border-none focus:shadow-none focus:ring-1 focus:ring-orange-500 disabled:opacity-50'
                        />
                        {cardErrors.holder_name && <p className='text-red-500 text-xs md:text-sm pt-1 pb-2'>{cardErrors.holder_name}</p>}
                    </div>
                    <div className='flex flex-col w-full gap-2'>
                        <label htmlFor="document" className='text-xs md:text-sm'>CPF ou CNPJ:</label>
                        <DocumentInputPayment
                            card={card}
                            cardErrors={cardErrors}
                            setCard={setCard}
                            setCardErrors={setCardErrors}
                            disabled={disableForm}
                        />
                        {cardErrors.holder_document && <p className='text-red-500 text-xs md:text-sm pt-1 pb-2'>{cardErrors.holder_document}</p>}
                    </div>
                    <div className='flex flex-col lg:flex-row gap-5'>
                        <div className='flex flex-col w-full lg:w-1/2 gap-2'>
                            <label htmlFor="exp_month" className='text-xs md:text-sm'>Mês expiração</label>
                            <input
                                type="text"
                                minLength="1"
                                maxLength="2"
                                id="exp_month"
                                name="exp_month"
                                value={card.exp_month.toString() || '0'}
                                onChange={handleInputChange}
                                disabled={disableForm}
                                required
                                className='w-full text-sm p-2 rounded-md bg-neutral-700 focus:bg-neutral-700 focus:outline-none focus:border-none focus:shadow-none focus:ring-1 focus:ring-orange-500 disabled:opacity-50'
                            />
                            {cardErrors.exp_month && <p className='text-red-500 text-xs md:text-sm pt-1 pb-2'>{cardErrors.exp_month}</p>}
                        </div>
                        <div className='flex flex-col w-full lg:w-1/2 gap-2'>
                            <label htmlFor="exp_year" className='text-xs md:text-sm'>Ano expiração</label>
                            <input
                                type="text"
                                minLength="2"
                                maxLength="4"
                                id="exp_year"
                                name="exp_year"
                                value={card.exp_year.toString() || '0'}
                                onChange={handleInputChange}
                                disabled={disableForm}
                                required
                                className='w-full text-sm p-2 rounded-md bg-neutral-700 focus:bg-neutral-700 focus:outline-none focus:border-none focus:shadow-none focus:ring-1 focus:ring-orange-500 disabled:opacity-50'
                            />
                            {cardErrors.exp_year && <p className='text-red-500 text-xs md:text-sm pt-1 pb-2'>{cardErrors.exp_year}</p>}
                        </div>
                    </div>
                    <div className='flex flex-col w-full gap-2'>
                        <label htmlFor="cvv" className='text-xs md:text-sm'>CVV</label>
                        <input
                            type="text"
                            minLength="3"
                            maxLength="4"
                            id="cvv"
                            name="cvv"
                            value={card.cvv}
                            onChange={handleInputChange}
                            required
                            className='w-full text-sm p-2 rounded-md bg-neutral-700 focus:bg-neutral-700 focus:outline-none focus:border-none focus:shadow-none focus:ring-1 focus:ring-orange-500 disabled:opacity-50'
                        />
                        {cardErrors.cvv && <p className='text-red-500 text-xs md:text-sm pt-1 pb-2'>{cardErrors.cvv}</p>}
                    </div>
                </div>
            )}

        </div>
    )
}

export default ModalCompraFormularioPagamento