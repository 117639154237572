import { Helmet } from 'react-helmet';
import Header from '../components/header';
import Menu from '../components/menu';
import Footer from '../components/footer';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

const PolticasDePrivacidade = (props) => {
  const usuarioAtual = useSelector(state => state.usuarioAtual);
  const [showMenu, setShowMenu] = useState(window.innerWidth >= 1024);

  return (
    <div className="min-h-screen bg-gray-100">
      <Helmet>
        <title>Polticas-de-Privacidade - Prestigious Imperfect Leopard</title>
        <meta property="og:title" content="Polticas-de-Privacidade - Prestigious Imperfect Leopard" />
      </Helmet>
      <div className="bg-neutral-950 shadow-md">
        <div className="relative overflow-hidden">
          <img
            alt="image"
            src="https://images.unsplash.com/photo-1632188006065-9db14ac39120?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDQ2fHxlbnRyZXBlbmV1cnxlbnwwfHx8fDE2OTE0NTA4OTR8MA&amp;ixlib=rb-4.0.3&amp;w=1500"
            className="w-full h-64 object-cover"
          />
          <div className="absolute inset-0 flex flex-col items-center justify-center text-center p-4 bg-gradient-to-t from-black via-transparent to-black">
            <a href='/'>
              <img src="/main_logo.png" className='w-52' alt="Logo" />
            </a>
            <h1 className="text-white text-5xl font-semibold mt-2">Políticas de Privacidade</h1>
            <p className="text-white mt-1">Atualizado em 07 de Agosto de 2023</p>
          </div>
        </div>
        <div className="p-6 max-w-4xl mx-auto text-justify">
          <div className="bg-neutral-900 shadow-md rounded-lg py-10 px-8 md:px-16 lg:px-20">
            <h2 className="text-neutral-50 text-xl font-semibold mb-4">Nossa Política</h2>
            <p className="text-neutral-400 mb-4">
              O site simbium.com é de propriedade da empresa Simbium, que é a controladora de seus dados pessoais.
            </p>
            <p className="text-neutral-400 mb-4">
              Nós adotamos esta Política de Privacidade, que determina como nós estamos processando as informações coletadas pelo site simbium.com e também explica por quais razões nós precisamos coletar dados pessoais sobre você. Portanto, você deve ler esta Política de Privacidade antes de usar o site simbium.com.
            </p>
            <p className="text-neutral-400 mb-4">
              Nós cuidamos dos seus dados pessoais e assumimos a responsabilidade de garantir a confidencialidade e segurança de suas informações pessoais.
            </p>
            <h3 className="text-neutral-50 text-lg font-semibold mb-2">Estes são as informações pessoais que coletamos:</h3>
            <p className="text-neutral-400 mb-4">
              Quando você visita o site simbium.com, nós automaticamente coletamos certas informações sobre seu dispositivo, incluindo informações sobre seu navegador, endereço IP, fuso horário e alguns dos cookies instalados no seu dispositivo. Além disso, quando você navega pelo Site, nós coletamos informações sobre as páginas individuais ou produtos que você visualiza, sobre quais sites ou termos de busca redirecionaram você para nosso Site, e sobre como você interage com o Site. Nós nos referimos a essas informações coletadas automaticamente como "Informações sobre o dispositivo". Além disso, nós podemos coletar dados pessoais que você fornecer (incluindo, mas não limitado a: Nome, Sobrenome, Endereço, informações de pagamento, etc) durante o processo de registro para poder cumprir o acordo.
            </p>
            <h3 className="text-neutral-50 text-lg font-semibold mb-2">Por que fazemos o processamento dos seus dados?</h3>
            <p className="text-neutral-400 mb-4">
              Nossa maior prioridade é a segurança dos dados pessoais dos usuários e, portanto, nós podemos processar apenas dados mínimos, apenas enquanto for absolutamente necessário para a manutenção do site. Informações coletadas automaticamente são usadas para identificar possíveis casos de abuso e estabelecer dados estatísticos sobre o uso do site. Esses dados estatísticos não são agregados de formas que permitam a identificação de usuários específicos do sistema.
            </p>
            <p className="text-neutral-400 mb-4">
              Você pode visitar o site sem nos contar sobre quem você é ou revelar qualquer informação que possa ser usada por outra pessoa para identificar você individualmente. Se, apesar disso, você quiser utilizar algum dos recursos do site, ou quiser receber nossa newsletter, ou quiser conceder outros detalhes através do preenchimento e envio de formulários, você poderá fornecer dados pessoais para nós, como seu email, nome, sobrenome, cidade de residência, organização e número de telefone. Você pode escolher não fornecer dados pessoais para nós, mas, dessa forma, talvez você não consiga usar alguns dos recursos do site. Por exemplo, você não conseguirá receber nossa Newsletter ou entrar em contato conosco diretamente pelo nosso site. Usuários que não tenham certeza sobre quais informações pessoais são obrigatórias são convidados a entrarem em contato conosco pelo e-mail simbium@gmail.com.
            </p>
            <h3 className="text-neutral-50 text-lg font-semibold mb-2">Seus direitos</h3>
            <p className="text-neutral-400 mb-4">
              Se você morar na Europa, estes são os direitos garantidos quando aos seus dados pessoais:
            </p>
            <ul className="list-disc list-inside text-neutral-300 mb-4">
              <li>- O direito de ser informado.</li>
              <li>- O direito ao acesso.</li>
              <li>- O direito à retificação.</li>
              <li>- O direito de deletar.</li>
              <li>- O direito de restringir o processamento.</li>
              <li>- O direito da portabilidade de dados.</li>
              <li>- O direito à objeção.</li>
              <li>- Direitos em relação a tomadas de decisões automáticas e à perfilagem.</li>
            </ul>
            <p className="text-neutral-400 mb-4">
              Se você quiser exercitar esses direitos, por favor entre em contato conosco usando os dados de contato abaixo.
            </p>
            <p className="text-neutral-400 mb-4">
              Adicionalmente, se você mora na Europa, nós afirmamos que estamos processando suas informações com a finalidade de cumprir contratos que possamos ter firmado com você (por exemplo, se você fizer uma compra no nosso Site), ou para exercer os interesses legítimos da nossa empresa listados acima. Além disso, por favor saiba que suas informações poderão ser transferidas para fora da Europa, incluindo para o Canadá e os Estados Unidos da América.
            </p>
            <h3 className="text-neutral-50 text-lg font-semibold mb-2">Links para outros sites</h3>
            <p className="text-neutral-400 mb-4">
              Nosso site pode conter links para outros sites que não são controlados por nós e/ou não são de nossa propriedade. Por favor esteja ciente de que nós não somos responsáveis pelas políticas de privacidade de tais sites e organizações terceiras. Nós incentivamos você a estar ciente de quando sair do nosso site, e também incentivamos você a ler a política de privacidade de cada um dos sites que podem coletar suas informações pessoais.
            </p>
            <h3 className="text-neutral-50 text-lg font-semibold mb-2">Segurança das informações</h3>
            <p className="text-neutral-400 mb-4">
              Nós garantimos que as informações que você fornece estão em servidores e computadores armazenados em ambientes seguros e controlados, protegidos de acessos, usos e divulgações não-autorizadas. Nós mantemos medidas de segurança administrativas, técnicas e físicas razoáveis, com a finalidade de proteger os dados pessoais sob nossa custódia de acessos, usos, modificações e divulgações não-autorizadas. Apesar disso, nenhuma transmissão de dados pela Internet ou por sistemas sem fio pode ser garantida.
            </p>
            <h3 className="text-neutral-50 text-lg font-semibold mb-2">Declaração legal</h3>
            <p className="text-neutral-400 mb-4">
              Nós vamos divulgar qualquer informação que coletarmos, usarmos ou recebermos caso tal divulgação seja solicitada ou permitida por lei, de forma a cumprir intimações ou processos judiciais similares, e também quando considerarmos em boa fé que a divulgação é necessária para a proteção de nossos direitos, para a proteção da segurança de outros, para investigações de fraude ou para responder a uma solicitação do governo.
            </p>
            <h3 className="text-neutral-50 text-lg font-semibold mb-2">Informações de contato:</h3>
            <p className="text-neutral-400">
              Se você quiser entrar em contato conosco para saber mais sobre esta Política de Privacidade, ou quiser acessar quaisquer informações relativas aos seus direitos individuais e às suas Informações Pessoais, você poderá enviar um e-mail para o endereço simbium@gmail.com.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default PolticasDePrivacidade;
