import React from 'react';
import axios from 'axios';

const ZipCodeInput = ({ billingAddress, billingAddressErrors, setBillingAddress, setBillingAddressErrors }) => {

    const formatZipCode = (formattedZipCode) => {
        if (!formattedZipCode) return '';
        if (formattedZipCode.length > 8) {
            formattedZipCode = formattedZipCode.slice(0, 8); // Limita a 8 dígitos
        }
        if (formattedZipCode.length === 8) {
            formattedZipCode = formattedZipCode.replace(/^(\d{2})(\d{3})(\d{3})$/, '$1.$2-$3');
        }
        return formattedZipCode;
    };

    const validateZipCode = (zipCode) => {
        const rawZipCode = zipCode.replace(/\D/g, ''); // Remove caracteres não numéricos
        return /^[0-9]{8}$/.test(rawZipCode);
    };

    const fetchAddress = async (rawZipCode) => {
        try {
            const response = await axios.get(`https://viacep.com.br/ws/${rawZipCode}/json/`);
            console.log("QUAL É: ", response.data)
            if (response.data.erro) {
                setBillingAddressErrors((prevErrors) => ({
                    ...prevErrors,
                    zip_code: 'CEP não encontrado',
                }));
            } else {
                // Atualiza o estado do billingAddress com os dados retornados
                setBillingAddress((prevBillingAddress) => ({
                    ...prevBillingAddress,
                    street: response.data.logradouro,
                    complement: response.data.complemento,
                    neighborhood: response.data.bairro,
                    city: response.data.localidade,
                    state: response.data.uf,
                    number: '',
                }));
                setBillingAddressErrors((prevErrors) => ({
                    ...prevErrors,
                    zip_code: '',
                }));
            }
        } catch (error) {
            setBillingAddressErrors((prevErrors) => ({
                ...prevErrors,
                zip_code: 'Erro ao buscar CEP',
            }));
        }
    };

    const handleZipCode = async (e) => {
        let { value } = e.target;
        // Formatação do CEP
        const zipCode = value.replace(/\D/g, '');
        const formattedZipCode = formatZipCode(zipCode);

        // Validação do CEP
        const isValidZipCode = validateZipCode(zipCode);
        if (!isValidZipCode) {
            setBillingAddressErrors((prevErrors) => ({
                ...prevErrors,
                zip_code: 'CEP inválido',
            }));
        } else {
            setBillingAddressErrors((prevErrors) => ({
                ...prevErrors,
                zip_code: '',
            }));
        }

        // Atualiza o estado do billingAddress com o CEP formatado
        setBillingAddress((prevBillingAddress) => ({
            ...prevBillingAddress,
            zip_code: zipCode,
        }));

        if (isValidZipCode) {
            await fetchAddress(zipCode);
        }
    };

    return (
        <input
            type="text"
            id="zip_code"
            name="zip_code"
            value={formatZipCode(billingAddress.zip_code || '')}
            onChange={handleZipCode}
            maxLength={10}
            required
            className='w-full text-sm p-2 rounded-md bg-neutral-700 focus:bg-neutral-700 focus:outline-none focus:border-none focus:shadow-none focus:ring-1 focus:ring-orange-500'
        />
    );
}

export default ZipCodeInput;
