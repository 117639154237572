//features/usuario/usuarioAtual.js
import { createSlice } from '@reduxjs/toolkit';

const cursoAtualSlice = createSlice({
  name: 'cursoAtual',
  initialState: null,
  reducers: {
    setCurso: (state,action) => {
      return action.payload
    }
  },
});

export const { setCurso } = cursoAtualSlice.actions;
export default cursoAtualSlice.reducer;
