import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

const ModalAgendamentos = ({ usuarioAtualId, consultores, setConsultores, setMostrarConsultorias }) => {
    const [disableAll, setDisableAll] = useState(false);

    const verificarPeriodoConsultoria = (dataAgendamento) => {
        const agora = new Date();
        dataAgendamento = new Date(dataAgendamento)

        // Verifica se a data atual é maior ou igual à data de agendamento
        if (agora < dataAgendamento) {
            return false;
        }

        // Calcula a diferença em milissegundos entre a data atual e a data de agendamento
        const diferencaMilissegundos = agora - dataAgendamento;
        const diferencaHoras = diferencaMilissegundos / (1000 * 60 * 60);

        // Verifica se a diferença é menor ou igual a 1 hora
        if (diferencaHoras <= 1) {
            return true;
        }

        return false;
    };

    const desmarcar = async (agendamento_id = null) => {
        if (!agendamento_id) {
            toast.error("Nenhum agendamento selecionado!");
            return;
        }

        if (confirm("Você tem certeza que deseja desmarcar esse agendamento?")) {
            setDisableAll(true);

            try {
                const response = await axios.delete(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}/agendamentos/desmarcar-consultoria/${agendamento_id}`,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`
                        }
                    }
                );

                if (response.status === 200) {
                    // Atualiza a lista de consultores removendo o agendamento desmarcado
                    const consultoresAtualizados = consultores.map(consultor => {
                        const novosAgendamentos = consultor.agendamentos.filter(agendamento => agendamento._id !== agendamento_id);
                        return { ...consultor, agendamentos: novosAgendamentos };
                    });

                    setConsultores(consultoresAtualizados);
                    toast.success("Agendamento desmarcado com sucesso!");
                } else {
                    toast.error("Erro ao desmarcar agendamento.");
                }
            } catch (error) {
                console.error("Error in desmarcar:", error);
                toast.error("Erro ao desmarcar agendamento.");
            } finally {
                setDisableAll(false);
            }
        }
    };

    const formatDate = (dateString) => {
        const options = { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' };
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('pt-BR', options).format(date);
    };

    return (
        <div className="fixed z-50 top-0 left-0 w-screen h-screen flex items-center justify-center select-none">
            <div
                className="absolute top-0 left-0 w-screen h-screen bg-neutral-950 opacity-95"
                onClick={() => setMostrarConsultorias(false)}
            ></div>
            <div className="absolute w-11/12 md:w-2/3 xl:w-1/2 max-w-7xl mx-auto rounded shadow-lg">
                <div className="space-y p-5 w-full flex relative flex-col pb-8 justify-start bg-[#212121] rounded-xl overflow-hidden max-h-[90vh] overflow-y-auto">
                    <p className="text-2xl text-neutral-50 font-bold border-neutral-500 pb-5 border-b">Meus Agendamentos <span className="float-right text-4xl cursor-pointer text-neutral-300 hover:text-neutral-50 active:text-orange-500" onClick={() => setMostrarConsultorias(false)}>&times;</span></p>
                    {consultores.map((consultor, index) => (
                        <div key={index}>
                            {consultor.agendamentos.length > 0 &&
                                <div key={index} className="mb-5">
                                    <div>
                                        <h1 className='text-neutral-400 text-lg py-5'>{consultor.nome}</h1>
                                        <div className='w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-3'>
                                            {consultor.agendamentos.map((agendamento, index_agendamento) => (
                                                <div className='p-2 bg-neutral-900 rounded-lg space-y-3' key={index_agendamento}>
                                                    <p className='text-neutral-400 hover:text-white text-xs text-center'>{formatDate(agendamento.data)}</p>
                                                    {(agendamento.titulo || agendamento.descricao) && (
                                                        <div className="max-h-52 overflow-y-auto text-justify space-y-5 bg-neutral-800 p-2 rounded-lg">
                                                            {agendamento.titulo && <p className="text-sm text-neutral-200">{agendamento.titulo}</p>}
                                                            {agendamento.descricao && <p className="text-xs text-neutral-400 whitespace-pre-wrap">{agendamento.descricao}</p>}
                                                        </div>
                                                    )}
                                                    {verificarPeriodoConsultoria(agendamento.data) ? (
                                                        <button className="rounded-lg py-1 px-2 text-orange-50 bg-orange-500 hover:text-white hover:bg-orange-600 active:bg-orange-700">
                                                            <a href={agendamento.linkDaReuniao} target="_blank">Ir para a reunião</a>
                                                        </button>
                                                    ) : (
                                                        <p className="text-xs text-center text-neutral-500">O link ficará disponível a partir da hora da consultoria por 60 minutos</p>
                                                    )}
                                                    <div className='flex flex-row justify-between text-center border-t border-neutral-800 pt-2'>
                                                        <button className='w-full text-xs text-red-500 hover:text-red-600 active:text-red-700 select-none' disabled={disableAll} onClick={() => desmarcar(agendamento._id)}>Desmarcar</button>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>

                                    </div>
                                </div>
                            }
                        </div>
                    ))}
                    <div className="border-neutral-500 pt-5 border-t text-end">
                        <button className="text-sm text-neutral-50 hover:bg-neutral-500 active:bg-orange-500 p-2 rounded-md" onClick={() => setMostrarConsultorias(false)}>Fechar</button>
                    </div>

                </div>
            </div>
        </div >
    )

}

export default ModalAgendamentos