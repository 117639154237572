import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, Link } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import ModalCompraFormularioIdentificacao from './formulario-identificacao';
import ModalCompraFormularioEnderecoCobranca from './formulario-endereco';
import ModalCompraFormularioPagamento from './formulario-pagamento';
import ModalCompraFormularioRevisao from './formulario-revisao';

const ModalCompra = (props) => {

    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const header_geral = { Authorization: `Bearer ${localStorage.getItem("token")}` }

    const [formularioPagina, setFormularioPagina] = useState(0)
    const [disableNext, setDisableNext] = useState(true)

    const usuarioTelefone = props.usuarioAtual.telefone.replace(/\D/g, '') || '';
    const telefoneAreaCode = usuarioTelefone.substring(0, 2);
    const telefoneNumber = usuarioTelefone.substring(2);

    const calcTotal = () => {
        const { preco, preco_centavos } = props.plano;

        // Verifica se preco e preco_centavos são números ou strings que representam números
        if ((typeof preco !== 'number' && typeof preco !== 'string') ||
            (typeof preco_centavos !== 'number' && typeof preco_centavos !== 'string')) {
            console.error("Erro: preco e preco_centavos devem ser números ou strings que representam números", { preco, preco_centavos });
            return 0; // Retorna um valor padrão ou trate o erro de outra forma
        }

        const totalString = `${preco}.${preco_centavos}`;
        const total = parseFloat(totalString);

        // Verifica se a conversão para número foi bem-sucedida
        if (isNaN(total)) {
            console.error("Erro ao calcular o total: valores inválidos após conversão", { preco, preco_centavos, totalString });
            return 0; // Retorna um valor padrão ou trate o erro de outra forma
        }

        return total;
    };



    const [customer, setCustomer] = useState({
        id: '',
        name: props.usuarioAtual.nome,
        email: props.usuarioAtual.email,
        code: props.usuarioAtual._id,
        document: '',
        document_type: '',
        type: '',
        delinquent: false,
        phones: {
            home_phone: {
                country_code: '55',
                area_code: telefoneAreaCode,
                number: telefoneNumber
            },
            mobile_phone: {
                country_code: '55',

                area_code: telefoneAreaCode,
                number: telefoneNumber
            }
        },
    });

    const [customerErrors, setCustomerErrors] = useState({
        name: '',
        email: '',
        document: '',
        document_type: '',
        type: '',
        phones: '',
    });

    const [billingAddress, setBillingAddress] = useState({
        id: '', // addr_W0djGXfLNIdVOPb2
        street: '', // Rua Bela Vista
        number: '', // 86
        complement: '', // Apto 202
        zip_code: '', // 45605298
        neighborhood: '', // Nossa Senhora da Conceição
        city: '', // Itabuna
        state: '', // BA
        country: 'BR',
        status: '', // active
    })

    const [billingAddressErrors, setBillingAddressErrors] = useState({
        street: '',
        number: '',
        complement: '',
        zip_code: '',
        neighborhood: '',
        city: '',
        state: '',
    })

    const [paymentMethod, setPaymentMethod] = useState(null)
    const [cardsList, setCardsList] = useState([])
    const [card, setCard] = useState({
        id: '',
        brand: '',
        exp_month: 0,
        exp_year: 0,
        first_six_digits: '',
        last_four_digits: '',
        holder_document: '',
        holder_name: '',
        id: '',
        status: '',
        type: '',
        cvv: '',
    })

    const [cardErrors, setCardErrors] = useState({
        number: '',
        holder_name: '',
        exp_month: '',
        exp_year: '',
        cvv: '',
        brand: '',
    })

    const [installments, setInstallments] = useState([])

    useEffect(() => {
        if (props.plano.periodo === "mensal") {
            setInstallments([{
                number: 1,
                total: calcTotal()
            }])
        } else {
            setInstallments(Array.from({ length: 12 }, (_, index) => {
                let total;
                if (index === 0) {
                    total = calcTotal();
                } else if (index > 0 && index < 10) {
                    total = calcTotal() * 1.05; // Aumento de 5% para parcelas de 2 a 10
                } else {
                    total = calcTotal() * 1.1; // Aumento de 10% para parcelas de 11 a 12
                }

                return {
                    number: index + 1,
                    total: total.toFixed(2)
                };
            }))
        }
    }, [props.plano])

    const [tarifas, setTarifas] = useState(0)
    const [selectedInstallment, setSelectedInstallment] = useState(0)

    const isFieldEmpty = (obj, fieldsToCheck) => {
        for (const field of fieldsToCheck) {
            if (field === 'phones') {
                const phone = obj[field].mobile_phone;
                if (!phone.area_code || !phone.number) {
                    return true;
                }
            } else if (field === 'number' && formularioPagina === 2) {
                if (!card.id.length && !card.number) return true;
            } else if (!obj[field]) {
                return true;
            }
        }
        return false;
    };


    const shouldDisableNextButton = (formData, formErrors, fieldsToCheck) => {
        if (formularioPagina === 2 && paymentMethod === 'ticket') return false
        if (isFieldEmpty(formData, fieldsToCheck)) {
            return true;
        }
        for (const key in formErrors) {
            if (formErrors[key].length > 0) {
                return true;
            }
        }
        return false;
    };

    useEffect(() => {
        const fieldsToCheck = ['name', 'email', 'document', 'document_type', 'type', 'phones'];
        setDisableNext(shouldDisableNextButton(customer, customerErrors, fieldsToCheck));
    }, [customer, customerErrors]);

    useEffect(() => {
        const fieldsToCheck = ['street', 'number', 'complement', 'zip_code', 'neighborhood', 'city', 'state'];
        setDisableNext(shouldDisableNextButton(billingAddress, billingAddressErrors, fieldsToCheck));
    }, [billingAddress, billingAddressErrors]);

    useEffect(() => {
        const fieldsToCheck = ['number', 'holder_name', 'exp_month', 'exp_year', 'cvv', 'brand'];
        setDisableNext(shouldDisableNextButton(card, cardErrors, fieldsToCheck));
    }, [card, cardErrors, paymentMethod]);

    useEffect(() => {
        setTarifas(installments.length ? installments[selectedInstallment].total - calcTotal() : 0)
    }, [selectedInstallment])

    const movePage = (side) => {
        if (side === 'previous') {
            props.updateUrl(formularioPagina - 1);
            setFormularioPagina(formularioPagina - 1)
        } else if (side === 'next') {
            props.updateUrl(formularioPagina + 1);
            setFormularioPagina(formularioPagina + 1)
        }
        setDisableNext(false)
    }

    const paymentTypeMap = {
        credit: "credit_card",
        debit: "debit_card",
        ticket: "boleto"
    };

    const [processing, setProcessing] = useState(false);
    const pagarPlano = async () => {
        setProcessing(true)
        setCustomer(prevCustomer => ({
            ...prevCustomer,
            address: billingAddress,
            code: props.usuarioAtual._id,
        }));

        setCard(prevCard => ({
            ...prevCard,
            billing_address_id: billingAddress.id
        }));

        const selectedPaymentMethod = paymentTypeMap[paymentMethod];
        const dueDate = new Date();

        const data = selectedPaymentMethod !== 'boleto' ?
            {
                code: props.plano.id,
                plan_id: props.plano.id,
                payment_method: selectedPaymentMethod,
                customer: customer,
                card_id: card.id ? card.id : '',
                card: !card.id ? card : null,
                installments: parseInt(selectedInstallment) + 1,
                increments: [
                    {
                        value: Math.round(tarifas * 100),
                        increment_type: 'flat',
                        cycles: '1000'
                    }
                ],
            }
            :
            {
                code: props.plano.id,
                plan_id: props.plano.id,
                payment_method: selectedPaymentMethod,
                customer: customer,
                boleto: {
                    bank: 198,
                    instructions: "Pagar até o vencimento",
                    due_at: dueDate.setDate(dueDate.getDate() + 1),
                    document_number: customer.document,
                    type: 'DM',
                    max_days_to_pay_past_due: 0,
                },
                boleto_due_days: 1,
                installments: 1,
                metadata: props.usuarioAtual
            };

        console.log("REQUISIÇÃO DE ASSINATURA: ", data);

        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/pagarme/pay/`, {
                data: data,
                apiKey: process.env.REACT_APP_PAGARME_TOKEN,
            }, {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
            });

            if (response.data) {
                mixPanelSignUpCompleted();
                console.log("Resposta do servidor à tentativa de compra: ", response.data);
                toast.success("Compra realizada com sucesso!")
                toast("Recarregando página...")
                setTimeout(() => {
                    window.location.replace('/login')
                }, 5000)
            } else {
                console.log("ERRO: Resposta do servidor à tentativa de compra: ", response);
                toast.error("Erro na tentativa de compra!")
                setProcessing(false)
            }

        } catch (error) {
            console.log("Erro na realização da compra: ", error.message);
            toast.error("Erro na tentativa de compra!")
            setProcessing(false)
        }
    };

    const closeModal = () => {
        if (processing) return;
        props.closeModal();
    }

    const toMoney = (number) => {
        return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(number)
    }

    const mixPanelSignUpCompleted = () => {
        if (!props.usuarioAtual || !props.usuarioAtual._id) {
            console.error('usuarioAtual or usuarioAtual._id is missing');
            return;
        }
        mixpanel.track("Sign Up Completed", {
            distinct_id: props.usuarioAtual._id,
            plan: props.plano,
            payment_method: paymentMethod,
        });
    }
    return (
        <div>
            <div className="fixed top-0 left-0 w-screen min-h-screen flex justify-center align-middle items-center z-40">
                <div className="w-full min-h-screen bg-neutral-950 opacity-95" onClick={() => closeModal()}></div>
                <div className="absolute w-11/12 md:w-2/3 rounded-lg bg-neutral-800 p-5 text-neutral-300 overflow-y-auto max-h-screen">
                    <div className="border-b border-neutral-700 py-5 space-y-2 flex flex-col items-center text-center">
                        <img src='/main_logo.png' className='w-52 md:w-80' />
                        <div className="w-6/7 space-y-2">
                            <div className="w-full text-xs md:text-md text-neutral-400">
                                <p>A Simbium não armazena seus dados pessoais de endereço e pagamento nativamente.</p>
                                <p>Eles são enviados por um canal seguro diretamente para nosso gateway de pagamento.</p>
                            </div>
                            <div className='flex flex-row gap-5 text-xs text-neutral-600 text-center items-center align-middle justify-center select-none'>
                                <a className='hover:text-orange-500 active:text-orange-600 cursor-pointer' target='_blank' href="/politicas-de-privacidade">Termo de Privacidade da Simbium</a>
                                <a className="hover:text-orange-500 active:text-orange-600 cursor-pointer" target="_blank" href="https://pagar.me/aviso-de-privacidade#link-section1-4">Termos de Privacidade Pagar.me</a>
                            </div>
                        </div>
                    </div>
                    <div className="py-5 flex flex-col md:flex-row md:divide-x divide-neutral-700">

                        <div className="w-full md:w-2/3 py-5 flex flex-col gap-5 p-5">

                            <div className='flex flex-row gap-1 md:gap-3 group select-none'>
                                <div className='w-1/4 truncate'>
                                    <div className={`w-full h-2 group-hover:h-fit group-hover:p-1 transition-all ${formularioPagina > -1 ? 'bg-orange-500' : 'bg-neutral-700'}`}>
                                        <p className='text-neutral-300 text-xs hidden group-hover:block'>Identificação</p>
                                    </div>
                                </div>
                                <div className='w-1/4 truncate'>
                                    <div className={`w-full h-2 group-hover:h-fit group-hover:p-1 transition-all ${formularioPagina > 0 ? 'bg-orange-500' : 'bg-neutral-700'}`}>
                                        <p className='text-neutral-300 text-xs hidden group-hover:block'>Endereço de Cobrança</p>
                                    </div>
                                </div>
                                <div className='w-1/4 truncate'>
                                    <div className={`w-full h-2 group-hover:h-fit group-hover:p-1 transition-all ${formularioPagina > 1 ? 'bg-orange-500' : 'bg-neutral-700'}`}>
                                        <p className='text-neutral-300 text-xs hidden group-hover:block'>Pagamento</p>
                                    </div>
                                </div>
                                <div className='w-1/4 truncate'>
                                    <div className={`w-full h-2 group-hover:h-fit group-hover:p-1 transition-all ${formularioPagina > 2 ? 'bg-orange-500' : 'bg-neutral-700'}`}>
                                        <p className='text-neutral-300 text-xs hidden group-hover:block'>Revisão</p>
                                    </div>
                                </div>
                            </div>

                            {formularioPagina === 0 && <ModalCompraFormularioIdentificacao
                                usuarioAtual={props.usuarioAtual}
                                customer={customer}
                                setCustomer={setCustomer}
                                setBillingAddress={setBillingAddress}
                                customerErrors={customerErrors}
                                setCustomerErrors={setCustomerErrors}
                            />}

                            {formularioPagina === 1 && <ModalCompraFormularioEnderecoCobranca
                                usuarioAtual={props.usuarioAtual}
                                billingAddress={billingAddress}
                                setBillingAddress={setBillingAddress}
                                billingAddressErrors={billingAddressErrors}
                                setBillingAddressErrors={setBillingAddressErrors}
                            />}

                            {formularioPagina === 2 && <ModalCompraFormularioPagamento
                                usuarioAtual={props.usuarioAtual}
                                paymentMethod={paymentMethod}
                                setPaymentMethod={setPaymentMethod}
                                cardsList={cardsList}
                                card={card}
                                setCardsList={setCardsList}
                                setCard={setCard}
                                cardErrors={cardErrors}
                                setCardErrors={setCardErrors}
                                installments={installments}
                                setInstallments={setInstallments}
                                tarifas={tarifas}
                                setTarifas={setTarifas}
                                selectedInstallment={selectedInstallment}
                                setSelectedInstallment={setSelectedInstallment}
                                toMoney={toMoney}
                            />}

                            {formularioPagina === 3 && <ModalCompraFormularioRevisao
                                usuarioAtual={props.usuarioAtual}
                                customer={customer}
                                billingAddress={billingAddress}
                                paymentMethod={paymentMethod}
                                card={card}
                                calcTotal={calcTotal()}
                                installments={installments}
                                tarifas={tarifas}
                                selectedInstallment={selectedInstallment}
                                toMoney={toMoney}
                            />}

                        </div>


                        <div className='w-full md:w-1/3 py-5 flex flex-col gap-5 p-5'>
                            <h2 className='text-xl'>{props.plano.titulo}</h2>
                            <div className='flex justify-between'>
                                <p className='text-sm'>Subtotal</p>
                                <p className='text-sm'>{toMoney(props.plano.preco + '.' + props.plano.preco_centavos)}</p>
                            </div>
                            <div className='flex justify-between border-b border-neutral-700 pb-5'>
                                <p className='text-sm'>Tarifas</p>
                                <p className='text-sm'>{toMoney(tarifas)}</p>
                            </div>
                            <div className='flex justify-between'>
                                <p className='text-sm'>Total</p>
                                <p className='text-sm'>{toMoney(calcTotal() + tarifas)}</p>
                            </div>
                            <p className='text-xs text-neutral-700 text-justify'>{props.plano.descricao}</p>
                            <ul className='space-y-3 text-xs text-neutral-400'>
                                {props.plano.lista_itens.length && props.plano.lista_itens.map((item, index) => (
                                    <li key={index}>🗹 {item}</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className="border-t border-neutral-700 py-5 flex flex-row justify-end items-center align-middle gap-x-3 select-none">
                        {formularioPagina > 0 &&
                            <button className="py-2 px-3 md:py-3 md:px-4 rounded-md text-xs md:text-sm hover:bg-neutral-700 active:bg-neutral-800" disabled={processing} onClick={() => movePage('previous')}>Voltar</button>
                        }

                        <button className="py-2 px-3 md:py-3 md:px-4 rounded-md text-xs md:text-sm bg-neutral-600 hover:bg-neutral-700 active:bg-neutral-800" disabled={processing} onClick={() => closeModal()}>Cancelar</button>

                        {formularioPagina < 3 ?
                            <button className="py-2 px-3 md:py-3 md:px-4 rounded-md text-xs md:text-sm bg-orange-700 active:bg-orange-800 disabled:bg-neutral-800" disabled={disableNext || processing} onClick={() => movePage('next')}>Próximo</button>
                            :
                            <button className="py-2 px-3 md:py-3 md:px-4  rounded-md text-xs font-semibold md:text-sm bg-yellow-600 hover:bg-yellow-700 active:bg-yellow-500 disabled:bg-neutral-800" disabled={disableNext || processing} onClick={() => pagarPlano()}>{processing ? 'Processando...' : 'Pagar'}</button>
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalCompra