import React, { useState, useEffect, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom'
import axios from 'axios'
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { setCurso } from '../features/curso/cursoAtual';
import { setModulo } from '../features/modulo/moduloAtual';


import './lista-modulos.css'


const ListaModulos = (props) => {
  const dispatch = useDispatch();
  const history = useHistory()
  const [moduloInit, setModuloInit] = useState(0);
  const [modulos, setModulos] = useState([]);
  const [modulosNext, setModulosNext] = useState(false);
  const [carregado, setCarregado] = useState(false);


  const firstAulaModulo = async (id) => {

    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/modulos/primeira-aula-modulo?id=${id}&cursoId=${props.cursoId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      });

      if (response.data && response.data.slug) {
        if (props.cursoId) {
          dispatch(setCurso(props.cursoId))
          dispatch(setModulo(id))
        }

        history.push(response.data.slug)
      }

    } catch (error) {
      toast.error(`Módulo vazio`)
    }
  }

  useEffect(() => {
    setModuloInit(0)
  }, [props.cursoId]);

  useEffect(() => {
    let isMounted = true

    async function fetchModulos(cInicial) {
      let slides;

      if (!modulosNext && carregado && window.innerWidth < 479) return;

      if (window.innerWidth >= 479) {
        let element = document.querySelector(".l-cont")
        if (!element) {
          slides = 2;
        } else {
          slides = Math.ceil(element.clientWidth / 200);
        }

        setCarregado(true)
      } else {

        slides = 50;

      }


      try {
        const response = await axios.get(process.env.REACT_APP_BACKEND_BASE_URL + '/modulos', {
          params: {
            curso: props.cursoId,
            quantidade: slides,
            aPartirDe: cInicial
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        });

        if (isMounted && response.data) {
          setModulos(response.data);
          setModulosNext((response.data?.totalModulos - (cInicial + slides - 1)) > 0)
        }

      } catch (error) {
      }
    }

    fetchModulos(moduloInit);

    return () => {
      isMounted = false
    }
  }, [moduloInit])

  return (
    <div style={modulos && modulos.totalModulos === 0 ? { display: 'none' } : {}}>
      <div className={`lista-modulos-modulos`}>
        <div className="lista-modulos-container">
          <svg viewBox="0 0 1024 1024" className="lista-modulos-icon">
            <path
              d="M766 726l-34-142 110-96-144-12-58-134-58 134-144 12 110 96-34 142 126-74zM854 256q34 0 59 26t25 60v426q0 34-25 60t-59 26h-684q-34 0-59-26t-25-60v-512q0-34 25-60t59-26h256l86 86h342z"
              className=""
            ></path>
          </svg>
          <span className="lista-modulos-text">Módulos do treinamento</span>
        </div>
        <div className="lista-modulos-container1"
        >
          {
            moduloInit > 0 && window.innerWidth > 479 && (
              <div className="home-prev2" onClick={() => setModuloInit(moduloInit - 1)}>
                <svg
                  viewBox="0 0 694.8571428571428 1024"
                  className="home-icon18"
                >
                  <path d="M632.571 501.143l-424 424c-14.286 14.286-37.143 14.286-51.429 0l-94.857-94.857c-14.286-14.286-14.286-37.143 0-51.429l303.429-303.429-303.429-303.429c-14.286-14.286-14.286-37.143 0-51.429l94.857-94.857c14.286-14.286 37.143-14.286 51.429 0l424 424c14.286 14.286 14.286 37.143 0 51.429z"></path>
                </svg>
              </div>
            )}
          {modulos && modulos.modulos && modulos.modulos.length > 0 && modulos.modulos.map((modulo, index) => (
            <div onClick={() => { firstAulaModulo(modulo._id) }} key={index} className="lista-modulos-navlink">
              <div className="lista-modulos-card">
                <img
                  alt="image"
                  src={modulo.capa}
                  className="lista-modulos-image"
                />
                <div className="lista-modulos-info-curso">
                  <span className="lista-modulos-text1">{modulo.nome}</span>
                  <span className="lista-modulos-text2">{modulo.aulas ? modulo.aulas.length : 0} aulas</span>
                </div>
              </div>
            </div>
          ))}
          {modulosNext && window.innerWidth > 479 && (
            <div className="lista-modulos-next" onClick={() => setModuloInit(moduloInit + 1)}>
              <svg
                viewBox="0 0 694.8571428571428 1024"
                className="lista-modulos-icon2"
              >
                <path
                  d="M632.571 501.143l-424 424c-14.286 14.286-37.143 14.286-51.429 0l-94.857-94.857c-14.286-14.286-14.286-37.143 0-51.429l303.429-303.429-303.429-303.429c-14.286-14.286-14.286-37.143 0-51.429l94.857-94.857c14.286-14.286 37.143-14.286 51.429 0l424 424c14.286 14.286 14.286 37.143 0 51.429z"
                  className=""
                ></path>
              </svg>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ListaModulos
