import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import styles from './usuario.module.css'
import { toast } from 'react-toastify';


import PopupRemoverItem from '../popups/remover_item'
import PopupAddUser from '../admin/add_usuario'
import PopupHistoricoComissoes from '../popups/historico_comissoes';




const TabelaUsuarios = (props) => {
  const [page, setPage] = useState(0)
  const [filtroPesquisa, setFiltroPesquisa] = useState('')
  const [filtroTipo, setFiltroTipo] = useState('')
  const [filtroAtivo, setFiltroAtivo] = useState('')
  const [filtroTrial, setFiltroTrial] = useState('')
  const [popupVisible, setPopupVisible] = useState(false)
  const [popupAddUser, setPopupAddUser] = useState(false)
  const [totalPages, setTotalPages] = useState(0)
  const [usuarios, setUsuarios] = useState([])
  const [lastPage, setLastPage] = useState(false)
  const [itemPopup, setItemPopup] = useState(null)
  const [popupComissoesVisible, setPopupComissoesVisible] = useState(false);

  const usuarioAtual = useSelector(state => state.usuarioAtual);

  const exportarUsuarios = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_BACKEND_BASE_URL + '/usuarios/exportar-usuarios', {
        responseType: 'blob', headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'users.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Erro ao exportar os usuários: ", error);
    }
  };

  const getUsuarios = async () => {

    try {

      let params = {
        page
      };

      if (filtroPesquisa !== '') params.filtroPesquisa = filtroPesquisa
      if (filtroTipo !== '') params.filtroTipo = filtroTipo
      if (filtroAtivo !== '') params.filtroAtivo = filtroAtivo
      if (filtroTrial !== '') params.filtroTrial = filtroTrial;

      const users = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/usuarios`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        },
        params
      })

      if (users.data && users.data.totalPages) setTotalPages(users.data.totalPages)
      if (users.data && users.data.usuarios) setUsuarios(users.data.usuarios.filter(usuario => usuario._id !== usuarioAtual._id))
      if (users.data && users.data.lastPage && users.data.lastPage === true) setLastPage(true)

    } catch (err) {
    }
  }

  useEffect(() => {

    getUsuarios()

  }, [page, filtroPesquisa, filtroTipo, filtroAtivo, filtroTrial]);

  return (
    <div className={styles['tabela-aulas']}>
      {popupVisible && (
        <PopupRemoverItem buscarUsuarios={getUsuarios} fecharPopup={() => setPopupVisible(false)} itemPopup={itemPopup} tipo="Usuário" removido={() => toast.success("Usuário deletado com sucesso")} />
      )}
      {popupAddUser && (
        <PopupAddUser getUsuarios={getUsuarios} fecharPopup={() => setPopupAddUser(false)} itemPopup={itemPopup} sucesso={() => toast.success("Usuário salvo com sucesso")} erro={(err) => toast.error(err)} />
      )}
      {popupComissoesVisible && (
        <PopupHistoricoComissoes getUsuarios={getUsuarios} comissaoPaga={() => toast.success("Comissões pagas com sucesso")} adminMode="true" uid={itemPopup._id} indicacoes={itemPopup.indicacoes} totalComissoes={itemPopup.totalComissoes} fecharPopup={() => setPopupComissoesVisible(false)} />
      )}
      <div className={styles['container']}>
        <span className={styles['text']}>Usuários</span>
        <div style={{ display: 'flex', gap: '8px' }}>
          <div
            onClick={() => {
              setItemPopup(null)
              setPopupAddUser(!popupAddUser)
            }}
            className={styles['btn-add-aula']}
          >
            <svg viewBox="0 0 804.5714285714286 1024" className={styles['icon']}>
              <path d="M804.571 420.571v109.714c0 30.286-24.571 54.857-54.857 54.857h-237.714v237.714c0 30.286-24.571 54.857-54.857 54.857h-109.714c-30.286 0-54.857-24.571-54.857-54.857v-237.714h-237.714c-30.286 0-54.857-24.571-54.857-54.857v-109.714c0-30.286 24.571-54.857 54.857-54.857h237.714v-237.714c0-30.286 24.571-54.857 54.857-54.857h109.714c30.286 0 54.857 24.571 54.857 54.857v237.714h237.714c30.286 0 54.857 24.571 54.857 54.857z"></path>
            </svg>
            <span className={styles['text01']}>Usuário</span>
          </div>
          <div
            onClick={() => {
              exportarUsuarios()
            }}
            className={styles['btn-add-aula']}
            style={{ background: 'rgb(132 132 132)' }}
          >
            <span className={styles['text01']}>Exportar todos</span>
          </div>
        </div>
      </div>
      <div className={styles['container01']}>
        <div className={styles['container02']}>
          <span className={styles['text02']}>Procure por um usuário</span>
          <input
            type="text"
            onChange={(e) => setFiltroPesquisa(e.target.value)}
            className={styles['input-dark']}
          />
        </div>
        <div className={styles['container03']}>
          <span className={styles['text03']}>Filtre por Tipo</span>
          <select
            type="text"
            className={styles['input-dark']}
            onChange={(e) => setFiltroTipo(e.target.value)}
          >
            <option value="">Todos</option>
            <option value="usuario">Usuário</option>
            <option value="professor">Professor</option>
            <option value="consultor">Consultor</option>
            <option value="admin">Admin</option>

          </select>
        </div>
        <div className={styles['container03']}>
          <span className={styles['text03']}>Filtre por Trial</span>
          <select
            type="text"
            className={styles['input-dark']}
            onChange={(e) => {
              setFiltroTrial(e.target.value)
            }}
          >
            <option value="">Todos</option>
            <option value="true">Sim</option>
            <option value="false">Não</option>
          </select>
        </div>
        <div className={styles['container04']}>
          <span className={styles['text04']}>Filtre por Assinantes</span>
          <select
            type="text"
            className={styles['dropdown']}
            onChange={(e) => setFiltroAtivo(e.target.value)}
          >
            <option value="">Todos</option>
            <option value={true}>Assinantes Ativos</option>
            <option value={false}>Assinantes Inativos</option>
          </select>
        </div>
      </div>
      <div className={styles['tabela-usuario']}>
        <div className={styles['not-empty']}>
          <div className={styles['container05']}>
            <div className={styles['container06']}>
              <div className={styles['container07']}></div>
              <span className={styles['text05']}>Nome</span>
              <span className={styles['text06']}>Email</span>
              <span className={styles['text07']}>Tipo</span>
              <span className={styles['text08']}>Acesso</span>
              <div className={styles['container08']}></div>
            </div>
            {usuarios.length > 0 && (
              usuarios.map((usuario, index) => (
                <div key={index} className={styles['infos']}>
                  <div className={styles['container09']}>
                    <svg
                      onClick={() => {
                        setPopupAddUser(true)
                        setItemPopup(usuario)
                      }}
                      viewBox="0 0 865.7188571428571 1024"
                      className={styles['icon02']}
                    >
                      <path d="M207.429 877.714l52-52-134.286-134.286-52 52v61.143h73.143v73.143h61.143zM506.286 347.429c0-7.429-5.143-12.571-12.571-12.571-3.429 0-6.857 1.143-9.714 4l-309.714 309.714c-2.857 2.857-4 6.286-4 9.714 0 7.429 5.143 12.571 12.571 12.571 3.429 0 6.857-1.143 9.714-4l309.714-309.714c2.857-2.857 4-6.286 4-9.714zM475.429 237.714l237.714 237.714-475.429 475.429h-237.714v-237.714zM865.714 292.571c0 19.429-8 38.286-21.143 51.429l-94.857 94.857-237.714-237.714 94.857-94.286c13.143-13.714 32-21.714 51.429-21.714s38.286 8 52 21.714l134.286 133.714c13.143 13.714 21.143 32.571 21.143 52z"></path>
                    </svg>
                    <svg
                      onClick={() => {
                        setPopupVisible(true)
                        setItemPopup(usuario)
                      }}
                      viewBox="0 0 804.5714285714286 1024"
                      className={styles['icon04']}
                    >
                      <path d="M292.571 786.286v-402.286c0-10.286-8-18.286-18.286-18.286h-36.571c-10.286 0-18.286 8-18.286 18.286v402.286c0 10.286 8 18.286 18.286 18.286h36.571c10.286 0 18.286-8 18.286-18.286zM438.857 786.286v-402.286c0-10.286-8-18.286-18.286-18.286h-36.571c-10.286 0-18.286 8-18.286 18.286v402.286c0 10.286 8 18.286 18.286 18.286h36.571c10.286 0 18.286-8 18.286-18.286zM585.143 786.286v-402.286c0-10.286-8-18.286-18.286-18.286h-36.571c-10.286 0-18.286 8-18.286 18.286v402.286c0 10.286 8 18.286 18.286 18.286h36.571c10.286 0 18.286-8 18.286-18.286zM274.286 219.429h256l-27.429-66.857c-1.714-2.286-6.857-5.714-9.714-6.286h-181.143c-3.429 0.571-8 4-9.714 6.286zM804.571 237.714v36.571c0 10.286-8 18.286-18.286 18.286h-54.857v541.714c0 62.857-41.143 116.571-91.429 116.571h-475.429c-50.286 0-91.429-51.429-91.429-114.286v-544h-54.857c-10.286 0-18.286-8-18.286-18.286v-36.571c0-10.286 8-18.286 18.286-18.286h176.571l40-95.429c11.429-28 45.714-50.857 76-50.857h182.857c30.286 0 64.571 22.857 76 50.857l40 95.429h176.571c10.286 0 18.286 8 18.286 18.286z"></path>
                    </svg>
                    {usuario.totalComissoes > 0 && (
                      <svg viewBox="0 0 1024 1024" className={styles['icon04']}
                        style={{ right: `${usuario.telefone ? '56' : '28'}px`, fill: "#fff", cursor: "pointer" }}
                        onClick={() => {
                          setPopupComissoesVisible(true)
                          setItemPopup(usuario)
                        }}
                      >
                        <path
                          d="M480 64c-265.096 0-480 214.904-480 480 0 265.098 214.904 480 480 480 265.098 0 480-214.902 480-480 0-265.096-214.902-480-480-480zM480 928c-212.078 0-384-171.922-384-384s171.922-384 384-384c212.078 0 384 171.922 384 384s-171.922 384-384 384zM512 512v-128h128v-64h-128v-64h-64v64h-128v256h128v128h-128v64h128v64h64v-64h128.002l-0.002-256h-128zM448 512h-64v-128h64v128zM576.002 704h-64.002v-128h64.002v128z"
                        ></path>
                      </svg>
                    )}
                    {usuario.telefone && (
                      <svg
                        onClick={() => {
                          window.open(`https://api.whatsapp.com/send?phone=55${usuario.telefone.replaceAll(/\D/g, "")}`);
                        }}
                        viewBox="0 0 877.7142857142857 1024"
                        className={styles['icon04']}
                        style={{ right: '28px', fill: "#fff", cursor: "pointer" }}
                      >
                        <path d="M562.857 556.571c9.714 0 102.857 48.571 106.857 55.429 1.143 2.857 1.143 6.286 1.143 8.571 0 14.286-4.571 30.286-9.714 43.429-13.143 32-66.286 52.571-98.857 52.571-27.429 0-84-24-108.571-35.429-81.714-37.143-132.571-100.571-181.714-173.143-21.714-32-41.143-71.429-40.571-110.857v-4.571c1.143-37.714 14.857-64.571 42.286-90.286 8.571-8 17.714-12.571 29.714-12.571 6.857 0 13.714 1.714 21.143 1.714 15.429 0 18.286 4.571 24 19.429 4 9.714 33.143 87.429 33.143 93.143 0 21.714-39.429 46.286-39.429 59.429 0 2.857 1.143 5.714 2.857 8.571 12.571 26.857 36.571 57.714 58.286 78.286 26.286 25.143 54.286 41.714 86.286 57.714 4 2.286 8 4 12.571 4 17.143 0 45.714-55.429 60.571-55.429zM446.857 859.429c197.714 0 358.857-161.143 358.857-358.857s-161.143-358.857-358.857-358.857-358.857 161.143-358.857 358.857c0 75.429 24 149.143 68.571 210.286l-45.143 133.143 138.286-44c58.286 38.286 127.429 59.429 197.143 59.429zM446.857 69.714c237.714 0 430.857 193.143 430.857 430.857s-193.143 430.857-430.857 430.857c-72.571 0-144.571-18.286-208.571-53.714l-238.286 76.571 77.714-231.429c-40.571-66.857-61.714-144-61.714-222.286 0-237.714 193.143-430.857 430.857-430.857z"></path>
                      </svg>
                    )}
                    <span className={styles['text09']}>{usuario.nome}</span>
                    <span className={styles['text10']}>{usuario.email}</span>
                    <span className={styles['text11']} style={usuario.tipo === 'admin' ? { background: '#1738f1 ' } : usuario.tipo === 'usuario' ? { background: '#0dd339' } : usuario.tipo === 'professor' ? { background: '#ff00f7' } : { background: '#737373' }}>{usuario.tipo}</span>
                    <span className={styles['text12']}>{new Date(usuario.acesso).toLocaleDateString('pt-br')}</span>
                  </div>
                </div>
              ))
            )}

          </div>
          {totalPages > 1 && (
            <div className={styles['paginao']}>
              <svg viewBox="0 0 768 1024" className={styles['icon06']} onClick={() => {
                if (page > 0) {
                  setPage(page - 1)
                }
              }}>
                <path d="M669.143 172l-303.429 303.429 303.429 303.429c14.286 14.286 14.286 37.143 0 51.429l-94.857 94.857c-14.286 14.286-37.143 14.286-51.429 0l-424-424c-14.286-14.286-14.286-37.143 0-51.429l424-424c14.286-14.286 37.143-14.286 51.429 0l94.857 94.857c14.286 14.286 14.286 37.143 0 51.429z"></path>
              </svg>
              <span className={styles['text13']}>{page + 1} de {totalPages}</span>
              <svg
                viewBox="0 0 694.8571428571428 1024"
                className={styles['icon08']}
                onClick={() => {
                  if ((page + 1) < totalPages) {
                    setPage(page + 1)
                  } else {
                    setPage(0)
                  }
                }}
              >
                <path d="M632.571 501.143l-424 424c-14.286 14.286-37.143 14.286-51.429 0l-94.857-94.857c-14.286-14.286-14.286-37.143 0-51.429l303.429-303.429-303.429-303.429c-14.286-14.286-14.286-37.143 0-51.429l94.857-94.857c14.286-14.286 37.143-14.286 51.429 0l424 424c14.286 14.286 14.286 37.143 0 51.429z"></path>
              </svg>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default TabelaUsuarios