import React from 'react'
import { useSelector } from 'react-redux'

import styles from './add_creditos.module.css'

const PopupComprarCreditos = (props) => {
  const usuarioAtual = useSelector(state => state.usuarioAtual);

  return (
    <div className={styles['container']}>
      <div className={styles['container1']}>
        <div className={styles['head']}>
          <span className={styles['text']}>Comprar Empréstimos</span>
          <div className={styles['fechar-popup']} onClick={props.fecharPopup}>
            <svg
              viewBox="0 0 804.5714285714286 1024"
              className={styles['icon']}
            >
              <path d="M741.714 755.429c0 14.286-5.714 28.571-16 38.857l-77.714 77.714c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-168-168-168 168c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-77.714-77.714c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l168-168-168-168c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l77.714-77.714c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l168 168 168-168c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l77.714 77.714c10.286 10.286 16 24.571 16 38.857s-5.714 28.571-16 38.857l-168 168 168 168c10.286 10.286 16 24.571 16 38.857z"></path>
            </svg>
          </div>
        </div>
        <div className={styles['opcoes']}>
          <a href={`https://clkdmg.site/pay/pacote-80-creditos?source=${usuarioAtual._id}`} target="_blank" onClick={props.fecharPopup} className={styles['btn-option']}>
            <div className={styles['container2']}>
              <div className={styles['container3']}>
                <svg viewBox="0 0 1024 1024" className={styles['icon2']}>
                  <path d="M504 466q44 12 73 24t61 33 49 53 17 76q0 62-41 101t-109 51v92h-128v-92q-66-14-109-56t-47-108h94q8 90 126 90 62 0 89-23t27-53q0-72-128-104-200-46-200-176 0-58 42-99t106-55v-92h128v94q66 16 101 60t37 102h-94q-4-90-108-90-52 0-83 22t-31 58q0 58 128 92z"></path>
                </svg>
                <span className={styles['text01']}>80 créditos</span>
              </div>
              <span className={styles['text02']}>
                <span>1 colaborador</span>
                <br></br>
              </span>
            </div>
            <span className={styles['text05']}>R$ 80,00</span>
          </a>
          <a href={`https://clkdmg.site/pay/pacote-240-creditos?source=${usuarioAtual._id}`} target="_blank" onClick={props.fecharPopup} className={styles['btn-option1']}>
            <div className={styles['container4']}>
              <div className={styles['container5']}>
                <svg viewBox="0 0 1024 1024" className={styles['icon4']}>
                  <path d="M504 466q44 12 73 24t61 33 49 53 17 76q0 62-41 101t-109 51v92h-128v-92q-66-14-109-56t-47-108h94q8 90 126 90 62 0 89-23t27-53q0-72-128-104-200-46-200-176 0-58 42-99t106-55v-92h128v94q66 16 101 60t37 102h-94q-4-90-108-90-52 0-83 22t-31 58q0 58 128 92z"></path>
                </svg>
                <span className={styles['text06']}>240 créditos</span>
              </div>
              <span className={styles['text07']}>
                <span>3 colaboradores</span>
                <br></br>
              </span>
            </div>
            <span className={styles['text10']}>
              <span className={styles['text11']}>R$ 240,00</span>
              <span> R$ 150,00</span>
            </span>
          </a>
          <a href={`https://clkdmg.site/pay/pacote-400-creditos?source=${usuarioAtual._id}`} target="_blank" onClick={props.fecharPopup} className={styles['btn-option2']}>
            <div className={styles['container6']}>
              <div className={styles['container7']}>
                <svg viewBox="0 0 1024 1024" className={styles['icon6']}>
                  <path d="M504 466q44 12 73 24t61 33 49 53 17 76q0 62-41 101t-109 51v92h-128v-92q-66-14-109-56t-47-108h94q8 90 126 90 62 0 89-23t27-53q0-72-128-104-200-46-200-176 0-58 42-99t106-55v-92h128v94q66 16 101 60t37 102h-94q-4-90-108-90-52 0-83 22t-31 58q0 58 128 92z"></path>
                </svg>
                <span className={styles['text13']}>400 créditos</span>
              </div>
              <span className={styles['text14']}>
                <span>5 colaboradores</span>
                <br></br>
              </span>
            </div>
            <span className={styles['text17']}>
              <span className={styles['text18']}>R$ 400,00</span>
              <span> R$ 250,00</span>
            </span>
          </a>
        </div>
      </div>
    </div>
  )
}

export default PopupComprarCreditos
