import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import usuarioAtual, { setUsuario } from '../features/usuario/usuarioAtual';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';

import Header from '../components/header';
import Menu from '../components/menu';
import Footer from '../components/footer'
import PopupAgendamento from '../components/popups/add_consultoria';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import './diagnostico-form.css';


const DiagnosticoForm = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [popupAgendamentoVisible, setPopupAgendamentoVisible] = useState(false);
  const [showMenu, setShowMenu] = useState(window.innerWidth >= 1024);
  const location = useLocation();
  const { index, diagnostico } = location.state || {};

  const [formulario_perguntas, setFormularioPerguntas] = useState({
    dados_basicos: {
      nome: "Dados Básicos",
      descricao: "Estes são os dados básicos",
      tutorial: "Preencha todos os campos",
      campos: {
        nome: {
          validacao: null,
          etiqueta: "Seu nome",
          opcoes: null,
        },
        whatsapp: {
          validacao: "tel",
          etiqueta: "Whatsapp",
          opcoes: null,
        },
        email: {
          validacao: "email",
          etiqueta: "E-mail",
          opcoes: null,
        },
        nome_empresa: {
          validacao: null,
          etiqueta: "Nome da Empresa",
          opcoes: null,
        },
        site: {
          validacao: "site",
          etiqueta: "Site",
          opcoes: null,
        },
        instagram: {
          validacao: "instagram",
          etiqueta: "Instagram",
          opcoes: null,
        },
        perfil: {
          validacao: null,
          etiqueta: "Onde você se encaixa?",
          opcoes: [
            "Sou uma startup",
            "Sou um Prestador de serviço (Ex: clinica odontológica, Escritório, contador, etc)",
            "Sou uma Agência de Marketing/Publicidade",
            "Sou um empreendedor/empresário solo",
            "Outro",
          ],
        },
      }
    },
    faturamento: {
      nome: "Faturamento",
      descricao: "Estes são os dados de faturamento",
      tutorial: "Preencha todos os campos!",
      campos: {
        faturamento: {
          validacao: null,
          etiqueta: "Qual seu faturamento nos últimos 12 meses?",
          opcoes: [
            "Até 100 mil",
            "Entre 100 e 300 mil",
            "Entre 300 e 500 mil",
            "Entre 500k e 1 Milhão",
            "Acima de 1M",
          ],
        },
        ticket_medio: {
          validacao: null,
          etiqueta: "Qual o ticket médio do seu Serviço/Produto?",
          opcoes: [
            "Até 100,00",
            "Entre R$ 100,00 e R$ 300,00",
            "Entre R$ 300,00 e R$ 500,00",
            "Entre R$ 500,00 e R$ 1.000,00",
            "Entre R$ 1.000,00 e R$ 3.000,00",
            "Acima de R$ R$ 3.000,00",
          ],
        }
      }
    },
    ultimo_mes: {
      nome: "Sobre o último mês...",
      descricao: "Vamos fazer um panorama de como foi seu faturamento no último mês",
      tutorial: "Responda de forma numérica usando virgulas quando o caso. Ex: 10,5",
      campos: {
        custo_por_lead: {
          validacao: "monetario",
          etiqueta: "Qual foi o seu CPL (Custo por Lead)?",
          opcoes: [],
        },
        taxa_conversao: {
          validacao: "porcentagem",
          etiqueta: "Qual foi a sua Taxa de Conversão de Leads em Oportunidades?",
          opcoes: [],
        },
        ticket_medio: {
          validacao: "monetario",
          etiqueta: "Qual foi o seu ticket médio?",
          opcoes: [],
        },
        custo_por_aquisicao: {
          validacao: "monetario",
          etiqueta: "Qual foi o seu CAC (Custo de Aquisição de Cliente)?",
          opcoes: [],
        },
        reunioes_agendadas: {
          validacao: "numerico",
          etiqueta: "Qual foi o seu número de reuniões AGENDADAS?",
          opcoes: [],
        },
        reunioes_realizadas: {
          validacao: "numerico",
          etiqueta: "Qual foi o seu número de reuniões REALIZADAS?",
          opcoes: [],
        },
        reunioes_fechadas: {
          validacao: "numerico",
          etiqueta: "Qual foi o seu número de reuniões FECHADAS?",
          opcoes: [],
        },
        faturamento_total: {
          validacao: "monetario",
          etiqueta: "Qual foi o seu Faturamento Total do último mês?",
          opcoes: [],
        },
      }
    },
    gestao: {
      nome: "Gestão",
      descricao: "Nos conte um pouco sobre sua empresa. ",
      tutorial: "Preencha todos os campos!",
      campos: {
        plano_negocios: {
          validacao: null,
          etiqueta: "Você tem um Plano de Negócios",
          opcoes: [],
        },
        dedicando_tempo: {
          validacao: null,
          etiqueta: "No que você está dedicando a maior parte do seu tempo, hoje? (Assinale o que for mais verdadeiro)",
          opcoes: [
            "Operação- Ainda preciso cuidar das atividades do dia a dia",
            "Gestão- Dedico boa parte do meu dia à gestão e não ao operacional",
            "Dedico a maior parte do meu tempo ao Marketing e Vendas",
          ],
        },
        dedicando_estrategias: {
          validacao: null,
          etiqueta: "Hoje eu dedico a maior parte do meu dia em atividades estratégicas e não operacionais",
          opcoes: [],
        },
        metas_ano: {
          validacao: null,
          etiqueta: "Você tem claramente definido (e escrito) as metas da empresa do ano, dividido em metas menores",
          opcoes: [],
        },
        documentacao_processos: {
          validacao: null,
          etiqueta: "Você tem documentado todos os processos da sua empresa",
          opcoes: [],
        },
        indicadores_metas: {
          validacao: null,
          etiqueta: "Sua empresa tem indicadores e metas claramente definidas por setor da empresa (ex marketing, vendas, etc)",
          opcoes: [],
        },
        clareza_cliente: {
          validacao: null,
          etiqueta: "Você tem clareza de quem é seu principal cliente, qual problema você resolve e quem são seus concorrentes direto.",
          opcoes: [],
        },
        delega_funcoes: {
          validacao: null,
          etiqueta: "Você delega funções e hoje não está preso à operação.",
          opcoes: [],
        },
        processo_contratacao: {
          validacao: null,
          etiqueta: "Você tem um processo de contratação; sabe exatamente o que cada função precisa ser preenchida na sua empresa",
          opcoes: [],
        },
        preso_operacao: {
          validacao: null,
          etiqueta: "Você se sente preso na operação.",
          opcoes: [],
        },
        desafio_gestao: {
          validacao: null,
          etiqueta: "Descreva seu(s) maior(es) desafio em Gestão, hoje.",
          opcoes: [],
        },
      }
    },
    marketing: {
      nome: "Marketing",
      descricao: "Agora vamos analisar a maturidade e saúde do seu marketing",
      tutorial: "Preencha todos os campos!",
      campos: {
        estrategia_marketing: {
          validacao: null,
          etiqueta: "Você tem uma estratégia clara de marketing",
          opcoes: [],
        },
        funil_marketing: {
          validacao: null,
          etiqueta: "Você tem um funil e sabe quais indicadores e métricas acompanhar em cada etapa do marketing",
          opcoes: [],
        },
        canais_marketing: {
          validacao: null,
          etiqueta: "Você tem claro quais canais de marketing funcionam para seu negócio, e quais não funcionam",
          opcoes: [],
        },
        sabe_persona: {
          validacao: null,
          etiqueta: "Você sabe quem é sua persona",
          opcoes: [],
        },
        lead_mql_sql: {
          validacao: null,
          etiqueta: "Você conhece qual seu lead MQL e SQL",
          opcoes: [],
        },
        sla_marketing_vendas: {
          validacao: null,
          etiqueta: "Você sabe qual o SLA de Marketing e Vendas",
          opcoes: [],
        },
        posicionamento_marca: {
          validacao: null,
          etiqueta: "Sua marca está claramente posicionada",
          opcoes: [],
        },
        siglas_indicadores: {
          validacao: null,
          etiqueta: "CPL, CAC, LTV, Churn: você sabe o que essas siglas significam e conhece todos esses indicadores do seu negócio.",
          opcoes: [],
        },
        verba_marketing: {
          validacao: null,
          etiqueta: "Você tem uma verba mínima mensal direcionada para o marketing.",
          opcoes: [],
        },
        desafio_marketing: {
          validacao: null,
          etiqueta: "Descreva seu(s) maior(es) desafio em Marketing, hoje.",
          opcoes: [],
        },
      }
    },
    vendas: {
      nome: "Vendas",
      descricao: "Agora vamos analisar seu Depto de Vendas",
      tutorial: "Preencha todos os campos!",
      campos: {
        estrutura_do_processo_vendas: {
          validacao: null,
          etiqueta: "Temos bem estruturado nosso processo de vendas",
          opcoes: [],
        },
        processo_documentado: {
          validacao: null,
          etiqueta: "Temos nosso processo documentado do inicio ao fim em um Playbook de Vendas",
          opcoes: [],
        },
        importancia_playbook: {
          validacao: null,
          etiqueta: "Eu sei da importância crucial que um Playbook de Vendas desempenha na minha organização",
          opcoes: [],
        },
        secretaria_vendas: {
          validacao: null,
          etiqueta: "Minha secretária/atendente também cuida das vendas na empresa.",
          opcoes: ["Sim", "Não"],
        },
        equipe_comercial: {
          validacao: null,
          etiqueta: "Eu tenho uma equipe comercial com colaboradores direcionados só para essa área.",
          opcoes: [],
        },
        icp: {
          validacao: null,
          etiqueta: "Sei quem é meu ICP",
          opcoes: [],
        },
        uso_crm: {
          validacao: null,
          etiqueta: "Temos um CRM e o usamos.",
          opcoes: [],
        },
        metas_acompanhadas: {
          validacao: null,
          etiqueta: "Temos metas claras e as acompanhamos, com reuniões regulares",
          opcoes: [],
        },
        oportunidades_pipeline: {
          validacao: null,
          etiqueta: "Sei quantas oportunidades precisamos ter no pipeline para bater as metas",
          opcoes: [],
        },
        resultados_previsiveis: {
          validacao: null,
          etiqueta: "Nossos resultados são previsíveis e o alcançamos 80% + das vezes",
          opcoes: [],
        },
        desafio_vendas: {
          validacao: null,
          etiqueta: "Descreva seu(s) maior(es) desafio em Vendas, hoje.",
          opcoes: [],
        },
      }
    },
    documentacao: {
      nome: "Documentando seus Resultados",
      descricao: "Hora de documentar seus resultados.",
      tutorial: "É fundamental que você olhe para cada um desses indicadores. Conte com seu Consultor para ajuda-lo a preencher. É em cima desses dados que vamos trabalhar para construir seu negócio e analisar os resultados.",
      campos: {
        leads_mensais: {
          validacao: null,
          etiqueta: "Quantos leads gera mensalmente:",
          opcoes: [
            "Não sei",
            "Sei",
          ],
        },
        cpl: {
          validacao: null,
          etiqueta: "Qual o CPL",
          opcoes: [
            "Não sei",
            "Até R$ 15",
            "Entre R$ 15 e R$30",
            "Outro",
          ],
        },
        cac: {
          validacao: null,
          etiqueta: "Qual meu CAC",
          opcoes: [
            "Não sei",
            "Sei",
          ],
        },
        vendas_mensais: {
          validacao: null,
          etiqueta: "Quantas vendas por mês (média)",
          opcoes: [
            "Não sei",
            "Sei",
          ],
        },
        lifetime_cliente: {
          validacao: null,
          etiqueta: "Qual meu LT (em outras palavras, quanto tempo um cliente fica com você)",
          opcoes: [
            "Não sei",
            "Sei",
          ],
        },
      }
    },
  })

  const [formulario_dados, setFormularioDados] = useState({
    dados_basicos: {
      nome: {
        anulavel: false,
        tipo: 0,
        valor: [null, ""],
        qtd_opcoes: 0,
      },
      whatsapp: {
        anulavel: false,
        tipo: 0,
        valor: [null, ""],
        qtd_opcoes: 0,
      },
      email: {
        anulavel: false,
        tipo: 0,
        valor: [null, ""],
        qtd_opcoes: 0,
      },
      nome_empresa: {
        anulavel: false,
        tipo: 0,
        valor: [null, ""],
        qtd_opcoes: 0,
      },
      site: {
        anulavel: true,
        tipo: 0,
        valor: [null, ""],
        qtd_opcoes: 0,
      },
      instagram: {
        anulavel: true,
        tipo: 0,
        valor: [null, ""],
        qtd_opcoes: 0,
      },
      perfil: {
        anulavel: false,
        tipo: 2,
        valor: [null, ""],
        qtd_opcoes: 0,
      },
    },
    faturamento:
    {
      faturamento: {
        anulavel: false,
        tipo: 1,
        valor: [null, ""],
        qtd_opcoes: 0,
      },
      ticket_medio: {
        anulavel: false,
        tipo: 1,
        valor: [null, ""]
      }
    },
    ultimo_mes:
    {
      custo_por_lead: {
        anulavel: false,
        tipo: 0,
        valor: [null, ""],
        qtd_opcoes: 0,
      },
      taxa_conversao: {
        anulavel: false,
        tipo: 0,
        valor: [null, ""],
        qtd_opcoes: 0,
      },
      ticket_medio: {
        anulavel: false,
        tipo: 0,
        valor: [null, ""],
        qtd_opcoes: 0,
      },
      custo_por_aquisicao: {
        anulavel: false,
        tipo: 0,
        valor: [null, ""],
        qtd_opcoes: 0,
      },
      reunioes_agendadas: {
        anulavel: false,
        tipo: 0,
        valor: [null, ""],
        qtd_opcoes: 0,
      },
      reunioes_realizadas: {
        anulavel: false,
        tipo: 0,
        valor: [null, ""],
        qtd_opcoes: 0,
      },
      reunioes_fechadas: {
        anulavel: false,
        tipo: 0,
        valor: [null, ""],
        qtd_opcoes: 0,
      },
      faturamento_total: {
        anulavel: false,
        tipo: 0,
        valor: [null, ""],
        qtd_opcoes: 0,
      },
    },
    gestao: {
      plano_negocios: {
        anulavel: false,
        tipo: 3,
        valor: [null, ""],
        qtd_opcoes: 0,
      },
      dedicando_tempo: {
        anulavel: false,
        tipo: 1,
        valor: [null, ""],
        qtd_opcoes: 0,
      },
      dedicando_estrategias: {
        anulavel: false,
        tipo: 3,
        valor: [null, ""],
        qtd_opcoes: 0,
      },
      metas_ano: {
        anulavel: false,
        tipo: 3,
        valor: [null, ""],
        qtd_opcoes: 0,
      },
      documentacao_processos: {
        anulavel: false,
        tipo: 3,
        valor: [null, ""],
        qtd_opcoes: 0,
      },
      indicadores_metas: {
        anulavel: false,
        tipo: 3,
        valor: [null, ""],
        qtd_opcoes: 0,
      },
      clareza_cliente: {
        anulavel: false,
        tipo: 3,
        valor: [null, ""],
        qtd_opcoes: 0,
      },
      delega_funcoes: {
        anulavel: false,
        tipo: 3,
        valor: [null, ""],
        qtd_opcoes: 0,
      },
      processo_contratacao: {
        anulavel: false,
        tipo: 3,
        valor: [null, ""],
        qtd_opcoes: 0,
      },
      preso_operacao: {
        anulavel: false,
        tipo: 3,
        valor: [null, ""],
        qtd_opcoes: 0,
      },
      desafio_gestao: {
        anulavel: false,
        tipo: 4,
        valor: [null, ""],
        qtd_opcoes: 0,
      },
    },
    marketing: {
      estrategia_marketing: {
        anulavel: false,
        tipo: 3,
        valor: [null, ""],
        qtd_opcoes: 0,
      },
      funil_marketing: {
        anulavel: false,
        tipo: 3,
        valor: [null, ""],
        qtd_opcoes: 0,
      },
      canais_marketing: {
        anulavel: false,
        tipo: 3,
        valor: [null, ""],
        qtd_opcoes: 0,
      },
      sabe_persona: {
        anulavel: false,
        tipo: 3,
        valor: [null, ""],
        qtd_opcoes: 0,
      },
      lead_mql_sql: {
        anulavel: false,
        tipo: 3,
        valor: [null, ""],
        qtd_opcoes: 0,
      },
      sla_marketing_vendas: {
        anulavel: false,
        tipo: 3,
        valor: [null, ""],
        qtd_opcoes: 0,
      },
      posicionamento_marca: {
        anulavel: false,
        tipo: 3,
        valor: [null, ""],
        qtd_opcoes: 0,
      },
      siglas_indicadores: {
        anulavel: false,
        tipo: 3,
        valor: [null, ""],
        qtd_opcoes: 0,
      },
      verba_marketing: {
        anulavel: false,
        tipo: 3,
        valor: [null, ""],
        qtd_opcoes: 0,
      },
      desafio_marketing: {
        anulavel: false,
        tipo: 4,
        valor: [null, ""],
        qtd_opcoes: 0,
      },
    },
    vendas: {
      estrutura_do_processo_vendas: {
        anulavel: false,
        tipo: 3,
        valor: [null, ""],
        qtd_opcoes: 0,
      },
      processo_documentado: {
        anulavel: false,
        tipo: 3,
        valor: [null, ""],
        qtd_opcoes: 0,
      },
      importancia_playbook: {
        anulavel: false,
        tipo: 3,
        valor: [null, ""],
        qtd_opcoes: 0,
      },
      secretaria_vendas: {
        anulavel: false,
        tipo: 1,
        valor: [null, ""],
        qtd_opcoes: 0,
      },
      equipe_comercial: {
        anulavel: false,
        tipo: 3,
        valor: [null, ""],
        qtd_opcoes: 0,
      },
      icp: {
        anulavel: false,
        tipo: 3,
        valor: [null, ""],
        qtd_opcoes: 0,
      },
      uso_crm: {
        anulavel: false,
        tipo: 3,
        valor: [null, ""],
        qtd_opcoes: 0,
      },
      metas_acompanhadas: {
        anulavel: false,
        tipo: 3,
        valor: [null, ""],
        qtd_opcoes: 0,
      },
      oportunidades_pipeline: {
        anulavel: false,
        tipo: 3,
        valor: [null, ""],
        qtd_opcoes: 0,
      },
      resultados_previsiveis: {
        anulavel: false,
        tipo: 3,
        valor: [null, ""],
        qtd_opcoes: 0,
      },
      desafio_vendas: {
        anulavel: false,
        tipo: 4,
        valor: [null, ""],
        qtd_opcoes: 0,
      },
    },
    documentacao: {
      leads_mensais: {
        anulavel: false,
        tipo: 2,
        valor: [null, ""],
        qtd_opcoes: 0,
      },
      cpl: {
        anulavel: false,
        tipo: 2,
        valor: [null, ""],
        qtd_opcoes: 0,
      },
      cac: {
        anulavel: false,
        tipo: 2,
        valor: [null, ""],
        qtd_opcoes: 0,
      },
      vendas_mensais: {
        anulavel: false,
        tipo: 2,
        valor: [null, ""],
        qtd_opcoes: 0,
      },
      lifetime_cliente: {
        anulavel: false,
        tipo: 2,
        valor: [null, ""],
        qtd_opcoes: 0,
      },
    },
  })

  useEffect(() => {
    const carregarDadosLocalStorage = () => {
      if (diagnostico) {
        setFormularioDados(diagnostico);
        setSecaoAtual(1);
      } else {
        const localStorageData = localStorage.getItem('simbium_diagnostico_form_state');
        if (localStorageData) {
          const estadoSalvo = JSON.parse(localStorageData);
          setFormularioDados(estadoSalvo.formulario_dados);
          setSecaoAtual(estadoSalvo.secao_atual);
        }
      }
    };
    carregarDadosLocalStorage();
  }, [diagnostico]); // Added diagnostico as dependency


  const onboardingDiagnostico = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/usuarios/onboarding`, {
        etapa: 'diagnostico'
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      });

      if (response.data && response.data.usuario) {
        dispatch(setUsuario(response.data.usuario));
      }
    } catch (err) {
      console.error("Failed to complete onboarding:", err);
      // Consider showing an error message to the user
    }
  };


  const [form_sections, setFormSections] = useState(() => {
    return Object.keys(formulario_dados).map(secao => [
      secao,
      Object.keys(formulario_dados[secao]).length
    ]);
  });


  const [secao_atual, setSecaoAtual] = useState([0, 0])
  const [instructions, setInstructions] = useState(true)

  const passarQuestao = (sentido = 'proximo') => {
    const incremento = (sentido === 'proximo' ? 1 : sentido === 'anterior' ? -1 : 0)
    let secao = secao_atual[0]
    let questao = secao_atual[1]

    questao += incremento
    if (questao === form_sections[secao][1]) {
      secao += incremento;
      questao = 0;
      if (secao === form_sections.length) salvarFormulario()
    } else if (questao < 0) {
      secao += incremento;
      questao = form_sections[secao][1] - 1;
    }

    if (incremento === -1) setInputValidado(true)

    setInstructions(true)
    setSecaoAtual([secao, questao])
    if (!diagnostico) salvarEstadoFormulario();
  }

  const fecharInstrucoes = () => {
    setInstructions(false)
  }

  const salvarEstadoFormulario = () => {
    // Verifica se há dados salvos no localStorage
    const localStorageData = localStorage.getItem('simbium_diagnostico_form_state');

    if (!localStorageData) {
      // Se não houver dados, cria um novo estado
      const novoEstado = {
        formulario_perguntas,
        formulario_dados,
        secao_atual,
        form_sections,
      };

      // Converte o estado para JSON e salva no localStorage
      localStorage.setItem('simbium_diagnostico_form_state', JSON.stringify(novoEstado));
    } else {
      // Se houver dados, atualiza o estado existente
      const estadoExistente = JSON.parse(localStorageData);

      // Atualiza apenas as partes relevantes do estado
      estadoExistente.formulario_perguntas = formulario_perguntas;
      estadoExistente.formulario_dados = formulario_dados;
      estadoExistente.secao_atual = secao_atual;
      estadoExistente.form_sections = form_sections;

      // Salva o estado atualizado no localStorage
      localStorage.setItem('simbium_diagnostico_form_state', JSON.stringify(estadoExistente));
    }
  };

  const [save_loading, setSaveLoading] = useState(false)

  const salvarFormulario = async () => {
    // setFormulario({ ...formulario, save_date: new Date() })
    setSaveLoading(true)
    try {
      console.log(formulario_dados)
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/usuarios/diagnostico`, // Substitua pela URL correta da sua rota
        formulario_dados,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`, // Substitua pela lógica correta de autenticação
          },
        }
      );
      console.log(response)

      if (response.data && response.data.message) {
        toast.success(response.data.message);
      }

      updateOnboard()
      localStorage.removeItem('simbium_diagnostico_form_state');
      dispatch(setUsuario());

      if (!usuarioAtual.onboarding || !usuarioAtual.onboarding.etapas || !usuarioAtual.onboarding.etapas.includes("diagnostico")) {
        onboardingDiagnostico()
      }

      history.push('/diagnostico');
    } catch (error) {
      if (!diagnostico) salvarEstadoFormulario()
      if (error.response && error.response.data && error.response.data.message)
        toast.error(error.response.data.message);
    }
    setSaveLoading(false);
  };

  const atualizarFormulario = async () => {
    setSaveLoading(true)
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/usuarios/diagnostico/${index}`,
        {
          formulario_dados,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );

      if (response.data && response.data.message) {
        toast.success(response.data.message);
      }

      dispatch(setUsuario(response.data.usuario));
      history.push('/diagnostico');
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message)
        toast.error(error.response.data.message);
    }
    setSaveLoading(false);
  };

  const updateOnboard = () => {
    const onboardingDiagnostico = async () => {
      try {

        const response = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/usuarios/onboarding`, {
          etapa: 'diagnostico'
        }, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        })

        if (response.data && response.data.usuario) {
          dispatch(setUsuario(response.data.usuario));
        }

      } catch (err) {

      }

    }
  }

  const [input_validado, setInputValidado] = useState(false)

  const validacao = (type, input) => {
    //setInputValidado(true);
    //return input
    setInputValidado(false);
    const cleanInput = input.replace(/[^\w]/g, ''); // Remove caracteres não alfanuméricos do input

    if (input.length < 1 || cleanInput.length < 1) {
      setInputValidado(false)
      return input;
    }

    switch (type) {
      case 'tel':
      case 'whatsapp':
        return formatTelefone(cleanInput);
      case 'email':
        return formatEmail(input);
      case 'site':
        return formatSite(input);
      case 'instagram':
        return formatInstagram(input);
      case 'porcentagem':
        return formatPorcentagem(input);
      case 'monetario':
      case 'numero':
        return formatNumero(input);
      default:
        setInputValidado(true);
    }

    setInputValidado(false)
  };

  const formatTelefone = (input) => {
    // Formatação para telefone ou WhatsApp
    const match = input.match(/^(\d{2})(\d{1})(\d{4})(\d{4})$/);
    if (match) {
      setInputValidado(true)
      return `(${match[1]}) ${match[2]} ${match[3]}-${match[4]}`;
    }
    return input;
  }

  const formatEmail = (input) => {
    // Formatação para e-mail
    const match = input.match(/^([a-zA-Z0-9._-]+)@([a-zA-Z0-9.-]+)\.([a-zA-Z]{2,4})$/);
    if (match) {
      setInputValidado(true)
      return `${match[1]}@${match[2]}.${match[3]}`;
    }
    return input;
  }

  const formatSite = (input) => {
    // Formatação para site
    const match = input.match(/^www\.([a-zA-Z0-9.-]+)\.([a-zA-Z]{2,4})$/);
    if (match) {
      setInputValidado(true)
      return `www.${match[1]}.${match[2]}`;
    }
    return input;
  }

  const formatInstagram = (input) => {
    // Formatação para Instagram
    const match = input.match(/^@([a-zA-Z0-9._]+)$/);
    if (match) {
      setInputValidado(true)
      return `@${match[1]}`;
    }
    return input;
  }

  const formatPorcentagem = (input) => {
    // Formatação para porcentagem
    const match = input.match(/^\d+(\.\d{1,2})?%?$/);
    if (match) {
      setInputValidado(true);
      return input;
    }
    return input;
  };

  const formatNumero = (input) => {
    // Formatação para valores numéricos (inteiros ou decimais)
    const match = input.match(/^(\d+|\d+[.,]\d+)$/);
    if (match) {
      setInputValidado(true);
      return input;
    }
    return input;
  };


  return (
    <div>
      <Header usuarioAtual={usuarioAtual} showMenu={showMenu} setShowMenu={setShowMenu} />
      <div className="diagnostico-container">
        <Helmet>
          <title>Formulário Diagnostico - Simbium</title>
          <meta
            property="og:title"
            content="Formulário Diagnostico - Simbium"
          />
        </Helmet>
        <div className="diagnostico-area">

          {popupAgendamentoVisible && (
            <PopupAgendamento erro={(e) => { toast.error(e) }} sucesso={() => toast.success("Consultoria agendada com sucesso!")} fecharPopup={() => setPopupAgendamentoVisible(false)} />
          )}

          <div className='diagnostico-panel l-cont'>
            <div className='diagnostico-content'>
              <h1 className='diag-form-page-title'>Formulário Diagnóstico</h1>
              <div id="diagnostico-form">
                {Object.entries(formulario_perguntas).map(([secao_nome, secao_dados], index) => (
                  <div key={index} className={`form-secao ${secao_atual[0] === index ? 'show' : 'hide'}`}>
                    <div className='secao-header'>
                      <h2 className='secao-nome'>
                        {secao_dados.nome}
                        <p className='secao-descricao'>{secao_dados.descricao}</p>
                      </h2>
                      <div className='secao-contador'>
                        {form_sections.map((registro, index) => (
                          <div key={index} className={`contador-item ${index === secao_atual[0] ? 'contador-atual' : 'contador-outro'}`}></div>
                        ))}
                      </div>
                    </div>

                    <div className='secao-questao'>
                      {Object.entries(secao_dados.campos).map(([questao_nome, questao_dados], index_questoes) => (
                        <div key={index_questoes} className={`questao ${secao_atual[0] === index && secao_atual[1] === index_questoes ? 'show' : 'hide'}`}>

                          {(instructions === true && index_questoes === 0) &&
                            <div className='explicacao'>
                              <h2 className='explicacao-nome'>
                                {secao_dados.nome}
                                <p className='explicacao-descricao'>{secao_dados.descricao}</p>
                              </h2>
                              <div className='explicacao-contador'>
                                {form_sections.map((registro, index) => (
                                  <div key={index} className={`contador-item ${index === secao_atual[0] ? 'contador-atual' : 'contador-outro'}`}></div>
                                ))}
                              </div>
                              <h3 className='explicacao-tutorial'>
                                {secao_dados.tutorial}
                              </h3>
                              <div className='explicacao-fechar' onClick={fecharInstrucoes}>Fechar</div>
                            </div>
                          }
                          <p className={`questao-texto ${formulario_dados[secao_nome][questao_nome].anulavel ? '' : 'required'}`}>{questao_dados.etiqueta}</p>
                          {formulario_dados[secao_nome][questao_nome].tipo === 0 && (
                            <div>
                              <div className="input-group text-group">
                                <input
                                  type="text"
                                  className="text-input"
                                  placeholder="Digite aqui"
                                  value={formulario_dados[secao_nome][questao_nome].valor[1]}
                                  onChange={(e) => setFormularioDados((prevFormularioDados) => ({
                                    ...prevFormularioDados, [secao_nome]: {
                                      ...prevFormularioDados[secao_nome], [questao_nome]: {
                                        ...prevFormularioDados[secao_nome][questao_nome], valor: [0, validacao(questao_dados.validacao, e.target.value)],
                                      },
                                    },
                                  }))}
                                />
                              </div>
                              <div className='btn-questao-container'>
                                <button className={`btn-anterior-questao ${secao_atual[0] > 0 || secao_atual[1] > 0 ? 'show' : 'hide'}`} onClick={() => passarQuestao('anterior')}>Anterior</button>
                                <button className={`btn-proxima-questao ${input_validado || formulario_dados[secao_nome][questao_nome].anulavel ? 'show' : 'hide'}`} onClick={() => passarQuestao('proximo')}>Próxima</button>
                              </div>
                            </div>
                          )}

                          {formulario_dados[secao_nome][questao_nome].tipo === 1 && (
                            <div>
                              <div className="input-group radio-group">
                                {questao_dados.opcoes.map((opcao, index) => (
                                  <label key={index} className="radio-option">
                                    <input
                                      type="radio"
                                      name={`option-${secao_nome}-${questao_nome}`}
                                      checked={formulario_dados[secao_nome][questao_nome].valor[0] === index}
                                      onChange={() => setFormularioDados((prevFormularioDados) => ({
                                        ...prevFormularioDados,
                                        [secao_nome]: {
                                          ...prevFormularioDados[secao_nome],
                                          [questao_nome]: {
                                            ...prevFormularioDados[secao_nome][questao_nome],
                                            valor: [index, ''],
                                            qtd_opcoes: questao_dados.opcoes.length,
                                          },
                                        },
                                      }))}
                                    />
                                    <div>{opcao}</div>
                                  </label>
                                ))}
                              </div>
                              <div className='btn-questao-container'>
                                <button className={`btn-anterior-questao ${secao_atual[0] > 0 || secao_atual[1] > 0 ? 'show' : 'hide'}`} onClick={() => passarQuestao('anterior')}>Anterior</button>
                                <button className={`btn-proxima-questao ${formulario_dados[secao_nome][questao_nome].valor[0] !== null ? 'show' : 'hide'}`} onClick={() => passarQuestao('proximo')}>Próxima</button>
                              </div>
                            </div>
                          )}


                          {formulario_dados[secao_nome][questao_nome].tipo === 2 && (
                            <div>
                              <div className="input-group radio-group">
                                {questao_dados.opcoes.map((opcao, index) => (
                                  <label key={index} className="radio-option">
                                    <input
                                      type="radio"
                                      name={`option-${secao_nome}-${questao_nome}`}
                                      checked={formulario_dados[secao_nome][questao_nome].valor[0] === index}
                                      onChange={() => setFormularioDados((prevFormularioDados) => ({
                                        ...prevFormularioDados,
                                        [secao_nome]: {
                                          ...prevFormularioDados[secao_nome],
                                          [questao_nome]: {
                                            ...prevFormularioDados[secao_nome][questao_nome],
                                            valor: [index, ''],
                                            qtd_opcoes: questao_dados.opcoes.length,
                                          },
                                        },
                                      }))}
                                    />
                                    <div>{opcao}</div>
                                  </label>
                                ))}
                              </div>
                              {formulario_dados[secao_nome][questao_nome].valor[0] === formulario_perguntas[secao_nome].campos[questao_nome].opcoes.length - 1 ? (
                                <div>
                                  <div className="input-group text-group">
                                    <input
                                      type="text"
                                      className="text-input"
                                      placeholder="Digite aqui"
                                      value={formulario_dados[secao_nome][questao_nome].valor[1]}
                                      onInput={(e) => setFormularioDados((prevFormularioDados) => ({
                                        ...prevFormularioDados,
                                        [secao_nome]: {
                                          ...prevFormularioDados[secao_nome],
                                          [questao_nome]: {
                                            ...prevFormularioDados[secao_nome][questao_nome],
                                            valor: [formulario_dados[secao_nome][questao_nome].valor[0], e.target.value],
                                          },
                                        },
                                      }))}
                                    />
                                  </div>
                                  <div className='btn-questao-container'>
                                    <button className={`btn-anterior-questao ${secao_atual[0] > 0 || secao_atual[1] > 0 ? 'show' : 'hide'}`} onClick={() => passarQuestao('anterior')}>Anterior</button>
                                    <button className={`btn-proxima-questao ${formulario_dados[secao_nome][questao_nome].valor[1].length > 0 ? 'show' : 'hide'}`} onClick={() => passarQuestao('proximo')}>Próxima</button>
                                  </div>
                                </div>
                              ) :
                                <div className='btn-questao-container'>
                                  <button className={`btn-anterior-questao ${secao_atual[0] > 0 || secao_atual[1] > 0 ? 'show' : 'hide'}`} onClick={() => passarQuestao('anterior')}>Anterior</button>
                                  <button className={`btn-proxima-questao ${formulario_dados[secao_nome][questao_nome].valor[0] !== null ? 'show' : 'hide'}`} onClick={() => passarQuestao('proximo')}>Próxima</button>
                                </div>
                              }
                            </div>
                          )}



                          {formulario_dados[secao_nome][questao_nome].tipo === 3 && (
                            <div>
                              <div className="radio-list-horizontal-container">
                                <div className="radio-list-horizontal">
                                  {[0, 1, 2, 3, 4].map((valor, index) => (
                                    <React.Fragment key={index}>
                                      <input
                                        type="radio"
                                        id={`radio${index + 1}-${questao_nome}`}
                                        name={`radio${index + 1}-${questao_nome}`}
                                        className={`radiobox radio${index + 1}`}
                                        checked={formulario_dados[secao_nome][questao_nome].valor[0] === index}
                                        onChange={() => setFormularioDados((prevFormularioDados) => ({
                                          ...prevFormularioDados,
                                          [secao_nome]: {
                                            ...prevFormularioDados[secao_nome],
                                            [questao_nome]: {
                                              ...prevFormularioDados[secao_nome][questao_nome],
                                              valor: [index, ''],
                                              qtd_opcoes: questao_dados.opcoes.length,
                                            },
                                          },
                                        }))}
                                      />
                                      <label
                                        htmlFor={`radio${index + 1}-${questao_nome}`}
                                        className={`circle-${index === 0 ? 'large' : index === 2 || index === 3 ? 'small' : 'medium'} `}
                                      ></label>
                                    </React.Fragment>
                                  ))}
                                </div>
                              </div>
                              <div className='btn-questao-container'>
                                <button className={`btn-anterior-questao ${secao_atual[0] > 0 || secao_atual[1] > 0 ? 'show' : 'hide'}`} onClick={() => passarQuestao('anterior')}>Anterior</button>
                                <button className={`btn-proxima-questao ${formulario_dados[secao_nome][questao_nome].valor[0] != null ? 'show' : 'hide'}`} onClick={() => passarQuestao('proximo')}>Próxima</button>
                              </div>
                            </div>
                          )}



                          {formulario_dados[secao_nome][questao_nome].tipo === 4 && (
                            <div>
                              <div className="input-group text-group">
                                <textarea
                                  className="text-area"
                                  placeholder="Digite aqui"
                                  rows="4"
                                  value={formulario_dados[secao_nome][questao_nome].valor[1]}
                                  onInput={(e) => setFormularioDados((prevFormularioDados) => ({
                                    ...prevFormularioDados,
                                    [secao_nome]: {
                                      ...prevFormularioDados[secao_nome],
                                      [questao_nome]: {
                                        ...prevFormularioDados[secao_nome][questao_nome],
                                        valor: [null, e.target.value],
                                      },
                                    },
                                  }))}
                                ></textarea>
                              </div>
                              <div className='btn-questao-container'>
                                <button className={`btn-anterior-questao ${secao_atual[0] > 0 || secao_atual[1] > 0 ? 'show' : 'hide'}`} onClick={() => passarQuestao('anterior')}>Anterior</button>
                                <button className={`btn-proxima-questao ${formulario_dados[secao_nome][questao_nome].valor[1].length > 0 ? 'show' : 'hide'}`} onClick={() => passarQuestao('proximo')}>Próximo</button>
                              </div>
                            </div>
                          )}
                          <p className='questao-contador'>{secao_atual[1] + 1}/{form_sections[secao_atual[0]] ? form_sections[secao_atual[0]][1] : ''}</p>

                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>

            </div>
          </div>

        </div>
      </div>
      <Footer></Footer>
    </div>
  )
}

export default DiagnosticoForm
