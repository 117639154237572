import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation, Link } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import Menu from '../../components/menu';
import Footer from '../../components/footer';
import Header from '../../components/header';

const SejaConsultor = () => {
    const usuarioAtual = useSelector(state => state.usuarioAtual);
    const [showMenu, setShowMenu] = useState(false);
    const [sendContact, setSendContact] = useState(false);

    const mixPanelAcesso = () => {
        if (!usuarioAtual || !usuarioAtual._id) {
            console.error('usuarioAtual or usuarioAtual._id is missing');
            return;
        }
        mixpanel.track("Seja Consultor | Acesso à página", {
            distinct_id: usuarioAtual._id,
        });
    }

    const [diferencaTempo, setDiferencaTempo] = useState(0);

    useEffect(() => {
        mixPanelAcesso();

        const interval = setInterval(() => {
            setDiferencaTempo(prevTempo => prevTempo + 1);
        }, 60000);

        return () => clearInterval(interval);
    }, []);

    const mixPanelContato = () => {
        if (!usuarioAtual || !usuarioAtual._id) {
            console.error('usuarioAtual ou usuarioAtual._id ausente');
            return;
        }
        mixpanel.track("Seja Consultor | Contato", {
            distinct_id: usuarioAtual._id,
            tempo_convencimento: diferencaTempo,
        });
    }

    const sendEmail = () => {
        setSendContact(true);

        axios.post(
            `${process.env.REACT_APP_BACKEND_BASE_URL}/usuarios/send-email`,
            {
                recipientName: usuarioAtual.name,
                recipientEmail: usuarioAtual.email,
                subject: 'QUERO SER UM CONSULTOR',
                htmlContent: `
                Nome: ${usuarioAtual.nome}<br>
                Email: ${usuarioAtual.email}<br>
                Telefone: ${usuarioAtual.telefone || "Não possui"}
            `,
            },
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            }
        )
            .then(response => {
                // Envia dados para o Mixpanel ao enviar o e-mail
                mixPanelContato();
                console.log("RESPOSTA DO SERVIDOR: ", response);
                toast.success('E-mail enviado com sucesso!');
            })
            .catch(error => {
                console.error("ERRO DO SERVIDOR: ", error);
                toast.error('Erro ao enviar e-mail. Por favor, tente novamente mais tarde');
                setSendContact(false);
            });
    }



    return (
        <div className='w-full'>
            <Helmet>
                <title>Simbium</title>
                <meta property="og:title" content="Simbium" />
            </Helmet>
            <Header usuarioAtual={usuarioAtual} showMenu={showMenu} setShowMenu={setShowMenu} />
            <div className='bg-neutral-900 flex flex-col font-poppins'>
                <div className='w-full flex flex-row lg:mt-14 lg:pt-10 pt-5 md:p-5 gap-5'>
                    {showMenu &&
                        <div className='w-3/12 mb-5'>
                            <Menu showMenu={showMenu} setShowMenu={setShowMenu} />
                        </div>
                    }
                    <div className={`${showMenu ? 'w-9/12' : 'w-11/12'} mb-5 mx-auto text-neutral-50`}>
                        <div className="flex flex-col w-full bg-orange-700 rounded-lg text-white">
                            {/* Hero Section */}
                            <div className="text-center py-10 text-neutral-50 w-10/12 mx-auto">
                                <h1 className="text-xl md:text-3xl lg:text-5xl font-extrabold leading-tight mb-5 text-white">
                                    Seja um Consultor e Ganhe Dinheiro com suas Habilidades
                                </h1>
                                <p className="text-xs md:text-sm lg:text-lg mt-2">
                                    Você é especialista em e-commerce e quer ganhar dinheiro com isso? <strong className='text-yellow-400'>Seja uma consultora e ajude outras pessoas a ter sucesso online.</strong>
                                </p>
                            </div>

                            {/* Benefits Section */}
                            <div className="flex flex-col items-center gap-12 md:gap-24 bg-neutral-950 p-5 py-10 lg:p-20 lg:py-24 shadow-md text-neutral-50">
                                <h2 className="text-xl md:text-2xl lg:text-4xl font-bold text-center">
                                    Oferecemos uma ampla gama de serviços de consultoria, incluindo:
                                </h2>
                                <ul className="flex flex-col gap-5 text-xs md:text-lg lg:text-xl divide-y divide-neutral-700 text-neutral-300 w-11/12 mx-auto">
                                    <li className="gap-5 pb-2.5 md:flex md:items-center text-center md:text-left">
                                        <svg className="w-10 h-10 md:mr-5 mx-auto fill-yellow-600" viewBox="0 -960 960 960">
                                            <path d="m344-60-76-128-144-32 14-148-98-112 98-112-14-148 144-32 76-128 136 58 136-58 76 128 144 32-14 148 98 112-98 112 14 148-144 32-76 128-136-58-136 58Zm34-102 102-44 104 44 56-96 110-26-10-112 74-84-74-86 10-112-110-24-58-96-102 44-104-44-56 96-110 24 10 112-74 86 74 84-10 114 110 24 58 96Zm102-318Zm-42 142 226-226-56-58-170 170-86-84-56 56 142 142Z" />
                                        </svg>
                                        <p>Consultoria em marketplace (Mercado Livre, Amazon, Shopee, Mercado Shops)</p>
                                    </li>
                                    <li className="gap-5 pt-5 pb-2.5 md:flex md:items-center text-center md:text-left">
                                        <svg className="w-10 h-10 md:mr-5 mx-auto fill-yellow-600" viewBox="0 -960 960 960">
                                            <path d="m344-60-76-128-144-32 14-148-98-112 98-112-14-148 144-32 76-128 136 58 136-58 76 128 144 32-14 148 98 112-98 112 14 148-144 32-76 128-136-58-136 58Zm34-102 102-44 104 44 56-96 110-26-10-112 74-84-74-86 10-112-110-24-58-96-102 44-104-44-56 96-110 24 10 112-74 86 74 84-10 114 110 24 58 96Zm102-318Zm-42 142 226-226-56-58-170 170-86-84-56 56 142 142Z" />
                                        </svg>
                                        <p>Consultoria em tráfego pago</p>
                                    </li>
                                    <li className="gap-5 pt-5 pb-2.5 md:flex md:items-center text-center md:text-left">
                                        <svg className="w-10 h-10 md:mr-5 mx-auto fill-yellow-600" viewBox="0 -960 960 960">
                                            <path d="m344-60-76-128-144-32 14-148-98-112 98-112-14-148 144-32 76-128 136 58 136-58 76 128 144 32-14 148 98 112-98 112 14 148-144 32-76 128-136-58-136 58Zm34-102 102-44 104 44 56-96 110-26-10-112 74-84-74-86 10-112-110-24-58-96-102 44-104-44-56 96-110 24 10 112-74 86 74 84-10 114 110 24 58 96Zm102-318Zm-42 142 226-226-56-58-170 170-86-84-56 56 142 142Z" />
                                        </svg>
                                        <p>Produção de foto e filmagem</p>
                                    </li>
                                    <li className="gap-5 pt-5 pb-2.5 md:flex md:items-center text-center md:text-left">
                                        <svg className="w-10 h-10 md:mr-5 mx-auto fill-yellow-600" viewBox="0 -960 960 960">
                                            <path d="m344-60-76-128-144-32 14-148-98-112 98-112-14-148 144-32 76-128 136 58 136-58 76 128 144 32-14 148 98 112-98 112 14 148-144 32-76 128-136-58-136 58Zm34-102 102-44 104 44 56-96 110-26-10-112 74-84-74-86 10-112-110-24-58-96-102 44-104-44-56 96-110 24 10 112-74 86 74 84-10 114 110 24 58 96Zm102-318Zm-42 142 226-226-56-58-170 170-86-84-56 56 142 142Z" />
                                        </svg>
                                        <p>Criação de anúncios e banners</p>
                                    </li>
                                    <li className="gap-5 pt-5 pb-2.5 md:flex md:items-center text-center md:text-left">
                                        <svg className="w-10 h-10 md:mr-5 mx-auto fill-yellow-600" viewBox="0 -960 960 960">
                                            <path d="m344-60-76-128-144-32 14-148-98-112 98-112-14-148 144-32 76-128 136 58 136-58 76 128 144 32-14 148 98 112-98 112 14 148-144 32-76 128-136-58-136 58Zm34-102 102-44 104 44 56-96 110-26-10-112 74-84-74-86 10-112-110-24-58-96-102 44-104-44-56 96-110 24 10 112-74 86 74 84-10 114 110 24 58 96Zm102-318Zm-42 142 226-226-56-58-170 170-86-84-56 56 142 142Z" />
                                        </svg>
                                        <p>Criação de e-commerces</p>
                                    </li>
                                    <li className="gap-5 pt-5 md:flex md:items-center text-center md:text-left">
                                        <svg className="w-10 h-10 md:mr-5 mx-auto fill-yellow-600" viewBox="0 -960 960 960">
                                            <path d="m344-60-76-128-144-32 14-148-98-112 98-112-14-148 144-32 76-128 136 58 136-58 76 128 144 32-14 148 98 112-98 112 14 148-144 32-76 128-136-58-136 58Zm34-102 102-44 104 44 56-96 110-26-10-112 74-84-74-86 10-112-110-24-58-96-102 44-104-44-56 96-110 24 10 112-74 86 74 84-10 114 110 24 58 96Zm102-318Zm-42 142 226-226-56-58-170 170-86-84-56 56 142 142Z" />
                                        </svg>
                                        <p>E muito mais!</p>
                                    </li>
                                </ul>
                            </div>

                            {/* Benefits Section */}
                            <div className="flex flex-col items-center gap-12 md:gap-24 bg-neutral-800 p-5 py-10 lg:p-20 lg:py-24 shadow-md text-neutral-50">
                                <h2 className="text-xl md:text-2xl lg:text-4xl font-bold text-center">
                                    Vantagens de ser um consultor:
                                </h2>

                                <ul className="mt-4 space-y-2 text-xl grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 break-words text-center gap-5">
                                    <li className="mx-auto p-5 bg-neutral-900 rounded-lg">
                                        <svg className="w-14 h-14 mx-auto mb-5 fill-orange-500" viewBox="0 -960 960 960"><path d="m612-292 56-56-148-148v-184h-80v216l172 172ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-400Zm0 320q133 0 226.5-93.5T800-480q0-133-93.5-226.5T480-800q-133 0-226.5 93.5T160-480q0 133 93.5 226.5T480-160Z" /></svg>
                                        <p>Trabalhe de qualquer lugar, a qualquer hora</p>
                                    </li>
                                    <li className="mx-auto p-5 bg-neutral-900 rounded-lg">
                                        <svg className="w-14 h-14 mx-auto mb-5 fill-orange-500" viewBox="0 -960 960 960"><path d="M400-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47ZM80-160v-112q0-33 17-62t47-44q51-26 115-44t141-18h14q6 0 12 2-8 18-13.5 37.5T404-360h-4q-71 0-127.5 18T180-306q-9 5-14.5 14t-5.5 20v32h252q6 21 16 41.5t22 38.5H80Zm560 40-12-60q-12-5-22.5-10.5T584-204l-58 18-40-68 46-40q-2-14-2-26t2-26l-46-40 40-68 58 18q11-8 21.5-13.5T628-460l12-60h80l12 60q12 5 22.5 11t21.5 15l58-20 40 70-46 40q2 12 2 25t-2 25l46 40-40 68-58-18q-11 8-21.5 13.5T732-180l-12 60h-80Zm40-120q33 0 56.5-23.5T760-320q0-33-23.5-56.5T680-400q-33 0-56.5 23.5T600-320q0 33 23.5 56.5T680-240ZM400-560q33 0 56.5-23.5T480-640q0-33-23.5-56.5T400-720q-33 0-56.5 23.5T320-640q0 33 23.5 56.5T400-560Zm0-80Zm12 400Z" /></svg>
                                        <p>Seja seu próprio chefe</p>
                                    </li>
                                    <li className="mx-auto p-5 bg-neutral-900 rounded-lg">
                                        <svg className="w-14 h-14 mx-auto mb-5 fill-orange-500" viewBox="0 -960 960 960"><path d="M300-520q-58 0-99-41t-41-99q0-58 41-99t99-41q58 0 99 41t41 99q0 58-41 99t-99 41Zm0-80q25 0 42.5-17.5T360-660q0-25-17.5-42.5T300-720q-25 0-42.5 17.5T240-660q0 25 17.5 42.5T300-600Zm360 440q-58 0-99-41t-41-99q0-58 41-99t99-41q58 0 99 41t41 99q0 58-41 99t-99 41Zm0-80q25 0 42.5-17.5T720-300q0-25-17.5-42.5T660-360q-25 0-42.5 17.5T600-300q0 25 17.5 42.5T660-240Zm-444 80-56-56 584-584 56 56-584 584Z" /></svg>
                                        <p>Defina suas próprias taxas</p>
                                    </li>
                                    <li className="mx-auto p-5 bg-neutral-900 rounded-lg">
                                        <svg className="w-14 h-14 mx-auto mb-5 fill-orange-500" viewBox="0 -960 960 960"><path d="m480-120-58-52q-101-91-167-157T150-447.5Q111-500 95.5-544T80-634q0-94 63-157t157-63q52 0 99 22t81 62q34-40 81-62t99-22q94 0 157 63t63 157q0 46-15.5 90T810-447.5Q771-395 705-329T538-172l-58 52Zm0-108q96-86 158-147.5t98-107q36-45.5 50-81t14-70.5q0-60-40-100t-100-40q-47 0-87 26.5T518-680h-76q-15-41-55-67.5T300-774q-60 0-100 40t-40 100q0 35 14 70.5t50 81q36 45.5 98 107T480-228Zm0-273Z" /></svg>
                                        <p>Tenha um impacto positivo na vida de outras pessoas</p>
                                    </li>
                                </ul>
                            </div>

                            {/* Call to Action Section */}
                            <div className="text-center bg-orange-700 p-5 lg:p-20 shadow-md text-neutral-50 py-10 lg:py-32 flex flex-col gap-12 md:gap-24 rounded-b-lg">
                                <h2 className="text-xl md:text-2xl lg:text-4xl font-bold">
                                    Cadastre-se hoje mesmo e comece a ganhar dinheiro com suas habilidades!
                                </h2>
                                {!sendContact ?
                                    <button className='p-5 w-full bg-green-500 hover:animate-none hover:bg-green-400 active:bg-green-600 animate-pulse text-orange-50 text-lg md:text-xl lg:text-2xl rounded-lg' onClick={() => sendEmail()}>Entrar em contato por e-mail</button>
                                    :
                                    <p className='text-orange-100'>Contato enviado. Entraremos em contato assim que possível {";)"}</p>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default SejaConsultor;
