import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setUsuario } from '../features/usuario/usuarioAtual';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';

import Header from '../components/header';
import Menu from '../components/menu';
import Footer from '../components/footer';
import PopupAgendamento from '../components/popups/add_consultoria';

import './em-breve.css';

const EmBreve = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [popupAgendamentoVisible, setPopupAgendamentoVisible] = useState(false);
  const [showConfetti, setShowConfetti] = useState(true);
  const usuarioAtual = useSelector(state => state.usuarioAtual);
  const [showMenu, setShowMenu] = useState(window.innerWidth >= 1024);
  return (
    <div>
      <Header usuarioAtual={usuarioAtual} showMenu={showMenu} setShowMenu={setShowMenu} />
      <div className="em-breve-container">
        <Helmet>
          <title>Diagnóstico - Simbium</title>
          <meta property="og:title" content="Diagnóstico - Simbium" />
        </Helmet>
        <div className="em-breve-area">
          {popupAgendamentoVisible && (
            <PopupAgendamento
              erro={(e) => {
                toast.error(e);
              }}
              sucesso={() => toast.success('Consultoria agendada com sucesso!')}
              fecharPopup={() => setPopupAgendamentoVisible(false)}
            />
          )}
          <div className="em-breve-panel l-cont">
            <div className="em-breve-content">
              <div className="app">
                <div className="content">
                  <h1 className="title">EM BREVE</h1>
                  <p className="description">
                    Logo teremos novidades para você turbinar os seus negócios ainda mais.
                  </p>
                  <p className="subscription">Recurso apenas para assinantes</p>
                  {/* Adicionando estrelas brilhantes */}
                  <div className="stars-container">
                    {[...Array(30)].map((_, index) => (
                      <div key={index} className="star" />
                    ))}
                  </div>
                  {/* Adicionando confetes */}
                  <div className="confetti-container">
                    {[...Array(100)].map((_, index) => (
                      <div key={index} className="confetti" />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default EmBreve;
