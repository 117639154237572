import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation, Link } from 'react-router-dom';

const ConteudosHome = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const [emBrevePath, setEmBrevePath] = useState({})
    const plano_aviao = process.env.REACT_APP_PLANO_AVIAO
    const plano_jato = process.env.REACT_APP_PLANO_JATO
    const plano_foguete = process.env.REACT_APP_PLANO_FOGUETE
    const [desativarConteudos, setDesativarConteudos] = useState(props.usuarioAtual.ref.plano === plano_aviao || props.usuarioAtual.ref.plano === plano_jato)

    const menu = [
        {
            titulo: 'Painel BI',
            img_url: '/home-clean/clean-backgrounds/painel-bi.png',
            link: '/master/painel-bi',
            url_tracker: '/painel-bi',
            description: 'Dados integrados para acompanhar o seu crescimento!',
            em_breve: true,
        },
        {
            titulo: 'Conteúdo de Especialistas',
            img_url: '/home-clean/clean-backgrounds/conteudo-especialistas.png',
            link: '/master/conteudos-especialistas',
            url_tracker: '/conteudos-especialistas',
            description: 'Em breve você terá acesso a conteúdos dos principais nomes do mercado direto para você. Dicas, aulas especiais e muito conteúdo único!',
            em_breve: false,
        },
        {
            titulo: 'Histórias de sucesso',
            img_url: '/home-clean/clean-backgrounds/be-a-ba-automacao.png',
            link: '/master/sugestoes-leitura',
            url_tracker: '/sugestoes-leitura',
            description: 'Conheça a história de lojas que conseguiram alcançar resultados surpreendentes seja em marketplaces e/ou em seus próprios ecommerces.',
            em_breve: true,
        },
        {
            titulo: 'IA da Simbium',
            img_url: '/home-clean/clean-backgrounds/ia.png',
            link: '/ia',
            url_tracker: '/ia',
            description: 'Todo poder da IA 100% acionável para você criar automações e descobrir novas formas de levar seu negócio a um outro nível, ganhando ainda mais vantagem competitiva sob a concorrência.',
            em_breve: true,
        },
        {
            titulo: 'Clube de Benefícios',
            img_url: '/home-clean/clean-backgrounds/clube-beneficios.png',
            link: '/em-breve',
            url_tracker: '/clube-beneficios',
            description: 'Junte-se ao Clube de Benefícios e abra as portas para um mundo de vantagens. Faça parte deste seleto grupo e aproveite descontos especiais, ofertas únicas e experiências premium direto de parceiros exclusivos.',
            em_breve: true,
        },
    ];

    useEffect(() => {
        const aux_em_breve_path = location.pathname.split('/');
        const lastSegment = aux_em_breve_path[aux_em_breve_path.length - 1];
        const matchingMenuItem = menu.find(el => el.url_tracker === `/${lastSegment}`);
        setEmBrevePath(matchingMenuItem);
    }, [location]);


    return (
        <div className='w-11/12 m-auto space-y-5'>
            <h2 className='font-bold text-lg text-white mb-5'>Conteúdos Exclusivos
                {desativarConteudos && <span className='text-xs text-orange-500'> Acesso apenas para <strong>assinantes do PLANO FOGUETE</strong>!</span>}
            </h2>
            {emBrevePath && (
                <section className='rounded-lg bg-neutral-950 text-white grid grid-cols-1 sm:grid-cols-2 overflow-hidden shadow-lg shadow-neutral-950'>
                    <div className='relative w-full h-[30vw] sm:h-auto lg:min-h-[20vw] bg-cover bg-center' style={{ backgroundImage: `url(${emBrevePath.img_url})` }}>
                        <div className='absolute w-full h-full bg-neutral-950 bg-opacity-50 flex items-center align-middle justify-center'>
                            <p className='text-xl md:text-4xl text-white font-semibold'>{emBrevePath.titulo}</p>
                        </div>
                    </div>
                    <div className='w-full h-fit space-y-3 p-5'>
                        <span className='flex justify-between text-xs text-neutral-800 select-none'>
                            <p className=''>Descrição</p>
                            <p className='text-lg hover:text-xl hover:text-neutral-50 cursor-pointer' onClick={() => history.push('/')}>&times;</p>
                        </span>
                        <p className='text-md md:text-lg font-light whitespace-pre-wrap'>{emBrevePath.description}</p>
                        <button className='text-xs sm:text-md select-none rounded-lg text-neutral-500 hover:text-white active:text-neutral-500' onClick={() => history.push('/')}>Fechar info</button >
                    </div>
                </section>
            )}

            <section className="flex flex-col bg-neutral-950 rounded-lg pb-5">

                <div className='w-11/12 mx-auto select-none'>
                    <div className='grid grid-cols-2 gap-2 sm:grid-cols-3 sm:gap-3 md:grid-cols-4 md:gap-4 2xl:grid-cols-5 2xl:gap-5 mt-7'>
                        {menu.map((pagina, index_pagina) => (
                            <div className={`p-2 h-[60vw] sm:h-[45vw] md:h-[30vw] 2xl:h-[25vw] relative bg-gray-800 rounded-lg overflow-hidden transform transition-transform duration-100 ease-in-out ${pagina.em_breve || desativarConteudos ? 'grayscale cursor-pointer' : 'cursor-pointer'} hover:scale-105 active:hover:scale-95 hover:border-orange-500 border-2 border-gray-800 bg-cover bg-no-repeat bg-center`} style={{ backgroundImage: `url('${pagina.img_url}')` }} key={index_pagina} title={pagina.titulo} onClick={() => {
                                if (!desativarConteudos) {
                                    if (pagina.link === '') {
                                        setAgendamentoPopupVisible(true);
                                    } else if (pagina.link.includes('http')) {
                                        window.open(pagina.link, '_blank');
                                    } else if (!pagina.em_breve) {
                                        history.push(pagina.link);
                                    } else {
                                        if (pagina.url_tracker) {
                                            history.push(`/em-breve${pagina.url_tracker}`);
                                        }
                                        console.log('Em breve');
                                    }
                                } else {
                                    console.log("Indisponível no plano atual")
                                }
                            }}>
                                <div className={`absolute inset-0 bg-black opacity-70 z-0`}></div>
                                <div className='h-full m-auto p-2 relative text-gray-200 text-center font-extrabold flex items-center justify-center text-md md:text-sm lg:text-3xl'>{pagina.titulo}</div>
                                <span className={`w-full ${pagina.em_breve ? 'p-2' : ''} text-center bg-orange-500 absolute bottom-0 inset-x-0 text-white text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl`}>{pagina.em_breve ? 'Em breve' : ''}</span>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </div>
    )
}
export default ConteudosHome