import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Calendar from 'react-calendar';
import { useSelector, useDispatch } from 'react-redux';
import usuarioAtual, { setUsuario } from '../../../features/usuario/usuarioAtual';
import ModalAgendamentos from './modal-agendamentos';
// import ModalCompra from '../../../components/modal-compra/main';
const ConsultoriaHome = (props) => {
    const dispatch = useDispatch();
    const [stepAgendamento, setStepAgendamento] = useState(1);
    const [tituloAgendamento, setTituloAgendamento] = useState('');
    const [descricaoAgendamento, setDescricaoAgendamento] = useState('');
    const [professores, setProfessores] = useState([]);
    const [selectedProfessor, setSelectedProfessor] = useState('');
    const [horariosDisponiveis, setHorariosDisponiveis] = useState([]);
    const [horarioSelecionado, setHorarioSelecionado] = useState('');
    const [agendamentoLoading, setAgendamentoLoading] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [pacotesVisible, setPacotesVisible] = useState(false);
    const [consultoriaLista, setConsultoriaLista] = useState([])
    const [mostrarConsultorias, setMostrarConsultorias] = useState(false);

    const [consultores, setConsultores] = useState([]);

    const { consultorias } = props.usuarioAtual;
    const cota = consultorias?.cota || 0;
    const expiracao = new Date(consultorias?.expiracao);

    const isConsultoriaValida = cota > -1 && expiracao > new Date();
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        let isMounted = true;

        const fetchProfessores = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/usuarios/professores?consultores=true`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                });
                if (isMounted) {
                    setProfessores(response.data);
                }
            } catch (error) {
                console.error("Erro ao buscar consultores:", error);
            }
        };
        fetchProfessores();

        if (isMounted) {
            setConsultores(professores.filter(professor => professor.agendamentos.length && professor.agendamentos.filter(agendamento => agendamento.aluno.id === usuarioAtual._id)))
        }

        return () => {
            isMounted = false;
        };
    }, []);



    const handleProfessorChange = (professorId) => {
        if (selectedProfessor === professorId) {
            setSelectedProfessor('');
        } else {
            setSelectedProfessor(professorId);
            setStepAgendamento(2);
        }

        if (professorId === '') {
            setHorariosDisponiveis([]);
        } else {
            getHorariosDisponiveis(selectedDate, professorId);
        }
    };

    const handleDateChange = (date) => {
        setLoading(true)
        console.log("LOADING: ", loading)
        setSelectedDate(date);
        setHorariosDisponiveis([])
        setHorarioSelecionado('');
        if (selectedProfessor !== '') {
            getHorariosDisponiveis(date, selectedProfessor);
        }
        setLoading(false)
    };

    const handleHorarioChange = (newHorario) => {
        if (newHorario !== '') {
            setHorarioSelecionado(newHorario);
            setStepAgendamento(3);
        }
    };

    const formatSelectedDate = (date) => {
        const daysOfWeek = [
            'Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira',
            'Quinta-feira', 'Sexta-feira', 'Sábado'
        ];
        const months = [
            'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
            'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
        ];

        const day = daysOfWeek[date.getDay()];
        const dayOfMonth = date.getDate();
        const month = months[date.getMonth()];

        return `${day}, ${dayOfMonth} de ${month}`;
    };

    const formattedDate = formatSelectedDate(selectedDate);

    const professorTemHorarioDisponivel = (date) => {
        if (!selectedProfessor) return false;
        const professor = professores.find(p => p._id === selectedProfessor);
        if (!professor) return false;
        const dayOfWeek = date.getDay();
        const weekdays = ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado'];
        const disponibilidade = professor.horariosDisponiveis.find(h => h.diaSemana === weekdays[dayOfWeek]);
        return !!disponibilidade && disponibilidade.horarios.length > 0;
    };

    const getHorariosDisponiveis = async (date, professorId) => {
        const dia = date.getDate();
        const mes = date.getMonth() + 1;
        const ano = date.getFullYear();

        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/usuarios/horarios-disponiveis?dia=${dia}&mes=${mes}&ano=${ano}&professorId=${professorId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            });
            setHorariosDisponiveis(response.data.filter(horario => horario.length));
        } catch (err) {
            console.error("Erro ao buscar horários disponíveis:", err);
        }
    };

    const agendarConsultoria = async () => {
        const data = new Date(selectedDate);
        const dia = data.getDate();
        const mes = data.getMonth();
        const ano = data.getFullYear();
        const hora = horarioSelecionado.split(":")[0];
        const minuto = horarioSelecionado.split(":")[1];
        const professorId = selectedProfessor;
        const titulo = tituloAgendamento;
        const descricao = descricaoAgendamento;

        try {
            setAgendamentoLoading(true);
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/agendamentos`, {
                data: new Date(ano, mes, dia, hora, minuto, 0, 0).toISOString(),
                professor: professorId,
                titulo,
                descricao
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            });

            setAgendamentoLoading(false);
            clearVars();
            props.sucesso();
            dispatch(setUsuario({
                ...props.usuarioAtual,
                consultorias: {
                    ...props.usuarioAtual.consultorias,
                    cota: props.usuarioAtual.consultorias.cota - 1
                }
            }));

            if (!props.usuarioAtual.onboarding || !props.usuarioAtual.onboarding.etapas || !props.usuarioAtual.onboarding.etapas.includes("agendar-consultoria")) {
                onboardingConsultoria();
            }
        } catch (err) {
            setAgendamentoLoading(false);
            clearVars();
            if (err?.response?.data?.error) {
                props.erro(err.response.data.error);
            } else {
                props.erro("Algo deu errado");
            }
            console.error("Erro ao agendar consultoria:", err);
        }
    };

    const clearVars = () => {
        setStepAgendamento(1);
        setTituloAgendamento('');
        setDescricaoAgendamento('');
        setSelectedProfessor('');
        setHorarioSelecionado('');
        setAgendamentoLoading(false);
        setSelectedDate(new Date());
    };

    const onboardingConsultoria = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/usuarios/onboarding`, {
                etapa: 'agendar-consultoria'
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            });

            if (response.data && response.data.usuario) {
                dispatch(setUsuario(response.data.usuario));
            }
        } catch (err) {
            console.error("Erro ao atualizar onboarding:", err);
        }
    };

    // const [showModalPagamento, setShowModalPagamento] = useState(false)

    return (
        <div className='w-full md:w-1/2 hidden sm:block'>
            {mostrarConsultorias && <ModalAgendamentos usuarioAtualId={props.usuarioAtual._id} consultores={consultores} setConsultores={setConsultores} setMostrarConsultorias={setMostrarConsultorias} />}
            {/*{showModalPagamento && <ModalCompra usuarioAtual={usuarioAtual} plano={planoSelecionado} closeModal={() => setShowModalPagamento(false)} />}*/}
            <section className='w-full'>
                {isConsultoriaValida && !pacotesVisible ? (
                    <div id="agendamento" className="w-full h-full flex flex-col gap-3 bg-neutral-950 p-5 rounded-lg">

                        {stepAgendamento === 1 && (
                            <div className='w-full bg-neutral-950 rounded-lg flex flex-col space-y-3'>
                                <h3 className="text-neutral-300 text-center"><span className='font-bold'>Agende sua consultoria:</span> Acelere seu crescimento e conte com apoio especializado!</h3>
                                <div className={`${props.usuarioAtual.consultorias.cota <= 1 ? 'text-red-500' : 'text-neutral-500'} text-center`}>
                                    Você possui {props.usuarioAtual.consultorias.cota} cota(s) para consultorias.
                                    <button className='p-0 m-0 animate-pulse text-neutral-400 hover:text-orange-500 active:text-orange-600'>&nbsp;Comprar mais cotas!</button>
                                </div>
                                <div className='overflow-auto'>
                                    <div className='grid grid-cols-2 gap-3 mb-5'>
                                        {professores.length > 0 ? (
                                            professores.map((professor, index) => (
                                                <div key={index} className={`w-full h-52 rounded-lg overflow-hidden relative cursor-pointer border-2 ${props.usuarioAtual.consultorias.cota > 0 ? 'hover:border-orange-500' : 'opacity-50 cursor-not-allowed'} border-neutral-950 `} title={props.usuarioAtual.consultorias.cota > 0 ? '' : 'Você está sem cotas!'} onClick={() => { props.usuarioAtual.consultorias.cota > 0 ? handleProfessorChange(professor._id) : console.log("Você está sem cotas!") }}>
                                                    <img src={professor.foto_perfil ? professor.foto_perfil : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png'} className='object-cover object-center' />
                                                    <div className='w-full bg-neutral-950 opacity-95 text-neutral-300 px-5 py-1 absolute bottom-0'>
                                                        <p className='py-3'>{professor.nome}</p>
                                                        {/*<p className='text-neutral-500'>{professor.area}</p>*/}
                                                    </div>
                                                </div>
                                            ))
                                        ) :
                                            <p className='text-gray-500'>Não há consultores cadastrados no momento!</p>
                                        }
                                    </div>
                                </div>
                            </div>
                        )}
                        {stepAgendamento === 2 && (
                            <div className=" flex flex-col gap-5">
                                <h3 className="text-neutral-300">Escolha a melhor Data e Horário</h3>
                                <div className="home-container52">
                                    <Calendar
                                        value={selectedDate}
                                        onChange={handleDateChange}
                                        minDate={new Date()}
                                        maxDate={new Date(props.usuarioAtual.consultorias.expiracao)}
                                        tileClassName={({ date, view }) => {
                                            if (view === 'month' && professorTemHorarioDisponivel(date)) {
                                                return 'day-with-availability';
                                            }
                                        }}
                                    />
                                    <div className="home-data">
                                        <span className="home-text88">{formattedDate}</span>
                                        <div className="home-horrios">
                                            {horariosDisponiveis.length ?
                                                <div className='w-full'>
                                                    <h3 className='text-neutral-300'>Escolha um horário dentre os disponíveis</h3>
                                                    <div className='flex flex-row w-full gap-2 my-3'>
                                                        {horariosDisponiveis.map((h, index) => (
                                                            <span
                                                                key={index}
                                                                className={`border border-neutral-300 text-neutral-300 rounded-lg w-fit py-1 px-2 text-center cursor-pointer hover:bg-orange-600 active:bg-orange-700 ${horarioSelecionado === h ? 'bg-orange-800' : ''}`}
                                                                onClick={() => handleHorarioChange(h)}
                                                            > {h}
                                                            </span>
                                                        ))}
                                                    </div>
                                                </div>
                                                : (
                                                    selectedProfessor ? <div>
                                                        {loading ?
                                                            <span className='animate-bounce'>Carregando...</span>
                                                            :
                                                            <span className="text-less">Nenhum horário disponível para a data selecionada</span>
                                                        }
                                                    </div> : (
                                                        <span className="text-less">Selecione um professor para ver os horários</span>
                                                    )
                                                )}
                                            {loading &&
                                                <span className='animate-bounce text-orange-500'>Carregando...</span>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {stepAgendamento === 3 && (
                            <div className='w-full'>
                                <div className="agendamento-detalhes  rounded-lg h-full">
                                    <div className="w-full flex flex-row items-center align-middle justify-center text-center gap-5 rounded-lg bg-neutral-900 ">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-calendar-time" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                            <path d="M11.795 21h-6.795a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v4"></path>
                                            <path d="M18 18m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0"></path>
                                            <path d="M15 3v4"></path>
                                            <path d="M7 3v4"></path>
                                            <path d="M3 11h16"></path>
                                            <path d="M18 16.496v1.504l1 1"></path>
                                        </svg>
                                        <div className="data-selecionada">
                                            <span>{selectedDate.toLocaleDateString('pt-br')}</span>
                                            <span>às {horarioSelecionado}h</span>
                                        </div>
                                        <svg className="cursor-pointer" onClick={() => setStepAgendamento(2)} viewBox="0 0 512 512">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M364.13 125.25L87 403l-23 45 44.99-23 277.76-277.13-22.62-22.62zM420.69 68.69l-22.62 22.62 22.62 22.63 22.62-22.63a16 16 0 000-22.62h0a16 16 0 00-22.62 0z" />
                                        </svg>
                                    </div>

                                    <div className="info-adicional">
                                        <h3 className='text-neutral-500'>Descreva o que você está precisando</h3>
                                        <input type="text" className='text-white placeholder:text-neutral-500 focus:outline-none focus:border-none focus:shadow-none focus:ring-1 focus:ring-orange-500' value={tituloAgendamento} placeholder='Resuma sua dúvida, pedido ou necessidade em 1 frase' onChange={(e) => setTituloAgendamento(e.target.value)} />
                                        <textarea value={descricaoAgendamento} className='text-white placeholder:text-neutral-500 focus:outline-none focus:border-none focus:shadow-none focus:ring-1 focus:ring-orange-500' placeholder='Descreva detalhadamente o que você precisa' onChange={(e) => setDescricaoAgendamento(e.target.value)}></textarea>
                                    </div>

                                    {selectedDate && selectedProfessor && horarioSelecionado && (
                                        <button className={`w-full bg-orange-500 text-white hover:bg-orange-700 active:bg-orange-900 p-2 rounded-lg ${agendamentoLoading ? 'loading' : ''}`} onClick={agendarConsultoria}>{agendamentoLoading ? 'Aguarde...' : 'Concluir Agendamento'}</button>
                                    )}
                                </div>
                            </div>
                        )}
                        {stepAgendamento > 1 &&
                            <div className='w-full text-center text-white hover:text-orange-500 active:text-orange:800 cursor-pointer' onClick={() => { setStepAgendamento(stepAgendamento - 1); setSelectedProfessor(''); }}>Voltar à etapa anterior</div>
                        }
                        {consultores.length ?
                            <button className='w-full p-3 rounded-lg bg-none text-orange-500 hover:text-white active:text-neutral-400' onClick={() => setMostrarConsultorias(!mostrarConsultorias)}>Mostrar meus agendamentos</button>
                            :
                            <p className='text-xs text-white text-center'>Você ainda não possui nenhuma consultoria</p>
                        }
                    </div>
                ) : (<div style={{ display: 'none' }}></div>)}
            </section>
        </div>
    )
}

export default ConsultoriaHome