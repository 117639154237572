import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { GridLoader } from 'react-spinners';

/*import "/node_modules/materialize-css/dist/css/materialize.min.css";
import "/node_modules/materialize-css/dist/js/materialize.min.js";*/

import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios'
import { Provider, useSelector, useDispatch } from 'react-redux';
import store from './store'
import usuarioAtual, { setUsuario } from './features/usuario/usuarioAtual';

import './style.css'
import MeuPerfil from './views/meu-perfil'
import TermosDeUso from './views/termos-de-uso'
import MeusColaboradores from './views/meus-colaboradores'
import DiagnosticoForm from './views/diagnostico-form'
import Diagnostico from './views/diagnostico'
import Curso from './views/curso'
import Admin from './views/admin'
import Consultor from './views/consultor'
import PolticasDePrivacidade from './views/polticas-de-privacidade'
import Aula from './views/aula'
import Login from './views/login'
import MeusCursos from './views/meus-cursos'
import Home from './views/home'
import HomeClean from './views/home-clean'
import HomeOfficial from './views/home-official/main';
import Indicacao from './views/indicacao'
import TrialExpirado from './views/trial-expirado'
import Onboarding from './components/onboarding';
import PopupBoasVindas from './components/popups/boas_vindas';
import Shorts from './views/shorts'
import IA from './views/ia'
import EmBreve from './views/em-breve'
import Master from './views/master';
import MasterAula from './views/master-aula';
import AulaNovoPadrao from './views/aula-novo-padrao';
import LandingPageAutomacao from './views/landing-page-automacao';

import Consultorias from './views/consultorias/main'
import FranquiaSimbium from './views/franquia-simbium/main'
import ImportacaoCompartilhada from './views/importacao-compartilhada/main'
import FornecedoresEParceiros from './views/fornecedores-e-parceiros/main'
import FornecedoresCatalogados from './views/fornecedores-catalogados/main'
import SejaConsultor from './views/seja-consultor/main';
import EmprestimoSimbium from './views/emprestimo-simbium/main';

import mixpanel from 'mixpanel-browser';
mixpanel.init(process.env.REACT_APP_MIX_PANEL_TOKEN, { debug: true, track_pageview: true, persistence: 'localStorage' });

const App = () => {
  return (
    <Provider store={store}>
      <Content />
    </Provider>
  )
}

const Content = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.usuarioAtual);
  const [isLoading, setIsLoading] = useState(true);
  const [isUserLoaded, setIsUserLoaded] = useState(false);
  const [popupBoasVindasVisible, setPopupBoasVindasVisible] = useState(true);
  const [onboardingOpened, setOnboardingOpened] = useState(false);
  const [videoFrame, setVideoFrame] = useState(null)

  const getVideoBoasVindas = async () => {
    try {

      const response = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/config`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      })

      if (response.data && response.data.boas_vindas && response.data.boas_vindas.url) {
        setVideoFrame(response.data.boas_vindas.url)
      }

    } catch (err) {
    }

  };

  useEffect(() => {
    if (user && user._id) {
      mixpanel.identify(user._id);
      mixpanel.people.set({
        $name: user.nome,
        $email: user.email,
        $plan: user.ref.plano
      });
    }
  }, [user]);

  useEffect(async () => {

    const token = localStorage.getItem("token");

    try {
      const response = await axios.get(process.env.REACT_APP_BACKEND_BASE_URL + '/usuarios/usuario_atual', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })

      dispatch(setUsuario(response.data))
      setIsUserLoaded(true);
      if (response.data && !response.data.onboarding?.etapas.includes("video-boas-vindas")) {
        getVideoBoasVindas()
      }

    } catch (err) {
    } finally {
      setIsLoading(false);
    }

    const onRouteChange = () => {
      window.scrollTo(0, 0);
    };

    window.addEventListener('hashchange', onRouteChange);

    return () => {
      window.removeEventListener('hashchange', onRouteChange);
    };

  }, []);


  const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => user ? <Component {...props} /> : <Redirect to="/login" />} />
  );

  const AdminRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => user && user.tipo === 'admin' ? <Component {...props} /> : <Redirect to="/" />} />
  );

  const ConsultorRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => user && user.tipo === 'consultor' ? <Component {...props} /> : <Redirect to="/" />} />
  );


  return (
    <Router>
      <div>
        {user && user.onboarding && !user.onboarding.concluido && (
          <Onboarding opened={onboardingOpened} />
        )}
        {user && !user?.onboarding?.etapas.includes("video-boas-vindas") && popupBoasVindasVisible && (new Date(user.acesso) >= new Date()) && (
          <PopupBoasVindas videoFrame={videoFrame} usuarioAtual={user} abrirOnboarding={() => setOnboardingOpened(true)} fecharPopup={() => setPopupBoasVindasVisible(false)} />
        )}
        <ToastContainer className={"toast"} theme="dark" />
        {isLoading ? (
          <div className="loading-overlay">
            <GridLoader color="#ff5b1c" size={18} />
          </div>
        ) : (
          <Switch>
            <PrivateRoute component={MeuPerfil} exact path="/meu-perfil" />
            <Route component={TermosDeUso} exact path="/termos-de-uso" />
            <PrivateRoute component={MeusColaboradores} exact path="/meus-colaboradores" />
            <PrivateRoute component={Diagnostico} exact path="/diagnostico" />
            <PrivateRoute component={DiagnosticoForm} exact path="/diagnostico-form" />
            <PrivateRoute component={Curso} path="/curso/:slug" />
            <AdminRoute component={Admin} path="/admin" />
            <ConsultorRoute component={Consultor} path="/consultor" />
            <Route component={PolticasDePrivacidade} exact path="/politicas-de-privacidade" />
            <PrivateRoute component={Aula} path="/aula/:slug" />
            <PrivateRoute component={MeusCursos} exact path="/meus-cursos" />
            <PrivateRoute component={Master} exact path="/master/:pagina_codigo" />
            <PrivateRoute component={MasterAula} exact path="/master/aula/:pagina_codigo/:curso_codigo/:aula_codigo?" />
            <PrivateRoute component={AulaNovoPadrao} exact path="/aula-novo-padrao" />
            <PrivateRoute component={Shorts} exact path="/shorts" />
            <PrivateRoute component={IA} exact path="/ia" />

            <PrivateRoute component={Consultorias} exact path="/consultorias" />
            <PrivateRoute component={FranquiaSimbium} exact path="/franquia-simbium" />
            <PrivateRoute component={ImportacaoCompartilhada} exact path="/importacao-compartilhada" />
            <PrivateRoute component={FornecedoresEParceiros} exact path="/fornecedores-e-parceiros" />
            <PrivateRoute component={FornecedoresCatalogados} exact path="/fornecedores-catalogados" />
            <PrivateRoute component={SejaConsultor} exact path="/seja-consultor" />
            <PrivateRoute component={EmprestimoSimbium} exact path="/emprestimo-simbium" />

            <PrivateRoute component={EmBreve} exact path="/em-breve" />
            <PrivateRoute component={LandingPageAutomacao} exact path="/automacao/:pagina_codigo/:curso_codigo" />
            {/*<PrivateRoute component={Home} exact path="/" />*/}
            <PrivateRoute component={HomeClean} exact path="/home-clean" />
            <PrivateRoute component={HomeOfficial} exact path="/" />
            <PrivateRoute component={HomeOfficial} exact path="/em-breve/:pagina_codigo" />
            <Route path="/indicacao/:id" component={Indicacao} />
            <Route path="/assine-simbium/:plan_id?" component={TrialExpirado} />
            <Route path="/login">
              {user ? <Redirect to="/" /> : <Login />}
            </Route>
            <Redirect to="/" />
          </Switch>
        )}
      </div>
    </Router>
  )
}

ReactDOM.render(<App />, document.getElementById('app'))