import React, { useState } from 'react';
import ModalCriarEditarPlano from './modalCriaEditarPlano';
import ModalDeletarPlano from './modalDeletarPlano';
import { toast } from 'react-toastify';
import axios from 'axios';

const TabelaPlanos = ({ tipo, planos, setPlanos, loading, plano, setPlano, planoErrors, setPlanoErrors, metadata, setMetadata, disabledAll, enableSend, handleSendData }) => {
    const [showEditModal, setShowEditModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [selectedPlan, setSelectedPlan] = useState('')

    const plano_init = {
        id: '', // plan_om0WxOS6BCyaJZ3q
        name: '', // Plano Anual Básico
        description: '', // Apenas acesso as aulas gratuito, o restante é pago
        interval: '', // year
        interval_count: 1,
        billing_type: 'prepaid',
        payment_methods: ["credit_card", "boleto", "debit_card"],
        installments: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        status: 'active',
        currency: 'BRL',
        items: [
            {
                name: "Item", // Plano Anual Básico
                quantity: 1,
                pricing_scheme: {
                    price: 0, // 997
                    scheme_type: "unit"
                }
            }
        ],
        metadata: null, // {plano:'foguete'}
    }

    const toMoney = (number) => {
        return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(number)
    }

    const openModal = (mode, plan_id) => {
        if (mode === 'edit') {
            const plano_escolhido = planos.find(el => el.id === plan_id)

            if (plano_escolhido) {
                // Create a copy of the selected plan
                const updatedPlano = {
                    ...plano_escolhido,
                    items: plano_escolhido.items.map((item, index) => {
                        if (index === 0) {
                            return {
                                ...item,
                                pricing_scheme: {
                                    ...item.pricing_scheme,
                                    price: item.pricing_scheme.price / 100
                                }
                            };
                        }
                        return item;
                    })
                };

                setPlano(updatedPlano);
            }

            setMetadata(plano_escolhido.metadata.plano)
            setShowEditModal(true)
            return
        }
        setSelectedPlan(plan_id)
        setShowDeleteModal(true)
    }

    const deletePlan = async (plan_id) => {
        if (confirm("Tem certeja que deseja excluir esse plano?")) {
            try {
                const response = await axios.delete(`${process.env.REACT_APP_BACKEND_BASE_URL}/pagarme/delete/plan/${plan_id}`, {
                    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
                });
                console.log("RESPONSE: ", response)

                if (response.data && response.data.success) {
                    console.log("Plano deletado com sucesso:", response.data.message);
                    toast.success("Plano deletado com sucesso!");
                    setPlanos(prevPlanos => ({
                        ...prevPlanos,
                        derivados: prevPlanos.derivados.filter(plano => plano.id !== plan_id)
                    }));
                } else {
                    console.error("Erro ao deletar o plano:", response.data.message || "Resposta sem dados válidos");
                    toast.error("Falha na deleção do plano: " + (response.data.message || "Resposta sem dados válidos"));
                }
            } catch (error) {
                console.error("Erro ao deletar plano:", error.message);
                toast.error("ERRO: Falha na deleção do plano: " + error.message);
            }
        }
    }

    const closeModal = () => {
        setPlano(plano_init)
        setMetadata('')
        setShowEditModal(false)
        setShowDeleteModal(false)
    }

    return (
        <div className="rounded-lg bg-neutral-900">
            {showEditModal && <ModalCriarEditarPlano
                mode="edit"
                closeModal={closeModal}
                plano={plano}
                setPlano={setPlano}
                planoErrors={planoErrors}
                setPlanoErrors={setPlanoErrors}
                metadata={metadata}
                setMetadata={setMetadata}
                disabledAll={disabledAll}
                enableSend={enableSend}
                handleSendData={handleSendData}
            />}
            {showDeleteModal && <ModalDeletarPlano plan_id={selectedPlan} closeModal={closeModal} />}
            <div className="p-3 bg-orange-600 rounded-t-lg">Planos <span className="capitalize">{tipo}</span></div>
            <table className="w-full">
                <thead>
                    <tr>
                        <th className='bg-neutral-900'>ID</th>
                        <th className='bg-neutral-900'>Nome</th>
                        {tipo === 'derivados' && <th className='bg-neutral-900'>Plano Base</th>}
                        <th className='bg-neutral-900'>Valor</th>
                        {tipo === 'derivados' && <th className='bg-neutral-900 text-center'>Controles</th>}
                    </tr>
                </thead>
                <tbody className="bg-neutral-800">
                    {planos?.length > 0 ? planos.map((plano_item, index) => (
                        <tr key={index} className="even:bg-neutral-700">
                            <td>{plano_item.id}</td>
                            <td>{plano_item.name}</td>
                            {tipo === 'derivados' && <td className='uppercase'>{plano_item.metadata?.plano}</td>}
                            <td>{plano_item.items?.length ? toMoney(plano_item.items[0].pricing_scheme.price / 100) : 0}</td>

                            {tipo === 'derivados' && <td className='text-center'><button onClick={() => deletePlan(plano_item.id)}>Excluir</button></td>}
                        </tr>
                    )) :
                        <tr>
                            <td colSpan="7">{!loading ? "Não há planos cadastrados." : <span className='w-full h-5 animate-pulse'>Carregando planos...</span>}</td>
                        </tr>
                    }
                </tbody>
            </table>
        </div>
    )
}

export default TabelaPlanos;
