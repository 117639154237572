
import { toast } from 'react-toastify';
const IndiqueGanheHome = (props) => {
    const copyToClipboard = (text) => {
        const textArea = document.createElement('textarea');
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
        toast.success("Link de afiliação copiado!")
    };
    return (
        <div className="w-11/12 mx-auto space-y-5">
            <h3 className="font-bold text-lg text-white my-5">Indique e Ganhe</h3>
            <section className="flex flex-col items-center space-y-10 bg-neutral-800 rounded-lg border border-neutral-600" id="indicacao">

                <div className="flex flex-col gap-30 m-5 items-center justify-center">
                    <div className="flex flex-col gap-15 items-center justify-center">
                        <div className="flex flex-row items-center justify-center">
                            <img alt="image244711" src="/external/image244711-bwb-200h.png" className="w-24" />
                            <div className="flex flex-col items-center justify-center align-middle">
                                <span className="text-orange-500 text-4xl">{props.usuarioAtual.creditos}</span>
                                <span className="text-white">Seus créditos</span>
                            </div>
                        </div>
                        {false && (
                            <span className="text-white text-sm">
                                <span className="text-gray-600">Próximo prêmio:</span>
                                <span className="text-orange-500"> 100 créditos</span>
                            </span>
                        )}
                    </div>
                    <span className="text-white text-lg text-center mt-10">
                        Indique nossa plataforma para seus amigos com o link abaixo e garanta presentes exclusivos.
                    </span>
                    <div className="flex flex-col gap-15 items-center justify-center">
                        <div className="flex flex-row divide-x-2 divide-neutral-700 border-2 my-5 border-dashed border-neutral-700 p-2 rounded-lg">
                            <span className="text-neutral-400 font-bold text-md break-all p-5">
                                {`${window.location.origin}/indicacao/${props.usuarioAtual._id}`}
                            </span>
                            <button className="text-neutral-400 font-bold text-sm p-5" onClick={() => copyToClipboard(`${window.location.origin}/indicacao/${props.usuarioAtual._id}`)}>Copiar link</button>
                        </div>
                        <span className="text-white text-sm text-center">
                            <span className="text-neutral-300">Cada indicação =</span>
                            <span className="text-orange-500"> 1 crédito e mais <strong>R$ 500,00</strong>*</span>
                            <p style={{ marginTop: '8px', fontSize: '13px', color: "#9e9e9e" }}>*Premiação em dinheiro <strong>APENAS</strong> se a pessoa que você indicou <strong>se tornar um assinante</strong>*</p>
                        </span>
                    </div>
                </div>
                <div className="flex flex-row gap-12 md:gap-24 items-center justify-center">
                    <div className="flex gap-3 cursor-pointer transition duration-300 items-center p-3 rounded-full bg-stone-500 font-bold" onClick={() => { if (props.usuarioAtual.totalComissoes > 0) { setPopupComissoesVisible(true) } }} style={props.usuarioAtual.totalComissoes > 0 ? { background: '#0cda07' } : {}}>
                        <svg className="fill-white w-5 h-5" style={props.usuarioAtual.totalComissoes > 0 ? { fill: '#fff' } : {}} viewBox="0 0 512 512"><path d="M341.28 22.78L254 123.75l63.156-33.656 4.438-2.375 4.437 2.405 18.314 9.938-3.063-77.282zM129.814 46.563l24.375 130.407 88.718-47.282-113.094-83.125zM321.53 108.97L83.345 235.905 192.156 293.5l237.47-125.75-108.095-58.78zm42.75 44.686l25.595 13.03-197.313 103.94-66.875-35.688 25.344-13.407 41.532 22.19 171.72-90.064zm92.19 18.22l9.092 24.03 26.22-22.656-35.313-1.375zm-15.783 11.155L199.47 310.78l-5.782 83.376L465.75 249.344l-25.063-66.313zm-334.562 19.564L16.562 226.78l36.876 19.564 6.093-16.28 1.25-3.314 3.126-1.656 42.22-22.5zm-34.438 48.312l-28.812 77 131.844 70.156 6.155-89.375-109.188-57.78zm335.344 50.875l-174.25 92.75 54.907 103.44L325.5 358.093l142.47 26.625-60.94-82.94zm-300.717 81.064l-19.438 44.812L144 402.876l-37.688-20.032z" /></svg>
                        <span className="text-white text-sm">Comissões em Dinheiro <em style={props.usuarioAtual.totalComissoes > 0 ? { fontSize: '14px', color: 'rgb(8 127 10)' } : { fontSize: '14px', color: '#9e9e9e' }}>(R$ {props.usuarioAtual.totalComissoes ? props.usuarioAtual.totalComissoes.toLocaleString('pt-br') : '0'},00)</em></span>
                    </div>
                </div>

                <div className="w-2/3 sm:w-[200px] mx-auto relative top-3 flex justify-between">
                    <a href="https://www.instagram.com/" target="_blank" className="rounded-full bg-[#d23589] shadow-[0_4px_15px_#d23589] text-white font-bold px-2 py-1 flex flex-row space-x-2 items-center">
                        <span><img className="w-4 h-4" src="/home-official/instagram.png" /></span>
                        <span className="text-xs text-center">Instagram</span>
                    </a>
                    <a href={`https://api.whatsapp.com/send?text=O%20que%20voc%C3%AA%20acha%20de%20uma%20ferramenta%20virtual%20para%20potencializar%20o%20seu%20neg%C3%B3cio?%0A%0AEu%20adquiri%20o%20Simbium%20e%20j%C3%A1%20amei!%0ATente%20voc%C3%AA%20tamb%C3%A9m%20;)%0A%0AAcesse%20o%20sistema%20via%20${window.location.origin}/indicacao/${props.usuarioAtual._id}`} target="_blank" className="rounded-full bg-[#249e16] shadow-[0_4px_15px_#249e16] text-white font-bold px-2 py-1 flex flex-row space-x-2 items-center">
                        <span><img className="w-4 h-4" src="/home-official/whatsapp.png" /></span>
                        <span className="text-xs text-center">Whatsapp</span>
                    </a>
                </div >
            </section>

        </div >
    )
}

export default IndiqueGanheHome