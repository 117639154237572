import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setCurso } from '../features/curso/cursoAtual';
import { toast } from 'react-toastify'
import { Helmet } from 'react-helmet'
import axios from 'axios'

import Header from '../components/header'
import Menu from '../components/menu';
import Footer from '../components/footer'
import Aside from '../components/aside'
import ListaModulos from '../components/lista-modulos'
import ListaOutrosCursos from '../components/lista-outros-cursos'
import PopupAgendamento from '../components/popups/add_consultoria'

import './curso.css'

const Curso = (props) => {
  const dispatch = useDispatch();
  const { slug } = useParams();
  const [curso, setCursoAtual] = useState(null)
  const [loading, setLoading] = useState(true);
  const [ultimaAula, setUltimaAula] = useState(null)
  const [agendamentoPopupVisible, setAgendamentoPopupVisible] = useState(false);
  const usuarioAtual = useSelector(state => state.usuarioAtual);
  const [showMenu, setShowMenu] = useState(window.innerWidth >= 1024);
  useEffect(() => {
    let isMounted = true

    async function fetchCurso() {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/cursos?slug=${slug}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        });
        if (isMounted) {
          setCursoAtual(response.data.cursos[0])
          setLoading(false);
          fetchUltimaAula(response.data.cursos[0]._id)
        }
      } catch (error) {
      }
    }

    const fetchUltimaAula = async (id) => {
      try {

        const meusCursos = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/meus-cursos/ultima-aula?cursoID=${id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        })

        if (isMounted && meusCursos.data && meusCursos.data.ultima_aula && meusCursos.data.ultima_aula.aula) {
          setUltimaAula(meusCursos.data.ultima_aula.aula)
          if (meusCursos.data.curso) {
            dispatch(setCurso(meusCursos.data.curso))
          }
        }

      } catch (err) {
        if (isMounted) {
          setUltimaAula(null)
        }
      }
    };

    setCursoAtual(null);
    setLoading(true);
    setUltimaAula(null);
    setAgendamentoPopupVisible(false);

    fetchCurso();

    return () => {
      isMounted = false
    }

  }, [slug]);

  return (
    <div>
      <Header usuarioAtual={usuarioAtual} showMenu={showMenu} setShowMenu={setShowMenu} />
      <div className="curso-container">
        <Helmet>
          <title>Curso - Simbium</title>
          <meta
            property="og:title"
            content={`Curso - Simbium`}
          />
        </Helmet>
        <div className="curso-container1">
          {window.outerWidth > 991 && (
            <Aside agendarConsultoria={() => setAgendamentoPopupVisible(true)}></Aside>
          )}
          {!loading && curso ? (
            <div className="curso-contedo l-cont">
              <div className="curso-container2">
                <div className="curso-escurecer-capa"></div>
                <img
                  alt="image"
                  src={curso.fundo ? curso.fundo : curso.capa ? curso.capa : ''}
                  className="curso-capa"
                />
                <div className="curso-container3">
                  <div className="curso-curso">
                    <svg
                      viewBox="0 0 1316.5714285714284 1024"
                      className="curso-icon"
                    >
                      <path d="M1013.714 477.714l10.286 180.571c4.571 80.571-164 146.286-365.714 146.286s-370.286-65.714-365.714-146.286l10.286-180.571 328 103.429c9.143 2.857 18.286 4 27.429 4s18.286-1.143 27.429-4zM1316.571 292.571c0 8-5.143 14.857-12.571 17.714l-640 201.143c-2.286 0.571-4 0.571-5.714 0.571s-3.429 0-5.714-0.571l-372.571-117.714c-32.571 25.714-55.429 88.571-60 165.714 21.714 12.571 36 35.429 36 62.286 0 25.714-13.143 48-33.143 61.143l33.143 247.429c0.571 5.143-1.143 10.286-4.571 14.286s-8.571 6.286-13.714 6.286h-109.714c-5.143 0-10.286-2.286-13.714-6.286s-5.143-9.143-4.571-14.286l33.143-247.429c-20-13.143-33.143-35.429-33.143-61.143 0-27.429 15.429-50.857 37.143-63.429 3.429-66.857 20.571-138.857 56-188.571l-190.286-59.429c-7.429-2.857-12.571-9.714-12.571-17.714s5.143-14.857 12.571-17.714l640-201.143c2.286-0.571 4-0.571 5.714-0.571s3.429 0 5.714 0.571l640 201.143c7.429 2.857 12.571 9.714 12.571 17.714z"></path>
                    </svg>
                    <span className="curso-text">{curso.nome}</span>
                  </div>
                  {ultimaAula && (

                    <Link to={`/aula/${ultimaAula.slug}`} className="curso-ultima-aula">
                      <div className="curso-container4">
                        <img
                          alt="image"
                          src={ultimaAula.capa}
                          className="curso-image"
                        />
                        <div className="curso-container5">
                          <span className="curso-text1">
                            {ultimaAula.nome}
                          </span>
                          <span className="curso-text2">{ultimaAula.modulo[0].nome}</span>
                        </div>
                      </div>
                      <div className="curso-b-continuar-aula">
                        <span className="curso-text3">Continuar aula</span>
                        <svg viewBox="0 0 1024 1024" className="curso-icon2">
                          <path d="M342 214l468 298-468 298v-596z"></path>
                        </svg>
                      </div>
                    </Link>
                  )}
                </div>
              </div>
              <ListaModulos cursoId={curso._id}></ListaModulos>
              <ListaOutrosCursos cursoId={curso._id}></ListaOutrosCursos>
              {agendamentoPopupVisible && (
                <PopupAgendamento erro={(e) => { toast.error(e) }} sucesso={() => toast.success("Consultoria agendada com sucesso!")} fecharPopup={() => setAgendamentoPopupVisible(false)} />
              )}
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </div>
      <Footer></Footer>
    </div>
  )
}

export default Curso