import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Calendar from 'react-calendar';
import { useSelector, useDispatch } from 'react-redux';
import { setUsuario } from '../../features/usuario/usuarioAtual';
import ModalCriarEditarPlano from './planos/modalCriaEditarPlano';
import TabelaPlanos from './planos/tabelaPlanos';
import { toast } from 'react-toastify';
import TabelaPacotes from './planos/tabelaPacotes';

const Planos = (props) => {
    const [planos, setPlanos] = useState({ base: [], derivados: [] }); // Inicializa com arrays vazios
    const [loading, setLoading] = useState(true);
    const [showCreateModal, setShowCreateModal] = useState(false)

    const plano_init = {
        id: '', // plan_om0WxOS6BCyaJZ3q
        name: '', // Plano Anual Básico
        description: '', // Apenas acesso as aulas gratuito, o restante é pago
        interval: '', // year
        interval_count: 1,
        billing_type: 'prepaid',
        payment_methods: ["credit_card", "boleto", "debit_card"],
        installments: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        status: 'active',
        currency: 'BRL',
        items: [
            {
                name: "Item", // Plano Anual Básico
                quantity: 1,
                pricing_scheme: {
                    price: 0, // 997
                    scheme_type: "unit"
                }
            }
        ],
        metadata: null, // {plano:'foguete'}
    }
    const [plano, setPlano] = useState(plano_init)

    const [planoErrors, setPlanoErrors] = useState({
        name: '',
        description: '',
        price: '',
        metadata: '',
    })

    const [disabledAll, setDisableAll] = useState(true)
    useEffect(() => {
        let isMounted = true;
        const fetchPlanos = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/pagarme/listar-planos`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                });
                if (response.data && isMounted) {
                    setPlanos(response.data);
                    if (response.data.base.length) setDisableAll(false)
                    else setDisableAll(true)
                }
                setLoading(false)
            } catch (error) {
                console.error("Erro ao buscar planos:", error);
                setLoading(false)
            }
        };

        fetchPlanos();

        return () => {
            isMounted = false;
        }
    }, []);

    const metadataToIdMap = {
        aviao: process.env.REACT_APP_PLANO_AVIAO,
        jato: process.env.REACT_APP_PLANO_JATO,
        foguete: process.env.REACT_APP_PLANO_FOGUETE,
    };

    const [metadata, setMetadata] = useState('');

    useEffect(() => {
        if (!metadata) return;

        const id_plano_base = metadataToIdMap[metadata];
        if (!id_plano_base) return;

        const plano_base_selecionado = planos.base.find(plano => plano.id === id_plano_base);
        if (!plano_base_selecionado) {
            return
        };

        const updatedItems = plano.items.map((item, index) => {
            if (index === 0 && !item.name) {
                return { ...item, name: plano.name };
            }
            return item;
        });

        const updatedPlano = {
            ...plano_base_selecionado,
            name: plano.name,
            description: plano.description,
            items: updatedItems,
            metadata: plano.metadata,
        };

        setPlano(updatedPlano);
    }, [metadata]);



    const [enableSend, setEnableSend] = useState(false);
    useEffect(() => {
        const isPlanoValid = plano.name?.length > 0 &&
            plano.description?.length > 0 &&
            plano.metadata &&
            plano.items?.[0]?.pricing_scheme?.price > 0;

        setEnableSend(isPlanoValid);
    }, [plano]);


    const openModal = () => {
        setShowCreateModal(true)
    }

    const closeModal = () => {
        setPlano(plano_init)
        setMetadata('')
        setShowCreateModal(false)
    }

    const handleSendData = async () => {
        setEnableSend(false)
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/pagarme/create/plan`, {
                plano: plano,
                apiKey: process.env.REACT_APP_PAGARME_TOKEN,
            }, {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
            });

            if (response.data && response.data.success) {
                setPlanos(prevPlanos => ({
                    ...prevPlanos,
                    derivados: [...prevPlanos.derivados, response.data.data]
                }));
                setEnableSend(true)
                closeModal()
                console.log("Plano enviado com sucesso:", response.data.message);
                toast.success("Plano criado com sucesso!");
            } else {
                setEnableSend(true)
                console.error("Erro no envio do plano:", response.data.message || "Resposta sem dados válidos");
                toast.error("Falha na criação do plano: " + (response.data.message || "Resposta sem dados válidos"));
            }
        } catch (error) {
            setEnableSend(true)
            console.error("Erro ao enviar plano:", error.message);
            toast.error("Falha na criação do plano: " + error.message);
        }
    };

    return (
        <div className="space-y-5 p-5 w-full flex relative flex-col pb-8 justify-start bg-[#212121] rounded-xl overflow-hidden text-neutral-50">
            {showCreateModal &&
                <ModalCriarEditarPlano
                    mode="create"
                    closeModal={closeModal}
                    plano={plano}
                    setPlano={setPlano}
                    planoErrors={planoErrors}
                    setPlanoErrors={setPlanoErrors}
                    metadata={metadata}
                    setMetadata={setMetadata}
                    disabledAll={disabledAll}
                    enableSend={enableSend}
                    handleSendData={handleSendData}
                />}
            <div className='w-full flex justify-between'>
                <h1 className='text-2xl'>Planos</h1>
                <button className='hover:bg-neutral-500 active:bg-orange-500 p-3 rounded-sm' disabled={disabledAll} onClick={() => openModal()}>Adicionar Plano</button>
            </div>
            <TabelaPlanos
                tipo="base"
                planos={planos.base}
                setPlanos={setPlanos}
                loading={loading}
                plano={plano}
                setPlano={setPlano}
                planoErrors={planoErrors}
                setPlanoErrors={setPlanoErrors}
                metadata={metadata}
                setMetadata={setMetadata}
                disabledAll={disabledAll}
                enableSend={enableSend}
                handleSendData={handleSendData}
            />
            <TabelaPlanos
                tipo="derivados"
                planos={planos.derivados}
                setPlanos={setPlanos}
                loading={loading}
                plano={plano}
                setPlano={setPlano}
                planoErrors={planoErrors}
                setPlanoErrors={setPlanoErrors}
                metadata={metadata}
                setMetadata={setMetadata}
                disabledAll={disabledAll}
                enableSend={enableSend}
                handleSendData={handleSendData}
            />

            {planos?.derivados &&
                <TabelaPacotes
                    planos={planos.derivados}
                />
            }
        </div>
    )
}

export default Planos;
