import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import usuarioAtual, { setUsuario } from '../features/usuario/usuarioAtual';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';

import Header from '../components/header';
import Menu from '../components/menu';
import Footer from '../components/footer'
import PopupAgendamento from '../components/popups/add_consultoria';
import { useHistory, useLocation, useParams } from 'react-router-dom';


const AulaNovoPadrao = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { pagina_codigo, curso_codigo, aula_codigo } = useParams();
    const usuarioAtual = useSelector(state => state.usuarioAtual);
    const header_geral = { Authorization: `Bearer ${localStorage.getItem("token")}` }
    // const [cloudUrlPublicoAula, setCloudUrlPublicoAula] = useState(`${process.env.REACT_APP_BUNNY_FILEHOST}paginas-automaticas/aulas/videos/`);
    const [dadosSecao, setDadosSecao] = useState([]);
    const [dadosCurso, setDadosCurso] = useState([]);
    const [listaAulas, setListaAulas] = useState([]);
    const [dadosAula, setDadosAula] = useState([]);
    const [tipoVideoAula, setTipoVideoAula] = useState('cloud');

    const [showMenu, setShowMenu] = useState(window.innerWidth >= 1024);

    useEffect(() => {
        let isMounted = true;
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/master/learn/aula/${curso_codigo}`, { headers: header_geral });

                if (isMounted && response.status === 200) {
                    setDadosSecao(response.data.secao);
                    setDadosCurso(response.data.curso);
                    setListaAulas(response.data.aulas);
                    setDadosAula(!aula_codigo ? response.data.aulas[0] : response.data.aulas.find((aula) => aula.codigo === aula_codigo));
                    setTipoVideoAula(!aula_codigo && response.data.aulas[0].video.includes('youtube.com') ? 'youtube' : 'cloud');

                    mixPanelAcesso();  // Chama quando o usuário acessa a página da aula

                    // Intervalo para medir o tempo decorrido
                    const interval = setInterval(() => {
                        setDiferencaTempo(prevTempo => prevTempo + 1);
                    }, 60000);

                    // Função para rastrear a saída da página
                    const handleBeforeUnload = () => {
                        mixPanelVideoConcluido();  // Chama quando o usuário sai da página
                    };

                    // Adiciona o event listener para detectar saída da página
                    window.addEventListener('beforeunload', handleBeforeUnload);

                } else {
                    console.log(response)
                }
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
        window.scrollTo(0, 0);

        return () => {
            isMounted = false
            clearInterval(interval);
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [pagina_codigo, curso_codigo, aula_codigo]);

    const goToNextLesson = () => {
        const currentLessonIndex = listaAulas.findIndex(aula => aula._id === dadosAula._id);
        const nextLesson = listaAulas[currentLessonIndex + 1];
        if (!nextLesson) nextLesson = listaAulas[0];

        if (nextLesson) {
            history.push(`/master/aula/${pagina_codigo}/${curso_codigo}/${nextLesson.codigo}`);
        } else {
            console.log("Erro! Próxima aula não encontrada. Chamar técnico.");
        }
    };

    function createWhatsAppLink(phone, message) {
        var encodedMessage = encodeURIComponent(message);
        var link = "https://api.whatsapp.com/send?phone=" + phone + "&text=" + encodedMessage;
        return link;
    }

    const enviarMsgWhatsApp = () => {
        var link = createWhatsAppLink("5511991737862", `Olá, sou o *${usuarioAtual.nome}* e gostaria de comprar a automação do curso de *${dadosCurso.titulo}* que vi no _Simbium_. Poderia me ajudar?`);
        window.open(link, '_blank');
    }

    const mixPanelAcesso = () => {
        if (!usuarioAtual || !usuarioAtual._id) {
            console.error('usuarioAtual or usuarioAtual._id is missing');
            return;
        }
        mixpanel.track(`Aula | Acessada`, {
            distinct_id: usuarioAtual._id,
            aula: dadosAula.titulo,
            aula_codigo: dadosAula.codigo,
            aula_id: dadosAula._id,
            curso: dadosCurso.titulo,
            curso_codigo: dadosCurso.codigo,
            curso_id: dadosCurso._id,
            secao: dadosSecao.titulo,
            secao_codigo: dadosSecao.codigo,
            secao_id: dadosSecao._id,
        });
    }

    const [diferencaTempo, setDiferencaTempo] = useState(0);

    const mixPanelVideoConcluido = () => {
        if (!usuarioAtual || !usuarioAtual._id) {
            console.error('usuarioAtual ou usuarioAtual._id ausente');
            return;
        }
        mixpanel.track(`Aula | Fechada`, {
            distinct_id: usuarioAtual._id,
            tempo_convencimento: diferencaTempo,
            aula: dadosAula.titulo,
            aula_codigo: dadosAula.codigo,
            aula_id: dadosAula._id,
            curso: dadosCurso.titulo,
            curso_codigo: dadosCurso.codigo,
            curso_id: dadosCurso._id,
            secao: dadosSecao.titulo,
            secao_codigo: dadosSecao.codigo,
            secao_id: dadosSecao._id,
        });
    }

    return (
        <div>
            <header className="w-full  py-3">
                <div className="w-11/12 m-auto flex items-center justify-between">
                    <a href='/'>
                        <img className="max-w-auto max-h-7 mx-0" src='/main_logo.png' />
                    </a>
                    <span className='flex text-center gap-2 items-center'>
                        {usuarioAtual?.tipo === 'admin' &&
                            <span>
                                <a href={`/admin/master-curso/${dadosCurso?._id}`} target='_blank' className="hidden sm:block text-yellow-500 text-sm float-right p-2 no-underline hover:text-orange-500 hover:border-orange-800">Editar Curso</a>
                                <a href={`/admin/master-curso/${dadosCurso?._id}`} target='_blank' className="block sm:hidden text-yellow-500 text-sm float-right p-2 no-underline hover:text-orange-500 hover:border-orange-800" title="Editar Curso">Editar</a>
                            </span>
                        }
                        <a href={`/master/${pagina_codigo}`} className="hidden sm:block text-gray-200 text-sm float-right border-2 border-gray-200 rounded-lg p-2 no-underline hover:text-orange-500 hover:border-orange-500">Voltar à Lista</a>
                        <a href={`/master/${pagina_codigo}`} className="block sm:hidden text-gray-200 text-sm float-right border-2 border-gray-200 rounded-lg p-2 no-underline hover:text-orange-500 hover:border-orange-500" title="Voltar à Lista">Voltar</a>
                    </span>
                </div>
            </header>

            <main className="m-auto p-0 w-11/12 flex flex-col gap-y-14">
                {dadosCurso && listaAulas && dadosAula &&
                    <div className="min-w-[340px] w-full rounded-lg shadow shadow-zinc-950 overflow-hidden bg-zinc-900 flex flex-col lg:flex-row">
                        <div className="w-full h-fit lg:w-3/4">
                            <h2 className='text-white font-sans text-2xl p-5'>{dadosCurso.titulo}</h2>
                            <div className='px-5 h-full'>
                                {dadosAula?.video && (
                                    tipoVideoAula === 'cloud' ?
                                        <video
                                            id="video-aula-banner"
                                            src={`${dadosAula.video}`}
                                            preload="auto"
                                            className="min-w-[250px] min-h-[35vw] w-full h-full rounded-md bg-black"
                                            controls
                                        >
                                            Seu navegador não suporta a tag de vídeo.
                                        </video>
                                        :
                                        <video
                                            src={dadosAula.video}
                                            className="w-full h-[400px] object-cover transition-opacity duration-300 ease-in-out"
                                            controls
                                        >
                                            Seu navegador não suporta a tag de vídeo.
                                        </video>
                                )}

                            </div>
                            <div className='no-underline text-gray-50 font-sans text-2xl flex justify-between items-center p-5'>
                                <span className='flex flex-col gap-5'>
                                    <h1 className="text-sm md:text-xl">{dadosAula.titulo}</h1>
                                    {dadosAula.descricao &&
                                        <p className='text-neutral-500 text-sm'>{dadosAula.descricao}</p>
                                    }
                                </span>
                                {dadosAula?._id !== listaAulas[listaAulas?.length - 1]?._id &&
                                    <span className='text-sm flex flex-col items-center min-w-fit ml-5'>
                                        <button onClick={goToNextLesson} className='p-2 bg-orange-700 hover:bg-orange-600 active:bg-orange-500 rounded-md'>Próxima Aula {'>'}</button>
                                    </span>
                                }
                            </div>

                        </div>
                        <div className="w-full lg:w-1/4 h-auto overflow-auto bg-zinc-950 flex flex-col">
                            <div className="w-full border-b border-gray-800 flex justify-between items-center p-5">
                                <h2 className="text-white font-sans text-2xl">Aulas</h2>
                                <p className="text-xl text-white float-right leading-5 text-right">{listaAulas.length} aulas</p>
                            </div>
                            <div className="w-11/12 mx-auto my-5 bg-zinc-900 flex-grow overflow-hidden rounded-md">
                                <div className="overflow-auto">
                                    <ul className="list-none max-h-[600px] divide-y divide-neutral-800 min-w-[250px] min-h-[35vw] w-full">
                                        {listaAulas.map((aula, index_aula) => {
                                            return (
                                                <li key={index_aula} className={`w-full p-5 flex flex-row space-x-2 text-white text-sm cursor-pointer hover:bg-zinc-700 active:bg-zinc-600 ${aula._id === dadosAula._id ? 'bg-zinc-800' : ''}`} onClick={() => { history.push(`/master/aula/${pagina_codigo}/${curso_codigo}/${aula?.codigo}`) }}>
                                                    <span className="">➤</span>
                                                    <p>{aula.titulo}
                                                        {usuarioAtual.tipo === 'admin' &&
                                                            <span>
                                                                <a href={`/admin/master-aula/${aula._id}`} target='_blank' className='hidden sm:inline-block text-xs text-yellow-500 hover:text-orange-600 active:text-orange-800 px-2'>Editar aula</a>
                                                                <a href={`/admin/master-aula/${aula._id}`} target='_blank' className='inline-block sm:hidden text-xs text-yellow-500 hover:text-orange-600 active:text-orange-800 px-2 py-1' title='Editar Aula'>Editar</a>
                                                            </span>
                                                        }
                                                    </p>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>


                    </div>
                }

                {dadosCurso?.descricao &&
                    <div className='rounded-xl shadow shadow-zinc-950 text-white bg-zinc-900 p-5'>
                        <p className='text-2xl text-gray-500 mb-5'>Descrição do Curso</p>
                        <p>{dadosCurso.descricao}</p>
                    </div>
                }

                <div className="w-full">
                    <div className="flex flex-col md:flex-row justify-between items-stretch gap-5">
                        {/* Seção de Material Gratuito */}
                        {dadosCurso?.materiais && dadosCurso?.materiais[0] &&
                            <div className="w-full md:w-1/2 m-auto bg-gray-800 p-10 rounded-lg shadow-md mb-5 flex flex-col">
                                <h2 className="text-white text-3xl">Faça você mesmo</h2>
                                <p className='text-gray-500 text-md'>Baixe aqui todo o material que você precisa para colocar as aulas em prática!</p>
                                <p className='text-gray-200 text-md my-5'>Nós disponibilizamos para você um pacote com todos os materiais que você para colocar a mão na massa e praticar tudo o que voçê aprender nas aulas deste curso.</p>
                                <a href={dadosCurso?.materiais[0]} target='_blank' className='w-full p-2 my-3 bg-gray-400 hover:bg-gray-300 active:bg-gray-200 text-lg text-gray-900 text-center'>Acessar Materiais</a>
                            </div>
                        }
                        {/* Seção de Material Pago*/}
                        {pagina_codigo && pagina_codigo !== "treinamento-colaboradores" &&
                            <div className="w-full m-auto md:w-1/2 bg-yellow-600 p-10 rounded-lg shadow-md mb-5 flex flex-col">
                                <h2 className="text-white text-3xl">Deixa com a gente</h2>
                                <p className='text-gray-200 text-md'>Opção prática para você quem precisa de soluções imediatas</p>
                                <p className='text-white text-md my-5'>Clicando no botão abaixo, você entra em contato com um dos consultores, onde vamos analisar o que você precisa e fazer para você!</p>
                                <button className='w-full p-2 my-3 bg-gray-100 hover:bg-gray-50 active:bg-white text-lg text-gray-900' onClick={enviarMsgWhatsApp}>Façam para mim</button>
                            </div>
                        }

                    </div>
                </div>


            </main>

            <Footer></Footer>
        </div>
    )
}

export default AulaNovoPadrao;