import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import usuarioAtual, { setUsuario } from '../features/usuario/usuarioAtual';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';

import Header from '../components/header';
import Menu from '../components/menu';
import Footer from '../components/footer'
import PopupAgendamento from '../components/popups/add_consultoria';
import { useHistory, useLocation, useParams } from 'react-router-dom';


const ChaveMestra = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const usuarioAtual = useSelector(state => state.usuarioAtual);
    const { pagina_codigo } = useParams();
    const [popupAgendamentoVisible, setPopupAgendamentoVisible] = useState(false);
    const header_geral = { Authorization: `Bearer ${localStorage.getItem("token")}` }
    const location = useLocation();
    const [bunnyUrlPublicoPagina, setBunnyUrlPublicoPagina] = useState(`${process.env.REACT_APP_BUNNY_FILEHOST}paginas-automaticas/paginas/banners/`);
    const [bunnyUrlPublicoCurso, setBunnyUrlPublicoCurso] = useState(`${process.env.REACT_APP_BUNNY_FILEHOST}paginas-automaticas/cursos/capas/`);
    const [bunnyUrlPublicoCursoPrevia, setBunnyUrlPublicoCursoPrevia] = useState(`${process.env.REACT_APP_BUNNY_FILEHOST}paginas-automaticas/cursos/previas/`);
    const [dadosPagina, setDadosPagina] = useState([]);
    const [showMenu, setShowMenu] = useState(window.innerWidth >= 1024);
    useEffect(() => {
        let isMounted = true;

        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/master/home/${pagina_codigo}`, { headers: header_geral });

                if (isMounted && response.status === 200) {
                    console.log("DADOS: ", response.data)
                    setDadosPagina(response.data);
                } else {
                    console.log(response)
                }
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();

        return () => { isMounted = false };
    }, [pagina_codigo]);

    const entrarNoCurso = (curso) => {
        if (!curso.ativo) {
            return null
        } else if (curso.automacao) {
            window.location.href = `/automacao/${pagina_codigo}/${curso.codigo}/`
        } else {
            window.location.href = `/master/aula/${pagina_codigo}/${curso.codigo}/`
        }
    }

    const [videoPrevia, setVideoPrevia] = useState(false)
    const [showModalPrevia, setShowModalPrevia] = useState(false)
    const abrirPrevia = (video_id) => {
        setVideoPrevia(video_id)
        setShowModalPrevia(true)
    }

    const fecharPrevia = () => {
        setShowModalPrevia(false)
        setVideoPrevia(null)
    }


    return (
        <div>
            {showModalPrevia &&
                <div className='fixed top-0 left-0 z-10 w-screen h-screen flex flex-col items-center align-middle justify-center'>
                    <div className='absolute w-[60%] h-[70%] z-10'>
                        {videoPrevia &&
                            <div className='w-full h-full'>
                                <video
                                    src={videoPrevia}
                                    autoPlay
                                    loop
                                    preload="auto"
                                    controls
                                    className="w-full h-full bg-neutral-950 rounded-lg"
                                >
                                    Seu navegador não suporta a tag de vídeo.
                                </video>
                                <p className='text-center py-5 text-white hover:text-orange-600 active:text-orange-800 cursor-pointer' onClick={fecharPrevia}>
                                    Fechar vídeo de prévia
                                </p>
                            </div>
                        }

                    </div>
                    <div className='w-full h-full bg-neutral-950 opacity-95' onClick={fecharPrevia}></div>

                </div>
            }
            <header className="absolute w-full py-3">
                <div className="w-11/12 m-auto flex items-center justify-between">
                    <a href='/'>
                        <img className="max-w-auto max-h-7 mx-0" src='/main_logo.png' />
                    </a>
                    <span className='flex text-center gap-2 items-center'>
                        {usuarioAtual?.tipo === 'admin' &&
                            <span>
                                <a href={`/admin/master-pagina/${dadosPagina?.pagina?._id}`} target='_blank' className="hidden sm:block text-yellow-500 text-sm float-right p-2 no-underline hover:text-orange-500 hover:border-orange-800">Editar Página</a>
                                <a href={`/admin/master-pagina/${dadosPagina?.pagina?._id}`} target='_blank' className="block sm:hidden text-yellow-500 text-sm float-right p-2 no-underline hover:text-orange-500 hover:border-orange-800" title="Editar Página">Editar</a>
                            </span>
                        }
                        <a href='/' className="hidden sm:block text-gray-200 text-sm float-right border-2 border-gray-200 rounded-lg p-2 no-underline hover:text-orange-500 hover:border-orange-500">Voltar ao início</a>
                        <a href='/' className="block sm:hidden text-gray-200 text-sm float-right border-2 border-gray-200 rounded-lg p-2 no-underline hover:text-orange-500 hover:border-orange-500" title="Voltar ao início">Voltar</a>
                    </span>
                </div>
            </header>

            <main className="w-full">
                {dadosPagina?.pagina &&
                    <div className="w-full bg-neutral-900">
                        <section className="w-full h-screen bg-no-repeat bg-cover bg-fixed bg-top" style={{ backgroundImage: `url(${dadosPagina.pagina.bannerImagem})` }}>
                            <div className="w-full h-screen flex flex-col bg-hero-gradient">
                                <div className="w-11/12 mt-[150px] m-auto text-white flex flex-col space-y-7 md:space-y-3">
                                    <h1 className="text-4xl md:text-7xl font-bold">{dadosPagina.pagina.titulo}</h1>
                                    <p className="text-gray-200 text-sm md:text-lg w-11/12 md:w-2/3">{dadosPagina.pagina.descricao}</p>
                                </div>
                            </div>
                        </section>

                        <div className="-mt-[50vh] md:-mt-[40vh] w-full flex flex-col space-y-14">
                            {!dadosPagina.secoes.length && <p className='text-2xl text-center py-10 text-white'>Não há seções cadastradas para esta página até o momento!</p>}
                            {dadosPagina.secoes.map((secao, index_secao) => {
                                return (
                                    <section key={index_secao} className="w-full">
                                        <div className="w-11/12 m-auto">
                                            <h2 className="text-white text-xl md:text-3xl">{secao.titulo}</h2>
                                            {secao.descricao && secao.descricao.length && <p className='text-gray-400  text-xs md:text-md'>{secao.descricao}</p>}
                                            <div className="w-full relative">
                                                <div className="my-5">
                                                    {!secao.cursos.filter((curso) => curso.imagem).length && <p className='w-11/12 m-auto text-2xl py-10 text-white'>Em breve - Mais conteúdo para você</p>}
                                                    <div className='grid grid-cols-2 gap-2 sm:grid-cols-3 sm:gap-3 md:grid-cols-4 md:gap-4 2xl:grid-cols-5 2xl:gap-5 mt-7'>
                                                        {secao.cursos.map((curso, index_curso) => {
                                                            let baseClasses = "flex flex-col items-center align-middle rounded-lg overflow-hidden bg-gray-800 border-2 border-gray-800 bg-cover bg-no-repeat bg-center group transform transition-transform duration-100 ease-in-out";
                                                            let sizeClasses = "h-[67vw] sm:h-[45vw] md:h-[33vw] 2xl:h-[25vw]";
                                                            let hoverClasses = "hover:border-orange-500 hover:scale-105";
                                                            let cursoClasses = curso.ativo ? "cursor-pointer" : (!curso.videoPrevia ? "cursor-default grayscale" : "grayscale");
                                                            let className = `${baseClasses} ${sizeClasses} ${hoverClasses} ${cursoClasses}`;
                                                            return (
                                                                <div key={curso._id} className='relative group' title={curso.titulo}>
                                                                    <div style={{ backgroundImage: `url(${curso.imagem})` }} onClick={() => entrarNoCurso(curso)} className={className}>
                                                                        {curso.automacao && usuarioAtual.ref.plano && usuarioAtual.ref.plano !== "plan_O0goOQrc0YcqgKd6" && !usuarioAtual.automacoes_compradas?.includes(dadosCurso._id) &&
                                                                            <div className='w-full h-fit bg-orange-600 text-white text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl text-center absolute bottom-0 py-1 hidden group-hover:block'>{curso.preco?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</div>
                                                                        }
                                                                    </div>
                                                                    {!curso.ativo &&
                                                                        <div className='absolute top-0 left-0 w-full h-full hidden group-hover:flex flex-col bg-neutral-900 opacity-75 items-center align-middle justify-center text-white text-xl lg:text-3xl'>
                                                                            <p>Em Breve</p>
                                                                            {curso.videoPrevia &&
                                                                                <p className='text-sm text-orange-600 hover:text-orange-800 active:text-orange-900 cursor-pointer' onClick={() => abrirPrevia(curso.videoPrevia)}>Ver prévia</p>
                                                                            }
                                                                        </div>
                                                                    }
                                                                </div>

                                                            )
                                                        })}
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                )
                            })}
                        </div>

                    </div>
                }
                <Footer></Footer>
            </main >
        </div >
    );
}

export default ChaveMestra;
