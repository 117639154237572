import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setUsuario } from '../features/usuario/usuarioAtual';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import { Chart } from 'chart.js/auto';
import { useHistory } from 'react-router-dom';

import Header from '../components/header';
import Menu from '../components/menu';
import Footer from '../components/footer'
import PopupAgendamento from '../components/popups/add_consultoria';

// import axios from 'axios';

import './diagnostico-old.css';

const Diagnostico = () => {
    // Estados para armazenar os dados
    const [marketingMetrics, setMarketingMetrics] = useState({});
    const [salesMetrics, setSalesMetrics] = useState({});
    const [managementMetrics, setManagementMetrics] = useState({});
    const usuarioAtual = useSelector(state => state.usuarioAtual);
    const [showMenu, setShowMenu] = useState(window.innerWidth >= 1024);
    // Função para buscar os dados do backend
    const fetchData = async () => {
        try {
            // Aqui você faria as solicitações HTTP para o backend para obter os dados
            // Exemplo utilizando axios:
            // const response = await axios.get('/api/metrics');
            // setMarketingMetrics(response.data.marketing);
            // setSalesMetrics(response.data.sales);
            // setManagementMetrics(response.data.management);

            // Por enquanto, estou apenas simulando dados de exemplo
            const exampleMarketingData = {
                cost: 5000,
                ctr: 0.05,
                conversionRate: 0.1,
                cpa: 50,
                cpl: 20,
                roas: 2.5,
            };
            const exampleSalesData = {
                cac: 100,
                roi: 150,
                meetingsScheduled: 50,
                meetingsHeld: 40,
                meetingsClosed: 20,
                conversionRate: 0.5,
                salesBySalesperson: [
                    { name: 'John', percentage: 40 },
                    { name: 'Emma', percentage: 60 },
                ],
                totalRevenue: 10000,
            };
            const exampleManagementData = {
                ltv: 500,
                roi: 0.3,
                netProfit: 3000,
            };

            setMarketingMetrics(exampleMarketingData);
            setSalesMetrics(exampleSalesData);
            setManagementMetrics(exampleManagementData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    // Efeito para buscar os dados ao carregar o componente
    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div>
            <Header usuarioAtual={usuarioAtual} showMenu={showMenu} setShowMenu={setShowMenu} />
            <div className="diagnostico-container">
                <Helmet>
                    <title>Diagnóstico - Simbium</title>
                    <meta
                        property="og:title"
                        content="Diagnóstico - Simbium"
                    />
                </Helmet>
                <div className="diagnostico-area">



                    <div className='diagnostico-panel l-cont'>
                        <div className='diagnostico-content'>

                            <div className="container mx-auto p-4">
                                <h1 className="text-3xl font-bold mb-4">Diagnóstico</h1>

                                <div className='w-full flex flex-row justify-between space-x-5'>
                                    {/* Métricas de Marketing */}
                                    <div className="bg-white rounded-lg p-6 w-1/3">
                                        <h2 className="text-2xl font-semibold mb-4">Métricas de Marketing</h2>
                                        <p className="text-6xl font-bold mb-4">R$ {marketingMetrics.cost}</p>
                                        <p className="text-lg mb-2">Custo com Marketing</p>
                                        <div className="flex justify-between">
                                            <div>
                                                <p className="text-lg font-semibold">CTR</p>
                                                <p className="text-2xl font-bold">{(marketingMetrics.ctr * 100).toFixed(2)}%</p>
                                            </div>
                                            <div>
                                                <p className="text-lg font-semibold">ROAS</p>
                                                <p className="text-2xl font-bold">{marketingMetrics.roas}</p>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Métricas de Vendas */}
                                    <div className="bg-white rounded-lg p-6 w-1/3">
                                        <h2 className="text-2xl font-semibold mb-4">Métricas de Vendas</h2>
                                        <p className="text-6xl font-bold mb-4">R$ {salesMetrics.totalRevenue}</p>
                                        <p className="text-lg mb-2">Faturamento Total</p>
                                        <div className="flex justify-between">
                                            <div>
                                                <p className="text-lg font-semibold">CAC</p>
                                                <p className="text-2xl font-bold">R$ {salesMetrics.cac}</p>
                                            </div>
                                            <div>
                                                <p className="text-lg font-semibold">ROI</p>
                                                <p className="text-2xl font-bold">{salesMetrics.roi}%</p>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Métricas de Gestão */}
                                    <div className="bg-white rounded-lg p-6 w-1/3">
                                        <h2 className="text-2xl font-semibold mb-4">Métricas de Gerência</h2>
                                        <p className="text-6xl font-bold mb-4">R$ {managementMetrics.netProfit}</p>
                                        <p className="text-lg mb-2">Lucro Líquido</p>
                                        <div className="flex justify-between">
                                            <div>
                                                <p className="text-lg font-semibold">LTV</p>
                                                <p className="text-2xl font-bold">R$ {managementMetrics.ltv}</p>
                                            </div>
                                            <div>
                                                <p className="text-lg font-semibold">ROI</p>
                                                <p className="text-2xl font-bold">{managementMetrics.roi}%</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Seção de Métricas de Marketing */}
                                <div className="bg-white rounded-lg p-6 mb-8">
                                    <h2 className="text-2xl font-semibold mb-4">Métricas de Marketing</h2>
                                    <div className="grid grid-cols-2 gap-4">
                                        <div>
                                            <p className="mb-2"><span className="font-semibold">Custo com Marketing:</span> R$ {marketingMetrics.cost}</p>
                                            <p className="mb-2"><span className="font-semibold">CTR:</span> {marketingMetrics.ctr * 100}%</p>
                                            <p className="mb-2"><span className="font-semibold">Taxa de Conversão:</span> {marketingMetrics.conversionRate * 100}%</p>
                                            <p className="mb-2"><span className="font-semibold">CPA:</span> R$ {marketingMetrics.cpa}</p>
                                            <p className="mb-2"><span className="font-semibold">CPL:</span> R$ {marketingMetrics.cpl}</p>
                                            <p className="mb-2"><span className="font-semibold">ROAS:</span> {marketingMetrics.roas}</p>
                                        </div>
                                    </div>
                                </div>

                                {/* Seção de Métricas de Vendas */}
                                <div className="bg-white rounded-lg p-6 mb-8">
                                    <h2 className="text-2xl font-semibold mb-4">Métricas de Venda</h2>
                                    <div className="grid grid-cols-2 gap-4">
                                        <div>
                                            <p className="mb-2"><span className="font-semibold">CAC:</span> R$ {salesMetrics.cac}</p>
                                            <p className="mb-2"><span className="font-semibold">ROI:</span> {salesMetrics.roi}%</p>
                                            <p className="mb-2"><span className="font-semibold">Reuniões Agendadas:</span> {salesMetrics.meetingsScheduled}</p>
                                            <p className="mb-2"><span className="font-semibold">Reuniões Realizadas:</span> {salesMetrics.meetingsHeld}</p>
                                            <p className="mb-2"><span className="font-semibold">Reuniões Fechadas:</span> {salesMetrics.meetingsClosed}</p>
                                            <p className="mb-2"><span className="font-semibold">Taxa de Conversão:</span> {salesMetrics.conversionRate * 100}%</p>
                                            <p className="mb-2"><span className="font-semibold">% de Vendas por Vendedor:</span></p>
                                            {salesMetrics?.salesBySalesperson?.map((salesperson) => (
                                                <p key={salesperson.name} className="mb-2">{salesperson.name}: {salesperson.percentage}%</p>
                                            ))}
                                            <p className="mb-2"><span className="font-semibold">Faturamento Total:</span> R$ {salesMetrics.totalRevenue}</p>
                                        </div>
                                    </div>
                                </div>

                                {/* Seção de Métricas de Gestão */}
                                <div className="bg-white rounded-lg p-6">
                                    <h2 className="text-2xl font-semibold mb-4">Métricas de Gerência</h2>
                                    <div className="grid grid-cols-2 gap-4">
                                        <div>
                                            <p className="mb-2"><span className="font-semibold">LTV:</span> R$ {managementMetrics.ltv}</p>
                                            <p className="mb-2"><span className="font-semibold">ROI:</span> {managementMetrics.roi}%</p>
                                            <p className="mb-2"><span className="font-semibold">Lucro Líquido:</span> R$ {managementMetrics.netProfit}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
            <Footer></Footer>
        </div>
    );
};

export default Diagnostico;
