import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux';
import { setUsuario } from '../../features/usuario/usuarioAtual';

import { useLocation } from 'react-router-dom'
import axios from 'axios'

import styles from './boas_vindas.module.css'


const PopupBoasVindas = (props) => {
  const dispatch = useDispatch();

  const location = useLocation()
  const [video, setVideo] = useState(props.videoFrame ? props.videoFrame : '');
  const [isFrameLoaded, setFrameLoaded] = useState(false);
  const containerRef = useRef(null);

  const concluirBoasVindas = async () => {

    try {

      const response = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/usuarios/onboarding`, {
        etapa: 'video-boas-vindas'
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      })

      if (response.data && response.data.usuario) {
        mixPanelVideoConcluido();
        dispatch(setUsuario(response.data.usuario));
      }

    } catch (err) {
    }

    props.fecharPopup()
    props.abrirOnboarding()
  };

  /*const getVideoBoasVindas = async () => {
    try {

      const response = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/config`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      })
      if (response.data && response.data.boas_vindas && response.data.boas_vindas.url) {
        setVideo(response.data.boas_vindas.url)
      }

    } catch (err) {
      props.fecharPopup()
    }

  };

  useEffect(() => {
    if (location.pathname.includes("/admin")) {
      props.fecharPopup()
    }

    getVideoBoasVindas()
  }, [])*/

  useEffect(() => {
    let isMounted = true;  // Adicione esta linha

    const getVideoBoasVindas = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/config`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        })
        if (isMounted && response.data && response.data.boas_vindas && response.data.boas_vindas.url) {  // Modifique esta linha
          setVideo(response.data.boas_vindas.url)
        }
      } catch (err) {
        if (isMounted) {  // Adicione esta linha
          props.fecharPopup()
        }
      }
    };

    if (location.pathname.includes("/admin")) {
      props.fecharPopup()
    }

    getVideoBoasVindas()

    return () => {  // Adicione esta função de limpeza
      isMounted = false;
    };
  }, [])


  useEffect(() => {
    if (!containerRef.current || isFrameLoaded) return;

    const iframeElement = containerRef.current.querySelector('iframe');
    if (!iframeElement) return;

    const handleLoad = () => {
      setFrameLoaded(true);
    };

    iframeElement.addEventListener('load', handleLoad);

    return () => {
      iframeElement.removeEventListener('load', handleLoad);
    };
  }, [video, isFrameLoaded]);


  const mixPanelAcesso = () => {
    if (!props.usuarioAtual || !props.usuarioAtual._id) {
      console.error('usuarioAtual or usuarioAtual._id is missing');
      return;
    }
    mixpanel.track("Video Boas Vindas | Iniciado", {
      distinct_id: props.usuarioAtual._id,
    });
  }

  const [diferencaTempo, setDiferencaTempo] = useState(0);

  const mixPanelVideoConcluido = () => {
    if (!props.usuarioAtual || !props.usuarioAtual._id) {
      console.error('usuarioAtual ou usuarioAtual._id ausente');
      return;
    }
    mixpanel.track("Video Boas Vindas | Concluído", {
      distinct_id: props.usuarioAtual._id,
      tempo_convencimento: diferencaTempo,
    });
  }

  const mixPanelVideoFechado = () => {
    if (!props.usuarioAtual || !props.usuarioAtual._id) {
      console.error('usuarioAtual ou usuarioAtual._id ausente');
      return;
    }
    mixpanel.track("Video Boas Vindas | Fechado", {
      distinct_id: props.usuarioAtual._id,
      tempo_convencimento: diferencaTempo,
    });
  }

  useEffect(() => {
    mixPanelAcesso();

    const interval = setInterval(() => {
      setDiferencaTempo(prevTempo => prevTempo + 1);
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className='flex flex-col items-center align-middle w-screen h-screen fixed z-10'>
      <div className='w-full h-full fixed z-20 bg-black bg-opacity-90 select-none' onClick={() => { mixPanelVideoFechado(); props.fecharPopup }}></div>
      <div className='w-11/12 md:w-3/4 xl:w-1/2 mx-auto my-auto z-20' onClick={() => { mixPanelVideoFechado(); props.fecharPopup }}>
        <iframe id="video-boas-vindas" className='w-full h-[400px]' src={video} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        <div className='w-full flex flex-col items-center bg-black'>
          <div className='w-fit flex flex-row gap-5 py-2 md:py-5 rounded-b-lg overflow-hidden'>
            <button className='text-white text-xs md:text-md text-center cursor-pointer hover:text-orange-600 active:text-orange-800' onClick={concluirBoasVindas}>Concluir Vídeo</button>
            <button className='text-white text-xs md:text-md text-center cursor-pointer p-2 bg-orange-600 hover:bg-orange-700 active:bg-orange-800 rounded-lg' onClick={props.fecharPopup}>Ver depois</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PopupBoasVindas
