import React, { useState, useEffect } from 'react'
import { useParams, useHistory, useLocation, Link } from 'react-router-dom'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import axios from 'axios'
import { toast } from 'react-toastify';

import PopupAddCurso from '../popups/add_curso';
import PopupModulo from '../popups/add_modulo';
import PopupAdminAula from '../popups/add_aula';
import PopupRemoverItem from '../popups/remover_item'

import './curso.css'


const CursoAdmin = (props) => {
  const history = useHistory();
  const [cursos, setCursos] = useState({});
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [cursoSelecionado, setCursoSelecionado] = useState(history.location.pathname.split("/")[history.location.pathname.split("/").length - 1])
  const [moduloSelecionado, setModuloSelecionado] = useState(null);
  const [modulosCurso, setModulosCurso] = useState([]);
  const [aulasModulo, setAulasModulo] = useState([]);
  const [popupVisible, setPopupVisible] = useState(null);
  const [itemPopup, setItemPopup] = useState(null);
  const [tipoPopup, setTipoPopup] = useState('');
  const [cursoEditado, setCursoEditado] = useState(null);
  const [mostrarAulas, setMostrarAulas] = useState('');

  const itensPorPagina = 10;

  const handleDragEnd = async (result) => {
    if (!result.destination) return;

    let start = result.source.index;
    let end = result.destination.index;

    if (start < end) {
      end++;
    } else if (start > end) {
      end--;
    } else {
      end--;
    }

    const dIndex = modulosCurso.findIndex(mc => mc._id === result.draggableId);
    if (dIndex !== -1) {
      const draggedModule = modulosCurso[dIndex];
      let newModulos = modulosCurso;
      newModulos[dIndex].posicao = end;
      newModulos.sort(function (a, b) { return a.posicao - b.posicao })
      setModulosCurso(newModulos)
    }

    try {

      let cursoId;
      if (cursos.cursos && cursos.cursos.length > 0) cursoId = cursos.cursos[0]._id

      const response = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/cursos/ordenar-modulos`, {
        modulo: result.draggableId,
        posicao: end,
        cursoId
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      })

      toast.success("Posição alterada com sucesso")

    } catch (err) {
    }



  };

  const handleDragEndAula = async (result) => {
    if (!result.destination) return;

    let start = result.source.index;
    let end = result.destination.index;

    if (start < end) {
      end++;
    } else if (start > end) {
      end--;
    } else {
      end--;
    }

    const dIndex = aulasModulo.aulas.findIndex(am => am._id === result.draggableId);
    if (dIndex !== -1) {
      let newAulas = aulasModulo;
      newAulas.aulas[dIndex].posicao = end;
      newAulas.aulas.sort(function (a, b) { return a.posicao - b.posicao })
      setAulasModulo(newAulas)
    }

    try {

      let moduloId;
      if (mostrarAulas) moduloId = mostrarAulas;

      const response = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/modulos/ordenar-aulas`, {
        aula: result.draggableId,
        posicao: end,
        moduloId
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      })

      toast.success("Posição alterada com sucesso")

    } catch (err) {
    }



  };

  const abrirPopup = (popup, item, tipo) => {
    setPopupVisible(popup);
    if (item) {
      setItemPopup(item)
    }

    if (tipo) {
      setTipoPopup(tipo)
    }
  };

  const fecharPopup = () => {
    setPopupVisible(null);
    setItemPopup(null);
    setTipoPopup(null)
  };


  if (cursoSelecionado === 'cursos') {
    setCursoSelecionado(null)
  }

  const handleCourseClick = (id) => {
    history.push(`/admin/cursos/${id}`);
  };

  const fetchAulasModulo = async (moduloId) => {

    try {
      let params = {
        modulo: moduloId,
        all: true
      }

      if (cursos.cursos.length > 0 && cursos.cursos[0]._id) {
        params.curso = cursos.cursos[0]._id
      }

      const resposta = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/aulas`, {
        params,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      });

      setAulasModulo(resposta.data)


    } catch (erro) {
    }
  };

  const buscarModulosCurso = async (cursoId) => {
    if (!cursoId) return;

    try {
      let params = {
        curso: cursoId
      }

      const resposta = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/modulos`, {
        params,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      });

      if (resposta.data && resposta.data.modulos) {
        setModulosCurso(resposta.data.modulos)
      }

      if (resposta.data && resposta.data.modulos && resposta.data.modulos && resposta.data.modulos.length > 0
        && resposta.data.modulos[0]._id) {
        setModuloSelecionado(resposta.data.modulos[0]._id)
      }

    } catch (erro) {
    }
  };

  const buscarCursos = async () => {
    try {
      let params = {
        quantidade: itensPorPagina,
        aPartirDe: (paginaAtual - 1) * itensPorPagina,
        _id: cursoSelecionado
      }

      const resposta = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/cursos`, {
        params,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      });

      if (resposta.data.cursos && resposta.data.cursos.length > 0) {
        setCursos(resposta.data);
      } else {
        history.push('/admin/cursos')
      }
    } catch (erro) {
      history.push('/admin/cursos')
    }
  };

  useEffect(() => {
    let id = history.location.pathname.split("/")[history.location.pathname.split("/").length - 1];
    if (id === 'cursos') id = null;
    setCursoSelecionado(id)
    setPaginaAtual(1)
  }, [window.location.pathname]);

  useEffect(() => {
    buscarCursos();
    window.scrollTo(0, 0);

  }, [paginaAtual, cursoSelecionado])

  useEffect(() => {
    setMostrarAulas('')
    buscarModulosCurso(cursoSelecionado);
  }, [cursoSelecionado])

  useEffect(() => {

    if (mostrarAulas !== '') {
      fetchAulasModulo(mostrarAulas)
    } else {
      setAulasModulo([])
    }

  }, [mostrarAulas])

  return (
    <div className="admin-cursos-container">
      {popupVisible === 'remover-item' && <PopupRemoverItem buscarAulasModulo={() => fetchAulasModulo(mostrarAulas)} buscarModulos={() => buscarModulosCurso(cursoSelecionado)} buscarCursos={buscarCursos} cursoId={cursos.cursos && cursos.cursos.length > 0 && cursos.cursos[0]._id ? cursos.cursos[0]._id : ''} moduloId={mostrarAulas} removido={() => { toast.success(`${tipoPopup} ${tipoPopup === 'Aula' ? 'removida!' : 'removido!'}`) }} itemPopup={itemPopup} tipo={tipoPopup} fecharPopup={fecharPopup} />}
      {popupVisible === 'add-curso' && <PopupAddCurso itemPopup={itemPopup} fecharPopup={fecharPopup} buscarCursos={buscarCursos} sucesso={() => { toast.success("Curso salvo com sucesso!") }} />}
      {popupVisible === 'add-modulo' && <PopupModulo cursoSelecionadoTrilha={cursos.cursos.length === 1 ? cursos.cursos[0].trilha : false} itemPopup={itemPopup} fecharPopup={fecharPopup} buscarModulos={() => buscarModulosCurso(cursoSelecionado)} sucesso={() => { toast.success("Módulo salvo com sucesso!") }} />}
      {popupVisible === 'add-aula' && <PopupAdminAula cursoSelecionadoTrilha={cursos.cursos.length === 1 ? cursos.cursos[0].trilha : false} itemPopup={itemPopup} fecharPopup={fecharPopup} buscarAulasModulos={() => { fetchAulasModulo(mostrarAulas) }} sucesso={() => { toast.success("Aula salva com sucesso!") }} />}
      {!cursoSelecionado ? (
        <div id="curso-lista" className="admin-cursos-cursos">
          <div className="admin-cursos-container01">
            <span className="admin-cursos-text">Cursos</span>
            <div id="add-curso" className="admin-cursos-btn-edit-curso"
              onClick={() => abrirPopup('add-curso')}
            >
              <svg
                viewBox="0 0 804.5714285714286 1024"
                className="admin-cursos-icon"
              >
                <path d="M804.571 420.571v109.714c0 30.286-24.571 54.857-54.857 54.857h-237.714v237.714c0 30.286-24.571 54.857-54.857 54.857h-109.714c-30.286 0-54.857-24.571-54.857-54.857v-237.714h-237.714c-30.286 0-54.857-24.571-54.857-54.857v-109.714c0-30.286 24.571-54.857 54.857-54.857h237.714v-237.714c0-30.286 24.571-54.857 54.857-54.857h109.714c30.286 0 54.857 24.571 54.857 54.857v237.714h237.714c30.286 0 54.857 24.571 54.857 54.857z"></path>
              </svg>
              <span className="admin-cursos-btn">Curso</span>
            </div>
          </div>
          <div className="admin-cursos-lista-cursos">
            {cursos && cursos.cursos && (
              cursos.cursos.map((curso, index) => (
                <div
                  onClick={() => handleCourseClick(curso._id)}
                  key={index} className="admin-cursos-card">
                  <img alt="imagem" src={curso.capa} className="admin-cursos-image" />
                  <div className="admin-cursos-info-curso">
                    <span className="admin-cursos-text1">{curso.nome}</span>
                    <span className="admin-cursos-text2">{curso.aulas} aulas</span>
                  </div>
                </div>
              ))
            )}
          </div>
          {cursos.metadata && cursos.metadata.totalPages && cursos.metadata.totalPages > 1 && (
            <div className="admin-cursos-paginao">
              <div
                className="pointer"
                onClick={() => setPaginaAtual(paginaAtual > 1 ? paginaAtual - 1 : 1)}
              >
                <svg
                  viewBox="0 0 768 1024" className="admin-cursos-icon02">
                  <path d="M669.143 172l-303.429 303.429 303.429 303.429c14.286 14.286 14.286 37.143 0 51.429l-94.857 94.857c-14.286 14.286-37.143 14.286-51.429 0l-424-424c-14.286-14.286-14.286-37.143 0-51.429l424-424c14.286-14.286 37.143-14.286 51.429 0l94.857 94.857c14.286 14.286 14.286 37.143 0 51.429z"></path>
                </svg>
              </div>
              <span className="admin-cursos-text3">
                Página {paginaAtual} {cursos.cursos && cursos.metadata && cursos.metadata.totalPages ? 'de ' + cursos.metadata.totalPages : ''}
              </span>
              <div
                className="pointer"
                onClick={() => setPaginaAtual(cursos.metadata && cursos.metadata.totalPages && paginaAtual < cursos.metadata.totalPages ? paginaAtual + 1 : 1)}
              >
                <svg
                  viewBox="0 0 694.8571428571428 1024"
                  className="admin-cursos-icon04"
                >
                  <path d="M632.571 501.143l-424 424c-14.286 14.286-37.143 14.286-51.429 0l-94.857-94.857c-14.286-14.286-14.286-37.143 0-51.429l303.429-303.429-303.429-303.429c-14.286-14.286-14.286-37.143 0-51.429l94.857-94.857c14.286-14.286 37.143-14.286 51.429 0l424 424c14.286 14.286 14.286 37.143 0 51.429z"></path>
                </svg>
              </div>
            </div>
          )}
        </div>
      ) : (
        cursos.cursos && cursos.cursos.length > 0 ? (
          <div id="edit-curso-box" className="admin-cursos-curso-edit">
            <div className="admin-cursos-container02">
              <div className="admin-cursos-container03">
                <span className="admin-cursos-text4">{cursos.cursos[0].nome}</span>
                <div className="admin-cursos-container04">
                  <div id="edit-curso" className="admin-cursos-btn-edit-curso" onClick={() => { abrirPopup('add-curso', cursos.cursos[0]) }}>
                    <svg viewBox="0 0 1024 1024" className="admin-cursos-icon06">
                      <path d="M884 300l-78 78-160-160 78-78q12-12 30-12t30 12l100 100q12 12 12 30t-12 30zM128 736l472-472 160 160-472 472h-160v-160z"></path>
                    </svg>
                    <span className="admin-cursos-btn-add-modulo1">Editar</span>
                  </div>
                  <div className="admin-cursos-container05"
                    onClick={() => { abrirPopup('remover-item', cursos.cursos[0], 'Curso') }}
                  >
                    <svg
                      viewBox="0 0 804.5714285714286 1024"
                      className="admin-cursos-icon08"
                    >
                      <path d="M741.714 755.429c0 14.286-5.714 28.571-16 38.857l-77.714 77.714c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-168-168-168 168c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-77.714-77.714c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l168-168-168-168c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l77.714-77.714c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l168 168 168-168c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l77.714 77.714c10.286 10.286 16 24.571 16 38.857s-5.714 28.571-16 38.857l-168 168 168 168c10.286 10.286 16 24.571 16 38.857z"></path>
                    </svg>
                  </div>
                </div>
              </div>
              <div id="add-modulo" className="admin-cursos-btn-edit-curso" onClick={() => { abrirPopup('add-modulo', { curso: cursos.cursos[0]._id }) }}>
                <svg
                  viewBox="0 0 804.5714285714286 1024"
                  className="admin-cursos-icon10"
                >
                  <path d="M804.571 420.571v109.714c0 30.286-24.571 54.857-54.857 54.857h-237.714v237.714c0 30.286-24.571 54.857-54.857 54.857h-109.714c-30.286 0-54.857-24.571-54.857-54.857v-237.714h-237.714c-30.286 0-54.857-24.571-54.857-54.857v-109.714c0-30.286 24.571-54.857 54.857-54.857h237.714v-237.714c0-30.286 24.571-54.857 54.857-54.857h109.714c30.286 0 54.857 24.571 54.857 54.857v237.714h237.714c30.286 0 54.857 24.571 54.857 54.857z"></path>
                </svg>
                <span className="admin-cursos-btn-add-modulo2">Módulo</span>
              </div>
            </div>
            <div className="admin-cursos-modulos">
              {modulosCurso && modulosCurso.length > 0 ? (
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="modulosCurso">
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {modulosCurso && (
                          modulosCurso.map((modulo, index) => (
                            <Draggable key={modulo._id.toString()} draggableId={modulo._id.toString()} index={modulo.posicao}>
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className="admin-cursos-modulo">
                                  <div className="admin-cursos-container06">
                                    <div className="admin-cursos-container07">
                                      <svg viewBox="0 0 1024 1024" className="admin-cursos-icon12">
                                        <path d="M170 640v-86h684v86h-684zM854 384v86h-684v-86h684z"></path>
                                      </svg>
                                      <span className="admin-cursos-text5">{modulo.nome} <Link to={`/admin/cursos/${modulo.curso[0]._id}`} style={{ fontSize: '14px', color: '#999', fontStyle: 'italic' }}>{modulo.curso.length > 1 && modulo.curso[0]._id !== cursos.cursos[0]._id ? `(Importado do ${modulo.curso[0].nome})` : ''}</Link></span>
                                    </div>
                                    <div className="admin-cursos-container08">
                                      <div id="add-aula" className="admin-cursos-btn-add-aula" onClick={() => { abrirPopup('add-aula', { cursoId: cursos.cursos[0], moduloId: modulo }) }}>
                                        <svg
                                          viewBox="0 0 804.5714285714286 1024"
                                          className="admin-cursos-icon14"
                                        >
                                          <path d="M804.571 420.571v109.714c0 30.286-24.571 54.857-54.857 54.857h-237.714v237.714c0 30.286-24.571 54.857-54.857 54.857h-109.714c-30.286 0-54.857-24.571-54.857-54.857v-237.714h-237.714c-30.286 0-54.857-24.571-54.857-54.857v-109.714c0-30.286 24.571-54.857 54.857-54.857h237.714v-237.714c0-30.286 24.571-54.857 54.857-54.857h109.714c30.286 0 54.857 24.571 54.857 54.857v237.714h237.714c30.286 0 54.857 24.571 54.857 54.857z"></path>
                                        </svg>
                                        <span className="admin-cursos-btn">Aula</span>
                                      </div>
                                      <div id="edit-modulo" className="admin-cursos-btn-editar" onClick={() => { abrirPopup('add-modulo', modulo) }}>
                                        <svg viewBox="0 0 1024 1024" className="admin-cursos-icon16">
                                          <path d="M884 300l-78 78-160-160 78-78q12-12 30-12t30 12l100 100q12 12 12 30t-12 30zM128 736l472-472 160 160-472 472h-160v-160z"></path>
                                        </svg>
                                      </div>
                                      <div id="ver-aulas" className="admin-cursos-btn-ver-aulas" onClick={() => {
                                        setMostrarAulas('')

                                        if (mostrarAulas !== modulo._id) {
                                          setMostrarAulas(modulo._id)
                                        }

                                      }}>
                                        <svg viewBox="0 0 1024 1024" className="admin-cursos-icon18" style={mostrarAulas === modulo._id ? { transform: 'rotate(180deg)' } : {}}>
                                          <path d="M961.714 461.714l-424 423.429c-14.286 14.286-37.143 14.286-51.429 0l-424-423.429c-14.286-14.286-14.286-37.714 0-52l94.857-94.286c14.286-14.286 37.143-14.286 51.429 0l303.429 303.429 303.429-303.429c14.286-14.286 37.143-14.286 51.429 0l94.857 94.286c14.286 14.286 14.286 37.714 0 52z"></path>
                                        </svg>
                                      </div>
                                      <div id="excluir-modulo" className="admin-cursos-btn-excluir" onClick={() => { abrirPopup('remover-item', modulo, 'Módulo') }}>
                                        <svg
                                          viewBox="0 0 804.5714285714286 1024"
                                          className="admin-cursos-icon20"
                                        >
                                          <path d="M741.714 755.429c0 14.286-5.714 28.571-16 38.857l-77.714 77.714c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-168-168-168 168c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-77.714-77.714c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l168-168-168-168c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l77.714-77.714c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l168 168 168-168c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l77.714 77.714c10.286 10.286 16 24.571 16 38.857s-5.714 28.571-16 38.857l-168 168 168 168c10.286 10.286 16 24.571 16 38.857z"></path>
                                        </svg>
                                      </div>
                                    </div>
                                  </div>
                                  {mostrarAulas === modulo._id && (
                                    <DragDropContext onDragEnd={handleDragEndAula} id="box-aulas" className="admin-cursos-aulas">
                                      <Droppable droppableId="box-aulas" className="admin-cursos-aulas">
                                        {(provided) => (
                                          <div style={{ width: '100%', paddingLeft: '32px', display: 'flex', flexDirection: 'column', gap: '10px' }} ref={provided.innerRef} {...provided.droppableProps}>
                                            {aulasModulo && aulasModulo.aulas && aulasModulo.aulas.length > 0 && (
                                              aulasModulo.aulas.map((aula, index) => (
                                                <Draggable key={aula._id.toString()} draggableId={aula._id.toString()} index={aula.posicao}>
                                                  {provided => (
                                                    <div className="admin-cursos-aula" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                      <div className="admin-cursos-container09">
                                                        <svg viewBox="0 0 1024 1024" className="admin-cursos-icon22">
                                                          <path d="M170 640v-86h684v86h-684zM854 384v86h-684v-86h684z"></path>
                                                        </svg>
                                                        <span className="admin-cursos-text6">{aula.nome}{aula.videoGuid && !aula.video ? ' ⚠️' : ''} <Link to={`/admin/cursos/${aula.curso[0]._id}`} style={{ fontSize: '14px', color: '#999', fontStyle: 'italic' }}>{aula.curso && aula.curso.length > 1 && aula.curso[0]._id !== cursos.cursos[0]._id ? `(Importado do ${aula.curso[0].nome})` : ''}</Link></span>
                                                      </div>
                                                      <div className="admin-cursos-container10">
                                                        <div id="editar-aula" className="admin-cursos-btn-editar1" onClick={() => { abrirPopup('add-aula', { ...aula, moduloId: modulo?._id, cursoId: cursoSelecionado }) }}>
                                                          <svg
                                                            viewBox="0 0 1024 1024"
                                                            className="admin-cursos-icon24"
                                                          >
                                                            <path d="M884 300l-78 78-160-160 78-78q12-12 30-12t30 12l100 100q12 12 12 30t-12 30zM128 736l472-472 160 160-472 472h-160v-160z"></path>
                                                          </svg>
                                                        </div>
                                                        <div id="excluir-aula" className="admin-cursos-btn-excluir1" onClick={() => { abrirPopup('remover-item', aula, 'Aula') }}>
                                                          <svg
                                                            viewBox="0 0 804.5714285714286 1024"
                                                            className="admin-cursos-icon26"
                                                          >
                                                            <path d="M741.714 755.429c0 14.286-5.714 28.571-16 38.857l-77.714 77.714c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-168-168-168 168c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-77.714-77.714c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l168-168-168-168c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l77.714-77.714c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l168 168 168-168c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l77.714 77.714c10.286 10.286 16 24.571 16 38.857s-5.714 28.571-16 38.857l-168 168 168 168c10.286 10.286 16 24.571 16 38.857z"></path>
                                                          </svg>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  )}
                                                </Draggable>
                                              ))
                                            )}
                                          </div>
                                        )}
                                      </Droppable>
                                    </DragDropContext>
                                  )}

                                </div>
                              )}
                            </Draggable>
                          ))
                        )}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              ) : (
                <p style={{ color: "#7e7e7e" }}>Nenhum módulo cadastrado</p>
              )}
            </div>
          </div>
        ) : (
          <p style={{ color: 'white' }}>Carregando...</p>
        )
      )}

    </div>
  )
}

export default CursoAdmin
