import React from 'react';

const SectionPageForm = ({ secaoPagina, setSecaoPagina, onSubmit, onCancel, processando, formSecaoMode, erroSecaoPagina }) => {
    return (
        <div className="mt-4 bg-neutral-700 p-4 rounded">
            <h1 className='text-xl mb-5 text-white font-bold'>Cadastro de Seção</h1>
            <input
                type="text"
                placeholder="Título da Seção"
                value={secaoPagina.titulo}
                onChange={(e) => setSecaoPagina({ ...secaoPagina, titulo: e.target.value })}
                className="w-full p-2 mb-2 rounded bg-neutral-600 text-white"
            />
            {erroSecaoPagina?.titulo && <p className='text-red-500 text-xs'>{erroSecaoPagina.titulo}</p>}
            <textarea
                placeholder="Descrição da Seção"
                value={secaoPagina.descricao}
                onChange={(e) => setSecaoPagina({ ...secaoPagina, descricao: e.target.value })}
                className="w-full p-2 mb-2 rounded bg-neutral-600 text-white"
            />
            {erroSecaoPagina?.descricao && <p className='text-red-500 text-xs'>{erroSecaoPagina.descricao}</p>}
            <div className='flex gap-5'>
                <button
                    type='button'
                    onClick={onSubmit}
                    className="bg-orange-500 text-white px-4 py-2 rounded disabled:opacity-50"
                    disabled={processando || !secaoPagina.titulo}
                >
                    {formSecaoMode === 'create' ? 'Adicionar Seção' : 'Salvar Alterações'}
                </button>
                <button
                    type='button'
                    onClick={onCancel}
                    className="bg-red-500 text-white px-4 py-2 rounded disabled:opacity-50"
                    disabled={processando}
                >
                    Cancelar
                </button>
            </div>
        </div>
    );
};

export default SectionPageForm;
