import React, { useEffect, useState } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import Cookies from 'js-cookie';

import './indicacao.css'

const Indicacao = (props) => {
  const usuarioAtual = useSelector(state => state.usuarioAtual);

  const { id } = useParams();
  const history = useHistory();
  const [nome, setNome] = useState('');

  useEffect(() => {

    let isMounted = true

    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/usuarios/${id}`);
        const usuario = response.data;

        if (isMounted) {
          if (usuario) {
            setNome(usuario.nome)

            Cookies.set('indicadorId', usuario._id);
          } else {
            history.push('/');
          }
        }
      } catch (error) {
        if (isMounted) {
          history.push('/');
        }
      }
    };

    if (usuarioAtual) {
      history.push('/');
    } else {
      fetchData();
    }

    return () => {
      isMounted = false
    }

  }, [id, history]);


  return (
    <div className="indicacao-container">
      <img
        src="/https___b3a6390b7a66b161ddd9c8f9e9c8f2de.cdn.bubble.io_f1689088670051x906951525380208500_logo_simbium-200h.png"
        alt="image"
        className="indicacao-image"
      />
      <div className="indicacao-container1">
        <div className="indicacao-container2">
          <span className="indicacao-text">
            <span>Parabéns! 🥳</span>
            <br></br>
          </span>
          <span className="indicacao-text03">
            <span>
              {nome} te indicou para experimentar o Ecossistema Simbium
              por
            </span>
            <br></br>
            <span className="indicacao-text05"> 14 dias grátis!</span>
          </span>
        </div>
        <span className="indicacao-text07">
          A Simbium é uma plataforma completa para micro e pequenas empresas,
          com consultoria, IA, comunidade, mentorias e muito mais.
        </span>
        <span className="indicacao-text08">
          São 7 dias grátis e não precisa de nenhum cartão
        </span>
        <Link to="/login?cadastro=true" className="indicacao-b-continuar-aula">
          <span className="indicacao-text09">Criar Conta</span>
        </Link>
      </div>
    </div>
  )
}

export default Indicacao
