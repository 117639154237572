import React, { useState, useEffect } from 'react';
import axios from 'axios';

const CreditCardNumberInput = ({ card, setCard, cardErrors, setCardErrors, paymentMethod, disableForm }) => {
    const [displayCardNumber, setDisplayCardNumber] = useState('');

    useEffect(() => {
        if (card.first_six_digits && card.last_four_digits) {
            setDisplayCardNumber(formatCardNumber(`${card.first_six_digits}******${card.last_four_digits}`));
        } else {
            setDisplayCardNumber(formatCardNumber(card.number));
        }
    }, [card]);

    useEffect(() => {
        if (!validateCardNumber(displayCardNumber.replace(/[^0-9*]/g, ''))) {
            setCardErrors((prevErrors) => ({
                ...prevErrors,
                number: 'Número do cartão inválido',
            }));
        } else {
            setCardErrors((prevErrors) => ({
                ...prevErrors,
                number: '',
            }));
        }
    }, [displayCardNumber]);

    useEffect(() => {
        console.log("CARTÃO LAELE: ", card)
    }, [card.brand])

    const detectCardBrand = (number) => {
        const regex = {
            visa: /^4[0-9]{0,15}$/,
            mastercard: /^(5[1-5][0-9]{0,14}|2[2-7][0-9]{0,14})$/,
            amex: /^3[47][0-9]{0,13}$/,
            diners: /^3(?:0[0-5]|[68][0-9])[0-9]{0,11}$/,
            discover: /^6(?:011|5[0-9]{2})[0-9]{0,12}$/,
            jcb: /^(?:2131|1800|35\d{3})\d{0,11}$/,
            unionpay: /^62[0-9]{0,17}$/,
            elo: /^4011|4312|4389|4514|4576|5041|5066|5067|5090|5091|6277|6363[0-9]{0,12}$/,
            aura: /^50[0-9]{0,14}$/,
            hipercard: /^(606282|3841)[0-9]{0,12}$/,
        };

        for (const brand in regex) {
            if (regex[brand].test(number)) {
                return brand;
            }
        }

        return "";
    };


    const formatCardNumber = (number = '') => {
        let cleanedNumber = number.replace(/[^0-9*]/g, '');
        let formattedNumber = cleanedNumber.replace(/(.{4})/g, '$1 ').trim();
        return formattedNumber;
    };


    const validateCardNumber = (number) => {
        if (card.id.length) return /^[0-9*]+$/.test(number);
        return /^[0-9]+$/.test(number);
    };

    const handleCardNumberChange = (e) => {
        const { name, value } = e.target;

        const cleanCardNumber = value.replace(/[^0-9]/g, '');
        if (!validateCardNumber(cleanCardNumber)) {
            setCardErrors((prevErrors) => ({
                ...prevErrors,
                number: 'Número do cartão inválido',
            }));
        } else {
            setCardErrors((prevErrors) => ({
                ...prevErrors,
                number: '',
            }));
        }

        const cardBrand = detectCardBrand(cleanCardNumber);
        setCard((prevCard) => ({
            ...prevCard,
            number: cleanCardNumber,
            brand: cardBrand,
        }));


        setDisplayCardNumber(formatCardNumber(cleanCardNumber));

    };

    return (
        <div>
            <input
                type="text"
                minLength="16"
                maxLength="19"
                id="number"
                name="number"
                value={displayCardNumber}
                onChange={handleCardNumberChange}
                disabled={disableForm}
                required
                className='w-full text-sm p-2 rounded-md bg-neutral-700 focus:bg-neutral-700 focus:outline-none focus:border-none focus:shadow-none focus:ring-1 focus:ring-orange-500 disabled:opacity-50'
            />
        </div>
    );
};

export default CreditCardNumberInput;
