import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import usuarioAtual, { setUsuario } from '../features/usuario/usuarioAtual';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';

import Header from '../components/header';
import Menu from '../components/menu';
import Footer from '../components/footer'
import PopupAgendamento from '../components/popups/add_consultoria';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

// import styles from './aula-novo-padrao.module.css';

const AulaNovoPadrao = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [popupAgendamentoVisible, setPopupAgendamentoVisible] = useState(false);
  const [showMenu, setShowMenu] = useState(window.innerWidth >= 1024);
  const location = useLocation();

  return (
    <div>
      <header className={styles.header}>
        <div className={styles.headerContent}>
          <div className={styles.headerLeft}>
            <a href='/'>
              <img src='/main_logo.png' />
            </a>
          </div>
          <div className={styles.headerRight}>
            <a href='/chave-mestra' className={styles.btnBackToHome}>Voltar ao Chave Mestra</a>
          </div>
        </div>
      </header>
      <main>
        <div className={styles.container}>
          <div className={styles.content}>
            <div className={styles.aula}>
              <div className={styles.aulaTitulo}>
                <h1>TOP 3 - Como começar um vídeo</h1>
                <p>Youtube - Dinheiro para sempre</p>
              </div>
              <div className={styles.aulaVideoContainer}>
                <div className={styles.aulaVideoContent}>
                  <iframe className={styles.aulaVideo} src="https://www.youtube.com/embed/Sq9dVpqHai0?si=v8Mxr2QV-0wapMkh" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                </div>
              </div>
              <div className={styles.aulaListaContainer}>
                <div className={styles.aulaListaHeader}>
                  <h2>Aulas</h2>
                  <p>16 aulas</p>
                </div>
                <div className={styles.aulaListaContent}>
                  <ul className={styles.aulaLista}>
                    <li className={styles.aulaListaItem}><span className={styles.aulaListaItemSeta}>➤</span>Boas-Vindas</li>
                    <li className={`${styles.aulaListaItem} ${styles.aulaListaItemActive}`}><span className={styles.aulaListaItemSeta}>➤</span>TOP 3 - Como começar um vídeo</li>
                    <li className={styles.aulaListaItem}><span className={styles.aulaListaItemSeta}>➤</span>Descobrindo Nichos Lucrativos</li>
                    <li className={styles.aulaListaItem}><span className={styles.aulaListaItemSeta}>➤</span>Estratégias de Monetização</li>
                    <li className={styles.aulaListaItem}><span className={styles.aulaListaItemSeta}>➤</span>SEO para Aumentar Visualizações</li>
                    <li className={styles.aulaListaItem}><span className={styles.aulaListaItemSeta}>➤</span>Engajamento e Construção de Audiência</li>
                    <li className={styles.aulaListaItem}><span className={styles.aulaListaItemSeta}>➤</span>Edição Profissional de Vídeos</li>
                    <li className={styles.aulaListaItem}><span className={styles.aulaListaItemSeta}>➤</span>Colaborações e Parcerias</li>
                    <li className={styles.aulaListaItem}><span className={styles.aulaListaItemSeta}>➤</span>Marketing nas Redes Sociais</li>
                    <li className={styles.aulaListaItem}><span className={styles.aulaListaItemSeta}>➤</span>Investindo em Equipamentos</li>
                    <li className={styles.aulaListaItem}><span className={styles.aulaListaItemSeta}>➤</span>Como Criar Conteúdo Consistente</li>
                    <li className={styles.aulaListaItem}><span className={styles.aulaListaItemSeta}>➤</span>Estratégias de Monetização Avançadas</li>
                    <li className={styles.aulaListaItem}><span className={styles.aulaListaItemSeta}>➤</span>Gestão Financeira para Criadores de Conteúdo</li>
                    <li className={styles.aulaListaItem}><span className={styles.aulaListaItemSeta}>➤</span>Dicas de Produtividade</li>
                    <li className={styles.aulaListaItem}><span className={styles.aulaListaItemSeta}>➤</span>Networking e Eventos do Setor</li>
                    <li className={styles.aulaListaItem}><span className={styles.aulaListaItemSeta}>➤</span>Manter a Motivação ao Longo do Tempo</li>
                  </ul>
                </div>
              </div>


              <span> .</span>
              <div className={styles.aulaAcoesAula}>
                <div className={styles.aulaAvaliacao}>
                  <div className={styles.aulaContainer05}>
                    <span className={styles.aulaText06}>Avaliar aula:</span>
                    <div className={styles.rate}>
                      <input type="radio" id="star5" name="rate" value="5" />
                      <label htmlFor="star5" title="text">5 stars</label>
                      <input type="radio" id="star4" name="rate" value="4" />
                      <label htmlFor="star4" title="text">4 stars</label>
                      <input type="radio" id="star3" name="rate" value="3" />
                      <label htmlFor="star3" title="text">3 stars</label>
                      <input type="radio" id="star2" name="rate" value="2" />
                      <label htmlFor="star2" title="text">2 stars</label>
                      <input type="radio" id="star1" name="rate" value="1" />
                      <label htmlFor="star1" title="text">1 star</label>
                    </div>
                  </div>
                </div>
                <div className={styles.aulaAnotacoes}>
                  <div className={styles.aulaContainer07}>
                    <span className={styles.aulaText07}>
                      <span>Anotações pessoais</span>
                      <br></br>
                    </span>
                    <div className={styles.aulaContainer08}>
                      <svg viewBox="0 0 804.5714285714286 1024" className={styles.aulaIcon10}>
                        <path d="M804.571 420.571v109.714c0 30.286-24.571 54.857-54.857 54.857h-237.714v237.714c0 30.286-24.571 54.857-54.857 54.857h-109.714c-30.286 0-54.857-24.571-54.857-54.857v-237.714h-237.714c-30.286 0-54.857-24.571-54.857-54.857v-109.714c0-30.286 24.571-54.857 54.857-54.857h237.714v-237.714c0-30.286 24.571-54.857 54.857-54.857h109.714c30.286 0 54.857 24.571 54.857 54.857v237.714h237.714c30.286 0 54.857 24.571 54.857 54.857z"></path>
                      </svg>
                      <span className={styles.aulaText10}>Adicionar Nota</span>
                    </div>
                  </div>
                </div>
                <div className={styles.aulaConcluida}>
                  <div className={styles.aulaContainer09}>
                    <span className={styles.aulaText11}>Marcar como concluída</span>
                  </div>
                  <div className={styles.aulaContainer10}>
                    <svg style={{ alignSelf: 'center' }} fill="none" xmlns="http://www.w3.org/2000/svg" className={styles.iconTabler} width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#1b1b1b" strokeLinecap="round" strokeLinejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" />
                      <path d="M3 3m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z" />
                    </svg>
                    <span className={styles.aulaText12}>Concluir Aula</span>
                  </div>
                </div>
                <div className={styles.aulaFavoritar}>
                  <div className={styles.aulaContainer11}>
                    <span className={styles.aulaText13}>Favoritar/Compartilhar</span>
                  </div>
                  <div className={styles.aulaContainer12}>
                    <div className={styles.aulaContainer13}>
                      <svg viewBox="0 0 1024 1024" className={styles.aulaIcon14}>
                        <path d="M512 950.857c-9.143 0-18.286-3.429-25.143-10.286l-356.571-344c-4.571-4-130.286-118.857-130.286-256 0-167.429 102.286-267.429 273.143-267.429 100 0 193.714 78.857 238.857 123.429 45.143-44.571 138.857-123.429 238.857-123.429 170.857 0 273.143 100 273.143 267.429 0 137.143-125.714 252-130.857 257.143l-356 342.857c-6.857 6.857-16 10.286-25.143 10.286z"></path>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.materialContainer}>
                <div className={styles.materialContent}>
                  {/* Seção de Material Gratuito */}
                  <div className={`${styles.materialGratuito} ${styles.materialSection}`}>
                    <h2>Faça você mesmo</h2>
                    <ul>
                      <li><a href="#">Planilha de Exercícios</a></li>
                      <li><a href="#">Vídeo Tutorial</a></li>
                      <li><a href="#">Documento de Texto</a></li>
                      {/* Adicione mais itens conforme necessário */}
                    </ul>
                  </div>

                  {/* Seção de Material Pago */}
                  <div className={`${styles.materialPago} ${styles.materialSection}`}>
                    <h2>Deixa com a gente!</h2>
                    <p>
                      Deseja uma solução completa para o seu canal? Confira nosso serviço premium que inclui:
                    </p>
                    <ul>
                      <li><span className={styles.materialSectionIcon}><svg fill="#white" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16 0c-8.836 0-16 7.163-16 16s7.163 16 16 16c8.837 0 16-7.163 16-16s-7.163-16-16-16zM16 30.032c-7.72 0-14-6.312-14-14.032s6.28-14 14-14 14 6.28 14 14-6.28 14.032-14 14.032zM22.386 10.146l-9.388 9.446-4.228-4.227c-0.39-0.39-1.024-0.39-1.415 0s-0.391 1.023 0 1.414l4.95 4.95c0.39 0.39 1.024 0.39 1.415 0 0.045-0.045 0.084-0.094 0.119-0.145l9.962-10.024c0.39-0.39 0.39-1.024 0-1.415s-1.024-0.39-1.415 0z"></path>
                      </svg></span>Criação do Canal no YouTube</li>
                      <li><span className={styles.materialSectionIcon}><svg fill="#white" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16 0c-8.836 0-16 7.163-16 16s7.163 16 16 16c8.837 0 16-7.163 16-16s-7.163-16-16-16zM16 30.032c-7.72 0-14-6.312-14-14.032s6.28-14 14-14 14 6.28 14 14-6.28 14.032-14 14.032zM22.386 10.146l-9.388 9.446-4.228-4.227c-0.39-0.39-1.024-0.39-1.415 0s-0.391 1.023 0 1.414l4.95 4.95c0.39 0.39 1.024 0.39 1.415 0 0.045-0.045 0.084-0.094 0.119-0.145l9.962-10.024c0.39-0.39 0.39-1.024 0-1.415s-1.024-0.39-1.415 0z"></path>
                      </svg></span>Edição Profissional de Vídeos</li>
                      <li><span className={styles.materialSectionIcon}><svg fill="#white" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16 0c-8.836 0-16 7.163-16 16s7.163 16 16 16c8.837 0 16-7.163 16-16s-7.163-16-16-16zM16 30.032c-7.72 0-14-6.312-14-14.032s6.28-14 14-14 14 6.28 14 14-6.28 14.032-14 14.032zM22.386 10.146l-9.388 9.446-4.228-4.227c-0.39-0.39-1.024-0.39-1.415 0s-0.391 1.023 0 1.414l4.95 4.95c0.39 0.39 1.024 0.39 1.415 0 0.045-0.045 0.084-0.094 0.119-0.145l9.962-10.024c0.39-0.39 0.39-1.024 0-1.415s-1.024-0.39-1.415 0z"></path>
                      </svg></span>Postagem Regular no YouTube</li>
                      <li><span className={styles.materialSectionIcon}><svg fill="#white" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16 0c-8.836 0-16 7.163-16 16s7.163 16 16 16c8.837 0 16-7.163 16-16s-7.163-16-16-16zM16 30.032c-7.72 0-14-6.312-14-14.032s6.28-14 14-14 14 6.28 14 14-6.28 14.032-14 14.032zM22.386 10.146l-9.388 9.446-4.228-4.227c-0.39-0.39-1.024-0.39-1.415 0s-0.391 1.023 0 1.414l4.95 4.95c0.39 0.39 1.024 0.39 1.415 0 0.045-0.045 0.084-0.094 0.119-0.145l9.962-10.024c0.39-0.39 0.39-1.024 0-1.415s-1.024-0.39-1.415 0z"></path>
                      </svg></span>Controle de Gastos e Ganhos</li>
                      {/* Adicione mais itens conforme necessário */}
                    </ul>
                    <button className={styles.materialPagoButton}>Façam para mim</button>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </main>

      <footer className={styles.footerContainer}>
        <div className={styles.footerContent}>
          <div className={styles.footerLinks}>
            <div className={styles.footerLink} onClick={() => history.push('/termos-de-uso')}>
              Termos de Uso
            </div>
            <span className={styles.separator}> • </span>
            <div className={styles.footerLink} onClick={() => history.push('/politicas-de-privacidade')}>
              Políticas de Privacidade
            </div>
          </div>
          <div className={styles.footerText}>
            © 2023 Simbium - Todos os direitos reservados
          </div>
        </div>
      </footer>
    </div>
  )
}

export default AulaNovoPadrao;