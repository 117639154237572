import React from 'react';

const PageForm = ({ pagina, setPagina, onSubmit, onCancel, processando, erroPagina }) => {
    return (
        <div className="mt-4 bg-neutral-700 p-4 rounded">
            <h1 className='text-xl mb-5 text-white font-bold'>Cadastro de Página</h1>
            <input
                type="text"
                placeholder="Título da Página"
                value={pagina.titulo}
                onChange={(e) => setPagina({ ...pagina, titulo: e.target.value })}
                className="w-full p-2 mb-2 rounded bg-neutral-600 text-white"
            />
            {erroPagina?.titulo && <p className='text-red-500 text-xs'>{erroPagina.titulo}</p>}
            <textarea
                placeholder="Descrição da Página"
                value={pagina.descricao}
                onChange={(e) => setPagina({ ...pagina, descricao: e.target.value })}
                className="w-full p-2 mb-2 rounded bg-neutral-600 text-white"
            />
            {erroPagina?.descricao && <p className='text-red-500 text-xs'>{erroPagina.descricao}</p>}
            <div className='flex gap-5'>
                <button
                    type='button'
                    onClick={onSubmit}
                    className="bg-orange-500 text-white px-4 py-2 rounded disabled:opacity-50"
                    disabled={processando || !pagina.titulo || !pagina.descricao}
                >
                    Adicionar Página
                </button>
                <button
                    type='button'
                    onClick={onCancel}
                    className="bg-red-500 text-white px-4 py-2 rounded disabled:opacity-50"
                    disabled={processando}
                >
                    Cancelar
                </button>
            </div>
        </div>
    );
};

export default PageForm;
