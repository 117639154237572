import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation, useHistory } from 'react-router-dom'
import axios from 'axios';

const ModalConsultoresHome = (props) => {
    const api = axios.create({
        baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
    });

    const adicionarColaborador = async () => {
        try {
            const response = await api.post('/usuarios/adicionar-colaborador', {
                nome: props.nomeNovoColaborador,
                email: props.emailNovoColaborador,
            });

            console.log(response);
            props.handleSuccessResponse(response, 'Colaborador adicionado com sucesso!', () => {
                const updatedUsuario = { ...props.usuarioAtual, creditos: props.usuarioAtual.creditos - 80 };
                props.setnomeNovoColaborador("");
                props.setemailNovoColaborador("");
                props.setPopupColaboradorVisible(false);
                props.fetchColaboradores();
                updatedUsuario(updatedUsuario);
                checkOnboardingStatus();
            });
        } catch (error) {
            props.handleRequestError(error);
        }
    };


    return (
        <div className="meus-colaboradores-popup-agendamento">
            <div className="meus-colaboradores-conteudo1">
                <div className="meus-colaboradores-container11">
                    <Link to="/" className="meus-colaboradores-navlink">
                        <img
                            alt="image"
                            src="/image9-200h.png"
                            className="meus-colaboradores-logo"
                        />
                    </Link>
                    <span className="meus-colaboradores-text19">
                        Adicionar colaborador
                    </span>
                </div>
                <div className="meus-colaboradores-inputs">
                    <div className="meus-colaboradores-container12">
                        <svg
                            viewBox="0 0 731.4285714285713 1024"
                            className="meus-colaboradores-icon2"
                        >
                            <path d="M731.429 799.429c0 83.429-54.857 151.429-121.714 151.429h-488c-66.857 0-121.714-68-121.714-151.429 0-150.286 37.143-324 186.857-324 46.286 45.143 109.143 73.143 178.857 73.143s132.571-28 178.857-73.143c149.714 0 186.857 173.714 186.857 324zM585.143 292.571c0 121.143-98.286 219.429-219.429 219.429s-219.429-98.286-219.429-219.429 98.286-219.429 219.429-219.429 219.429 98.286 219.429 219.429z"></path>
                        </svg>
                        <input
                            type="text"
                            placeholder="Nome"
                            value={props.nomeNovoColaborador}
                            onChange={(e) => props.setnomeNovoColaborador(e.target.value)}
                            className="meus-colaboradores-textinput input"
                        />
                    </div>
                    <div className="meus-colaboradores-container13">
                        <svg viewBox="0 0 1024 1024" className="meus-colaboradores-icon4">
                            <path d="M854 342v-86l-342 214-342-214v86l342 212zM854 170q34 0 59 26t25 60v512q0 34-25 60t-59 26h-684q-34 0-59-26t-25-60v-512q0-34 25-60t59-26h684z"></path>
                        </svg>
                        <input
                            type="text"
                            placeholder="Email"
                            value={props.emailNovoColaborador}
                            onChange={(e) => props.setemailNovoColaborador(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    adicionarColaborador()
                                }
                            }}
                            className="meus-colaboradores-textinput1 input"
                        />
                    </div>
                </div>
                <div className="meus-colaboradores-container14">
                    <div className="meus-colaboradores-b-entrar" onClick={adicionarColaborador}>
                        <svg
                            viewBox="0 0 804.5714285714286 1024"
                            className="meus-colaboradores-icon6"
                        >
                            <path d="M804.571 420.571v109.714c0 30.286-24.571 54.857-54.857 54.857h-237.714v237.714c0 30.286-24.571 54.857-54.857 54.857h-109.714c-30.286 0-54.857-24.571-54.857-54.857v-237.714h-237.714c-30.286 0-54.857-24.571-54.857-54.857v-109.714c0-30.286 24.571-54.857 54.857-54.857h237.714v-237.714c0-30.286 24.571-54.857 54.857-54.857h109.714c30.286 0 54.857 24.571 54.857 54.857v237.714h237.714c30.286 0 54.857 24.571 54.857 54.857z"></path>
                        </svg>
                        <span className="meus-colaboradores-text20">Adicionar</span>
                    </div>
                    <span className="meus-colaboradores-text21">
                        <span onClick={() => props.setPopupColaboradorVisible(false)}>
                            Cancelar
                        </span>
                    </span>
                </div>
            </div>
        </div>
    )
}

export default ModalConsultoresHome