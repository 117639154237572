import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation, Link } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';

const Header = ({ usuarioAtual, showMenu, setShowMenu }) => {
    const history = useHistory();

    const header_geral = { Authorization: `Bearer ${localStorage.getItem("token")}` }
    const [showSearchPanel, setShowSearchPanel] = useState(false)
    const [dataSearchPanel, setDataSearchPanel] = useState([]);
    const [searching, setSearching] = useState(false)
    const [notificacoes, setNotificacoes] = useState([])
    const [qtdNotificacoes, setQtdNotificacoes] = useState(0)
    const [notificacaoVisible, setNotificacaoVisible] = useState(false)
    const isSuper = ['admin', 'consultor'].includes(usuarioAtual.tipo);

    useEffect(() => {
        let isMounted = true;
        if (!localStorage.getItem("token") || new Date(usuarioAtual.acesso) < new Date()) {
            if (isMounted) {
                if (usuarioAtual.trial && localStorage.getItem("token")) {
                    history.push("/assine-simbium");
                } else {
                    localStorage.removeItem("token")
                    dispatch(setUsuario(null))
                }
            }
        }

        return () => {
            isMounted = false;
        }
    }, [usuarioAtual])

    const searchAula = async (event, blur = false) => {
        const value = event.target.value || null
        if (value && value.length > 2) {
            if (blur === true) {
                setShowSearchPanel(true)
                return;
            }

            setSearching(true)
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/master/search/aulas/${value}`, { headers: header_geral });

                if (response.status === 200) {
                    setDataSearchPanel(response.data);
                } else {
                    toast.error("Erro na obtenção do conteúdo")
                    console.log(response)
                }
                setSearching(true)
            } catch (error) {
                toast.error("Erro na obtenção do conteúdo")
                console.log(error)
            }
            setSearching(false)
            setShowSearchPanel(true)
        } else {
            setShowSearchPanel(false)
        }
    }

    const scrollIndicacao = () => {
        history.push('/');
        setTimeout(() => {
            window.scrollTo({
                top: document.body.scrollHeight,
                behavior: 'smooth'
            });
        }, 500);
    };


    const formatDateDistance = (date) => {
        const currentDate = new Date();
        const creationDate = new Date(date);
        const timeDiff = currentDate - creationDate;

        const seconds = Math.floor(timeDiff / 1000);
        if (seconds < 60) {
            return `há ${seconds} segundos atrás`;
        }

        const minutes = Math.floor(timeDiff / (1000 * 60));
        if (minutes === 1) {
            return 'há 1 minuto atrás';
        } else if (minutes < 60) {
            return `há ${minutes} minutos atrás`;
        }

        const hours = Math.floor(timeDiff / (1000 * 60 * 60));
        if (hours === 1) {
            return 'há 1 hora atrás';
        } else if (hours < 24) {
            return `há ${hours} horas atrás`;
        }

        const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
        if (days === 1) {
            return 'há 1 dia atrás';
        } else if (days < 7) {
            return `há ${days} dias atrás`;
        }

        const weeks = Math.floor(timeDiff / (1000 * 60 * 60 * 24 * 7));
        if (weeks === 1) {
            return 'há 1 semana atrás';
        } else if (weeks < 4) {
            return `há ${weeks} semanas atrás`;
        }

        const months = Math.floor(timeDiff / (1000 * 60 * 60 * 24 * 30.44));
        if (months === 1) {
            return 'há 1 mês atrás';
        } else if (months < 12) {
            return `há ${months} meses atrás`;
        }

        const years = Math.floor(timeDiff / (1000 * 60 * 60 * 24 * 365.25));
        if (years === 1) {
            return 'há 1 ano atrás';
        } else {
            return `há ${years} anos atrás`;
        }
    }

    const lerNotificacao = async (id, link) => {
        try {
            // Faz a requisição para marcar a notificação como lida
            await axios.post(
                `${process.env.REACT_APP_BACKEND_BASE_URL}/notificacoes/ler`,
                { id },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                }
            );

            // Abre o link em uma nova aba
            window.open(link, '_blank');

        } catch (err) {
            console.error('Erro ao marcar a notificação como lida:', err);
        }
    };


    const toggleNotificacaoVisible = () => {
        setNotificacaoVisible(!notificacaoVisible)
    }

    useEffect(async () => {
        let isMounted = true;

        window.scrollTo(0, 0)

        await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/usuarios/usuario_atual`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
        }).then(res => {
            if (isMounted && res.data && res.data.acesso !== usuarioAtual.acesso) {
                dispatch(setUsuario(res.data));
            }

        }).catch(err => {

        })


        // if (usuarioAtual.tipo === 'usuario') {
        if (!localStorage.getItem("token") || new Date(usuarioAtual.acesso) < new Date()) {
            if (isMounted) {
                if (usuarioAtual.trial && localStorage.getItem("token")) {
                    history.push("/assine-simbium");
                } else {
                    localStorage.removeItem("token")
                    dispatch(setUsuario(null))
                }
            }
        }
        //}

        try {
            const notificacoes = await axios.get(process.env.REACT_APP_BACKEND_BASE_URL + '/notificacoes', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            if (isMounted) {
                setNotificacoes(notificacoes.data)
                setQtdNotificacoes(notificacoes.data.filter(n => !n.lida).length)
            }
        } catch (err) {
        }

        return () => {
            isMounted = false
        }

    }, [history]);

    return (
        <header className="lg:fixed w-screen z-30">
            <section className='w-full rounded-b-3xl md:rounded-b-none bg-neutral-800 text-gray-100 py-3 px-5 shadow-lg shadow-neutral-950'>
                <div className='w-full md:w-11/12 mx-auto flex justify-between gap-3'>
                    <div className='w-8 m-auto flex flex-col items-center align-middle space-y-1.5 group cursor-pointer' onClick={() => setShowMenu(!showMenu)}>
                        <div className='w-full h-1 rounded-xl bg-neutral-500 group-hover:bg-neutral-100 group-active:bg-neutral-200'></div>
                        <div className='w-full h-1 rounded-xl bg-neutral-500 group-hover:bg-neutral-100 group-active:bg-neutral-200'></div>
                        <div className='w-full h-1 rounded-xl bg-neutral-500 group-hover:bg-neutral-100 group-active:bg-neutral-200'></div>
                    </div>
                    <div className='w-full ml-2 md:w-1/2 flex justify-between items-center gap-2'>
                        <div className=''>
                            <div className='w-full flex justify-between items-center align-middle gap-5'>
                                <div
                                    className='flex items-center align-middle space-x-2 mb-1 cursor-pointer'
                                    onClick={() => { window.location.href = '/' }}>
                                    <img src="/image9-200h.png" className='h-6 float-left' />
                                    <p className='font-bold text-xl'>Simbium</p>
                                </div>


                                <div className='w-fit flex items-center gap-2'>
                                    {isSuper &&
                                        <div className='w-full'>
                                            <a href={`/${usuarioAtual.tipo}`}
                                                className='hidden md:block text-yellow-500 text-center text-sm hover:text-yellow-600 active:text-yellow-700'
                                            >
                                                {{ admin: "Administrador", consultor: "Consultor" }[usuarioAtual.tipo]}
                                            </a>


                                            <a href={`/${usuarioAtual.tipo}`} className='block md:hidden text-center text-sm'>
                                                {usuarioAtual.tipo === 'admin' ?
                                                    <svg className='w-5 h-5 fill-yellow-500 hover:fill-yellow-600 active:fill-yellow-700' viewBox="0 0 20 20">
                                                        <rect x="0" fill="none" width="20" height="20" /><g><path d="M16.95 2.58c1.96 1.95 1.96 5.12 0 7.07-1.51 1.51-3.75 1.84-5.59 1.01l-1.87 3.31-2.99.31L5 18H2l-1-2 7.95-7.69c-.92-1.87-.62-4.18.93-5.73 1.95-1.96 5.12-1.96 7.07 0zm-2.51 3.79c.74 0 1.33-.6 1.33-1.34 0-.73-.59-1.33-1.33-1.33-.73 0-1.33.6-1.33 1.33 0 .74.6 1.34 1.33 1.34z" /></g>
                                                    </svg>
                                                    :
                                                    <svg className='w-5 h-5 fill-yellow-500 hover:fill-yellow-600 active:fill-yellow-700' viewBox="0 0 512.003 512.003">
                                                        <g><g><g><path d="M256.001,238.426c65.738,0,119.219-53.48,119.219-119.219C375.221,53.475,321.739,0,256.001,0
				S136.782,53.475,136.782,119.207C136.782,184.946,190.263,238.426,256.001,238.426z"/><path d="M288.254,442.692h-64.506c-3.562,0-6.451,2.889-6.451,6.451v56.409c0,3.563,2.889,6.451,6.451,6.451h64.506
				c3.562,0,6.451-2.887,6.451-6.451v-56.409C294.705,445.58,291.816,442.692,288.254,442.692z"/><path d="M289.464,256.864c-4.425-0.848-8.908-1.531-13.442-2.044c-3.803-0.43-7.12,2.592-7.12,6.42v45.738
				c0,7.409-6.245,13.359-13.759,12.873c-6.857-0.444-12.043-6.444-12.043-13.315v-45.297c0-3.828-3.318-6.849-7.12-6.42
				c-4.533,0.512-9.017,1.196-13.442,2.044c-3.042,0.583-5.24,3.248-5.24,6.346v147.228c0,3.562,2.889,6.451,6.451,6.451h64.506
				c3.562,0,6.451-2.889,6.451-6.451V263.21C294.705,260.113,292.506,257.448,289.464,256.864z"/><path d="M182.371,269.691c-61.274,28.032-103.952,89.916-103.952,161.584v61.376c0,10.687,8.664,19.352,19.352,19.352h87.275
				c3.562,0,6.451-2.887,6.451-6.451V275.571C191.496,270.879,186.637,267.739,182.371,269.691z"/><path d="M329.632,269.689c-4.266-1.951-9.125,1.19-9.125,5.882v229.98c0,3.563,2.889,6.451,6.451,6.451h87.275
				c10.687,0,19.352-8.664,19.352-19.352v-61.376C433.584,359.605,390.906,297.721,329.632,269.689z"/></g></g></g>
                                                    </svg>
                                                }
                                            </a>
                                        </div>
                                    }
                                </div>
                            </div>
                            <h2 className='text-slate-300 text-xs truncate max-w-40 sm:max-w-none'>Bem vindo(a) <span className='font-bold'>{usuarioAtual.nome}</span></h2>
                        </div>
                        <div className="sm:relative" onClick={toggleNotificacaoVisible}>
                            <svg viewBox="0 0 1024 1024" className="cursor-pointer w-5 h-5 fill-neutral-300 hover:fill-neutral-200 active:fill-neutral-100">
                                <path
                                    d="M521.143 969.143c0-5.143-4-9.143-9.143-9.143-45.143 0-82.286-37.143-82.286-82.286 0-5.143-4-9.143-9.143-9.143s-9.143 4-9.143 9.143c0 55.429 45.143 100.571 100.571 100.571 5.143 0 9.143-4 9.143-9.143zM140.571 804.571h742.857c-102.286-115.429-152-272-152-475.429 0-73.714-69.714-182.857-219.429-182.857s-219.429 109.143-219.429 182.857c0 203.429-49.714 360-152 475.429zM987.429 804.571c0 40-33.143 73.143-73.143 73.143h-256c0 80.571-65.714 146.286-146.286 146.286s-146.286-65.714-146.286-146.286h-256c-40 0-73.143-33.143-73.143-73.143 84.571-71.429 182.857-199.429 182.857-475.429 0-109.714 90.857-229.714 242.286-252-2.857-6.857-4.571-14.286-4.571-22.286 0-30.286 24.571-54.857 54.857-54.857s54.857 24.571 54.857 54.857c0 8-1.714 15.429-4.571 22.286 151.429 22.286 242.286 142.286 242.286 252 0 276 98.286 404 182.857 475.429z"
                                    className=""
                                ></path>
                            </svg>
                            {notificacaoVisible && (
                                <div>
                                    {/* Fundo que cobre a tela toda */}
                                    <div
                                        className="fixed inset-0 bg-black opacity-50 z-20"
                                        onClick={() => setNotificacaoVisible(false)} // Fecha ao clicar no fundo
                                    />

                                    {/* Contêiner das notificações */}
                                    <div className="absolute z-30 bg-neutral-900 shadow-lg w-full sm:top-full sm:w-[50vw] md:w-[40vw] lg:w-[30vw] xl:w-[20vw] top-20 left-0 rounded-lg p-2">
                                        {notificacoes.length > 0 ? (
                                            notificacoes.map((notificacao, index) => (
                                                <div
                                                    onClick={() => { lerNotificacao(notificacao._id, notificacao.link) }}
                                                    key={index}
                                                    className={`flex items-center p-4 mb-4 bg-neutral-700 rounded-lg cursor-pointer transition-all duration-200 ease-in-out hover:bg-neutral-600 ${notificacao.lida ? 'opacity-70' : ''}`}
                                                >
                                                    <div className="flex-shrink-0 mr-4">
                                                        <img
                                                            alt="Imagem de notificação"
                                                            src={notificacao.foto}
                                                            className="w-12 h-12 rounded-full object-cover"
                                                        />
                                                    </div>
                                                    <div className="flex-grow">
                                                        <div className="flex items-center">
                                                            {/* Ícone desativado para este exemplo */}
                                                            {/* <div className="text-orange-500 mr-2" dangerouslySetInnerHTML={{ __html: notificacao.icone }} /> */}
                                                            <span className="font-semibold text-white">{notificacao.conteudo}</span>
                                                        </div>
                                                        <span className="text-neutral-50 text-sm">{formatDateDistance(notificacao.data_criacao)}</span>
                                                    </div>
                                                    {!notificacao.lida && (
                                                        <div className="ml-4 bg-orange-500 w-3 h-3 rounded-full" />
                                                    )}
                                                </div>
                                            ))
                                        ) : (
                                            <div className="text-center text-gray-50">Nenhuma notificação no momento.</div>
                                        )}
                                    </div>
                                </div>
                            )}


                            {qtdNotificacoes > 0 && (
                                <div className="relative w-4 h-4 p-1 -top-4 left-3 flex items-center justify-center rounded-full bg-orange-500 select-none">
                                    <span className="text-white text-xs">{qtdNotificacoes}</span>
                                </div>
                            )}
                        </div>

                    </div>
                    <div className='w-auto md:w-1/2 flex flex-row gap-5 justify-between'>
                        <div className='hidden md:flex items-center w-2/3'>
                            <input type='text' placeholder='Pesquisar conteúdos (03 caracteres no mínimo)' className='w-full rounded-lg bg-neutral-800 focus:bg-neutral-700 focus:outline-none focus:border-none focus:shadow-none focus:ring-1 focus:ring-orange-500' onKeyDown={(e) => searchAula(e)} onFocus={(e) => searchAula(e, true)} />
                        </div>
                        <a target="_blank" href={`${process.env.REACT_APP_COMUNIDADE_URL}`} className='text-sm hidden sm:flex items-center hover:text-orange-500'>Acesse a Comunidade</a>
                        <a href="/meu-perfil" className="rounded-full bg-neutral-950 w-[50px] h-[50px] border border-orange-500 overflow-hidden flex items-center justify-center">
                            <img src={usuarioAtual.foto_perfil || '/sem-foto.png'} className='object-cover w-full h-full hover:scale-105 active:scale-95' alt="Foto do perfil"></img>
                        </a>
                    </div>
                </div>
            </section>

            {showSearchPanel &&
                <section className="fixed w-screen h-screen flex justify-center">
                    <div className="bg-neutral-950 opacity-90 z-10 w-full h-full" onClick={() => setShowSearchPanel(false)}></div>
                    <div className="absolute top-0  mt-5 p-5 z-20 rounded-xl w-11/12 bg-neutral-700">
                        <div className='text-neutral-50'>
                            {searching ?
                                <div>Buscando dados...</div>
                                :
                                <div>
                                    {dataSearchPanel.length === 0 ?
                                        <p>Nenhum conteúdo encontrado...</p>
                                        :
                                        <div className='flex flex-col divide-y divide-neutral-500'>
                                            {dataSearchPanel?.map((aula, index) => {
                                                return (
                                                    <a key={index} className='w-full py-5 hover:bg-neutral-500' href={`/master/aula/${aula.pagina_codigo}/${aula.curso_codigo}/${aula.codigo}`}>{aula.titulo}</a>
                                                )
                                            })}
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </section>
            }

        </header>
    )
}

export default Header