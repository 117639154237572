import React from 'react';

const PhonesInput = ({ customer, customerErrors, setCustomer, setCustomerErrors }) => {
    const formatPhone = (phone) => {
        // Formata o número de telefone para (XX) XXXX-XXXX ou (XX) 9XXXX-XXXX
        return phone
            .replace(/\D/g, '') // Remove caracteres não numéricos
            .replace(/^(\d{2})(\d)/g, '($1) $2') // Adiciona parênteses ao DDD
            .replace(/(\d)(\d{4})$/, '$1-$2'); // Adiciona o hífen ao número
    };

    const validatePhone = (phone) => {
        // Remove caracteres não numéricos
        const rawPhone = phone.replace(/\D/g, '');
        // Verifica se o telefone possui 10 ou 11 dígitos
        return rawPhone.length === 10 || rawPhone.length === 11;
    };

    const handlePhoneChange = (e) => {
        const { value } = e.target;

        // Remove caracteres não numéricos para armazenamento
        const rawPhone = value.replace(/\D/g, '');
        console.log("PHONE NUMBER CARACTERES NÃO NUMÉRICOS: ", rawPhone)

        // Extrai o código de área e o número
        const areaCode = rawPhone.slice(0, 2);
        const number = rawPhone.slice(2);

        // Formata o número de telefone para exibição
        const formattedPhone = formatPhone(rawPhone);

        // Valida o número de telefone
        const isValid = validatePhone(rawPhone);

        // Atualiza o estado do cliente
        setCustomer((prevCustomer) => ({
            ...prevCustomer,
            phones: {
                home_phone: { country_code: '55', area_code: areaCode, number: number },
                mobile_phone: { country_code: '55', area_code: areaCode, number: number }
            }
        }));

        // Atualiza os erros do cliente
        setCustomerErrors((prevErrors) => ({
            ...prevErrors,
            phones: isValid ? '' : 'Número de celular inválido'
        }));
    };

    return (
        <input
            type="text"
            id="phone"
            name="phone"
            value={formatPhone(`${customer.phones.mobile_phone.area_code}${customer.phones.mobile_phone.number}`)}
            onChange={handlePhoneChange}
            required
            minLength={14}
            maxLength={15}
            className='w-full text-sm p-2 rounded-md bg-neutral-700 focus:bg-neutral-700 focus:outline-none focus:border-none focus:shadow-none focus:ring-1 focus:ring-orange-500'
        />
    );
};

export default PhonesInput;
