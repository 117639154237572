import React, { useEffect, useState } from 'react';
import axios from 'axios';
import DocumentInput from '../form/document-input';
import PhonesInput from '../form/phones-input';

const ModalCompraFormularioIdentificacao = ({ usuarioAtual, customer, setCustomer, setBillingAddress, customerErrors, setCustomerErrors }) => {
    useEffect(() => {
        let isMounted = true;

        const fetchCustomer = async () => {
            try {
                const consumidorId = usuarioAtual.consumidorId;

                if (!consumidorId) return;

                const response = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/pagarme/get-customer/`, {
                    consumidorId: consumidorId,
                    apiKey: process.env.REACT_APP_PAGARME_TOKEN,
                }, {
                    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
                });

                if (isMounted && response.data) {
                    // console.log(response.data.message);
                    // console.log("Dados recebidos do servidor: ", response.data.data);

                    const { address, ...customer } = response.data.data;
                    setCustomer(customer);
                    setBillingAddress(address);
                } else {
                    console.log("Resposta do servidor sem dados válidos: ", response);
                }

            } catch (error) {
                console.log("Erro ao buscar dados do cliente: ", error.message);
            }
        };

        fetchCustomer();

        return () => {
            isMounted = false;
        };
    }, [usuarioAtual.consumidorId, setCustomer]);

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return email.length && emailRegex.test(email);
    };

    const isValidName = (name) => {
        return name.length && name.split(' ').length >= 2;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        let errors = {};

        if (name === "name" && !isValidName(value)) {
            errors.name = "Nome não pode ser vazio!";
        }

        if (name === "email" && !isValidEmail(value)) {
            errors.email = "E-mail inválido";
        }

        // Atualiza os erros se houverem
        if (Object.keys(errors).length > 0) {
            setCustomerErrors((prevErrors) => ({
                ...prevErrors,
                ...errors
            }));
        } else {
            // Limpa os erros específicos
            setCustomerErrors((prevErrors) => ({
                ...prevErrors,
                [name]: ""
            }));
        }

        // Atualiza o estado do customer
        setCustomer(prevCustomer => ({
            ...prevCustomer,
            [name]: value
        }));
    };

    return (
        <div className='w-full mt-5 space-y-5'>
            <h2 className='font-semibold uppercase text-xl'>Identificação</h2>
            <div className='flex flex-col lg:flex-row gap-5'>
                <div className='flex flex-col w-full lg:w-1/2 gap-2'>
                    <label htmlFor="name" className='text-xs md:text-sm'>Nome Completo:</label>
                    <input type="text" id="name" name="name" value={customer.name} onChange={handleInputChange} required className='w-full text-sm p-2 rounded-md bg-neutral-700 focus:bg-neutral-700 focus:outline-none focus:border-none focus:shadow-none focus:ring-1 focus:ring-orange-500' />
                    {customerErrors.name && <p className='text-red-500 text-xs md:text-sm pt-1 pb-2'>{customerErrors.name}</p>}
                </div>
                <div className='flex flex-col w-full lg:w-1/2 gap-2'>
                    <label htmlFor="email" className='text-xs md:text-sm'>E-mail:</label>
                    <input type="email" id="email" name="email" value={customer.email} onChange={handleInputChange} required className='w-full text-sm p-2 rounded-md bg-neutral-700 focus:bg-neutral-700 focus:outline-none focus:border-none focus:shadow-none focus:ring-1 focus:ring-orange-500' />
                    {customerErrors.email && <p className='text-red-500 text-xs md:text-sm pt-1 pb-2'>{customerErrors.email}</p>}
                </div>
            </div>
            <div className='flex flex-col lg:flex-row gap-5'>
                <div className='flex flex-col w-full lg:w-1/2 gap-2'>
                    <label htmlFor="document" className='text-xs md:text-sm'>CPF ou CNPJ:</label>
                    <DocumentInput
                        customer={customer}
                        customerErrors={customerErrors}
                        setCustomer={setCustomer}
                        setCustomerErrors={setCustomerErrors}
                    />
                    {customerErrors.document && <p className='text-red-500 text-xs md:text-sm pt-1 pb-2'>{customerErrors.document}</p>}
                </div>

                <div className='flex flex-col w-full lg:w-1/2 gap-2'>
                    <label htmlFor="phone" className='text-xs md:text-sm'>Número de Celular:</label>
                    <PhonesInput
                        customer={customer}
                        customerErrors={customerErrors}
                        setCustomer={setCustomer}
                        setCustomerErrors={setCustomerErrors}
                    />
                    {customerErrors.phones && <p className='text-red-500 text-xs md:text-sm pt-1 pb-2'>{customerErrors.phones}</p>}
                </div>
            </div>
        </div>
    );
}

export default ModalCompraFormularioIdentificacao;
