import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios'
import { Link, useLocation } from 'react-router-dom'

import './lista-outros-cursos.css'


const ListaOutrosCursos = (props) => {

  const [cursoInit, setCursoInit] = useState(0);
  const [cursosNext, setCursosNext] = useState(false)
  const [cursos, setCursos] = useState([]);
  const [carregado, setCarregado] = useState(false)

  const handleScroll = async (e) => {
    setTimeout(() => {
      const element = e.target;
      const scrollOffset = element.scrollWidth - element.clientWidth;
      const currentScroll = element.scrollLeft;

      const scrollPercentage = (currentScroll / scrollOffset) * 100;

      if (scrollPercentage >= 90) {
        setCursoInit(cursos?.cursos?.length)
      }
    }, 200)
  };

  useEffect(() => {

    setCursoInit(0)

  }, [props.cursoId]);


  useEffect(() => {
    let isMounted = true;

    const fetchCursos = async (cInicial) => {

      if (!cursosNext && carregado && window.innerWidth < 479) return;
      let slides;

      if (!carregado || window.innerWidth >= 479) {
        let element = document.querySelector(".l-cont")
        if (!element) {
          slides = 2;
        } else {
          slides = Math.ceil(element.clientWidth / 200);
        }

        setCarregado(true)
      } else {
        slides = 2
      }

      try {
        const response = await axios.get(process.env.REACT_APP_BACKEND_BASE_URL + '/cursos', {
          params: {
            quantidade: slides,
            aPartirDe: cInicial,
            trilha: false,
            excluirCursoId: props.cursoId,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        });

        if (response.data && response.data.cursos && isMounted) {
          if (window.innerWidth >= 479) {
            setCursos(response.data)
          } else {
            setCursos(prevState => ({
              ...prevState,
              cursos: [...(prevState.cursos || []), ...response.data.cursos]
            }));
          }

          setCursosNext((response.data?.metadata?.totalCursos - (cInicial + slides - 1)) > 0)
        }

      } catch (error) {
      }
    };

    fetchCursos(cursoInit)

    return () => {
      isMounted = false
    }

  }, [cursoInit]);


  return (
    <div className={`lista-outros-cursos-descricao`}>
      <div className="lista-outros-cursos-container">
        <svg viewBox="0 0 1024 1024" className="lista-outros-cursos-icon">
          <path
            d="M766 726l-34-142 110-96-144-12-58-134-58 134-144 12 110 96-34 142 126-74zM854 256q34 0 59 26t25 60v426q0 34-25 60t-59 26h-684q-34 0-59-26t-25-60v-512q0-34 25-60t59-26h256l86 86h342z"
            className=""
          ></path>
        </svg>
        <span className="lista-outros-cursos-text">Outros Treinamentos</span>
      </div>
      <div className="lista-outros-cursos-container1"
        onScroll={(e) => handleScroll(e)}
      >
        {
          cursoInit > 0 && window.innerWidth > 479 && (
            <div className="home-prev2" onClick={() => setCursoInit(cursoInit - 1)}>
              <svg
                viewBox="0 0 694.8571428571428 1024"
                className="home-icon18"
              >
                <path d="M632.571 501.143l-424 424c-14.286 14.286-37.143 14.286-51.429 0l-94.857-94.857c-14.286-14.286-14.286-37.143 0-51.429l303.429-303.429-303.429-303.429c-14.286-14.286-14.286-37.143 0-51.429l94.857-94.857c14.286-14.286 37.143-14.286 51.429 0l424 424c14.286 14.286 14.286 37.143 0 51.429z"></path>
              </svg>
            </div>
          )}
        {cursos.cursos && (
          cursos.cursos.map((curso, index) => (
            <Link key={index} to={`/curso/${curso.slug}`} className="lista-outros-cursos-navlink">
              <div className="lista-outros-cursos-card">
                <img
                  alt="image"
                  src={curso.capa}
                  className="lista-outros-cursos-image"
                />
                <div className="lista-outros-cursos-info-curso">
                  <span className="lista-outros-cursos-text1">
                    {curso.nome}
                  </span>
                  <span className="lista-outros-cursos-text2">{curso.aulas} aulas</span>
                </div>
              </div>
            </Link>
          ))
        )}
        {
          cursosNext && window.innerWidth > 479 && (
            <div className="lista-outros-cursos-next" onClick={() => setCursoInit(cursoInit + 1)}>
              <svg
                viewBox="0 0 694.8571428571428 1024"
                className="lista-cursos-icon2"
              >
                <path
                  d="M632.571 501.143l-424 424c-14.286 14.286-37.143 14.286-51.429 0l-94.857-94.857c-14.286-14.286-14.286-37.143 0-51.429l303.429-303.429-303.429-303.429c-14.286-14.286-14.286-37.143 0-51.429l94.857-94.857c14.286-14.286 37.143-14.286 51.429 0l424 424c14.286 14.286 14.286 37.143 0 51.429z"
                  className=""
                ></path>
              </svg>
            </div>
          )}
      </div>
    </div>
  )
}

export default ListaOutrosCursos
