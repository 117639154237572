import React, { useEffect, useState } from 'react';
import axios from 'axios';

const ModalCompraFormularioRevisao = ({ usuarioAtual, customer, billingAddress, paymentMethod, card, calcTotal, installments, tarifas, selectedInstallment, toMoney }) => {
    const paymentTitle = {
        credit: "Cartão de Crédito",
        debit: "Cartão de Débito",
        ticket: "Boleto"
    };
    const formatcpf = (cpf) => {
        return cpf.replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d{1,2})$/, '$1-$2');
    };

    const formatcnpj = (cnpj) => {
        return cnpj.replace(/(\d{2})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d)/, '$1/$2')
            .replace(/(\d{4})(\d{1,2})$/, '$1-$2');
    };

    const formatDocument = (type, document) => {
        if (type === 'cpf') return formatcpf(document)
        else return formatcnpj(document)
    }

    const formatPhone = (phone) => {
        // Formata o número de telefone para (XX) XXXX-XXXX ou (XX) 9XXXX-XXXX
        return phone
            .replace(/\D/g, '') // Remove caracteres não numéricos
            .replace(/^(\d{2})(\d)/g, '($1) $2') // Adiciona parênteses ao DDD
            .replace(/(\d)(\d{4})$/, '$1-$2'); // Adiciona o hífen ao número
    };

    const formatZipCode = (formattedZipCode) => {
        if (!formattedZipCode) return '';
        if (formattedZipCode.length > 8) {
            formattedZipCode = formattedZipCode.slice(0, 8); // Limita a 8 dígitos
        }
        if (formattedZipCode.length === 8) {
            formattedZipCode = formattedZipCode.replace(/^(\d{2})(\d{3})(\d{3})$/, '$1.$2-$3');
        }
        return formattedZipCode;
    };

    const formatCardNumber = (number = '') => {
        let cleanedNumber = number.replace(/[^0-9*]/g, '');
        let formattedNumber = cleanedNumber.replace(/(.{4})/g, '$1 ').trim();
        return formattedNumber;
    };
    return (
        <div className='w-full mt-5 space-y-5'>
            <h2 className='font-semibold uppercase text-xl'>Identificação</h2>
            <div className='border border-neutral-500 p-2 md:p-5 flex justify-between align-middle items-center rounded-lg'>
                <div className='flex flex-col gap-3 text-neutral-400'>
                    <h3 className='text-neutral-200'>{customer.name}</h3>
                    <p className='text-xs'>{customer.email}</p>
                    <p className='text-xs'>{formatDocument(customer.document_type, customer.document)}</p>
                    <p className='text-xs'>{formatPhone(customer.phones.home_phone.area_code + customer.phones.home_phone.number)}</p>
                </div>
                <div>
                    <svg className='w-10 h-10 fill-neutral-200' fill="#000000" viewBox="0 0 575.616 575.616">
                        <g>
                            <g>
                                <path d="M429.248,141.439C429.248,63.33,365.985,0,287.808,0c-78.109,0-141.439,63.33-141.439,141.439    c0,78.11,63.33,141.439,141.439,141.439C365.988,282.878,429.248,219.549,429.248,141.439z M181.727,144.499    c0,0-4.079-40.12,24.82-70.72c20.34,20.389,81.261,70.72,187.342,70.72c0,58.498-47.586,106.081-106.081,106.081    S181.727,202.994,181.727,144.499z" />
                                <path d="M45.049,391.68v62.559v80.919c0,22.365,18.136,40.459,40.459,40.459h404.6c22.365,0,40.459-18.097,40.459-40.459v-80.919    V391.68c0-44.688-36.193-80.919-80.919-80.919H377.91c-5.07,0-11.46,3.422-14.271,7.639l-70.735,99.982    c-2.812,4.22-7.372,4.22-10.184,0l-70.738-99.986c-2.812-4.22-9.202-7.638-14.272-7.638h-71.742    C81.319,310.758,45.049,346.991,45.049,391.68z" />
                            </g>
                        </g>
                    </svg>
                </div>
            </div>

            <h2 className='font-semibold uppercase text-xl'>Endereço de Cobrança</h2>

            <div className='border border-neutral-500 p-2 md:p-5 flex justify-between align-middle items-center rounded-lg'>
                <div className='flex flex-col gap-3 text-neutral-400'>
                    <h3 className='text-neutral-200'>{billingAddress.street + ', ' + billingAddress.number + ', ' + billingAddress.complement}</h3>
                    <p className='text-xs'>{billingAddress.neighborhood}</p>
                    <p className='text-xs'>{formatZipCode(billingAddress.zip_code)}</p>
                    <p className='text-xs'>{billingAddress.city + ' - ' + billingAddress.state + ' - ' + billingAddress.country}</p>
                </div>
                <div>
                    <svg className='w-10 h-10 fill-neutral-200' viewBox="0 0 24 24">
                        <path fillRule="evenodd" clipRule="evenodd" d="M2.5192 7.82274C2 8.77128 2 9.91549 2 12.2039V13.725C2 17.6258 2 19.5763 3.17157 20.7881C4.34315 22 6.22876 22 10 22H14C17.7712 22 19.6569 22 20.8284 20.7881C22 19.5763 22 17.6258 22 13.725V12.2039C22 9.91549 22 8.77128 21.4808 7.82274C20.9616 6.87421 20.0131 6.28551 18.116 5.10812L16.116 3.86687C14.1106 2.62229 13.1079 2 12 2C10.8921 2 9.88939 2.62229 7.88403 3.86687L5.88403 5.10813C3.98695 6.28551 3.0384 6.87421 2.5192 7.82274ZM9 17.25C8.58579 17.25 8.25 17.5858 8.25 18C8.25 18.4142 8.58579 18.75 9 18.75H15C15.4142 18.75 15.75 18.4142 15.75 18C15.75 17.5858 15.4142 17.25 15 17.25H9Z" />
                    </svg>
                </div>
            </div>

            <h2 className='font-semibold uppercase text-xl'>Pagamento</h2>

            <div className='border border-neutral-500 p-2 md:p-5 flex justify-between align-middle items-center rounded-lg'>
                <div className='flex flex-col gap-3 text-neutral-400'>
                    <h3 className='text-neutral-200'>
                        {paymentTitle[paymentMethod]}
                    </h3>
                    {paymentMethod === 'ticket' ?
                        <p className='text-xs'>{toMoney(calcTotal + tarifas)}</p>
                        :
                        <div className='flex flex-col gap-3 text-neutral-400'>
                            <p className='text-xs'>{card.brand.toUpperCase()}</p>
                            <p className='text-xs'>{formatCardNumber(card.id ? card.first_six_digits + '******' + card.last_four_digits : card.number)}</p>
                            <p className='text-xs'>{selectedInstallment.number < 9 ? '0' + installments[selectedInstallment].number : installments[selectedInstallment].number} x {toMoney(installments[selectedInstallment].total)}</p>
                        </div>
                    }
                </div>
                <div>
                    <svg className="w-10 h-10 fill-none" viewBox="0 0 24 24">
                        <rect x="3" y="6" width="18" height="13" rx="2" stroke="#CCCCCC" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M3 10H20.5" stroke="#CCCCCC" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M7 15H9" stroke="#CCCCCC" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
            </div>
        </div>
    )
}

export default ModalCompraFormularioRevisao