import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify';
import axios from 'axios'
import styles from './configuracoes.module.css'
import { debounce } from 'lodash';


const TabelaConfiguracoes = (props) => {

  const [config, setConfig] = useState({
    whatsapp: '',
    boas_vindas: {
      url: '',
      guid: '',
      thumb: ''
    },
    premiacoes: {
      indicacao: '',
      iniciar_curso: '',
      concluir_aula: '',
      escrever_nota: '',
      favoritar_aula: '',
      completou_onboarding: ''
    }
  })

  const [loading, setLoading] = useState(false)
  const [video, setVideo] = useState('')
  const [videosPesquisa, setVideosPesquisa] = useState('');

  const fetchVideosPesquisa = async (searchBunny) => {
    setVideo('')
    setVideosPesquisa(["loading"]);

    if (searchBunny !== '') {
      try {

        const response = await axios.get(`https://video.bunnycdn.com/library/${process.env.REACT_APP_BUNNY_STREAM_LIBRARY_ID}/videos`, {
          params: {
            page: 1,
            itemsPerPage: 4,
            orderBy: 'date',
            search: searchBunny
          },
          headers: {
            accept: 'application/json',
            AccessKey: `${process.env.REACT_APP_BUNNY_STREAM_TOKEN}`
          }
        })


        if (response.data && response.data.items && response.data.items.length > 0) {
          const items = response.data.items.map(item => {
            item.capa = `https://vz-01910c8b-ac9.b-cdn.net/${item?.guid}/thumbnail.jpg`
            item.frame = `<div style="position:relative;padding-top:56.25%;"><iframe src="https://iframe.mediadelivery.net/embed/${process.env.REACT_APP_BUNNY_STREAM_LIBRARY_ID}/${item?.guid}?autoplay=true&loop=false&muted=false&preload=true" loading="lazy" style="border:none;position:absolute;top:0;height:100%;width:100%;" allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;" allowfullscreen="true"></iframe></div>`;
            return item;
          })
          setVideosPesquisa(items.slice(0, 2))
        } else {
          setVideosPesquisa([])
        }

      } catch (err) {
      }
    } else {
      setVideosPesquisa([])
    }
  }

  const debouncedFetchVideosPesquisa = debounce(fetchVideosPesquisa, 300)

  const formatarTelefone = (input) => {
    const numericValue = input.replace(/\D/g, '');

    const maxLength = 11;
    if (numericValue.length > maxLength) {
      return config.whatsapp;
    }

    let formattedValue = '';

    for (let i = 0; i < numericValue.length; i++) {
      if (i === 0) {
        formattedValue += `(${numericValue[i]}`;
      } else if (i === 2) {
        formattedValue += `) ${numericValue[i]}`;
      } else if (i === 6 && numericValue.length <= 10) {
        formattedValue += `-${numericValue[i]}`;
      } else if (i === 7 && numericValue.length === 11) {
        formattedValue += `-${numericValue[i]}`;
      } else {
        formattedValue += numericValue[i];
      }
    }

    return formattedValue;
  };

  const handleTelefoneChange = (e) => {
    const inputValue = e.target.value;
    const formattedValue = formatarTelefone(inputValue);

    setConfig((prevState) => ({
      ...prevState,
      whatsapp: formattedValue
    }));
  };

  const salvarConfig = async () => {

    try {
      setLoading(true)
      if (video && video.guid && video.frame) {

        let newConfig = {
          ...config,
          boas_vindas: {
            ...config.boas_vindas,
            guid: video.guid,
            url: video.frame,
            thumb: video.capa
          }
        }

        setConfig(newConfig)


        const response = axios.post(process.env.REACT_APP_BACKEND_BASE_URL + '/config', newConfig, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        })

        toast.success("Configurações salvas com sucesso!")


      } else {
        const response = axios.post(process.env.REACT_APP_BACKEND_BASE_URL + '/config', config, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        })

        toast.success("Configurações salvas com sucesso!")
      }

    } catch (err) {

    }

    setLoading(false)
  }

  const getConfig = async () => {
    try {

      const response = await axios.get(process.env.REACT_APP_BACKEND_BASE_URL + '/config', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      })

      if (response.data) {
        delete response.data._id;
        setConfig(response.data)
      }

    } catch (err) {

    }
  }

  useEffect(() => {
    getConfig()
  }, [])

  return (
    <div className={styles['tabela-aulas']}>
      <div className={styles['container']}>
        <span className={styles['text']}>Configurações</span>
        <button disabled={loading} className={styles['btn-add']} onClick={salvarConfig}>
          <svg viewBox="0 0 1024 1024" className={styles['icon']}>
            <path d="M933.79 610.25c-53.726-93.054-21.416-212.304 72.152-266.488l-100.626-174.292c-28.75 16.854-62.176 26.518-97.846 26.518-107.536 0-194.708-87.746-194.708-195.99h-201.258c0.266 33.41-8.074 67.282-25.958 98.252-53.724 93.056-173.156 124.702-266.862 70.758l-100.624 174.292c28.97 16.472 54.050 40.588 71.886 71.478 53.638 92.908 21.512 211.92-71.708 266.224l100.626 174.292c28.65-16.696 61.916-26.254 97.4-26.254 107.196 0 194.144 87.192 194.7 194.958h201.254c-0.086-33.074 8.272-66.57 25.966-97.218 53.636-92.906 172.776-124.594 266.414-71.012l100.626-174.29c-28.78-16.466-53.692-40.498-71.434-71.228zM512 719.332c-114.508 0-207.336-92.824-207.336-207.334 0-114.508 92.826-207.334 207.336-207.334 114.508 0 207.332 92.826 207.332 207.334-0.002 114.51-92.824 207.334-207.332 207.334z"></path>
          </svg>
          <span className={styles['text01']}>{!loading ? 'Salvar Configurações' : 'Salvando...'}</span>
        </button>
      </div>
      <div className={styles['container01']}>
        <div className={styles['form']}>
          <span className={styles['text02']}>Dados Simbium</span>
          <div className={styles['container02']}>
            <span className={styles['text03']}>WhatsApp Suporte</span>
            <input type="text" value={config.whatsapp} onChange={handleTelefoneChange} className={styles['input-dark']} />
          </div>
          <div className={styles['container03']}>
            <span className={styles['text04']}>Vídeo Boas Vindas</span>
            <input type="text" className={styles['input-dark']}
              onChange={(e) => {
                debouncedFetchVideosPesquisa(e.target.value);
              }}
            />
            <div className={styles["resultados"]}>
              {videosPesquisa.length > 0 && videosPesquisa[0] === 'loading' && (
                <div style={{ fontSize: '13px', color: '#9f9f9f' }}>Carregando...</div>
              )}
              {videosPesquisa.length > 0 && videosPesquisa[0] !== 'loading' && videosPesquisa.map((v, index) => (
                <div key={index} className={styles["resultado"]} onClick={() => {
                  if (video.guid && video.guid === v.guid) {
                    setVideo('')
                  } else {
                    setVideo(v)
                  }
                }}>
                  <img src={v.capa} style={video.guid && v.guid === video.guid ? { border: '5px solid #E86817' } : {}} />
                  <p>{v.title}</p>
                </div>))}
            </div>
          </div>
        </div>
        <div className={styles['form1']}>
          <span className={styles['text05']}>Premiações</span>
          <div className={styles['container04']}>
            <span className={styles['text06']}>Indicação</span>
            <input type="number" value={config.premiacoes.indicacao} onChange={(e) => {

              const newConfig = {
                ...config,
                premiacoes: {
                  ...config.premiacoes,
                  indicacao: e.target.value
                }
              }

              setConfig(newConfig)


            }} className={styles['input-dark']} />
          </div>
          <div className={styles['container05']}>
            <span className={styles['text07']}>Iniciar curso</span>
            <input type="number" value={config.premiacoes.iniciar_curso} onChange={(e) => {

              const newConfig = {
                ...config,
                premiacoes: {
                  ...config.premiacoes,
                  iniciar_curso: e.target.value
                }
              }

              setConfig(newConfig)

            }} className={styles['input-dark']} />
          </div>
          <div className={styles['container06']}>
            <span className={styles['text08']}>Concluir aula</span>
            <input type="number" value={config.premiacoes.concluir_aula} onChange={(e) => {

              const newConfig = {
                ...config,
                premiacoes: {
                  ...config.premiacoes,
                  concluir_aula: e.target.value
                }
              }

              setConfig(newConfig)
            }} className={styles['input-dark']} />
          </div>
          <div className={styles['container07']}>
            <span className={styles['text09']}>Escrever nota</span>
            <input type="number" value={config.premiacoes.escrever_nota} onChange={(e) => {

              const newConfig = {
                ...config,
                premiacoes: {
                  ...config.premiacoes,
                  escrever_nota: e.target.value
                }
              }

              setConfig(newConfig)

            }} className={styles['input-dark']} />
          </div>
          <div className={styles['container08']}>
            <span className={styles['text10']}>Favoritar aula</span>
            <input type="number" value={config.premiacoes.favoritar_aula} onChange={(e) => {

              const newConfig = {
                ...config,
                premiacoes: {
                  ...config.premiacoes,
                  favoritar_aula: e.target.value
                }
              }

              setConfig(newConfig)

            }} className={styles['input-dark']} />
          </div>
          <div className={styles['container09']}>
            <span className={styles['text11']}>Completou onboarding</span>
            <input type="number" value={config.premiacoes.completou_onboarding} onChange={(e) => {

              const newConfig = {
                ...config,
                premiacoes: {
                  ...config.premiacoes,
                  completou_onboarding: e.target.value
                }
              }

              setConfig(newConfig)

            }} className={styles['input-dark']} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default TabelaConfiguracoes
