//features/usuario/usuarioAtual.js
import { createSlice } from '@reduxjs/toolkit';

const moduloAtualSlice = createSlice({
  name: 'moduloAtual',
  initialState: null,
  reducers: {
    setModulo: (state,action) => {
      return action.payload
    }
  },
});

export const { setModulo } = moduloAtualSlice.actions;
export default moduloAtualSlice.reducer;
