import React, { useState, useEffect } from 'react'
import axios from 'axios'
import styles from './historico_comissoes.module.css'


const PopupHistoricoComissoes = (props) => {
  const [adminMode, setAdminMode] = useState(props.adminMode && props.adminMode === 'true');
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [totalPaginas, setTotalPaginas] = useState(1);
  const [whatsApp, setWhatsApp] = useState('');

  const calcularIndicesResultados = () => {
    const inicio = (paginaAtual - 1) * 4;
    const fim = inicio + 4;
    return { inicio, fim };
  };

  const proximaPagina = () => {
    if (paginaAtual < totalPaginas) {
      setPaginaAtual(paginaAtual + 1);
    }
  };

  const paginaAnterior = () => {
    if (paginaAtual > 1) {
      setPaginaAtual(paginaAtual - 1);
    }
  };

  const { inicio, fim } = calcularIndicesResultados();

  const efetuarPagamento = async () => {
    if (!props.uid) return

    try {

      const response = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/usuarios/pagar-comissao`, {
        uid: props.uid
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      })


      props.getUsuarios()
      props.fecharPopup()
      props.comissaoPaga()

    } catch (err) {

    }
  };


  useEffect(() => {
    if (props.indicacoes && props.indicacoes.length > 0) {
      const totalPages = Math.ceil(props.indicacoes.length / 4);
      setTotalPaginas(totalPages);
    }
  }, [props.indicacoes]);

  useEffect(() => {
    let isMounted = true

    const getWhatsApp = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_BACKEND_BASE_URL + "/config", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        })


        if (isMounted && response.data && response.data.whatsapp) {
          setWhatsApp(response.data.whatsapp.replaceAll(/\D/g, ""));
        }

      } catch (err) {

      }
    }

    getWhatsApp()

    return () => {
      isMounted = false
    }
  }, [])

  return (
    <div className={styles['container']}>
      <div className={styles['container1']}>
        <div className={styles['head']}>
          <span className={styles['text']}>Histórico de Comissões</span>
          <div className={styles['fechar-popup']} onClick={props.fecharPopup}>
            <svg
              viewBox="0 0 804.5714285714286 1024"
              className={styles['icon']}
            >
              <path d="M741.714 755.429c0 14.286-5.714 28.571-16 38.857l-77.714 77.714c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-168-168-168 168c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-77.714-77.714c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l168-168-168-168c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l77.714-77.714c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l168 168 168-168c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l77.714 77.714c10.286 10.286 16 24.571 16 38.857s-5.714 28.571-16 38.857l-168 168 168 168c10.286 10.286 16 24.571 16 38.857z"></path>
            </svg>
          </div>
        </div>
        <div className={styles['opcoes']}>
          <div className={styles['tabela']}>
            <div className={styles['not-empty']}>
              <div className={styles['container2']}>
                <div className={styles['container3']}>
                  <span className={styles['text01']}>Data</span>
                  <span className={styles['text02']}>Indicado</span>
                  <span className={styles['text03']}>Comissão</span>
                </div>
                <div className={styles['infos']}>
                  {props.indicacoes &&
                    props.indicacoes.length > 0 &&
                    props.indicacoes.slice(inicio, fim).map((indicacao, index) => {
                      const dataUmAnoAntes = new Date(indicacao.acesso);
                      dataUmAnoAntes.setFullYear(dataUmAnoAntes.getFullYear() - 1);

                      return (
                        <div key={index} className={styles['info']}>
                          <span className={styles['text04']}>
                            {dataUmAnoAntes.toLocaleDateString('pt-br')}
                          </span>
                          <span className={styles['text05']}>{indicacao.email}</span>
                          <span className={styles['text06']}>R$ 500,00</span>
                        </div>
                      );
                    })}
                </div>
              </div>
              {props.indicacoes && props.indicacoes.length > 5 && (

                <div className={styles['paginao']}>
                  <svg viewBox="0 0 768 1024" className={styles['icon2']} onClick={paginaAnterior}>
                    <path d="M669.143 172l-303.429 303.429 303.429 303.429c14.286 14.286 14.286 37.143 0 51.429l-94.857 94.857c-14.286 14.286-37.143 14.286-51.429 0l-424-424c-14.286-14.286-14.286-37.143 0-51.429l424-424c14.286-14.286 37.143-14.286 51.429 0l94.857 94.857c14.286 14.286 14.286 37.143 0 51.429z"></path>
                  </svg>
                  <span className={styles['text07']}>{paginaAtual} de {totalPaginas}</span>
                  <svg
                    viewBox="0 0 694.8571428571428 1024"
                    className={styles['icon4']}
                    onClick={proximaPagina}
                  >
                    <path d="M632.571 501.143l-424 424c-14.286 14.286-37.143 14.286-51.429 0l-94.857-94.857c-14.286-14.286-14.286-37.143 0-51.429l303.429-303.429-303.429-303.429c-14.286-14.286-14.286-37.143 0-51.429l94.857-94.857c14.286-14.286 37.143-14.286 51.429 0l424 424c14.286 14.286 14.286 37.143 0 51.429z"></path>
                  </svg>
                </div>
              )}
            </div>
            <div className={styles['empty']}>
              <svg viewBox="0 0 1024 1024" className={styles['icon6']}>
                <path d="M229.714 404l282.286 174.286-195.429 162.857-280-182.286zM793.143 721.143v61.714l-280 167.429v0.571l-0.571-0.571-0.571 0.571v-0.571l-279.429-167.429v-61.714l84 54.857 195.429-162.286v-1.143l0.571 0.571 0.571-0.571v1.143l196 162.286zM316.571 67.429l195.429 162.857-282.286 173.714-193.143-154.286zM794.286 404l193.143 154.857-279.429 182.286-196-162.857zM708 67.429l279.429 182.286-193.143 154.286-282.286-173.714z"></path>
              </svg>
              <span className={styles['text08']}>Nenhuma aula cadastrada</span>
            </div>
          </div>
        </div>
        <div className={styles['head1']}>
          <span className={styles['text09']}>
            <span className={styles['text10']}>Total em comissões:</span>
            <span> R$ {props.totalComissoes ? props.totalComissoes.toLocaleString('pt-br') : "0"},00</span>
          </span>
          <div className={styles['btn-nao-mostrar-mais']} onClick={props.fecharPopup}>
            <span className={styles['text12']}>Cancelar</span>
          </div>
          <div className={styles['btn-nao-mostrar-mais1']} onClick={() => {
            if (!adminMode) {
              window.open(`https://api.whatsapp.com/send?phone=55${whatsApp}`)
              props.fecharPopup()
            } else {
              efetuarPagamento()
            }
          }}>
            <span className={styles['text13']}>{adminMode ? 'Pagamento Feito' : 'Solicitar Saque'}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PopupHistoricoComissoes
