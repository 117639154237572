import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Calendar from 'react-calendar';
import { useSelector, useDispatch } from 'react-redux';
import { setUsuario } from '../../features/usuario/usuarioAtual';
const Painel = (props) => {

    return (
        <div className="space-y-5 p-5 w-full flex relative flex-col pb-8 justify-start bg-[#212121] rounded-xl overflow-hidden">
            Nada nesta página no momento...
        </div>
    )
}

export default Painel