import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useSelector, useDispatch } from 'react-redux';
import Header from '../components/header'
import Menu from '../components/menu';
import Footer from '../components/footer'
import Aside from '../components/aside'
import PopupAgendamento from '../components/popups/add_consultoria'

import './ia.css'

const IA = () => {
    const [popupAgendamentoVisible, setPopupAgendamentoVisible] = useState(false)
    const usuarioAtual = useSelector(state => state.usuarioAtual);
    const [showMenu, setShowMenu] = useState(window.innerWidth >= 1024);
    return (
        <div className='w-full'>
            <Helmet>
                <title>Simbium</title>
                <meta property="og:title" content="Simbium" />
            </Helmet>
            <Header usuarioAtual={usuarioAtual} showMenu={showMenu} setShowMenu={setShowMenu} />
            <div className='flex flex-col space-y-10'>
                <div className='lg:p-5 flex flex-row lg:mt-14 pt-5 lg:pt-10 space-x-5'>
                    {showMenu &&
                        <div className='w-3/12 mb-5'>
                            <Menu usuarioAtual={usuarioAtual} showMenu={showMenu} setShowMenu={setShowMenu} />
                        </div>
                    }
                    <div className={`${showMenu ? 'w-9/12' : 'w-11/12'} mb-5 mx-auto`}>
                        <div className="flex flex-col gap-8 w-full p-7 bg-[#212121] rounded-lg">
                            <div className="flex flex-col gap-2">
                                <div className="flex items-center gap-2">
                                    <img className="h-6 w-6 fill-white" src="./aiicon.png" />
                                    <span className="text-white text-2xl font-medium">Inteligência Artificial</span>
                                </div>
                                <span className="text-gray-400 leading-snug max-w-2xl">Disponível para você 24 horas por dia, 7 dias da semana. A nossa inteligência artificial irá te ajudar com todas suas dúvidas e complementar seu aprendizado para seu negócio atingir o próximo nível 🚀</span>
                            </div>
                            <iframe
                                src="https://www.chatbase.co/chatbot-iframe/l9sARdJiP3-fs6JfCwYcY"
                                width="100%"
                                style={{ height: '65vh', borderRadius: '10px' }}
                                frameborder="0"
                            ></iframe>
                        </div>
                    </div>

                </div>
            </div>
            <Footer></Footer>
        </div>
    )
};

export default IA;