import React, { useEffect, useState } from 'react'
import { span, useLocation, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify';
import axios from 'axios';

const Menu = ({ usuarioAtual, showMenu, setShowMenu }) => {
    const location = useLocation();
    const [paginaAtiva, setPaginaAtiva] = useState('')

    const [dropdownVisible, setDropdownVisible] = useState(false);

    const [secoesPagina, setSecoesPagina] = useState([]);
    const [processando, setProcessando] = useState(false);

    const mixPanelComunidadeAcesso = () => {
        if (!usuarioAtual || !usuarioAtual._id) {
            console.error('usuarioAtual or usuarioAtual._id is missing');
            return;
        }
        mixpanel.track("Comunidade | Acesso à página", {
            distinct_id: usuarioAtual._id,
        });
    }

    const fetchData = async () => {
        const data = await fetchConteudo('secao-pagina-com-paginas');
        if (data) {
            setSecoesPagina(data.secoesComPaginas);
            console.log("Seções com pagina: ", data.secoesComPaginas)
        }
    };

    useEffect(async () => {
        setProcessando(true)
        await fetchData();
        setProcessando(false)
    }, []);

    const fetchConteudo = async (opcao) => {
        try {
            const token = localStorage.getItem("token");
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/master/${opcao}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.status === 200) {
                return response.data;
            } else {
                // toast.error("Erro ao tentar buscar as seções com páginas");
                console.log("Resposta do servidor: ", response);
                return null;
            }
        } catch (err) {
            // toast.error("Erro ao tentar buscar as seções com páginas");
            console.error("Erro da requisição: ", err);
            return null;
        }
    };

    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible);
    };

    useEffect(() => {
        if (location) setPaginaAtiva(location.pathname)
    }, [location])

    return (
        <div className="fixed z-40 top-0 left-0 lg:static w-full shadow-lg shadow-neutral-950 h-full bg-neutral-800 rounded-lg m-0 lg:mx-auto overflow-y-auto select-none">
            <div className='block lg:hidden w-10 m-5 space-y-1.5 group cursor-pointer' onClick={() => setShowMenu(!showMenu)}>
                <div className='w-full h-1 rounded-xl bg-neutral-500 group-hover:bg-neutral-100 group-active:bg-neutral-200'></div>
                <div className='w-full h-1 rounded-xl bg-neutral-500 group-hover:bg-neutral-100 group-active:bg-neutral-200'></div>
                <div className='w-full h-1 rounded-xl bg-neutral-500 group-hover:bg-neutral-100 group-active:bg-neutral-200'></div>
            </div>
            <dl className="w-full h-fit divide-neutral-700 text-neutral-200 text-md space-y-2">
                <dd className="m-2">
                    <a href="/" className={`p-2 flex flex-row gap-3 rounded-lg hover:bg-neutral-700 active:bg-neutral-900 ${paginaAtiva === '/' ? 'text-orange-50 bg-orange-500' : ''}`}>
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 fill-none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" strokeLinecap="round">
                                <path stroke="none" d="M0 0h24v24H0z" />
                                <path className={`${paginaAtiva === '/' ? 'fill-orange-50' : 'fill-neutral-200'}`} d="M18.333 2c1.96 0 3.56 1.537 3.662 3.472l.005 .195v12.666c0 1.96 -1.537 3.56 -3.472 3.662l-.195 .005h-12.666a3.667 3.667 0 0 1 -3.662 -3.472l-.005 -.195v-12.666c0 -1.96 1.537 -3.56 3.472 -3.662l.195 -.005h12.666zm-12.333 13a1 1 0 0 0 -1 1v2l.007 .117a1 1 0 0 0 1.993 -.117v-2l-.007 -.117a1 1 0 0 0 -.993 -.883zm3 -4a1 1 0 0 0 -1 1v6l.007 .117a1 1 0 0 0 1.993 -.117v-6l-.007 -.117a1 1 0 0 0 -.993 -.883zm3 2a1 1 0 0 0 -1 1v4l.007 .117a1 1 0 0 0 1.993 -.117v-4l-.007 -.117a1 1 0 0 0 -.993 -.883z" strokeWidth="0" />
                            </svg>
                        </span>
                        <span>Home</span>
                    </a>
                </dd>

                <dd
                    className={`rounded-lg ${paginaAtiva.includes('conteudos') ? 'text-neutral-200 bg-neutral-700' : ''}`}

                >
                    <div className='m-4 truncate flex flex-row items-center gap-3 cursor-pointer' onClick={toggleDropdown}>
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 fill-none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" strokeLinecap="round">
                                <path stroke="none" d="M0 0h24v24H0z" />
                                <path className={`${paginaAtiva === '/' ? 'fill-orange-50' : 'fill-neutral-200'}`} d="M18.333 2c1.96 0 3.56 1.537 3.662 3.472l.005 .195v12.666c0 1.96 -1.537 3.56 -3.472 3.662l-.195 .005h-12.666a3.667 3.667 0 0 1 -3.662 -3.472l-.005 -.195v-12.666c0 -1.96 1.537 -3.56 3.472 -3.662l.195 -.005h12.666zm-12.333 13a1 1 0 0 0 -1 1v2l.007 .117a1 1 0 0 0 1.993 -.117v-2l-.007 -.117a1 1 0 0 0 -.993 -.883zm3 -4a1 1 0 0 0 -1 1v6l.007 .117a1 1 0 0 0 1.993 -.117v-6l-.007 -.117a1 1 0 0 0 -.993 -.883zm3 2a1 1 0 0 0 -1 1v4l.007 .117a1 1 0 0 0 1.993 -.117v-4l-.007 -.117a1 1 0 0 0 -.993 -.883z" strokeWidth="0" />
                            </svg>
                        </span>
                        <span>Conteúdos</span>
                        <span className={`${dropdownVisible ? 'rotate-180' : ''} transition-all`}>
                            <svg className='w-2 h-2' fill="#fff" version="1.1" viewBox="0 0 30.727 30.727">
                                <g>
                                    <path d="M29.994,10.183L15.363,24.812L0.733,10.184c-0.977-0.978-0.977-2.561,0-3.536c0.977-0.977,2.559-0.976,3.536,0
l11.095,11.093L26.461,6.647c0.977-0.976,2.559-0.976,3.535,0C30.971,7.624,30.971,9.206,29.994,10.183z"/>
                                </g>
                            </svg>
                        </span>
                    </div>
                    <dl className={`w-full p-2 space-y-1 bg-neutral-900 ${dropdownVisible ? 'block' : 'hidden'}`}>
                        {secoesPagina ? (
                            secoesPagina.length > 0 ? (
                                secoesPagina.map(secao => (
                                    <div key={secao._id}>
                                        <dt className='font-extrabold'>{secao.titulo}</dt>
                                        {secao.paginas && secao.paginas.length > 0 ? (
                                            secao.paginas.map(pagina => (
                                                <dd key={pagina._id} className="m-2">
                                                    <a
                                                        href={`/master/${pagina.codigo}`}
                                                        className={`p-2 flex flex-row gap-3 rounded-lg hover:bg-neutral-700 active:bg-neutral-900 ${paginaAtiva.includes(pagina.codigo) ? 'text-orange-500' : ''}`}
                                                    >
                                                        <span>
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                className="w-5 h-5 fill-none"
                                                                viewBox="0 0 24 24"
                                                                strokeWidth="2"
                                                                stroke="currentColor"
                                                                strokeLinecap="round"
                                                            >
                                                                <path stroke="none" d="M0 0h24v24H0z" />
                                                                <path
                                                                    className={`${paginaAtiva.includes(pagina.codigo) ? 'fill-orange-50' : 'fill-neutral-200'}`}
                                                                    d="M18.333 2c1.96 0 3.56 1.537 3.662 3.472l.005 .195v12.666c0 1.96 -1.537 3.56 -3.472 3.662l-.195 .005h-12.666a3.667 3.667 0 0 1 -3.662 -3.472l-.005 -.195v-12.666c0 -1.96 1.537 -3.56 3.472 -3.662l.195 -.005h12.666zm-12.333 13a1 1 0 0 0 -1 1v2l.007 .117a1 1 0 0 0 1.993 -.117v-2l-.007 -.117a1 1 0 0 0 -.993 -.883zm3 -4a1 1 0 0 0 -1 1v6l.007 .117a1 1 0 0 0 1.993 -.117v-6l-.007 -.117a1 1 0 0 0 -.993 -.883zm3 2a1 1 0 0 0 -1 1v4l.007 .117a1 1 0 0 0 1.993 -.117v-4l-.007 -.117a1 1 0 0 0 -.993 -.883z"
                                                                    strokeWidth="0"
                                                                />
                                                            </svg>
                                                        </span>
                                                        <span>{pagina.titulo}</span>
                                                    </a>
                                                </dd>
                                            ))
                                        ) : (
                                            <p className='text-xs text-neutral-500 px-3'>Não há páginas para esta seção</p>
                                        )}
                                    </div>
                                ))
                            ) : (
                                <p className='text-xs text-neutral-500 px-3'>Desculpe, não conseguimos carregar os conteúdos. Por favor, recarregue a página...</p>
                            )
                        ) : (
                            <p className='text-xs text-neutral-500 px-3'>Desculpe, não conseguimos carregar os conteúdos. Por favor, recarregue a página...</p>
                        )}
                    </dl>

                </dd>

                <dd className="m-2">
                    <a href="/consultorias" className={`p-2 flex flex-row gap-3 rounded-lg hover:bg-neutral-700 active:bg-neutral-900 ${paginaAtiva === '/consultorias' ? 'text-orange-50 bg-orange-500' : ''}`}>
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 fill-none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" strokeLinecap="round">
                                <path stroke="none" d="M0 0h24v24H0z" />
                                <path className={`${paginaAtiva === '/consultorias' ? 'fill-orange-50' : 'fill-neutral-200'}`} d="M18.333 2c1.96 0 3.56 1.537 3.662 3.472l.005 .195v12.666c0 1.96 -1.537 3.56 -3.472 3.662l-.195 .005h-12.666a3.667 3.667 0 0 1 -3.662 -3.472l-.005 -.195v-12.666c0 -1.96 1.537 -3.56 3.472 -3.662l.195 -.005h12.666zm-12.333 13a1 1 0 0 0 -1 1v2l.007 .117a1 1 0 0 0 1.993 -.117v-2l-.007 -.117a1 1 0 0 0 -.993 -.883zm3 -4a1 1 0 0 0 -1 1v6l.007 .117a1 1 0 0 0 1.993 -.117v-6l-.007 -.117a1 1 0 0 0 -.993 -.883zm3 2a1 1 0 0 0 -1 1v4l.007 .117a1 1 0 0 0 1.993 -.117v-4l-.007 -.117a1 1 0 0 0 -.993 -.883z" strokeWidth="0" />
                            </svg>
                        </span>
                        <span>Consultorias</span>
                    </a>
                </dd>

                <dd className="m-2">
                    <a href="/meus-colaboradores" className={`p-2 flex flex-row gap-3 rounded-lg hover:bg-neutral-700 active:bg-neutral-900 ${paginaAtiva === '/meus-colaboradores' ? 'text-orange-50 bg-orange-500' : ''}`}>
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 fill-none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" strokeLinecap="round">
                                <path stroke="none" d="M0 0h24v24H0z" />
                                <path className={`${paginaAtiva === '/meus-colaboradores' ? 'fill-orange-50' : 'fill-neutral-200'}`} d="M18.333 2c1.96 0 3.56 1.537 3.662 3.472l.005 .195v12.666c0 1.96 -1.537 3.56 -3.472 3.662l-.195 .005h-12.666a3.667 3.667 0 0 1 -3.662 -3.472l-.005 -.195v-12.666c0 -1.96 1.537 -3.56 3.472 -3.662l.195 -.005h12.666zm-12.333 13a1 1 0 0 0 -1 1v2l.007 .117a1 1 0 0 0 1.993 -.117v-2l-.007 -.117a1 1 0 0 0 -.993 -.883zm3 -4a1 1 0 0 0 -1 1v6l.007 .117a1 1 0 0 0 1.993 -.117v-6l-.007 -.117a1 1 0 0 0 -.993 -.883zm3 2a1 1 0 0 0 -1 1v4l.007 .117a1 1 0 0 0 1.993 -.117v-4l-.007 -.117a1 1 0 0 0 -.993 -.883z" strokeWidth="0" />
                            </svg>
                        </span>
                        <span>Colaboradores</span>
                    </a>
                </dd>

                <dd className="m-2">
                    <a href={`${process.env.REACT_APP_COMUNIDADE_URL}`} target="_blank" className={`p-2 flex flex-row gap-3 rounded-lg hover:bg-neutral-700 active:bg-neutral-900`} onClick={() => mixPanelComunidadeAcesso()}>
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 fill-none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" strokeLinecap="round">
                                <path stroke="none" d="M0 0h24v24H0z" />
                                <path className={`fill-orange-50`} d="M18.333 2c1.96 0 3.56 1.537 3.662 3.472l.005 .195v12.666c0 1.96 -1.537 3.56 -3.472 3.662l-.195 .005h-12.666a3.667 3.667 0 0 1 -3.662 -3.472l-.005 -.195v-12.666c0 -1.96 1.537 -3.56 3.472 -3.662l.195 -.005h12.666zm-12.333 13a1 1 0 0 0 -1 1v2l.007 .117a1 1 0 0 0 1.993 -.117v-2l-.007 -.117a1 1 0 0 0 -.993 -.883zm3 -4a1 1 0 0 0 -1 1v6l.007 .117a1 1 0 0 0 1.993 -.117v-6l-.007 -.117a1 1 0 0 0 -.993 -.883zm3 2a1 1 0 0 0 -1 1v4l.007 .117a1 1 0 0 0 1.993 -.117v-4l-.007 -.117a1 1 0 0 0 -.993 -.883z" strokeWidth="0" />
                            </svg>
                        </span>
                        <span>Comunidade</span>
                    </a>
                </dd>

                <dd className="m-2">
                    <a href="/franquia-simbium" className={`p-2 flex flex-row gap-3 rounded-lg hover:bg-neutral-700 active:bg-neutral-900 ${paginaAtiva === '/franquia-simbium' ? 'text-orange-50 bg-orange-500' : ''}`}>
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 fill-none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" strokeLinecap="round">
                                <path stroke="none" d="M0 0h24v24H0z" />
                                <path className={`${paginaAtiva === '/franquia-simbium' ? 'fill-orange-50' : 'fill-neutral-200'}`} d="M18.333 2c1.96 0 3.56 1.537 3.662 3.472l.005 .195v12.666c0 1.96 -1.537 3.56 -3.472 3.662l-.195 .005h-12.666a3.667 3.667 0 0 1 -3.662 -3.472l-.005 -.195v-12.666c0 -1.96 1.537 -3.56 3.472 -3.662l.195 -.005h12.666zm-12.333 13a1 1 0 0 0 -1 1v2l.007 .117a1 1 0 0 0 1.993 -.117v-2l-.007 -.117a1 1 0 0 0 -.993 -.883zm3 -4a1 1 0 0 0 -1 1v6l.007 .117a1 1 0 0 0 1.993 -.117v-6l-.007 -.117a1 1 0 0 0 -.993 -.883zm3 2a1 1 0 0 0 -1 1v4l.007 .117a1 1 0 0 0 1.993 -.117v-4l-.007 -.117a1 1 0 0 0 -.993 -.883z" strokeWidth="0" />
                            </svg>
                        </span>
                        <span>Franquia Simbium</span>
                    </a>
                </dd>

                <dd className="m-2">
                    <a href="/importacao-compartilhada" className={`p-2 flex flex-row gap-3 rounded-lg hover:bg-neutral-700 active:bg-neutral-900 ${paginaAtiva === '/importacao-compartilhada' ? 'text-orange-50 bg-orange-500' : ''}`}>
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 fill-none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" strokeLinecap="round">
                                <path stroke="none" d="M0 0h24v24H0z" />
                                <path className={`${paginaAtiva === '/importacao-compartilhada' ? 'fill-orange-50' : 'fill-neutral-200'}`} d="M18.333 2c1.96 0 3.56 1.537 3.662 3.472l.005 .195v12.666c0 1.96 -1.537 3.56 -3.472 3.662l-.195 .005h-12.666a3.667 3.667 0 0 1 -3.662 -3.472l-.005 -.195v-12.666c0 -1.96 1.537 -3.56 3.472 -3.662l.195 -.005h12.666zm-12.333 13a1 1 0 0 0 -1 1v2l.007 .117a1 1 0 0 0 1.993 -.117v-2l-.007 -.117a1 1 0 0 0 -.993 -.883zm3 -4a1 1 0 0 0 -1 1v6l.007 .117a1 1 0 0 0 1.993 -.117v-6l-.007 -.117a1 1 0 0 0 -.993 -.883zm3 2a1 1 0 0 0 -1 1v4l.007 .117a1 1 0 0 0 1.993 -.117v-4l-.007 -.117a1 1 0 0 0 -.993 -.883z" strokeWidth="0" />
                            </svg>
                        </span>
                        <span>Importe com a Simbium</span>
                    </a>
                </dd>

                <dd className="m-2">
                    <a href="/emprestimo-simbium" className={`p-2 flex flex-row gap-3 rounded-lg hover:bg-neutral-700 active:bg-neutral-900 ${paginaAtiva === '/emprestimo-simbium' ? 'text-orange-50 bg-orange-500' : ''}`}>
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 fill-none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" strokeLinecap="round">
                                <path stroke="none" d="M0 0h24v24H0z" />
                                <path className={`${paginaAtiva === '/emprestimo-simbium' ? 'fill-orange-50' : 'fill-neutral-200'}`} d="M18.333 2c1.96 0 3.56 1.537 3.662 3.472l.005 .195v12.666c0 1.96 -1.537 3.56 -3.472 3.662l-.195 .005h-12.666a3.667 3.667 0 0 1 -3.662 -3.472l-.005 -.195v-12.666c0 -1.96 1.537 -3.56 3.472 -3.662l.195 -.005h12.666zm-12.333 13a1 1 0 0 0 -1 1v2l.007 .117a1 1 0 0 0 1.993 -.117v-2l-.007 -.117a1 1 0 0 0 -.993 -.883zm3 -4a1 1 0 0 0 -1 1v6l.007 .117a1 1 0 0 0 1.993 -.117v-6l-.007 -.117a1 1 0 0 0 -.993 -.883zm3 2a1 1 0 0 0 -1 1v4l.007 .117a1 1 0 0 0 1.993 -.117v-4l-.007 -.117a1 1 0 0 0 -.993 -.883z" strokeWidth="0" />
                            </svg>
                        </span>
                        <span>Empréstimo Simbium</span>
                    </a>
                </dd>

                <dd className="m-2">
                    <a href="/shorts" className={`p-2 flex flex-row gap-3 rounded-lg hover:bg-neutral-700 active:bg-neutral-900 ${paginaAtiva === '/shorts' ? 'text-orange-50 bg-orange-500' : ''}`}>
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 fill-none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" strokeLinecap="round">
                                <path stroke="none" d="M0 0h24v24H0z" />
                                <path className={`${paginaAtiva === '/shorts' ? 'fill-orange-50' : 'fill-neutral-200'}`} d="M18.333 2c1.96 0 3.56 1.537 3.662 3.472l.005 .195v12.666c0 1.96 -1.537 3.56 -3.472 3.662l-.195 .005h-12.666a3.667 3.667 0 0 1 -3.662 -3.472l-.005 -.195v-12.666c0 -1.96 1.537 -3.56 3.472 -3.662l.195 -.005h12.666zm-12.333 13a1 1 0 0 0 -1 1v2l.007 .117a1 1 0 0 0 1.993 -.117v-2l-.007 -.117a1 1 0 0 0 -.993 -.883zm3 -4a1 1 0 0 0 -1 1v6l.007 .117a1 1 0 0 0 1.993 -.117v-6l-.007 -.117a1 1 0 0 0 -.993 -.883zm3 2a1 1 0 0 0 -1 1v4l.007 .117a1 1 0 0 0 1.993 -.117v-4l-.007 -.117a1 1 0 0 0 -.993 -.883z" strokeWidth="0" />
                            </svg>
                        </span>
                        <span>Shorts</span>
                    </a>
                </dd>

                <dd className="m-2">
                    <a href="/ia" className={`p-2 flex flex-row gap-3 rounded-lg hover:bg-neutral-700 active:bg-neutral-900 ${paginaAtiva === '/ia' ? 'text-orange-50 bg-orange-500' : ''}`}>
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 fill-none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" strokeLinecap="round">
                                <path stroke="none" d="M0 0h24v24H0z" />
                                <path className={`${paginaAtiva === '/ia' ? 'fill-orange-50' : 'fill-neutral-200'}`} d="M18.333 2c1.96 0 3.56 1.537 3.662 3.472l.005 .195v12.666c0 1.96 -1.537 3.56 -3.472 3.662l-.195 .005h-12.666a3.667 3.667 0 0 1 -3.662 -3.472l-.005 -.195v-12.666c0 -1.96 1.537 -3.56 3.472 -3.662l.195 -.005h12.666zm-12.333 13a1 1 0 0 0 -1 1v2l.007 .117a1 1 0 0 0 1.993 -.117v-2l-.007 -.117a1 1 0 0 0 -.993 -.883zm3 -4a1 1 0 0 0 -1 1v6l.007 .117a1 1 0 0 0 1.993 -.117v-6l-.007 -.117a1 1 0 0 0 -.993 -.883zm3 2a1 1 0 0 0 -1 1v4l.007 .117a1 1 0 0 0 1.993 -.117v-4l-.007 -.117a1 1 0 0 0 -.993 -.883z" strokeWidth="0" />
                            </svg>
                        </span>
                        <span>IA da Simbium</span>
                    </a>
                </dd>

                <dd className="m-2">
                    <a href="/fornecedores-catalogados" className={`p-2 flex flex-row gap-3 rounded-lg hover:bg-neutral-700 active:bg-neutral-900 ${paginaAtiva === '/fornecedores-catalogados' ? 'text-orange-50 bg-orange-500' : ''}`}>
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 fill-none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" strokeLinecap="round">
                                <path stroke="none" d="M0 0h24v24H0z" />
                                <path className={`${paginaAtiva === '/fornecedores-catalogados' ? 'fill-orange-50' : 'fill-neutral-200'}`} d="M18.333 2c1.96 0 3.56 1.537 3.662 3.472l.005 .195v12.666c0 1.96 -1.537 3.56 -3.472 3.662l-.195 .005h-12.666a3.667 3.667 0 0 1 -3.662 -3.472l-.005 -.195v-12.666c0 -1.96 1.537 -3.56 3.472 -3.662l.195 -.005h12.666zm-12.333 13a1 1 0 0 0 -1 1v2l.007 .117a1 1 0 0 0 1.993 -.117v-2l-.007 -.117a1 1 0 0 0 -.993 -.883zm3 -4a1 1 0 0 0 -1 1v6l.007 .117a1 1 0 0 0 1.993 -.117v-6l-.007 -.117a1 1 0 0 0 -.993 -.883zm3 2a1 1 0 0 0 -1 1v4l.007 .117a1 1 0 0 0 1.993 -.117v-4l-.007 -.117a1 1 0 0 0 -.993 -.883z" strokeWidth="0" />
                            </svg>
                        </span>
                        <span>Fornecedores Catalogados</span>
                    </a>
                </dd>

                <dd className="m-2">
                    <a href="/fornecedores-e-parceiros" className={`p-2 flex flex-row gap-3 rounded-lg hover:bg-neutral-700 active:bg-neutral-900 ${paginaAtiva === '/fornecedores-e-parceiros' ? 'text-orange-50 bg-orange-500' : ''}`}>
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 fill-none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" strokeLinecap="round">
                                <path stroke="none" d="M0 0h24v24H0z" />
                                <path className={`${paginaAtiva === '/fornecedores-e-parceiros' ? 'fill-orange-50' : 'fill-neutral-200'}`} d="M18.333 2c1.96 0 3.56 1.537 3.662 3.472l.005 .195v12.666c0 1.96 -1.537 3.56 -3.472 3.662l-.195 .005h-12.666a3.667 3.667 0 0 1 -3.662 -3.472l-.005 -.195v-12.666c0 -1.96 1.537 -3.56 3.472 -3.662l.195 -.005h12.666zm-12.333 13a1 1 0 0 0 -1 1v2l.007 .117a1 1 0 0 0 1.993 -.117v-2l-.007 -.117a1 1 0 0 0 -.993 -.883zm3 -4a1 1 0 0 0 -1 1v6l.007 .117a1 1 0 0 0 1.993 -.117v-6l-.007 -.117a1 1 0 0 0 -.993 -.883zm3 2a1 1 0 0 0 -1 1v4l.007 .117a1 1 0 0 0 1.993 -.117v-4l-.007 -.117a1 1 0 0 0 -.993 -.883z" strokeWidth="0" />
                            </svg>
                        </span>
                        <span>Seja um Parceiro Homologado</span>
                    </a>
                </dd>

                <dd className="m-2">
                    <a href="/seja-consultor" className={`p-2 flex flex-row gap-3 rounded-lg hover:bg-neutral-700 active:bg-neutral-900 ${paginaAtiva === '/seja-consultor' ? 'text-orange-50 bg-orange-500' : ''}`}>
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 fill-none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" strokeLinecap="round">
                                <path stroke="none" d="M0 0h24v24H0z" />
                                <path className={`${paginaAtiva === '/seja-consultor' ? 'fill-orange-50' : 'fill-neutral-200'}`} d="M18.333 2c1.96 0 3.56 1.537 3.662 3.472l.005 .195v12.666c0 1.96 -1.537 3.56 -3.472 3.662l-.195 .005h-12.666a3.667 3.667 0 0 1 -3.662 -3.472l-.005 -.195v-12.666c0 -1.96 1.537 -3.56 3.472 -3.662l.195 -.005h12.666zm-12.333 13a1 1 0 0 0 -1 1v2l.007 .117a1 1 0 0 0 1.993 -.117v-2l-.007 -.117a1 1 0 0 0 -.993 -.883zm3 -4a1 1 0 0 0 -1 1v6l.007 .117a1 1 0 0 0 1.993 -.117v-6l-.007 -.117a1 1 0 0 0 -.993 -.883zm3 2a1 1 0 0 0 -1 1v4l.007 .117a1 1 0 0 0 1.993 -.117v-4l-.007 -.117a1 1 0 0 0 -.993 -.883z" strokeWidth="0" />
                            </svg>
                        </span>
                        <span>Seja um Consultor</span>
                    </a>
                </dd>
            </dl>
        </div>
    )
}

export default Menu