import React, { useState, useEffect } from 'react';

const RadioWithOtherOption = ({ label, options, selectedOption, otherValue, onChange, onOtherChange, isRequired, paginaQuestao }) => {
    const [error, setError] = useState('');

    const handleInput = (value, other_value = '') => {
        const finalValue = (value === 'other' ? other_value : value)
        const isValid = !isRequired || finalValue;

        setError(isValid ? '' : 'Selecione uma opção ou preencha o campo de "Outro"');

        onChange(value, isValid);
        if (value === 'other') {
            onOtherChange(other_value, isValid);
        }
    };

    useEffect(() => {
        const finalValue = (selectedOption === 'other' ? otherValue : selectedOption)
        const isValid = !isRequired || finalValue;
        setError(isValid ? '' : 'Selecione uma opção');

        onChange(selectedOption, isValid);
        if (selectedOption === 'other') {
            onOtherChange(otherValue, isValid);
        }
    }, [paginaQuestao]);

    return (
        <div className="space-y-2">
            <div className="">
                {options && options.map((option) => (
                    <label
                        key={option.codigo}
                        className={`block p-3 bg-neutral-700 cursor-pointer 
                            ${selectedOption === option.codigo ? 'bg-orange-500 text-white' : 'bg-neutral-800 hover:bg-neutral-900 active:bg-neutral-950 text-neutral-200'} 
                         transition-colors duration-200`}
                        onClick={() => handleInput(option.codigo)}
                    >
                        <span>{option.descricao}</span>
                    </label>
                ))}
                <label
                    className={`block p-3 cursor-pointer 
                        ${selectedOption === 'other' ? 'bg-orange-500 text-white' : 'bg-neutral-800 hover:bg-neutral-900 active:bg-neutral-950 text-neutral-200'} 
                     transition-colors duration-200`}
                    onClick={() => handleInput('other')}
                >
                    <span>Outro</span>
                </label>
                {selectedOption === 'other' && (
                    <input
                        type="text"
                        className={`w-full p-4 text-xl bg-transparent border-b-4 border-neutral-800 ${otherValue ? 'border-b-orange-500' : 'border-b-neutral-900'} focus:border-neutral-800 focus:border-b-orange-500 focus:ring-0 placeholder:text-neutral-500`}
                        placeholder="Digite outra opção não listada"
                        value={otherValue}
                        onInput={(e) => handleInput('other', e.target.value)}
                    />
                )}
                {error && <p className="text-red-500">{error}</p>}
            </div>
        </div>
    );
};

export default RadioWithOtherOption;
