import React, { useEffect, useState } from 'react'
import { span, useLocation, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify';

import './aside.css'


const Aside = (props) => {
  const history = useHistory();
  const location = useLocation();
  const [progresso, setProgresso] = useState(0)
  const usuarioAtual = useSelector(state => state.usuarioAtual);

  const [shouldRender, setShouldRender] = useState(true);

  useEffect(() => {
    if (['admin', 'consultor'].some(path => location.pathname.includes(path))) {
      setShouldRender(false);
    }
  }, []);

  const onboardingDiagnostico = async () => {
    try {

      const response = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/usuarios/onboarding`, {
        etapa: 'diagnostico'
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      })

      if (response.data && response.data.usuario) {
        dispatch(setUsuario(response.data.usuario));
      }

    } catch (err) {

    }

  }


  const scrollIndicacao = () => {
    history.push('/');
    setTimeout(() => {
      const element = document.getElementById('indicacao');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }, 500);
  };

  useEffect(() => {

    if (usuarioAtual.onboarding && !usuarioAtual.onboarding.concluido && usuarioAtual.onboarding.etapas && usuarioAtual.onboarding.etapas.length > 0) {
      const qtdEtapas = 5;
      const etapasUsuario = usuarioAtual.onboarding.etapas.length;
      setProgresso((etapasUsuario / qtdEtapas) * 100)
    }

  }, [])

  /*
    const [progresso, setProgresso] = useState(0)

  useEffect(async() => {
    
    try {

      const result = await axios.get(process.env.REACT_APP_BACKEND_BASE_URL + '/usuarios/progresso-usuario', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      })

      if(result.data && result.data.progresso) {
        setProgresso(result.data.progresso)
      }

    } catch(err) {
    }

  },[])*/

  if (!shouldRender) {
    return null;
  }

  return (
    <aside className={`aside-aside bg-green-500`}>
      <div id="aside-desktop">
        <div className='aside-overflow'>
          {/*<div className="aside-container">
            <div className="box-control">
              <a href='/'>
                <span to="/meu-perfil">
                  <img
                    alt="image"
                    src={usuarioAtual.foto_perfil ? usuarioAtual.foto_perfil : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png'}
                    className="aside-image"
                  />
                </span>
              </a>
            </div>
            <div style={{ display: 'flex', gap: '5px', flexDirection: 'column', alignItems: 'center' }}>
              <span className="aside-text">{usuarioAtual.nome}</span>
            </div>
  </div>
          {(!usuarioAtual.onboarding || (usuarioAtual.onboarding && !usuarioAtual.onboarding.concluido)) && (
            <div className="aside-container1">
              <span className="aside-text1">Progresso nos Primeiros Passos</span>
              <div className="aside-container2">
                <div className="aside-container3"
                  style={{
                    width: `${progresso}%`,
                    borderRadius: progresso === 100 ? '10px' : '10px 0 0 10px'
                  }}
                ></div>
              </div>
            </div>
          )}
          */}
          <div className="aside-menu">
            {usuarioAtual.tipo === "admin" && (
              <a href='/admin' className="aside-navlink">
                <div className="aside-container4" style={{ background: 'linear-gradient(45deg, #c5590f, #cf6c28)', border: "none", paddingLeft: '25px' }}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-align-box-bottom-left-filled" width="18" height="18" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M18.333 2c1.96 0 3.56 1.537 3.662 3.472l.005 .195v12.666c0 1.96 -1.537 3.56 -3.472 3.662l-.195 .005h-12.666a3.667 3.667 0 0 1 -3.662 -3.472l-.005 -.195v-12.666c0 -1.96 1.537 -3.56 3.472 -3.662l.195 -.005h12.666zm-12.333 13a1 1 0 0 0 -1 1v2l.007 .117a1 1 0 0 0 1.993 -.117v-2l-.007 -.117a1 1 0 0 0 -.993 -.883zm3 -4a1 1 0 0 0 -1 1v6l.007 .117a1 1 0 0 0 1.993 -.117v-6l-.007 -.117a1 1 0 0 0 -.993 -.883zm3 2a1 1 0 0 0 -1 1v4l.007 .117a1 1 0 0 0 1.993 -.117v-4l-.007 -.117a1 1 0 0 0 -.993 -.883z" strokeWidth="0" fill="white" />
                  </svg>
                  <span className="aside-text2" style={{ color: '#fff' }}>Administração</span>
                </div>
              </a>
            )}
            {usuarioAtual.tipo === "consultor" && (
              <a href="/consultor" className="aside-navlink">
                <div className="aside-container4" style={{ background: 'linear-gradient(45deg, #c5590f, #cf6c28)', border: "none", paddingLeft: '25px' }}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-align-box-bottom-left-filled" width="18" height="18" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M18.333 2c1.96 0 3.56 1.537 3.662 3.472l.005 .195v12.666c0 1.96 -1.537 3.56 -3.472 3.662l-.195 .005h-12.666a3.667 3.667 0 0 1 -3.662 -3.472l-.005 -.195v-12.666c0 -1.96 1.537 -3.56 3.472 -3.662l.195 -.005h12.666zm-12.333 13a1 1 0 0 0 -1 1v2l.007 .117a1 1 0 0 0 1.993 -.117v-2l-.007 -.117a1 1 0 0 0 -.993 -.883zm3 -4a1 1 0 0 0 -1 1v6l.007 .117a1 1 0 0 0 1.993 -.117v-6l-.007 -.117a1 1 0 0 0 -.993 -.883zm3 2a1 1 0 0 0 -1 1v4l.007 .117a1 1 0 0 0 1.993 -.117v-4l-.007 -.117a1 1 0 0 0 -.993 -.883z" strokeWidth="0" fill="white" />
                  </svg>
                  <span className="aside-text2" style={{ color: '#fff' }}>Área do consultor</span>
                </div>
              </a>
            )}

            {/*<div className='subtitulo-menu'>
              <h3>Acelere seu negócio</h3>
              <p>Ferramentas práticas para acelerar seu negócio</p>
            </div>
            <a href='/' className={`aside-navlink ${location.pathname === '/' ? 'activePage' : ''}`}>
              <div className="aside-container4">
                <svg viewBox="0 0 1024 1024" className="aside-icon">
                  <path
                    d="M426 854h-212v-342h-128l426-384 426 384h-128v342h-212v-256h-172v256z"
                    className=""
                  ></path>
                </svg>
                <span className="aside-text2">Início</span>
              </div>
          </a>*/}

            <button onClick={() => {
              if (props.agendarConsultoria) {
                props.agendarConsultoria()
              }
            }} className="aside-navlink2">
              <span className="aside-container4">
                <svg viewBox="0 0 804.5714285714286 1024" className="aside-icon">
                  <path d="M804.571 420.571v109.714c0 30.286-24.571 54.857-54.857 54.857h-237.714v237.714c0 30.286-24.571 54.857-54.857 54.857h-109.714c-30.286 0-54.857-24.571-54.857-54.857v-237.714h-237.714c-30.286 0-54.857-24.571-54.857-54.857v-109.714c0-30.286 24.571-54.857 54.857-54.857h237.714v-237.714c0-30.286 24.571-54.857 54.857-54.857h109.714c30.286 0 54.857 24.571 54.857 54.857v237.714h237.714c30.286 0 54.857 24.571 54.857 54.857z"></path>
                </svg>
                <span className="aside-text2">Consultorias Mão na Massa</span>
              </span>
            </button>

            <a href='/meus-colaboradores' className={`aside-navlink2 ${location.pathname === '/meus-colaboradores' ? 'activePage' : ''}`}>
              <div className="aside-container4">
                <svg viewBox="0 0 1024 1024" className="aside-icon">
                  <path
                    d="M598 256v-86h-172v86h172zM854 256q36 0 60 25t24 61v468q0 36-24 61t-60 25h-684q-36 0-60-25t-24-61v-468q0-36 24-61t60-25h172v-86q0-36 24-60t60-24h172q36 0 60 24t24 60v86h172z"
                    className=""
                  ></path>
                </svg>
                <span className="aside-text2">Adicione seus Colaboradores</span>
              </div>
            </a>

            <a target="_blank" href={`${process.env.REACT_APP_COMUNIDADE_URL}`} className="aside-navlink">
              <span className="aside-container4">
                <svg viewBox="0 0 1152 1024" className="aside-icon">
                  <path
                    d="M480 0v0c265.096 0 480 173.914 480 388.448s-214.904 388.448-480 388.448c-25.458 0-50.446-1.62-74.834-4.71-103.106 102.694-222.172 121.108-341.166 123.814v-25.134c64.252-31.354 116-88.466 116-153.734 0-9.106-0.712-18.048-2.030-26.794-108.558-71.214-177.97-179.988-177.97-301.89 0-214.534 214.904-388.448 480-388.448zM996 870.686c0 55.942 36.314 104.898 92 131.772v21.542c-103.126-2.318-197.786-18.102-287.142-106.126-21.14 2.65-42.794 4.040-64.858 4.040-95.47 0-183.408-25.758-253.614-69.040 144.674-0.506 281.26-46.854 384.834-130.672 52.208-42.252 93.394-91.826 122.414-147.348 30.766-58.866 46.366-121.582 46.366-186.406 0-10.448-0.45-20.836-1.258-31.168 72.57 59.934 117.258 141.622 117.258 231.676 0 104.488-60.158 197.722-154.24 258.764-1.142 7.496-1.76 15.16-1.76 22.966z"
                    className=""
                  ></path>
                </svg>
                <span className="aside-text2">Acesse a Comunidade</span>
              </span>
            </a>

            {/*<div className='subtitulo-menu'>
              <h3>Destrave seu negócio</h3>
              <p>Conteúdos e benefícios exclusivos para você destravar e ir além</p>
          </div>*/}

            <a href='/master/chave-mestra' className="aside-navlink">
              <span className={`aside-navlink2 ${location.pathname === '/master/chave-mestra' ? 'activePage' : ''}`}>
                <div className="aside-container4">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className='aside-icon'>

                    <g id="SVGRepo_bgCarrier" strokeWidth="0" />

                    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />

                    <g id="SVGRepo_iconCarrier"> <path fillRule="evenodd" clipRule="evenodd" d="M22 8.29344C22 11.7692 19.1708 14.5869 15.6807 14.5869C15.0439 14.5869 13.5939 14.4405 12.8885 13.8551L12.0067 14.7333C11.4883 15.2496 11.6283 15.4016 11.8589 15.652C11.9551 15.7565 12.0672 15.8781 12.1537 16.0505C12.1537 16.0505 12.8885 17.075 12.1537 18.0995C11.7128 18.6849 10.4783 19.5045 9.06754 18.0995L8.77362 18.3922C8.77362 18.3922 9.65538 19.4167 8.92058 20.4412C8.4797 21.0267 7.30403 21.6121 6.27531 20.5876L5.2466 21.6121C4.54119 22.3146 3.67905 21.9048 3.33616 21.6121L2.45441 20.7339C1.63143 19.9143 2.1115 19.0264 2.45441 18.6849L10.0963 11.0743C10.0963 11.0743 9.3615 9.90338 9.3615 8.29344C9.3615 4.81767 12.1907 2 15.6807 2C19.1708 2 22 4.81767 22 8.29344ZM15.681 10.4889C16.8984 10.4889 17.8853 9.50601 17.8853 8.29353C17.8853 7.08105 16.8984 6.09814 15.681 6.09814C14.4635 6.09814 13.4766 7.08105 13.4766 8.29353C13.4766 9.50601 14.4635 10.4889 15.681 10.4889Z" /> </g>

                  </svg>
                  <span className="aside-text2">Automações</span>
                </div>
              </span>
            </a>

            <a href='/shorts' className="aside-navlink">
              <span className={`aside-navlink2 ${location.pathname === '/shorts' ? 'activePage' : ''}`}>
                <div className="aside-container4">
                  <svg viewBox="0 0 1024 1024" className="aside-icon">
                    <path d="M406.286 644.571l276.571-142.857-276.571-144.571v287.429zM512 152c215.429 0 358.286 10.286 358.286 10.286 20 2.286 64 2.286 102.857 43.429 0 0 31.429 30.857 40.571 101.714 10.857 82.857 10.286 165.714 10.286 165.714v77.714s0.571 82.857-10.286 165.714c-9.143 70.286-40.571 101.714-40.571 101.714-38.857 40.571-82.857 40.571-102.857 42.857 0 0-142.857 10.857-358.286 10.857v0c-266.286-2.286-348-10.286-348-10.286-22.857-4-74.286-2.857-113.143-43.429 0 0-31.429-31.429-40.571-101.714-10.857-82.857-10.286-165.714-10.286-165.714v-77.714s-0.571-82.857 10.286-165.714c9.143-70.857 40.571-101.714 40.571-101.714 38.857-41.143 82.857-41.143 102.857-43.429 0 0 142.857-10.286 358.286-10.286v0z"></path>
                  </svg>

                  <span className="aside-text2">Shorts - Direto ao Ponto</span>
                </div>
              </span>
            </a>


            <a href='/master/treinamento-colaboradores' className="aside-navlink">
              <span className={`aside-navlink2 ${location.pathname === '/treinamento-colaboradores' ? 'activePage' : ''}`}>
                <div className="aside-container4">
                  <svg viewBox="0 0 512 512" className='aside-icon'><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <g> <path d="M256,0c-62.052,0-112.532,50.481-112.532,112.532c0,62.05,50.481,112.532,112.532,112.532s112.532-50.483,112.532-112.532 C368.532,50.481,318.052,0,256,0z"></path> </g> </g> <g> <g> <path d="M282.9,280.617v27.155v27.28v114.18c0,3.149-0.568,6.158-1.564,8.967c-3.696,10.439-13.629,17.933-25.336,17.933 s-21.638-7.494-25.336-17.933c-0.995-2.808-1.564-5.818-1.564-8.967v-114.18v-27.28v-27.155 C128.045,293.84,49.765,380.496,49.765,485.1c0,14.854,12.044,26.9,26.9,26.9h358.669c14.856,0,26.9-12.046,26.9-26.9 C462.235,380.498,383.955,293.841,282.9,280.617z"></path> </g> </g> </g></svg>

                  <span className="aside-text2">Treinamento para Colaboradores</span>
                </div>
              </span>
            </a>

            <div className='p-3 bg-neutral-950 text-justify space-y-2'>
              <h3 className='text-neutral-300 text-lg'>Conteúdos Exclusivos</h3>
              <p className='text-neutral-600 text-xs'>Conteúdos de outro nível que vão levar o seu negócio à outro patamar.</p>
            </div>


            <a href='/master/conteudos-especialistas' className="aside-navlink" disabled>
              <span className={`aside-navlink2 ${location.pathname === '/conteudos-especialistas' ? 'activePage' : ''}`}>
                <div className="aside-container4">
                  <svg viewBox="0 0 48 48" className="aside-icon">
                    <g id="Layer_2" data-name="Layer 2">
                      <g id="invisible_box" data-name="invisible box">
                        <rect width="48" height="48" fill="none" />
                      </g>
                      <g id="icons_Q2" data-name="icons Q2">
                        <path d="M40,18A16,16,0,1,0,15,31.2V43.9a2,2,0,0,0,3.1,1.7L24,41l5.9,4.6A2,2,0,0,0,33,43.9V31.2A16,16,0,0,0,40,18ZM12,18A12,12,0,1,1,24,30,12,12,0,0,1,12,18Z" />
                        <path d="M20.6,23.4l-4-3.9a2.1,2.1,0,0,1-.2-2.7,1.9,1.9,0,0,1,3-.2L22,19.2l6.6-6.6a2,2,0,0,1,2.8,2.8l-8,8A1.9,1.9,0,0,1,20.6,23.4Z" />
                      </g>
                    </g>
                  </svg>

                  <span className="aside-text2">Conteúdos de Especialistas</span>
                </div>
              </span>
            </a>



            <a href='#' className="aside-navlink" disabled>
              <span className={`aside-navlink2 ${location.pathname === '/sugestoes-leitura' ? 'activePage' : ''}`}>
                <div className="aside-container4">
                  <svg viewBox="-63 65 128 128" className='aside-icon'><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M1,102.1c9.7,0,17.5-7.9,17.5-17.6S10.7,66.9,1,66.9s-17.6,7.8-17.6,17.6C-16.6,94.2-8.7,102.1,1,102.1 M18,106.3 c8,0.3,11.4,6.7,11.4,6.7l25.9,35.6c1.1,1.6,1.7,3.5,1.7,5.6c0,5.5-4.5,10-10,10c-1.3,0-2.5-0.3-3.7-0.7l-15.6-4.4v15.6h-54.2v-15.6 l-15.6,4.4c-1.1,0.4-2.3,0.7-3.7,0.7c-5.5,0-10-4.5-10-10c0-2.1,0.6-4,1.7-5.6l25.9-35.6c0,0,3.4-6.4,11.4-6.7H18z M0.6,163.8 L0.6,163.8l20.3-6.7l-0.5-0.1c-14-4.1-8.6-22.6,5.4-18.5l1.9,0.7v-20.9l-27.1,8.9l-27.2-8.9v20.9l1.9-0.7c14-4.1,19.4,14.3,5.4,18.5 l-0.5,0.1L0.6,163.8z M56.4,188.6c1.4,0,2.5-1.4,2.5-3.3c0-1.9-1.1-3.3-2.5-3.3H-53.2c-1.4,0-2.5,1.5-2.5,3.3c0,1.8,1.1,3.3,2.5,3.3 H56.4z"></path> </g></svg>

                  <span className="aside-text2">Histórias de Sucesso <span className='em-breve-menu'>Em breve</span></span>
                </div>
              </span>
            </a>


            <a href='#' className="aside-navlink" disabled>
              <span className={`aside-navlink2 ${location.pathname === '/ia' ? 'activePage' : ''}`}>
                <div className="aside-container4">
                  <svg viewBox="0 0 48 48" className='aside-icon'><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <title>ai-solid</title> <g id="Layer_2" data-name="Layer 2"> <g id="invisible_box" data-name="invisible box"> <rect width="48" height="48" fill="none"></rect> </g> <g id="Q3_icons" data-name="Q3 icons"> <g> <path d="M17.9,2h-.4L7.6,6.6a1,1,0,0,0-.6.9v7.4l-.6.5-4,3.3a.8.8,0,0,0-.4.8v9a.9.9,0,0,0,.4.8l4,3.3.6.5v7.4a1,1,0,0,0,.6.9l9.9,4.5h.4l.6-.2,4-2.7V25.5H21a1.5,1.5,0,0,1,0-3h1.5V4.9l-4-2.7ZM9,13.5l2.8,1.9a1.5,1.5,0,0,1,.4,2.1,1.4,1.4,0,0,1-1.2.7,1.1,1.1,0,0,1-.8-.3L9,17.1Zm-5,9H7.5a1.5,1.5,0,0,1,0,3H4Zm5,8.4,1.2-.8a1.4,1.4,0,0,1,2,.4,1.5,1.5,0,0,1-.4,2.1L9,34.5ZM19.5,18.6l-4,4v2.8l4,4v5.2l-3.4,3.5a2.1,2.1,0,0,1-1.1.4,2.1,2.1,0,0,1-1.1-.4,1.6,1.6,0,0,1,0-2.2l2.6-2.5V30.6l-4-4V21.4l4-4V14.6l-2.6-2.5a1.6,1.6,0,1,1,2.2-2.2l3.4,3.5Z"></path> <path d="M45.6,18.7l-4-3.3-.6-.5V7.5a1,1,0,0,0-.6-.9L30.5,2.1h-.4l-.6.2-4,2.7V22.5H27a1.5,1.5,0,0,1,0,3H25.5V43.1l4,2.7.6.2h.4l9.9-4.5a1,1,0,0,0,.6-.9V33.1l.6-.5,4-3.3a.9.9,0,0,0,.4-.8v-9A.8.8,0,0,0,45.6,18.7ZM39,17.1l-1.2.8a1.1,1.1,0,0,1-.8.3,1.4,1.4,0,0,1-1.2-.7,1.5,1.5,0,0,1,.4-2.1L39,13.5ZM28.5,29.4l4-4V22.6l-4-4V13.4l3.4-3.5a1.6,1.6,0,0,1,2.2,2.2l-2.6,2.5v2.8l4,4v5.2l-4,4v2.8l2.6,2.5a1.6,1.6,0,0,1,0,2.2,1.7,1.7,0,0,1-2.2,0l-3.4-3.5ZM39,34.5l-2.8-1.9a1.5,1.5,0,0,1-.4-2.1,1.4,1.4,0,0,1,2-.4l1.2.8Zm5-9H40.5a1.5,1.5,0,0,1,0-3H44Z"></path> </g> </g> </g> </g></svg>
                  <span className="aside-text2">IA do Simbium <span className='em-breve-menu'>Em breve</span></span>
                </div>
              </span>
            </a>


            <a href='#' className="aside-navlink" disabled>
              <span className={`aside-navlink2 ${location.pathname === '/club-beneficios' ? 'activePage' : ''}`}>
                <div className="aside-container4">
                  <svg viewBox="0 0 23.382 23.382" className='aside-icon'><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path d="M14.58,15.181c0.438-0.732,1.082-1.107,1.936-1.117c0.854-0.01,1.285-0.445,1.299-1.301 c0.012-0.852,0.383-1.496,1.115-1.932c0.734-0.438,0.893-1.027,0.475-1.773c-0.416-0.744-0.416-1.488,0-2.234 c0.418-0.744,0.26-1.332-0.475-1.77c-0.732-0.439-1.104-1.082-1.115-1.938c-0.014-0.852-0.445-1.287-1.299-1.297 c-0.854-0.012-1.498-0.385-1.936-1.117c-0.438-0.734-1.027-0.893-1.771-0.475c-0.744,0.416-1.49,0.416-2.234,0 C9.83-0.19,9.241-0.032,8.803,0.702C8.366,1.435,7.721,1.808,6.868,1.819c-0.852,0.01-1.285,0.445-1.297,1.297 C5.557,3.972,5.186,4.614,4.454,5.054C3.72,5.492,3.559,6.079,3.979,6.824c0.418,0.746,0.418,1.49,0,2.234 c-0.42,0.746-0.26,1.336,0.475,1.773c0.732,0.436,1.104,1.08,1.117,1.932c0.012,0.855,0.445,1.291,1.297,1.301 c0.854,0.01,1.498,0.385,1.936,1.117c0.438,0.734,1.027,0.893,1.771,0.473c0.744-0.412,1.49-0.412,2.234,0 C13.553,16.073,14.143,15.915,14.58,15.181z M11.694,12.614c-2.582,0-4.674-2.092-4.674-4.674c0-2.58,2.092-4.672,4.674-4.672 c2.58,0,4.672,2.092,4.672,4.672C16.366,10.522,14.274,12.614,11.694,12.614z"></path> <path d="M6.793,14.749c-0.898,0-1.018-0.418-1.018-0.418l-3.507,6.893l2.812-0.316l1.555,2.34c0,0,3.24-6.76,3.24-6.715 C8.196,16.491,8.864,14.794,6.793,14.749z"></path> <path d="M17.563,14.601c-2.562,0.268-2.041,0.904-2.627,1.398c-0.674,0.719-1.516,0.578-1.516,0.578l3.955,6.805l0.973-2.52 l2.766,0.361L17.563,14.601z"></path> <polygon points="12.67,6.909 11.692,4.929 10.713,6.909 8.524,7.229 10.106,8.772 9.733,10.954 11.692,9.925 13.651,10.954 13.278,8.772 14.86,7.229 "></polygon> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> </g> </g></svg>

                  <span className="aside-text2">Clube de Benefícios <span className='em-breve-menu'>Em breve</span></span>
                </div>
              </span>
            </a>


            {/*<a href='/' onClick={() => {
              history.push('/meus-cursos')
            }} className={`aside-navlink1 ${location.pathname === '/meus-cursos' ? 'activePage' : ''}`}>
              <div className="aside-container4">
                <svg viewBox="0 0 1024 1024" className="aside-icon">
                  <path
                    d="M512 128l470 256v342h-86v-296l-384 210-470-256zM214 562l298 164 298-164v172l-298 162-298-162v-172z"
                    className=""
                  ></path>
                </svg>
                <span className="aside-text2">Meus Treinamentos</span>
              </div>
            </a>*/}
          </div>
          {/*<div className="aside-footer"></div>*/}
        </div>
      </div>



















































      <div id="aside-mobile">
        <div className="aside-container">
          <div className="box-control">
            <button onClick={() => {
              props.toggleAside()
            }}>
              <svg className="icon icon-tabler icon-tabler-x" style={{ width: '22px', height: 'auto' }} viewBox="0 0 24 24" strokeWidth="3" stroke="white" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z"></path>
                <path d="M18 6l-12 12"></path>
                <path d="M6 6l12 12"></path>
              </svg>
            </button>
            {/*<a href='/'>
              <span to="/meu-perfil">
                <img
                  alt="image"
                  src={usuarioAtual.foto_perfil ? usuarioAtual.foto_perfil : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png'}
                  className="aside-image"
                />
              </span>
            </a>*/}
            <div>
              <button onClick={() => {
                if (props.pesquisaMobileFull) props.pesquisaMobileFull()
                /*if (props.toggleAside) props.toggleAside()*/
              }}>
                <svg viewBox="0 0 950.8571428571428 1024" className="header-icon12">
                  <path
                    d="M658.286 475.429c0-141.143-114.857-256-256-256s-256 114.857-256 256 114.857 256 256 256 256-114.857 256-256zM950.857 950.857c0 40-33.143 73.143-73.143 73.143-19.429 0-38.286-8-51.429-21.714l-196-195.429c-66.857 46.286-146.857 70.857-228 70.857-222.286 0-402.286-180-402.286-402.286s180-402.286 402.286-402.286 402.286 180 402.286 402.286c0 81.143-24.571 161.143-70.857 228l196 196c13.143 13.143 21.143 32 21.143 51.429z"
                    className=""
                  ></path>
                </svg>
              </button>
              <a href='https://api.whatsapp.com/send?phone=55${props.whatsapp}'>
                <svg viewBox="0 0 1024 1024" className="header-icon12">
                  <path
                    d="M512 0c282.857 0 512 229.143 512 512s-229.143 512-512 512-512-229.143-512-512 229.143-512 512-512zM512 73.143c-74.286 0-144.571 18.857-206.286 51.429l110.857 110.857c30.286-10.286 62.286-16 95.429-16 33.714 0 65.143 5.714 95.429 16l110.857-110.857c-61.714-32.571-132-51.429-206.286-51.429zM124.571 718.286l110.857-110.857c-10.286-30.286-16-62.286-16-95.429 0-33.714 5.714-65.143 16-95.429l-110.857-110.857c-32.571 61.714-51.429 132-51.429 206.286s18.857 144.571 51.429 206.286zM512 950.857c74.286 0 144.571-18.857 206.286-51.429l-110.857-110.857c-30.286 10.286-61.714 16-95.429 16-33.143 0-65.143-5.714-95.429-16l-110.857 110.857c61.714 32.571 132 51.429 206.286 51.429zM512 731.429c121.143 0 219.429-98.286 219.429-219.429s-98.286-219.429-219.429-219.429-219.429 98.286-219.429 219.429 98.286 219.429 219.429 219.429zM788.571 607.429l110.857 110.857c32.571-61.714 51.429-132 51.429-206.286s-18.857-144.571-51.429-206.286l-110.857 110.857c10.286 30.286 16 62.286 16 95.429s-5.714 65.143-16 95.429z"
                    className=""
                  ></path>
                </svg>
              </a>

              <button onClick={() => {
                if (props.handleLogout) props.handleLogout()
              }}>
                <svg viewBox="0 0 1024 1024" className="header-icon12">
                  <path
                    d="M768 640v-128h-320v-128h320v-128l192 192zM704 576v256h-320v192l-384-192v-832h704v320h-64v-256h-512l256 128v576h256v-192z"
                    className=""
                  ></path>
                </svg>
              </button>
            </div>
          </div>

        </div>
        <div className='aside-overflow'>
          {/*<div className="aside-container">
            <div className="box-control">
              <a href='/'>
                <span to="/meu-perfil">
                  <img
                    alt="image"
                    src={usuarioAtual.foto_perfil ? usuarioAtual.foto_perfil : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png'}
                    className="aside-image"
                  />
                </span>
              </a>
            </div>
            <div style={{ display: 'flex', gap: '5px', flexDirection: 'column', alignItems: 'center' }}>
              <span className="aside-text">{usuarioAtual.nome}</span>
            </div>
  </div>
          {(!usuarioAtual.onboarding || (usuarioAtual.onboarding && !usuarioAtual.onboarding.concluido)) && (
            <div className="aside-container1">
              <span className="aside-text1">Progresso nos Primeiros Passos</span>
              <div className="aside-container2">
                <div className="aside-container3"
                  style={{
                    width: `${progresso}%`,
                    borderRadius: progresso === 100 ? '10px' : '10px 0 0 10px'
                  }}
                ></div>
              </div>
            </div>
          )}
          */}
          <div className="aside-menu">
            {usuarioAtual.tipo === "admin" && (
              <a href='/admin' className="aside-navlink">
                <div className="aside-container4" style={{ background: 'linear-gradient(45deg, #c5590f, #cf6c28)', border: "none", paddingLeft: '25px' }}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-align-box-bottom-left-filled" width="18" height="18" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M18.333 2c1.96 0 3.56 1.537 3.662 3.472l.005 .195v12.666c0 1.96 -1.537 3.56 -3.472 3.662l-.195 .005h-12.666a3.667 3.667 0 0 1 -3.662 -3.472l-.005 -.195v-12.666c0 -1.96 1.537 -3.56 3.472 -3.662l.195 -.005h12.666zm-12.333 13a1 1 0 0 0 -1 1v2l.007 .117a1 1 0 0 0 1.993 -.117v-2l-.007 -.117a1 1 0 0 0 -.993 -.883zm3 -4a1 1 0 0 0 -1 1v6l.007 .117a1 1 0 0 0 1.993 -.117v-6l-.007 -.117a1 1 0 0 0 -.993 -.883zm3 2a1 1 0 0 0 -1 1v4l.007 .117a1 1 0 0 0 1.993 -.117v-4l-.007 -.117a1 1 0 0 0 -.993 -.883z" strokeWidth="0" fill="white" />
                  </svg>
                  <span className="aside-text2" style={{ color: '#fff' }}>Administração</span>
                </div>
              </a>
            )}
            {usuarioAtual.tipo === "consultor" && (
              <a href="/consultor" className="aside-navlink">
                <div className="aside-container4" style={{ background: 'linear-gradient(45deg, #c5590f, #cf6c28)', border: "none", paddingLeft: '25px' }}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-align-box-bottom-left-filled" width="18" height="18" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M18.333 2c1.96 0 3.56 1.537 3.662 3.472l.005 .195v12.666c0 1.96 -1.537 3.56 -3.472 3.662l-.195 .005h-12.666a3.667 3.667 0 0 1 -3.662 -3.472l-.005 -.195v-12.666c0 -1.96 1.537 -3.56 3.472 -3.662l.195 -.005h12.666zm-12.333 13a1 1 0 0 0 -1 1v2l.007 .117a1 1 0 0 0 1.993 -.117v-2l-.007 -.117a1 1 0 0 0 -.993 -.883zm3 -4a1 1 0 0 0 -1 1v6l.007 .117a1 1 0 0 0 1.993 -.117v-6l-.007 -.117a1 1 0 0 0 -.993 -.883zm3 2a1 1 0 0 0 -1 1v4l.007 .117a1 1 0 0 0 1.993 -.117v-4l-.007 -.117a1 1 0 0 0 -.993 -.883z" strokeWidth="0" fill="white" />
                  </svg>
                  <span className="aside-text2" style={{ color: '#fff' }}>Área do consultor</span>
                </div>
              </a>
            )}

            <div className='subtitulo-menu'>
              <h3>Acelere seu negócio</h3>
              <p>Ferramentas práticas para acelerar seu negócio</p>
            </div>
            <a href='/' className={`aside-navlink ${location.pathname === '/' ? 'activePage' : ''}`}>
              <div className="aside-container4">
                <svg viewBox="0 0 1024 1024" className="aside-icon">
                  <path
                    d="M426 854h-212v-342h-128l426-384 426 384h-128v342h-212v-256h-172v256z"
                    className=""
                  ></path>
                </svg>
                <span className="aside-text2">Início</span>
              </div>
            </a>

            <button onClick={() => {
              props.toggleAside()
              if (props.agendarConsultoria) props.agendarConsultoria()
              /*if (props.toggleAside) props.toggleAside()*/
            }} className="aside-navlink2">
              <span className="aside-container4">
                <svg viewBox="0 0 804.5714285714286 1024" className="aside-icon">
                  <path d="M804.571 420.571v109.714c0 30.286-24.571 54.857-54.857 54.857h-237.714v237.714c0 30.286-24.571 54.857-54.857 54.857h-109.714c-30.286 0-54.857-24.571-54.857-54.857v-237.714h-237.714c-30.286 0-54.857-24.571-54.857-54.857v-109.714c0-30.286 24.571-54.857 54.857-54.857h237.714v-237.714c0-30.286 24.571-54.857 54.857-54.857h109.714c30.286 0 54.857 24.571 54.857 54.857v237.714h237.714c30.286 0 54.857 24.571 54.857 54.857z"></path>
                </svg>
                <span className="aside-text2">Consultorias Mão na Massa</span>
              </span>
            </button>

            <a href='/meus-colaboradores' className={`aside-navlink2 ${location.pathname === '/meus-colaboradores' ? 'activePage' : ''}`}>
              <div className="aside-container4">
                <svg viewBox="0 0 1024 1024" className="aside-icon">
                  <path
                    d="M598 256v-86h-172v86h172zM854 256q36 0 60 25t24 61v468q0 36-24 61t-60 25h-684q-36 0-60-25t-24-61v-468q0-36 24-61t60-25h172v-86q0-36 24-60t60-24h172q36 0 60 24t24 60v86h172z"
                    className=""
                  ></path>
                </svg>
                <span className="aside-text2">Adicione seus Colaboradores</span>
              </div>
            </a>

            <a target="_blank" href={`${process.env.REACT_APP_COMUNIDADE_URL}`} className="aside-navlink">
              <span className="aside-container4">
                <svg viewBox="0 0 1152 1024" className="aside-icon">
                  <path
                    d="M480 0v0c265.096 0 480 173.914 480 388.448s-214.904 388.448-480 388.448c-25.458 0-50.446-1.62-74.834-4.71-103.106 102.694-222.172 121.108-341.166 123.814v-25.134c64.252-31.354 116-88.466 116-153.734 0-9.106-0.712-18.048-2.030-26.794-108.558-71.214-177.97-179.988-177.97-301.89 0-214.534 214.904-388.448 480-388.448zM996 870.686c0 55.942 36.314 104.898 92 131.772v21.542c-103.126-2.318-197.786-18.102-287.142-106.126-21.14 2.65-42.794 4.040-64.858 4.040-95.47 0-183.408-25.758-253.614-69.040 144.674-0.506 281.26-46.854 384.834-130.672 52.208-42.252 93.394-91.826 122.414-147.348 30.766-58.866 46.366-121.582 46.366-186.406 0-10.448-0.45-20.836-1.258-31.168 72.57 59.934 117.258 141.622 117.258 231.676 0 104.488-60.158 197.722-154.24 258.764-1.142 7.496-1.76 15.16-1.76 22.966z"
                    className=""
                  ></path>
                </svg>
                <span className="aside-text2">Acesse a Comunidade</span>
              </span>
            </a>

            <div className='subtitulo-menu'>
              <h3>Destrave seu negócio</h3>
              <p>Conteúdos e benefícios exclusivos para você destravar e ir além</p>
            </div>

            <a href='/' className="aside-navlink">
              <span to={`/master/chave-mestra`} className={`aside-navlink2 ${location.pathname === '/master/chave-mestra' ? 'activePage' : ''}`}>
                <div className="aside-container4">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className='aside-icon'>

                    <g id="SVGRepo_bgCarrier" strokeWidth="0" />

                    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />

                    <g id="SVGRepo_iconCarrier"> <path fillRule="evenodd" clipRule="evenodd" d="M22 8.29344C22 11.7692 19.1708 14.5869 15.6807 14.5869C15.0439 14.5869 13.5939 14.4405 12.8885 13.8551L12.0067 14.7333C11.4883 15.2496 11.6283 15.4016 11.8589 15.652C11.9551 15.7565 12.0672 15.8781 12.1537 16.0505C12.1537 16.0505 12.8885 17.075 12.1537 18.0995C11.7128 18.6849 10.4783 19.5045 9.06754 18.0995L8.77362 18.3922C8.77362 18.3922 9.65538 19.4167 8.92058 20.4412C8.4797 21.0267 7.30403 21.6121 6.27531 20.5876L5.2466 21.6121C4.54119 22.3146 3.67905 21.9048 3.33616 21.6121L2.45441 20.7339C1.63143 19.9143 2.1115 19.0264 2.45441 18.6849L10.0963 11.0743C10.0963 11.0743 9.3615 9.90338 9.3615 8.29344C9.3615 4.81767 12.1907 2 15.6807 2C19.1708 2 22 4.81767 22 8.29344ZM15.681 10.4889C16.8984 10.4889 17.8853 9.50601 17.8853 8.29353C17.8853 7.08105 16.8984 6.09814 15.681 6.09814C14.4635 6.09814 13.4766 7.08105 13.4766 8.29353C13.4766 9.50601 14.4635 10.4889 15.681 10.4889Z" /> </g>

                  </svg>
                  <span className="aside-text2">Automações</span>
                </div>
              </span>
            </a>

            <a href='/' className="aside-navlink">
              <span to={`/shorts`} className={`aside-navlink2 ${location.pathname === '/shorts' ? 'activePage' : ''}`}>
                <div className="aside-container4">
                  <svg viewBox="0 0 1024 1024" className="aside-icon">
                    <path d="M406.286 644.571l276.571-142.857-276.571-144.571v287.429zM512 152c215.429 0 358.286 10.286 358.286 10.286 20 2.286 64 2.286 102.857 43.429 0 0 31.429 30.857 40.571 101.714 10.857 82.857 10.286 165.714 10.286 165.714v77.714s0.571 82.857-10.286 165.714c-9.143 70.286-40.571 101.714-40.571 101.714-38.857 40.571-82.857 40.571-102.857 42.857 0 0-142.857 10.857-358.286 10.857v0c-266.286-2.286-348-10.286-348-10.286-22.857-4-74.286-2.857-113.143-43.429 0 0-31.429-31.429-40.571-101.714-10.857-82.857-10.286-165.714-10.286-165.714v-77.714s-0.571-82.857 10.286-165.714c9.143-70.857 40.571-101.714 40.571-101.714 38.857-41.143 82.857-41.143 102.857-43.429 0 0 142.857-10.286 358.286-10.286v0z"></path>
                  </svg>

                  <span className="aside-text2">Shorts - Direto ao Ponto</span>
                </div>
              </span>
            </a>


            <a href='/' className="aside-navlink">
              <span to={`/master/treinamento-colaboradores`} className={`aside-navlink2 ${location.pathname === '/treinamento-colaboradores' ? 'activePage' : ''}`}>
                <div className="aside-container4">
                  <svg viewBox="0 0 512 512" className='aside-icon'><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <g> <path d="M256,0c-62.052,0-112.532,50.481-112.532,112.532c0,62.05,50.481,112.532,112.532,112.532s112.532-50.483,112.532-112.532 C368.532,50.481,318.052,0,256,0z"></path> </g> </g> <g> <g> <path d="M282.9,280.617v27.155v27.28v114.18c0,3.149-0.568,6.158-1.564,8.967c-3.696,10.439-13.629,17.933-25.336,17.933 s-21.638-7.494-25.336-17.933c-0.995-2.808-1.564-5.818-1.564-8.967v-114.18v-27.28v-27.155 C128.045,293.84,49.765,380.496,49.765,485.1c0,14.854,12.044,26.9,26.9,26.9h358.669c14.856,0,26.9-12.046,26.9-26.9 C462.235,380.498,383.955,293.841,282.9,280.617z"></path> </g> </g> </g></svg>

                  <span className="aside-text2">Treinamento para Colaboradores</span>
                </div>
              </span>
            </a>

            <div className='subtitulo-menu'>
              <h3>Seu negócio em outro nível</h3>
              <p>Temos ainda mais para te oferecer. Conteúdos de outro nível que vão levar o seu negócio à outro patamar.</p>
            </div>


            <a href='/' className="aside-navlink" disabled>
              <span to={`#`} className={`aside-navlink2 ${location.pathname === '/conteudos-especialistas' ? 'activePage' : ''}`}>
                <div className="aside-container4">
                  <svg viewBox="0 0 48 48" className="aside-icon">
                    <g id="Layer_2" data-name="Layer 2">
                      <g id="invisible_box" data-name="invisible box">
                        <rect width="48" height="48" fill="none" />
                      </g>
                      <g id="icons_Q2" data-name="icons Q2">
                        <path d="M40,18A16,16,0,1,0,15,31.2V43.9a2,2,0,0,0,3.1,1.7L24,41l5.9,4.6A2,2,0,0,0,33,43.9V31.2A16,16,0,0,0,40,18ZM12,18A12,12,0,1,1,24,30,12,12,0,0,1,12,18Z" />
                        <path d="M20.6,23.4l-4-3.9a2.1,2.1,0,0,1-.2-2.7,1.9,1.9,0,0,1,3-.2L22,19.2l6.6-6.6a2,2,0,0,1,2.8,2.8l-8,8A1.9,1.9,0,0,1,20.6,23.4Z" />
                      </g>
                    </g>
                  </svg>

                  <span className="aside-text2">Conteúdos de Especialistas <span className='em-breve-menu'>Em breve</span></span>
                </div>
              </span>
            </a>



            <a href='/' className="aside-navlink" disabled>
              <span to={`#`} className={`aside-navlink2 ${location.pathname === '/sugestoes-leitura' ? 'activePage' : ''}`}>
                <div className="aside-container4">
                  <svg viewBox="-63 65 128 128" className='aside-icon'><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M1,102.1c9.7,0,17.5-7.9,17.5-17.6S10.7,66.9,1,66.9s-17.6,7.8-17.6,17.6C-16.6,94.2-8.7,102.1,1,102.1 M18,106.3 c8,0.3,11.4,6.7,11.4,6.7l25.9,35.6c1.1,1.6,1.7,3.5,1.7,5.6c0,5.5-4.5,10-10,10c-1.3,0-2.5-0.3-3.7-0.7l-15.6-4.4v15.6h-54.2v-15.6 l-15.6,4.4c-1.1,0.4-2.3,0.7-3.7,0.7c-5.5,0-10-4.5-10-10c0-2.1,0.6-4,1.7-5.6l25.9-35.6c0,0,3.4-6.4,11.4-6.7H18z M0.6,163.8 L0.6,163.8l20.3-6.7l-0.5-0.1c-14-4.1-8.6-22.6,5.4-18.5l1.9,0.7v-20.9l-27.1,8.9l-27.2-8.9v20.9l1.9-0.7c14-4.1,19.4,14.3,5.4,18.5 l-0.5,0.1L0.6,163.8z M56.4,188.6c1.4,0,2.5-1.4,2.5-3.3c0-1.9-1.1-3.3-2.5-3.3H-53.2c-1.4,0-2.5,1.5-2.5,3.3c0,1.8,1.1,3.3,2.5,3.3 H56.4z"></path> </g></svg>

                  <span className="aside-text2">Sugestões de Leitura <span className='em-breve-menu'>Em breve</span></span>
                </div>
              </span>
            </a>


            <a href='/' className="aside-navlink" disabled>
              <span to={`#`} className={`aside-navlink2 ${location.pathname === '/ia' ? 'activePage' : ''}`}>
                <div className="aside-container4">
                  <svg viewBox="0 0 48 48" className='aside-icon'><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <title>ai-solid</title> <g id="Layer_2" data-name="Layer 2"> <g id="invisible_box" data-name="invisible box"> <rect width="48" height="48" fill="none"></rect> </g> <g id="Q3_icons" data-name="Q3 icons"> <g> <path d="M17.9,2h-.4L7.6,6.6a1,1,0,0,0-.6.9v7.4l-.6.5-4,3.3a.8.8,0,0,0-.4.8v9a.9.9,0,0,0,.4.8l4,3.3.6.5v7.4a1,1,0,0,0,.6.9l9.9,4.5h.4l.6-.2,4-2.7V25.5H21a1.5,1.5,0,0,1,0-3h1.5V4.9l-4-2.7ZM9,13.5l2.8,1.9a1.5,1.5,0,0,1,.4,2.1,1.4,1.4,0,0,1-1.2.7,1.1,1.1,0,0,1-.8-.3L9,17.1Zm-5,9H7.5a1.5,1.5,0,0,1,0,3H4Zm5,8.4,1.2-.8a1.4,1.4,0,0,1,2,.4,1.5,1.5,0,0,1-.4,2.1L9,34.5ZM19.5,18.6l-4,4v2.8l4,4v5.2l-3.4,3.5a2.1,2.1,0,0,1-1.1.4,2.1,2.1,0,0,1-1.1-.4,1.6,1.6,0,0,1,0-2.2l2.6-2.5V30.6l-4-4V21.4l4-4V14.6l-2.6-2.5a1.6,1.6,0,1,1,2.2-2.2l3.4,3.5Z"></path> <path d="M45.6,18.7l-4-3.3-.6-.5V7.5a1,1,0,0,0-.6-.9L30.5,2.1h-.4l-.6.2-4,2.7V22.5H27a1.5,1.5,0,0,1,0,3H25.5V43.1l4,2.7.6.2h.4l9.9-4.5a1,1,0,0,0,.6-.9V33.1l.6-.5,4-3.3a.9.9,0,0,0,.4-.8v-9A.8.8,0,0,0,45.6,18.7ZM39,17.1l-1.2.8a1.1,1.1,0,0,1-.8.3,1.4,1.4,0,0,1-1.2-.7,1.5,1.5,0,0,1,.4-2.1L39,13.5ZM28.5,29.4l4-4V22.6l-4-4V13.4l3.4-3.5a1.6,1.6,0,0,1,2.2,2.2l-2.6,2.5v2.8l4,4v5.2l-4,4v2.8l2.6,2.5a1.6,1.6,0,0,1,0,2.2,1.7,1.7,0,0,1-2.2,0l-3.4-3.5ZM39,34.5l-2.8-1.9a1.5,1.5,0,0,1-.4-2.1,1.4,1.4,0,0,1,2-.4l1.2.8Zm5-9H40.5a1.5,1.5,0,0,1,0-3H44Z"></path> </g> </g> </g> </g></svg>
                  <span className="aside-text2">IA do Simbium <span className='em-breve-menu'>Em breve</span></span>
                </div>
              </span>
            </a>


            <a href='/' className="aside-navlink" disabled>
              <span to={`#`} className={`aside-navlink2 ${location.pathname === '/club-beneficios' ? 'activePage' : ''}`}>
                <div className="aside-container4">
                  <svg viewBox="0 0 23.382 23.382" className='aside-icon'><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path d="M14.58,15.181c0.438-0.732,1.082-1.107,1.936-1.117c0.854-0.01,1.285-0.445,1.299-1.301 c0.012-0.852,0.383-1.496,1.115-1.932c0.734-0.438,0.893-1.027,0.475-1.773c-0.416-0.744-0.416-1.488,0-2.234 c0.418-0.744,0.26-1.332-0.475-1.77c-0.732-0.439-1.104-1.082-1.115-1.938c-0.014-0.852-0.445-1.287-1.299-1.297 c-0.854-0.012-1.498-0.385-1.936-1.117c-0.438-0.734-1.027-0.893-1.771-0.475c-0.744,0.416-1.49,0.416-2.234,0 C9.83-0.19,9.241-0.032,8.803,0.702C8.366,1.435,7.721,1.808,6.868,1.819c-0.852,0.01-1.285,0.445-1.297,1.297 C5.557,3.972,5.186,4.614,4.454,5.054C3.72,5.492,3.559,6.079,3.979,6.824c0.418,0.746,0.418,1.49,0,2.234 c-0.42,0.746-0.26,1.336,0.475,1.773c0.732,0.436,1.104,1.08,1.117,1.932c0.012,0.855,0.445,1.291,1.297,1.301 c0.854,0.01,1.498,0.385,1.936,1.117c0.438,0.734,1.027,0.893,1.771,0.473c0.744-0.412,1.49-0.412,2.234,0 C13.553,16.073,14.143,15.915,14.58,15.181z M11.694,12.614c-2.582,0-4.674-2.092-4.674-4.674c0-2.58,2.092-4.672,4.674-4.672 c2.58,0,4.672,2.092,4.672,4.672C16.366,10.522,14.274,12.614,11.694,12.614z"></path> <path d="M6.793,14.749c-0.898,0-1.018-0.418-1.018-0.418l-3.507,6.893l2.812-0.316l1.555,2.34c0,0,3.24-6.76,3.24-6.715 C8.196,16.491,8.864,14.794,6.793,14.749z"></path> <path d="M17.563,14.601c-2.562,0.268-2.041,0.904-2.627,1.398c-0.674,0.719-1.516,0.578-1.516,0.578l3.955,6.805l0.973-2.52 l2.766,0.361L17.563,14.601z"></path> <polygon points="12.67,6.909 11.692,4.929 10.713,6.909 8.524,7.229 10.106,8.772 9.733,10.954 11.692,9.925 13.651,10.954 13.278,8.772 14.86,7.229 "></polygon> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> </g> </g></svg>

                  <span className="aside-text2">Clube de Benefícios <span className='em-breve-menu'>Em breve</span></span>
                </div>
              </span>
            </a>

            {/*<a href='/' onClick={() => {
              history.push('/meus-cursos')
            }} className={`aside-navlink1 ${location.pathname === '/meus-cursos' ? 'activePage' : ''}`}>
              <div className="aside-container4">
                <svg viewBox="0 0 1024 1024" className="aside-icon">
                  <path
                    d="M512 128l470 256v342h-86v-296l-384 210-470-256zM214 562l298 164 298-164v172l-298 162-298-162v-172z"
                    className=""
                  ></path>
                </svg>
                <span className="aside-text2">Meus Treinamentos</span>
              </div>
            </a>*/}
          </div>
          {/*<div className="aside-footer"></div>*/}
        </div>
      </div>
    </aside >
  )
}


export default Aside
