import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify'
import styles from './notificacoes.module.css';
import axios from 'axios'


const TabelaNotificacoes = (props) => {

    const [data, setData] = useState({
        icone: '<svg style="width:70%;height:auto;" xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-wand" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="#fff" fill="none" strokeLinecap="round" strokeLinejoin="round">    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>    <path d="M6 21l15 -15l-3 -3l-15 15l3 3"></path>    <path d="M15 6l3 3"></path>    <path d="M9 3a2 2 0 0 0 2 2a2 2 0 0 0 -2 2a2 2 0 0 0 -2 -2a2 2 0 0 0 2 -2"></path>    <path d="M19 13a2 2 0 0 0 2 2a2 2 0 0 0 -2 2a2 2 0 0 0 -2 -2a2 2 0 0 0 2 -2"></path> </svg>',
        foto: '',
        mensagem: 'Escreva uma mensagem criativa para seus usuários',
        link: '',
        publico: 'Todos'
    });

    const [notificacoesHoje, setNotificacoesHoje] = useState(0);
    const [processando, setProcessando] = useState(false);
    const [postFotoUrl, setPostFotoUrl] = useState('');
    const [publicFotoUrl, setPublicFotoUrl] = useState('');
    const [postFotoFile, setPostFotoFile] = useState('');
    const [flag, setFlag] = useState(false);

    const uploadFile = async (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            console.log("File selected:", selectedFile); // Verificar os dados do arquivo
            setProcessando(true);
            setPostFotoFile(selectedFile);

            const tempUrl = URL.createObjectURL(selectedFile);
            setData((prevData) => ({ ...prevData, foto: tempUrl }));

            try {
                // Verificar dados enviados na requisição
                console.log("Enviando requisição com: ", {
                    fileName: selectedFile.name,
                    fileType: selectedFile.type
                });

                const response = await axios.post(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}/notificacoes/upload-url`,
                    {
                        fileName: selectedFile.name,
                        fileType: selectedFile.type,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('token')}`,
                        },
                    }
                );

                const { uploadUrl, publicUrl } = response.data;
                setPostFotoUrl(uploadUrl);
                setPublicFotoUrl(publicUrl);

                setData((prevData) => ({ ...prevData, foto: tempUrl }));

            } catch (error) {
                console.log("Erro ao fazer upload:", error.response.data); // Log do erro
                setData((prevData) => ({ ...prevData, foto: '' }));
                toast.error('Erro ao fazer o upload da imagem.');
            } finally {
                event.target.value = null;
                setProcessando(false);
            }
        } else {
            console.log("Nenhum arquivo selecionado");
        }
    };


    const uploadOnGoogleStorage = async () => {
        try {
            // Fazer o upload do arquivo para o Google Cloud Storage usando a URL assinada
            await axios.put(postFotoUrl, postFotoFile, {
                headers: {
                    'Content-Type': postFotoFile.type,
                },
            });
            return true;
        } catch (err) {
            console.log("ERRO:", err);
            toast.error('Erro ao fazer upload da foto!');
            return false;
        }
    };


    /* useEffect(() => {
            if (flag) {
                enviarNotificacao();
            }
            setFlag(true);
        }, [data])
        */

    const enviarNotificacao = async () => {
        setProcessando(true);

        let uploadSuccess = true;

        try {
            // Fazer upload da imagem, se houver
            if (data.foto) {
                const uploadResult = await uploadOnGoogleStorage();
                if (!uploadResult) {
                    uploadSuccess = false; // Se o upload falhar
                }
            }

            if (uploadSuccess) {
                // Enviar notificação para o backend
                try {
                    const response = await axios.post(
                        `${process.env.REACT_APP_BACKEND_BASE_URL}/notificacoes/enviar`,
                        { ...data, foto: publicFotoUrl || "undefined" },
                        {
                            headers: {
                                Authorization: `Bearer ${localStorage.getItem("token")}`
                            }
                        }
                    );

                    // Atualizar a lista de notificações
                    getNotificacoesHoje();

                    // Notificar sucesso ao usuário
                    toast.success('Notificação enviada com sucesso!');

                    // Resetar os dados do formulário
                    setData({
                        icone: '',
                        foto: '',
                        mensagem: '',
                        link: '',
                        publico: 'Todos'
                    });

                } catch (error) {
                    // Excluir foto da nuvem se houver erro ao enviar notificação
                    if (publicFotoUrl) {
                        try {
                            await axios.delete(`${process.env.REACT_APP_BACKEND_BASE_URL}/notificacoes/delete-foto`, {
                                data: { fotoUrl: publicFotoUrl },
                                headers: {
                                    Authorization: `Bearer ${localStorage.getItem("token")}`
                                }
                            });
                        } catch (deleteError) {
                            console.error("Erro ao excluir a foto:", deleteError);
                        }
                    }

                    console.error("Erro ao enviar notificação:", error);
                    toast.error("Houve um erro ao enviar a notificação.");
                }
            }

        } catch (error) {
            console.error("Erro no upload da foto:", error);
            toast.error("Houve um erro ao fazer o upload da foto.");
        } finally {
            setProcessando(false); // Garantir que o processando seja falso no final
        }
    };



    const getNotificacoesHoje = async () => {
        setProcessando(true);
        try {

            const result = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/notificacoes/total-hoje`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            });
            setFlag(false)
            setNotificacoesHoje(result.data)

        } catch (err) {
            toast.error("Erro ao tentar recuperar as notificações cadastradas")
            console.log("ERRO: ", err)

        } finally {
            setProcessando(false);
        }

    };

    useEffect(() => {
        getNotificacoesHoje();
    }, [])

    return (
        <div className={styles['tabela-aulas']}>
            <div className={styles['container']}>
                <span className={styles['text']}>Notificações</span>
            </div>
            <div className={styles['container01']}>
                <div className={styles['form']}>
                    <div className={styles['container02']}>
                        <span className={styles['text01']}>
                            <span>Ícone SVG </span>
                        </span>
                        <input
                            type="text"
                            className={`${styles['input-dark']} disabled:opacity-50`}
                            value={data.icone}
                            disabled={processando}
                            onChange={(e) => setData({ ...data, icone: e.target.value })}
                        />
                    </div>
                    <div className={styles['container3']} onClick={() => document.getElementById("file-input").click()}>
                        <span className={styles['label1']}>Foto</span>
                        <div className={styles['container4']}>
                            <img
                                alt="image"
                                src={data.foto ? data.foto : '../default-img.svg'}
                                className={`${styles['image']} ${processando ? 'opacity-50' : ''}`}
                            />
                            <div className={styles['fechar-popup1']} id="upload-file">
                                <label>
                                    <svg
                                        viewBox="0 0 804.5714285714286 1024"
                                        className={styles['icon2']}
                                    >
                                        <path d="M804.571 420.571v109.714c0 30.286-24.571 54.857-54.857 54.857h-237.714v237.714c0 30.286-24.571 54.857-54.857 54.857h-109.714c-30.286 0-54.857-24.571-54.857-54.857v-237.714h-237.714c-30.286 0-54.857-24.571-54.857-54.857v-109.714c0-30.286 24.571-54.857 54.857-54.857h237.714v-237.714c0-30.286 24.571-54.857 54.857-54.857h109.714c30.286 0 54.857 24.571 54.857 54.857v237.714h237.714c30.286 0 54.857 24.571 54.857 54.857z"></path>
                                    </svg>
                                </label>
                                <input
                                    id="file-input"
                                    type="file"
                                    style={{ display: 'none' }}
                                    disabled={processando}
                                    accept="image/jpeg, image/png, image/gif"
                                    onChange={uploadFile}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={styles['container04']}>
                        <span className={styles['text05']}>Mensagem</span>
                        <input
                            type="text"
                            className={`${styles['input-dark']} disabled:opacity-50`}
                            disabled={processando}
                            value={data.mensagem}
                            onChange={(e) => setData({ ...data, mensagem: e.target.value })}
                        />
                    </div>
                    <div className={styles['container05']}>
                        <span className={styles['text06']}>Link</span>
                        <input
                            type="text"
                            className={`${styles['input-dark']} disabled:opacity-50`}
                            disabled={processando}
                            value={data.link}
                            onChange={(e) => setData({ ...data, link: e.target.value })}
                        />
                    </div>
                    <div className={styles['container06']}>
                        <span className={styles['text07']}>Público</span>
                        <select
                            className={`${styles['dropdown']} disabled:opacity-50`}
                            disabled={processando}
                            value={data.publico}
                            onChange={(e) => setData({ ...data, publico: e.target.value })}
                        >
                            <option value="">Todos</option>
                            <option value="usuario">Usuários</option>
                            <option value="professor">Professores</option>
                            <option value="admin">Admin</option>
                        </select>
                    </div>
                    <button
                        disabled={data?.icone === '' || data?.foto === '' || data?.mensagem === '' || processando}
                        className={`${styles['btn-add']} disabled:opacity-50`}
                        onClick={enviarNotificacao}
                    >
                        <svg
                            viewBox="0 0 1025.1702857142857 1024"
                            className={styles['icon']}
                        >
                            <path d="M1008 6.286c12 8.571 17.714 22.286 15.429 36.571l-146.286 877.714c-1.714 10.857-8.571 20-18.286 25.714-5.143 2.857-11.429 4.571-17.714 4.571-4.571 0-9.143-1.143-13.714-2.857l-258.857-105.714-138.286 168.571c-6.857 8.571-17.143 13.143-28 13.143-4 0-8.571-0.571-12.571-2.286-14.286-5.143-24-18.857-24-34.286v-199.429l493.714-605.143-610.857 528.571-225.714-92.571c-13.143-5.143-21.714-17.143-22.857-31.429-0.571-13.714 6.286-26.857 18.286-33.714l950.857-548.571c5.714-3.429 12-5.143 18.286-5.143 7.429 0 14.857 2.286 20.571 6.286z"></path>
                        </svg>                        <span className={styles['text08']}>Enviar</span>
                    </button>
                </div>
                <div className={styles['container07']}>
                    <div className={styles['container08']}>
                        <div className={styles['container09']}>
                            <img
                                alt="image"
                                src={data.foto || 'https://images.unsplash.com/photo-1605711285791-0219e80e43a3?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDIzfHx0ZWFjaGVyfGVufDB8fHx8MTY5MTUwNjM1Nnww&amp;ixlib=rb-4.0.3&amp;w=200'}
                                className={styles['image']}
                            />
                            <div className={styles['container10']} dangerouslySetInnerHTML={{ __html: data.icone }}>

                            </div>
                        </div>
                        <div className={styles['container11']}>
                            <span className={styles['text09']}>{data.mensagem}</span>
                            <span className={styles['text10']}>há alguns segundos</span>
                        </div>
                        <div className={styles['container12']}></div>
                    </div>
                    <div className={styles['stats']}>
                        <svg viewBox="0 0 1024 1024" className={styles['icon4']}>
                            <path d="M1025.5 800c0-288-256-224-256-448 0-18.56-1.788-34.42-5.048-47.928-16.83-113.018-92.156-203.72-189.772-231.36 0.866-3.948 1.32-8.032 1.32-12.21 0-33.278-28.8-60.502-64-60.502s-64 27.224-64 60.5c0 4.18 0.456 8.264 1.32 12.21-109.47 30.998-190.914 141.298-193.254 273.442-0.040 1.92-0.066 3.864-0.066 5.846 0 224.002-256 160.002-256 448.002 0 76.226 170.59 139.996 398.97 156.080 21.524 40.404 64.056 67.92 113.030 67.92s91.508-27.516 113.030-67.92c228.38-16.084 398.97-79.854 398.97-156.080 0-0.228-0.026-0.456-0.028-0.682l1.528 0.682zM826.246 854.096c-54.23 14.47-118.158 24.876-186.768 30.648-5.704-65.418-60.582-116.744-127.478-116.744s-121.774 51.326-127.478 116.744c-68.608-5.772-132.538-16.178-186.768-30.648-74.63-19.914-110.31-42.19-123.368-54.096 13.058-11.906 48.738-34.182 123.368-54.096 86.772-23.152 198.372-35.904 314.246-35.904s227.474 12.752 314.246 35.904c74.63 19.914 110.31 42.19 123.368 54.096-13.058 11.906-48.738 34.182-123.368 54.096z"></path>
                        </svg>                        <span className={styles['text11']}>
                            <span className={styles['text12']}>Enviadas hoje</span>
                            <br></br>
                            <span className={styles['text14']}>{notificacoesHoje}</span>
                            <br></br>
                            <span>notificações</span>
                            <br></br>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default TabelaNotificacoes;
