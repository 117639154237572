import React, { useState, useEffect } from 'react'
import RichTextEditor from '../richtext'
import axios from 'axios'
import { debounce } from 'lodash';
import { toast } from 'react-toastify'

import styles from './add_aula.module.css'
import { configureStore } from '@reduxjs/toolkit';




const PopupAdminAula = (props) => {
    const [nome, setNome] = useState(props.itemPopup && props.itemPopup.nome ? props.itemPopup.nome : '')
    const [curso, setCurso] = useState(props.itemPopup && props.itemPopup.cursoId ? props.itemPopup.cursoId : '')
    const [modulo, setModulo] = useState(props.itemPopup && props.itemPopup.moduloId ? props.itemPopup.moduloId : '')
    const [video, setVideo] = useState(props.itemPopup && props.itemPopup.video ? props.itemPopup.video : '')
    const [descricao, setDescricao] = useState(props.itemPopup && props.itemPopup.descricao ? props.itemPopup.descricao : '')
    const [professores, setProfessores] = useState(props.itemPopup && props.itemPopup.professores ? props.itemPopup.professores : [])
    const [shorts, setShorts] = useState(props.itemPopup && props.itemPopup.shorts ? props.itemPopup.shorts : true)
    const [listaProfessores, setListaProfessores] = useState([])
    const [professoresInput, setProfessoresInput] = useState('')
    const [tags, setTags] = useState(props.itemPopup && props.itemPopup.tags ? props.itemPopup.tags : [])
    const [tagsInput, setTagsInput] = useState('');
    const [capa, setCapa] = useState(props.itemPopup && props.itemPopup.capa ? props.itemPopup.capa : '')
    const [materiais, setMateriais] = useState(props.itemPopup && props.itemPopup.materiais ? props.itemPopup.materiais : [])
    const [materiaisFiles, setMateriaisFiles] = useState([]);
    const [loading, setLoading] = useState(false)
    const [importarAula, setImportarAula] = useState(false)
    const [filtroCurso, setFiltroCurso] = useState(null)
    const [listaCursos, setListaCursos] = useState([])
    const [filtroModulo, setFiltroModulo] = useState(null)
    const [listaModulos, setListaModulos] = useState([])
    const [listaAulas, setListaAulas] = useState([]);
    const [aulaImportada, setAulaImportada] = useState('');
    const [videosPesquisa, setVideosPesquisa] = useState([]);

    const [processando, setProcessando] = useState();

    // const [postCapaUrl, setPostCapaUrl] = useState();
    // const [publicCapaUrl, setPublicCapaUrl] = useState();
    const [postCapaFile, setPostCapaFile] = useState();
    // const [postVideoUrl, setPostVideoUrl] = useState();
    // const [publicVideoUrl, setPublicVideoUrl] = useState();
    const [postVideoFile, setPostVideoFile] = useState();


    const fetchVideosPesquisa = async (searchBunny) => {
        setVideo('')
        setVideosPesquisa(["loading"]);

        if (searchBunny !== '') {
            try {

                const response = await axios.get(`https://video.bunnycdn.com/library/${process.env.REACT_APP_BUNNY_STREAM_LIBRARY_ID}/videos`, {
                    params: {
                        page: 1,
                        itemsPerPage: 4,
                        orderBy: 'date',
                        search: searchBunny
                    },
                    headers: {
                        accept: 'application/json',
                        AccessKey: `${process.env.REACT_APP_BUNNY_STREAM_TOKEN}`
                    }
                })

                if (response.data && response.data.items && response.data.items.length > 0) {
                    const items = response.data.items.map(item => {
                        item.capa = `https://vz-01910c8b-ac9.b-cdn.net/${item?.guid}/thumbnail.jpg`
                        item.frame = `<div style="position:relative;padding-top:56.25%;"><iframe src="https://iframe.mediadelivery.net/embed/${process.env.REACT_APP_BUNNY_STREAM_LIBRARY_ID}/${item?.guid}?autoplay=true&loop=false&muted=false&preload=true" loading="lazy" style="border:none;position:absolute;top:0;height:100%;width:100%;" allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;" allowfullscreen="true"></iframe></div>`;
                        return item;
                    })
                    setVideosPesquisa(items.slice(0, 4))
                } else {
                    setVideosPesquisa([])
                }

            } catch (err) {
            }
        } else {
            setVideosPesquisa([])
        }
    }

    const handleFileUpload = (event, setFileState, setTempUrlState) => {
        const selectedFile = event.target.files[0];
        if (!selectedFile) return;

        setFileState(selectedFile);
        setTempUrlState(URL.createObjectURL(selectedFile)); // Creates a temporary URL for preview
    };

    const uploadFileToCloud = async (file, uploadUrl) => {
        try {
            await axios.put(uploadUrl, file, {
                headers: {
                    'Content-Type': file.type,
                },
            });
        } catch (error) {
            console.error('Erro ao fazer upload para o Google Cloud Storage:', error);
            throw new Error('Erro ao fazer upload do arquivo.');
        }
    };


    const getPostUrl = async (aulaId, file, endpoint) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_BASE_URL}/aulas/${endpoint}`,
                {
                    fileName: file.name,
                    fileType: file.type,
                    aulaId: aulaId,
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                }
            );
            if (response.status === 200 || response.status === 201) {
                const { uploadUrl, publicUrl } = response.data;
                console.log("UPLOAD URL: ", uploadUrl)
                console.log("PUBLIC URL: ", publicUrl)
                return { uploadUrl, publicUrl };
            } else {
                throw new Error("Erro ao gerar URL de upload");
            }
        } catch (error) {
            console.error("Erro ao fazer upload:", error);
            toast.error(`Erro ao fazer o upload do ${endpoint === 'upload-capa' ? 'imagem' : 'vídeo'}.`);
            return null;
        }
    };

    const uploadFilesOnGoogleStorage = async (aulaId, postCapaFile, postCapaUrl, publicCapaUrl, postVideoFile, postVideoUrl, publicVideoUrl) => {
        try {
            // Upload da capa se disponível
            if (publicCapaUrl) {
                await uploadFileToCloud(postCapaFile, postCapaUrl);
                toast.success("Upload da capa realizado com sucesso!");
            }

            // Upload do vídeo se disponível
            if (publicVideoUrl) {
                await uploadFileToCloud(postVideoFile, postVideoUrl);
                toast.success("Upload do vídeo realizado com sucesso!");
            }

            // Salvar URLs públicas na aula
            await salvarUrl(aulaId, publicCapaUrl, publicVideoUrl);

        } catch (err) {
            console.error("Erro ao fazer upload dos arquivos:", err);
            toast.error('Erro ao fazer upload dos arquivos!');
        }
    };

    const salvarUrl = async (aulaId, publicCapaUrl, publicVideoUrl) => {

        if (!publicCapaUrl || !publicVideoUrl) return;

        try {
            // Cria o corpo da requisição
            const body = {
                _id: aulaId,
                capa: publicCapaUrl || capa, // Usa a URL pública se disponível, senão mantém o valor atual
                video: publicVideoUrl || video, // Usa a URL pública se disponível, senão mantém o valor atual
            };

            // Envia a requisição para atualizar a aula
            const result = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/aulas`, body, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });

            if (result.status === 200 || result.status === 201) {
                toast.success("Aula e URLs públicas salvas com sucesso!");
            } else {
                throw new Error("Erro ao salvar as URLs públicas.");
            }
        } catch (err) {
            console.error("Erro ao salvar URLs públicas:", err);
            toast.error("Erro ao salvar as URLs públicas.");
        }
    };


    const salvarAula = async () => {
        setLoading(true);

        let body = {
            nome,
            descricao,
            modulo,
            curso,
            tags,
            professores,
            materiais,
            shorts
        };

        if (props?.itemPopup?._id) body._id = props.itemPopup._id

        try {
            const salvarAula = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/aulas`, body, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                }
            });

            if (salvarAula.status === 200 || salvarAula.status === 201) {
                const aula = salvarAula.data.aula;
                console.log("AULA: ", aula);

                // Verifica se a capa ou o vídeo foram alterados
                const capaAlterada = capa.includes('blob');
                const videoAlterado = video.includes('blob');

                let postCapaUrl, publicCapaUrl, postVideoUrl, publicVideoUrl;

                // Se a capa foi alterada, gera as URLs de upload
                if (capaAlterada) {
                    const capaUploadResponse = await getPostUrl(aula._id, postCapaFile, 'upload-capa');
                    if (capaUploadResponse) {
                        ({ uploadUrl: postCapaUrl, publicUrl: publicCapaUrl } = capaUploadResponse);
                    }
                }

                // Se o vídeo foi alterado, gera as URLs de upload
                if (videoAlterado) {
                    const videoUploadResponse = await getPostUrl(aula._id, postVideoFile, 'upload-video');
                    if (videoUploadResponse) {
                        ({ uploadUrl: postVideoUrl, publicUrl: publicVideoUrl } = videoUploadResponse);
                    }
                }

                // Se houver URLs para a capa ou vídeo, realiza o upload e salva as URLs públicas
                if (publicCapaUrl || publicVideoUrl) {
                    await uploadFilesOnGoogleStorage(
                        aula._id,
                        postCapaFile,
                        postCapaUrl,
                        publicCapaUrl,
                        postVideoFile,
                        postVideoUrl,
                        publicVideoUrl
                    );
                }

                // Recarrega as aulas e chama o callback de sucesso
                props.buscarAulasModulos();
                props.sucesso();
            } else {
                console.log("ERRO: ", salvarAula);
                toast.error("Erro ao salvar a aula.");
            }

        } catch (err) {
            toast.error("Erro ao salvar a aula.");
            console.error("ERRO:", err);
        } finally {
            setLoading(false);
            props.fecharPopup();
        }
    };


    const removerMaterial = (material) => {
        const newMateriais = materiais.filter(mt => mt.url !== material)
        setMateriais(newMateriais)
    }

    const uploadFiles = async (listaMateriais) => {
        const selectedFile = listaMateriais;

        if (selectedFile.length > 0) {
            const uploadPromises = [];

            for (const file of selectedFile) {
                const config = {
                    headers: {
                        'Content-Type': file.type,
                        'AccessKey': process.env.REACT_APP_BUNNY_TOKEN
                    },
                    transformRequest: [(data) => {
                        return data;
                    }]
                };

                uploadPromises.push(
                    axios.put(`${process.env.REACT_APP_BUNNY_STORAGE_ENDPOINT}${file.name}`, file, config)
                );
            }

            try {
                const responses = await Promise.all(uploadPromises);

                const uploadedFileUrls = responses.map(response => {

                    const obj = {
                        url: `${process.env.REACT_APP_BUNNY_FILEHOST}${response.config.data.name}`,
                        nome: response.config.data.name.replace(/\.[^.]+$/, "")
                    }

                    return obj;
                });

                let newMateriais = materiais;
                newMateriais = newMateriais.concat(uploadedFileUrls)

                return newMateriais

            } catch (err) {
            }
        }
    };

    const getProfessorNome = (professorId) => {
        if (professorId) {
            const professor = listaProfessores.find(professor => professor._id === professorId)
            if (professor && professor.nome) {
                return professor.nome
            }

        }

        return '';
    }

    const selectProfessor = async (professor) => {
        let newProfessores = professores;
        newProfessores.push(professor)
        setProfessores(newProfessores)
    }

    const removeProfessor = async (professorId) => {
        const newProfessores = professores.filter(professor => professor._id !== professorId)
        setProfessores(newProfessores)
    };

    const adicionarTag = (tag) => {
        const newTags = tags;
        if (!newTags.includes(tag)) newTags.push(tag)
        setTags(newTags)
        setTagsInput('')
    }

    const removerTag = (tag) => {
        let newTags = tags.filter(tg => tg !== tag)
        setTags(newTags)
    }

    useEffect(() => {

        let isMounted = true;

        if (isMounted && professoresInput !== '') {
            selectProfessor(professoresInput)
            setProfessoresInput('')
        }

        return () => {
            isMounted = false;
        }

    }, [professoresInput])

    useEffect(() => {

        let isMounted = true;

        const fetchProfessores = async () => {
            try {

                const professores = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/usuarios/professores`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                })

                if (isMounted && professores.data) {
                    setListaProfessores(professores.data)
                }

            } catch (err) {
            }
        };


        const fetchCursos = async () => {
            try {

                const cursos = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/cursos`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                })

                if (isMounted && cursos.data && cursos.data.cursos) {
                    setListaCursos(cursos.data.cursos.filter(c => c._id !== curso))
                }

            } catch (err) {
            }
        };

        fetchProfessores()
        fetchCursos()

        return () => {
            isMounted = false;
        }

    }, [])

    useEffect(() => {
        let isMounted = true

        const fetchModulos = async (cursoId) => {
            try {

                const modulos = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/modulos?curso=${cursoId}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                })


                if (isMounted && modulos.data && modulos.data.modulos) {
                    setListaModulos(modulos.data.modulos)
                }

            } catch (err) {
            }
        };


        if (isMounted) {
            setAulaImportada('')
            setFiltroModulo(null)
            setListaModulos([])

            if (filtroCurso && filtroCurso !== '') {
                fetchModulos(filtroCurso)
            }
        }

        return () => {
            isMounted = false
        }

    }, [filtroCurso])

    useEffect(() => {
        let isMounted = true;


        const fetchAulas = async (moduloId) => {
            try {

                const aulas = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/aulas?modulo=${moduloId}`);


                if (isMounted && aulas.data && aulas.data.aulas && aulas.data.aulas.length > 0) {
                    setListaAulas(aulas.data.aulas)
                }

            } catch (err) {
            }
        }


        if (isMounted) {
            setListaAulas([])
            setAulaImportada('')

            if (filtroModulo && filtroModulo !== '') {
                fetchAulas(filtroModulo)
            }
        }

        return () => {
            isMounted = false;
        }

    }, [filtroModulo])

    return (
        <div className={styles['container']}>
            <div className={styles['container01']}>
                <div className={styles['head']}>
                    <span className={styles['text']}>{props.itemPopup && props.itemPopup._id ? 'Editar Short' : 'Adicionar Short'}</span>
                    <div className={styles['fechar-popup']} onClick={props.fecharPopup}>
                        <svg
                            viewBox="0 0 804.5714285714286 1024"
                            className={styles['icon']}
                        >
                            <path d="M741.714 755.429c0 14.286-5.714 28.571-16 38.857l-77.714 77.714c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-168-168-168 168c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-77.714-77.714c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l168-168-168-168c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l77.714-77.714c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l168 168 168-168c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l77.714 77.714c10.286 10.286 16 24.571 16 38.857s-5.714 28.571-16 38.857l-168 168 168 168c10.286 10.286 16 24.571 16 38.857z"></path>
                        </svg>
                    </div>
                </div>
                <div className={styles['form']}>
                    {props.cursoSelecionadoTrilha && (props.itemPopup && !props.itemPopup._id) && (
                        <div className={styles['container2']}>
                            <span className={styles['label']} style={{
                                color: '#777', textDecoration: 'underline',
                                fontSize: '13px', cursor: 'pointer'
                            }}
                                onClick={() => setImportarAula(!importarAula)}
                            >{importarAula ? 'Adicionar nova aula' : 'Importar aula de outro curso para essa trilha'}</span>
                        </div>
                    )}
                    {importarAula && (
                        <div className={styles['container2']}
                            style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '100%' }}
                        >
                            <select className={styles['select2']} onChange={(e) => { setFiltroCurso(e.target.value) }}>
                                <option value="" selected={filtroCurso === ''}>Selecione um curso</option>
                                {
                                    listaCursos.length > 0 && (
                                        listaCursos.map((course, index) => (
                                            <option key={index} value={course._id} selected={course._id === filtroCurso}>{course.nome}</option>
                                        ))
                                    )
                                }
                            </select>
                            <select className={styles['select2']} onChange={(e) => { setFiltroModulo(e.target.value) }}>
                                <option value="" selected={filtroModulo === ''}>Selecione um módulo</option>
                                {
                                    listaModulos.length > 0 && (
                                        listaModulos.map((module, index) => (
                                            <option key={index} value={module._id} selected={module._id === filtroModulo}>{module.nome}</option>
                                        ))
                                    )
                                }
                            </select>
                            <select className={styles['select2']} onChange={(e) => { setAulaImportada(e.target.value) }}>
                                <option value="" selected={aulaImportada === ''}>Selecione uma aula</option>
                                {listaAulas.length > 0 && (
                                    listaAulas.map((module, index) => (
                                        <option key={index} value={module._id} selected={module._id === aulaImportada}>{module.nome}</option>
                                    ))
                                )}
                            </select>
                        </div>
                    )}
                    {!importarAula && (
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                            {/*{!props.itemPopup?.cursoId && (
                                <div className={styles['container07']}>
                                    <span className={styles['label4']}>Shorts?</span>
                                    <input type="checkbox" checked={shorts} onChange={(e) => setShorts(e.target.checked)} />
                                </div>
                            )}*/}
                            <div className={styles['container04']}>
                                <span className={styles['label2']}>Nome</span>
                                <input type="text" className={styles['textinput']}
                                    value={nome}
                                    onChange={(e) => setNome(e.target.value)}
                                />
                            </div>
                            <div className={styles['container05']}>
                                <span className={styles['label3']}>Vídeo</span>
                                <div className={styles['container06']}>
                                    <img
                                        onClick={() => document.getElementById('file-input-video').click()}
                                        alt="video"
                                        src={video ? video : '/default-img.svg'} // Verificação do vídeo otimizada
                                        className={styles['image']}
                                    />
                                    <div className={styles['fechar-popup1']} id="upload-video">
                                        <label onClick={() => document.getElementById('file-input-video').click()}>
                                            <svg
                                                viewBox="0 0 804.5714285714286 1024"
                                                className={styles['icon2']}
                                            >
                                                <path d="M804.571 420.571v109.714c0 30.286-24.571 54.857-54.857 54.857h-237.714v237.714c0 30.286-24.571 54.857-54.857 54.857h-109.714c-30.286 0-54.857-24.571-54.857-54.857v-237.714h-237.714c-30.286 0-54.857-24.571-54.857-54.857v-109.714c0-30.286 24.571-54.857 54.857-54.857h237.714v-237.714c0-30.286 24.571-54.857 54.857-54.857h109.714c30.286 0 54.857 24.571 54.857 54.857v237.714h237.714c30.286 0 54.857 24.571 54.857 54.857z"></path>
                                            </svg>
                                        </label>
                                        <input
                                            id="file-input-video"
                                            type="file"
                                            style={{ display: 'none' }}
                                            accept="video/*"
                                            onChange={(event) => handleFileUpload(event, setPostVideoFile, setVideo)} // Função otimizada
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className={styles['container05']}>
                                <span className={styles['label3']}>Capa</span>
                                <div className={styles['container06']}>
                                    <img
                                        onClick={() => document.getElementById('file-input-capa').click()}
                                        alt="capa"
                                        src={capa ? capa : '/default-img.svg'} // Verificação da capa otimizada
                                        className={styles['image']}
                                    />
                                    <div className={styles['fechar-popup1']} id="upload-capa">
                                        <label onClick={() => document.getElementById('file-input-capa').click()}>
                                            <svg
                                                viewBox="0 0 804.5714285714286 1024"
                                                className={styles['icon2']}
                                            >
                                                <path d="M804.571 420.571v109.714c0 30.286-24.571 54.857-54.857 54.857h-237.714v237.714c0 30.286-24.571 54.857-54.857 54.857h-109.714c-30.286 0-54.857-24.571-54.857-54.857v-237.714h-237.714c-30.286 0-54.857-24.571-54.857-54.857v-109.714c0-30.286 24.571-54.857 54.857-54.857h237.714v-237.714c0-30.286 24.571-54.857 54.857-54.857h109.714c30.286 0 54.857 24.571 54.857 54.857v237.714h237.714c30.286 0 54.857 24.571 54.857 54.857z"></path>
                                            </svg>
                                        </label>
                                        <input
                                            id="file-input-capa"
                                            type="file"
                                            style={{ display: 'none' }}
                                            accept="image/jpeg, image/png, image/gif"
                                            onChange={(event) => handleFileUpload(event, setPostCapaFile, setCapa)} // Função otimizada
                                        />
                                    </div>
                                </div>
                            </div>

                            {!shorts && (
                                <div className={styles['container07']}>
                                    <span className={styles['label4']}>Descrição</span>
                                    <RichTextEditor value={descricao} onChange={(newValue) => setDescricao(newValue)} />
                                </div>
                            )}
                            {!shorts && (
                                <div className={styles['container08']}>
                                    <span className={styles['label5']}>Professores</span>
                                    <select className={styles['select2']} onChange={(e) => setProfessoresInput(e.target.value)}>
                                        <option value="" selected={professoresInput === ''}>Selecione um professor</option>
                                        {listaProfessores.length > 0 && (
                                            listaProfessores.map((professor, index) => (
                                                <option key={index} value={professor._id} disabled={professores.includes(professor._id)}>{professor.nome}</option>
                                            ))
                                        )}
                                    </select>
                                    <div className={styles['tags']}>
                                        {professores.length > 0 && (
                                            professores.map((professor, index) => (
                                                <div key={index} className={styles['tag']}
                                                    onClick={() => removeProfessor(professor._id)}
                                                >
                                                    <span className={styles['label6']}>{getProfessorNome(professor)}</span>
                                                    <svg
                                                        viewBox="0 0 804.5714285714286 1024"
                                                        className={styles['icon4']}
                                                    >
                                                        <path d="M741.714 755.429c0 14.286-5.714 28.571-16 38.857l-77.714 77.714c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-168-168-168 168c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-77.714-77.714c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l168-168-168-168c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l77.714-77.714c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l168 168 168-168c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l77.714 77.714c10.286 10.286 16 24.571 16 38.857s-5.714 28.571-16 38.857l-168 168 168 168c10.286 10.286 16 24.571 16 38.857z"></path>
                                                    </svg>
                                                </div>
                                            ))
                                        )}
                                    </div>
                                </div>
                            )}
                            <div className={styles['container09']}>
                                <span className={styles['label7']}>Tags</span>
                                <input type="text" value={tagsInput} onChange={(e) => setTagsInput(e.target.value)} onKeyDown={(event) => {
                                    if (event.key === 'Enter') adicionarTag(event.target.value)
                                }} className={styles['textinput2']} />
                                <div className={styles['tags1']}>
                                    {tags.length > 0 && (
                                        tags.map((tag, index) => (
                                            <div key={index} className={styles['tag1']} onClick={() => removerTag(tag)}>
                                                <span className={styles['label8']}>{tag}</span>
                                                <svg
                                                    viewBox="0 0 804.5714285714286 1024"
                                                    className={styles['icon6']}
                                                >
                                                    <path d="M741.714 755.429c0 14.286-5.714 28.571-16 38.857l-77.714 77.714c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-168-168-168 168c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-77.714-77.714c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l168-168-168-168c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l77.714-77.714c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l168 168 168-168c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l77.714 77.714c10.286 10.286 16 24.571 16 38.857s-5.714 28.571-16 38.857l-168 168 168 168c10.286 10.286 16 24.571 16 38.857z"></path>
                                                </svg>
                                            </div>
                                        ))
                                    )}
                                </div>
                            </div>
                            {!shorts && (
                                <div className={styles['container05']} onClick={() => document.getElementById('file-input-files').click()}>
                                    <span className={styles['label3']}>Materiais</span>
                                    <div className={styles['container06']}>
                                        <img
                                            alt="image"
                                            src={materiais ? '/arquivo.png' : '/default-img.svg'}
                                            className={styles['image']}
                                        />
                                        <div className={styles['fechar-popup1']} id="upload-files">
                                            <label>
                                                <svg
                                                    viewBox="0 0 804.5714285714286 1024"
                                                    className={styles['icon2']}
                                                >
                                                    <path d="M804.571 420.571v109.714c0 30.286-24.571 54.857-54.857 54.857h-237.714v237.714c0 30.286-24.571 54.857-54.857 54.857h-109.714c-30.286 0-54.857-24.571-54.857-54.857v-237.714h-237.714c-30.286 0-54.857-24.571-54.857-54.857v-109.714c0-30.286 24.571-54.857 54.857-54.857h237.714v-237.714c0-30.286 24.571-54.857 54.857-54.857h109.714c30.286 0 54.857 24.571 54.857 54.857v237.714h237.714c30.286 0 54.857 24.571 54.857 54.857z"></path>
                                                </svg>
                                            </label>
                                            <input
                                                id="file-input-files"
                                                type="file"
                                                style={{ display: 'none' }}
                                                accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                                multiple
                                                onChange={(event) => {
                                                    if (event.target.files) setMateriaisFiles(event.target.files)
                                                }}
                                            />
                                        </div>
                                        {materiaisFiles.length > 0 && (

                                            <span style={{ fontSize: '13px', marginTop: '5px', color: '#777' }}>{materiaisFiles.length} arquivos carregados</span>

                                        )}
                                    </div>
                                    <div className={styles['tags1']}>
                                        {materiais.length > 0 && (
                                            materiais.map((material, index) => (
                                                <div key={index} className={styles['tag1']} onClick={() => removerMaterial(material.url)}>
                                                    <span className={styles['label8']}>{material.nome}</span>
                                                    <svg
                                                        viewBox="0 0 804.5714285714286 1024"
                                                        className={styles['icon6']}
                                                    >
                                                        <path d="M741.714 755.429c0 14.286-5.714 28.571-16 38.857l-77.714 77.714c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-168-168-168 168c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-77.714-77.714c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l168-168-168-168c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l77.714-77.714c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l168 168 168-168c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l77.714 77.714c10.286 10.286 16 24.571 16 38.857s-5.714 28.571-16 38.857l-168 168 168 168c10.286 10.286 16 24.571 16 38.857z"></path>
                                                    </svg>
                                                </div>
                                            ))
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <div className={styles['container10']}>
                    <button type="button" className={styles['button']} onClick={props.fecharPopup}>
                        Cancelar
                    </button>
                    <button type="button" className={styles['button1']} disabled={((((curso === '' || modulo === '') && props.itemPopup?.curso?.length > 0 && props.itemPopup?.modulo?.length > 0) || video === '') && !importarAula) || loading || (importarAula && aulaImportada === '')} onClick={salvarAula}>
                        {loading ? 'Salvando...' : importarAula ? 'Importar Short' : 'Salvar Short'}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default PopupAdminAula
