import React, { useState } from 'react'

import axios from 'axios'

import styles from './add_modulo.module.css'
import { useEffect } from 'react'

const PopupModulo = (props) => {
  const [nome, setNome] = useState(props.itemPopup && props.itemPopup.nome ? props.itemPopup.nome : '')
  const [capa, setCapa] = useState(props.itemPopup && props.itemPopup.capa ? props.itemPopup.capa : '')
  const [curso, setCurso] = useState(props.itemPopup && props.itemPopup.curso ? props.itemPopup.curso : '')
  const [importarModulo, setImportarModulo] = useState(false)
  const [listaModulos, setModulos] = useState([])
  const [listaCursos, setListaCursos] = useState([])
  const [moduloImportado, setModuloImportado] = useState(null)
  const [filtroCurso, setFiltroCurso] = useState(null)
  const [loading, setLoading] = useState(false)

  const uploadFile = async (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const config = {
        headers: {
          'Content-Type': selectedFile.type,
          'AccessKey': process.env.REACT_APP_BUNNY_TOKEN
        },
        transformRequest: [(data) => {
          return data;
        }]
      };

      try {
        const response = await axios.put(
          `${process.env.REACT_APP_BUNNY_STORAGE_ENDPOINT}${selectedFile.name}`,
          selectedFile,
          config
        );

        setCapa(`${process.env.REACT_APP_BUNNY_FILEHOST}${selectedFile.name}`)

      } catch (err) {
      }
    }
  };

  const salvarModulo = async () => {
    setLoading(true)
    if (importarModulo) {

      try {

        const salvarModulo = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/modulos/importar-modulo`, {
          cursoId: curso,
          moduloId: moduloImportado
        }, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        })

        props.sucesso()
        props.buscarModulos()

      } catch (err) {
      }


    } else {

      let body = {
        nome,
        curso,
        capa
      }

      if (props.itemPopup && props.itemPopup._id) body._id = props.itemPopup._id

      try {
        const salvarModulo = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/modulos`, body, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        })

        props.buscarModulos()
        props.sucesso()

      } catch (err) {
      }
    }


    props.fecharPopup()
    setLoading(false)
  }

  useEffect(() => {
    let isMounted = true

    const fetchModulos = async () => {
      try {

        const result = await axios.get(process.env.REACT_APP_BACKEND_BASE_URL + '/modulos', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        })

        if (result.data && result.data.modulos && result.data.modulos.length > 0) {

          const modulos = result.data.modulos.filter(modulo => {
            return !modulo.curso.map(c => c._id).includes(curso)
          })

          setModulos(modulos)

        }

      } catch (err) {
      }
    };


    if (isMounted && importarModulo && listaModulos.length === 0) {

      fetchModulos()

    }

    return () => {
      isMounted = false
    }

  }, [importarModulo])

  useEffect(() => {
    let isMounted = true

    if (isMounted && listaModulos && listaModulos.length > 0) {
      const cursos = [];
      listaModulos.forEach(modulo => {
        modulo.curso.forEach(cursosModulo => {
          if (cursos.findIndex(curso => curso._id === cursosModulo._id) === -1) cursos.push(cursosModulo)
        })
      })
      setListaCursos(cursos)
    }

    return () => {
      isMounted = false
    }
  }, [listaModulos]);

  useEffect(() => {

    setModuloImportado(null)

  }, [filtroCurso])

  return (
    <div className={styles['container']}>
      <div className={styles['container1']}>
        <div className={styles['head']}>
          <span className={styles['text']}>{props.itemPopup && props.itemPopup._id ? 'Editar' : 'Adicionar'} Módulo</span>
          <div className={styles['fechar-popup']} onClick={props.fecharPopup}>
            <svg
              viewBox="0 0 804.5714285714286 1024"
              className={styles['icon']}
            >
              <path d="M741.714 755.429c0 14.286-5.714 28.571-16 38.857l-77.714 77.714c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-168-168-168 168c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-77.714-77.714c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l168-168-168-168c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l77.714-77.714c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l168 168 168-168c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l77.714 77.714c10.286 10.286 16 24.571 16 38.857s-5.714 28.571-16 38.857l-168 168 168 168c10.286 10.286 16 24.571 16 38.857z"></path>
            </svg>
          </div>
        </div>
        <div className={styles['form']}>
          {props.cursoSelecionadoTrilha && (props.itemPopup && !props.itemPopup._id) && (
            <div className={styles['container2']}>
              <span className={styles['label']} style={{
                color: '#777', textDecoration: 'underline',
                fontSize: '13px', cursor: 'pointer'
              }}
                onClick={() => setImportarModulo(!importarModulo)}
              >{importarModulo ? 'Adicionar módulo novo' : 'Importar módulo de outro curso para essa trilha'}</span>
            </div>
          )}
          {importarModulo && (
            <div className={styles['container2']}>
              <select className={styles['select2']} onChange={(e) => { setFiltroCurso(e.target.value) }}>
                <option value="">Filtre Módulos por Curso</option>
                {
                  listaCursos.length > 0 && (
                    listaCursos.map((course, index) => (
                      <option key={index} value={course._id} selected={course._id === filtroCurso}>{course.nome}</option>
                    ))
                  )
                }
              </select>
              <select className={styles['select2']} onChange={(e) => { setModuloImportado(e.target.value) }}>
                <option value="" selected={moduloImportado === ''}>Selecione um módulo</option>
                {listaModulos.length > 0 && (
                  listaModulos.filter(m => m.curso.map(c => c._id).includes(filtroCurso)).map((module, index) => (
                    <option key={index} value={module._id} selected={module._id === moduloImportado}>{module.nome}</option>
                  ))
                )}
              </select>
            </div>
          )}
          {!importarModulo && (
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '10px' }}>
              <div className={styles['container2']}>
                <span className={styles['label']}>Nome</span>
                <input
                  value={nome}
                  onChange={(e) => setNome(e.target.value)}
                  type="text" className={styles['textinput']} />
              </div>
              <div className={styles['container3']} onClick={() => document.getElementById('file-input').click()}>
                <span className={styles['label1']}>Capa</span>
                <div className={styles['container4']}>
                  <img
                    alt="image"
                    src={capa ? capa : 'https://play.teleporthq.io/static/svg/default-img.svg'}
                    className={styles['image']}
                  />
                  <div className={styles['fechar-popup1']} id="upload-file">
                    <label>
                      <svg
                        viewBox="0 0 804.5714285714286 1024"
                        className={styles['icon2']}
                      >
                        <path d="M804.571 420.571v109.714c0 30.286-24.571 54.857-54.857 54.857h-237.714v237.714c0 30.286-24.571 54.857-54.857 54.857h-109.714c-30.286 0-54.857-24.571-54.857-54.857v-237.714h-237.714c-30.286 0-54.857-24.571-54.857-54.857v-109.714c0-30.286 24.571-54.857 54.857-54.857h237.714v-237.714c0-30.286 24.571-54.857 54.857-54.857h109.714c30.286 0 54.857 24.571 54.857 54.857v237.714h237.714c30.286 0 54.857 24.571 54.857 54.857z"></path>
                      </svg>
                    </label>
                    <input
                      id="file-input"
                      type="file"
                      style={{ display: 'none' }}
                      accept="image/jpeg, image/png, image/gif"
                      onChange={uploadFile}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className={styles['container5']}>
          <button type="button" className={styles['button']} onClick={props.fecharPopup}>
            Cancelar
          </button>
          <button type="button" className={styles['button1']} disabled={((nome === '' || curso === '' || capa === '') && !importarModulo) || (importarModulo && !moduloImportado) || loading} onClick={salvarModulo}>
            {importarModulo ? 'Importar Módulo' : loading ? 'Salvando...' : 'Salvar Módulo'}
          </button>
        </div>
      </div>
    </div>
  )
}

export default PopupModulo
