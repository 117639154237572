import React from 'react'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import styles from './remover_item.module.css'


const PopupCurso = (props) => {
  const history = useHistory()

  const excluirItem = async () => {

    function removeAccents(str) {
      return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }

    const tipoSemAcentos = removeAccents(props.tipo.toLowerCase());


    if (!props.tipo) {
      props.fecharPopup()
    }


    try {
      const result = await axios.delete(`${process.env.REACT_APP_BACKEND_BASE_URL}/${tipoSemAcentos}s?id=${props.itemPopup && props.itemPopup._id ? props.itemPopup._id : ''}${(tipoSemAcentos === 'modulo' || tipoSemAcentos === 'aula') && props.cursoId ? `&cursoId=${props.cursoId}` : ''}${tipoSemAcentos === 'aula' && props.moduloId ? `&moduloId=${props.moduloId}` : ''}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      })

      if (tipoSemAcentos === 'curso') {
        props.buscarCursos()
        history.push(`/admin/${tipoSemAcentos}s`)
      } else if (tipoSemAcentos === 'modulo') {
        props.buscarModulos()
      }

      if (tipoSemAcentos === 'aula') {
        props.buscarAulasModulo()
      }

      if (tipoSemAcentos === 'usuario') {
        props.buscarUsuarios()
      }

      props.removido()
      props.fecharPopup()


    } catch (err) {
    }

  }

  return (
    <div className={styles['container']}>
      <div className={styles['container1']}>
        <div className={styles['head']}>
          <span className={styles['text']}>Excluir {props.tipo ? props.tipo : 'Item'}</span>
          <div className={styles['fechar-popup']}
            onClick={props.fecharPopup}
          >
            <svg
              viewBox="0 0 804.5714285714286 1024"
              className={styles['icon']}
            >
              <path d="M741.714 755.429c0 14.286-5.714 28.571-16 38.857l-77.714 77.714c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-168-168-168 168c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-77.714-77.714c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l168-168-168-168c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l77.714-77.714c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l168 168 168-168c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l77.714 77.714c10.286 10.286 16 24.571 16 38.857s-5.714 28.571-16 38.857l-168 168 168 168c10.286 10.286 16 24.571 16 38.857z"></path>
            </svg>
          </div>
        </div>
        <div className={styles['form']}>
          <span className={styles['descricao']}>
            <span>
              O {props.tipo ? props.tipo.toLowerCase() : 'item'} {props.itemPopup && props.itemPopup._id && props.itemPopup.nome ? props.itemPopup.nome : ''} será excluído da base de dados e não será mais possível
              desfazer essa ação.
            </span>
            <br></br>
            <br></br>
            <span>Você tem certeza que quer continuar?</span>
            <br></br>
          </span>
        </div>
        <div className={styles['container2']}>
          <button type="button" className={styles['btn-cancelar']}
            onClick={props.fecharPopup}
          >
            Cancelar
          </button>
          <button onClick={excluirItem} type="button" className={styles['btn-excluir']}>
            Excluir
          </button>
        </div>
      </div>
    </div>
  )
}

export default PopupCurso