import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation, Link } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';

const TreinamentosHome = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const header_geral = { Authorization: `Bearer ${localStorage.getItem("token")}` }
    const [dadosPagina, setDadosPagina] = useState({});
    const [bunnyUrlPublicoCurso, setBunnyUrlPublicoCurso] = useState(`${process.env.REACT_APP_BUNNY_FILEHOST}paginas-automaticas/cursos/capas/`);

    useEffect(() => {
        let isMounted = true;

        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/master/home/treinamento-colaboradores/amostra`, { headers: header_geral });

                if (isMounted && response.status === 200) {
                    setDadosPagina(response.data);
                } else {
                    console.log(response)
                }
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();

        return () => { isMounted = false };
    }, []);

    const entrarNoCurso = (curso) => {
        if (curso.automacao) {
            history.push(`/automacao/treinamento-colaboradores/${curso.codigo}/`)
        } else {
            history.push(`/master/aula/treinamento-colaboradores/${curso.codigo}/`)
        }
    }

    return (
        <section className='flex flex-col space-y-10'>
            <section className=''>
                <div className='w-11/12 mx-auto flex justify-between items-center'>
                    <h2 className='text-md md:text-xl text-neutral-50 font-bold'>{dadosPagina?.pagina?.titulo}</h2>
                    <a href='/master/treinamento-colaboradores' className='text-neutral-500 text-sm hover:text-orange-500 active:text-orange-800'>Ver Mais</a>
                </div>
                <div className='w-full overflow-auto'>
                    <ul className='flex flex-row w-fit lg:w-11/12 lg:mx-auto p-5 lg:px-0 gap-3'>
                        {dadosPagina?.cursos?.map((curso, index) => {
                            return (
                                <li key={index} className='text-red-500 w-[36vw] lg:w-[16.67vw] h-[54vw] lg:h-[20vw] hover:scale-105 active:scale-95 rounded-lg bg-neutral-500 overflow-hidden bg-center bg-cover cursor-pointer border-2 border-transparent transition-all hover:border-orange-500' style={{ backgroundImage: `url(${encodeURI(bunnyUrlPublicoCurso + curso.imagem)})` }} onClick={() => entrarNoCurso(curso)}></li>
                            )
                        })}
                    </ul>
                </div>
            </section>
            {/*
            <section className=''>
                <div className='w-11/12 mx-auto flex justify-between items-center'>
                    <h2 className='text-md md:text-xl text-neutral-300'>Automações Prontas</h2>
                    <a href='/master/treinamento-colaboradores' className='text-neutral-500 text-sm hover:text-orange-500 active:text-orange-800'>Ver Mais</a>
                </div>
                <div className='w-full overflow-auto'>
                    <ul className='flex flex-row w-fit  p-5 gap-3'>
                        {dadosPagina?.automacoes?.map((curso, index) => {
                            return (
                                <li key={index} className='text-red-500 w-[36vw] h-[54vw] hover:scale-105 active:scale-95 rounded-lg bg-neutral-500 overflow-hidden bg-center bg-cover' style={{ backgroundImage: `url(${encodeURI(bunnyUrlPublicoCurso + curso.imagem)})` }} onClick={() => entrarNoCurso(curso)}>

                                </li>
                            )
                        })}
                    </ul>
                </div>
            </section>
                    */}
        </section>
    )
}

export default TreinamentosHome