import { Helmet } from 'react-helmet'
import Header from '../components/header'
import Menu from '../components/menu';
import Footer from '../components/footer'
import './termos-de-uso.css'
import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

const TermosDeUso = (props) => {
  const usuarioAtual = useSelector(state => state.usuarioAtual);
  const [showMenu, setShowMenu] = useState(window.innerWidth >= 1024);
  return (
    <div>
      <div className="min-h-screen bg-gray-100">
        <Helmet>
          <title>Termos-de-Uso - Prestigious Imperfect Leopard</title>
          <meta
            property="og:title"
            content="Termos-de-Uso - Prestigious Imperfect Leopard"
          />
        </Helmet>
        <div className="bg-neutral-950 shadow-md">
          <div className="relative overflow-hidden">
            <img
              alt="image"
              src="https://images.unsplash.com/photo-1604933762023-7213af7ff7a7?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDF8fGVudHJlcGVuZXVyfGVufDB8fHx8MTY5MTQ1MDg2Mnww&amp;ixlib=rb-4.0.3&amp;w=1500"
              className="w-full h-64 object-cover"
            />
            <div className="absolute inset-0 flex flex-col items-center justify-center text-center bg-gradient-to-t from-black via-transparent to-black">
              <a href='/'>
                <img src="/main_logo.png" className='w-52' alt="Logo" />
              </a>
              <h1 className="text-white text-5xl font-semibold mt-2">Termos de Uso</h1>
              <p className="text-white mt-1">Atualizado em 07 de Agosto de 2023</p>
            </div>
          </div>
          <div className="p-6 max-w-4xl mx-auto text-justify">
            <div className="termos-de-uso-descricao">
              <span className="termos-de-uso-text002">
                Termos e Condições de Uso
              </span>
              <span className="termos-de-uso-text003">
                <span className="termos-de-uso-text004">
                  Seja bem-vindo a simbium.com!
                </span>
                <br className="termos-de-uso-text005"></br>
                <span className="termos-de-uso-text006">
                  Estes termos e condições descrevem as regras de uso do site da
                  empresa Simbium, localizado no endereço <a className='text-orange-5 00' href='https://simbium.com'>https://simbium.com</a>.
                </span>
                <br className="termos-de-uso-text007"></br>
                <span className="termos-de-uso-text008">
                  Ao acessar este site, consideramos que você está de acordo com
                  os termos e condições abaixo. Não continue a usar o simbium.com
                  caso você discorde dos termos e condições descritos neste
                  contrato.
                </span>
                <br className="termos-de-uso-text009"></br>
                <br className="termos-de-uso-text010"></br>
                <span className="termos-de-uso-text011">Cookies</span>
                <br className="termos-de-uso-text012"></br>
                <br className="termos-de-uso-text013"></br>
                <span className="termos-de-uso-text014">
                  O site usa cookies para ajudar na personalização da sua
                  experiência na internet. Ao acessar o site simbium.com, você
                  concorda com o uso dos cookies requeridos.
                </span>
                <br className="termos-de-uso-text015"></br>
                <span className="termos-de-uso-text016">
                  Cookies são arquivos de texto inseridos no seu disco rígido por
                  um servidor de página web. Os cookies não têm permissão para
                  executar programas ou transferir vírus para seu computador. Os
                  cookies são associados exclusivamente a você e só podem ser
                  lidos pelo servidor web do domínio que emitiu o cookie.
                </span>
                <br className="termos-de-uso-text017"></br>
                <span className="termos-de-uso-text018">
                  Nós podemos usar cookies para coletar, armazenar ou rastrear
                  informações para finalidades estatísticas e mercadológicas do
                  nosso site. Você tem a opção de aceitar ou rejeitar os cookies
                  opcionais. Existem alguns cookies obrigatórios, que são
                  necessários para o funcionamento de nosso site. Esses cookies
                  obrigatórios são requerem seu consentimento. Por favor, tenha em
                  mente que, ao aceitar os cookies obrigatórios, você também
                  estará aceitando cookies de terceiros, que podem ser usados por
                  terceiros caso você utilize serviços fornecidos por eles em
                  nosso site – por exemplo, uma janela de reprodução de vídeo
                  fornecida por empresas terceiras e integrada ao nosso site.
                </span>
                <br className="termos-de-uso-text019"></br>
                <br className="termos-de-uso-text020"></br>
                <span className="termos-de-uso-text021">Licença</span>
                <br className="termos-de-uso-text022"></br>
                <br className="termos-de-uso-text023"></br>
                <span className="termos-de-uso-text024">
                  Exceto em casos em que for indicado o contrário, Simbium e seus
                  licenciados têm direito à propriedade intelectual de todo o
                  material postado no simbium.com. Todos os direitos à propriedade
                  intelectual são reservados.
                </span>
                <br className="termos-de-uso-text025"></br>
                <span className="termos-de-uso-text026">
                  Você não tem permissão para:
                </span>
                <br className="termos-de-uso-text027"></br>
                <br className="termos-de-uso-text028"></br>
                <span className="termos-de-uso-text029">
                  - Copiar ou republicar materiais do simbium.com
                </span>
                <br className="termos-de-uso-text030"></br>
                <span className="termos-de-uso-text031">
                  - Vender, alugar ou sublocar materiais do simbium.com
                </span>
                <br className="termos-de-uso-text032"></br>
                <span className="termos-de-uso-text033">
                  - Reproduzir, duplicar ou copiar materiais do simbium.com
                </span>
                <br className="termos-de-uso-text034"></br>
                <span className="termos-de-uso-text035">
                  - Redistribuir conteúdos do simbium.com
                </span>
                <br className="termos-de-uso-text036"></br>
                <br className="termos-de-uso-text037"></br>
                <span className="termos-de-uso-text038">
                  {' '}
                  Este Acordo terá efeito a partir da data presente.
                </span>
                <br className="termos-de-uso-text039"></br>
                <span className="termos-de-uso-text040">
                  Partes deste site oferecem ao usuário a oportunidade de postar e
                  discutir opiniões e informações em determinadas áreas. Simbium
                  não filtra, edita, publica ou revisa Comentários antes que eles
                  sejam apresentados no site. Comentários refletem as opiniões da
                  pessoa que os posta. Na extensão em que as leis aplicáveis
                  permitem, Simbium não se responsabiliza legalmente pelos
                  Comentários ou quaisquer danos, riscos ou despesas causadas ou
                  sofridas como resultado do uso, e/ou postagem e/ou aparência dos
                  Comentários deste site.
                </span>
                <br className="termos-de-uso-text041"></br>
                <span className="termos-de-uso-text042">
                  Simbium reserva para si o direito de remover quaisquer
                  Comentários que possam ser considerados inapropriados, ofensivos
                  ou quebrem os Termos e Condições deste contrato.
                </span>
                <br className="termos-de-uso-text043"></br>
                <span className="termos-de-uso-text044">
                  Você declara e garante que:
                </span>
                <br className="termos-de-uso-text045"></br>
                <br className="termos-de-uso-text046"></br>
                <span className="termos-de-uso-text047">
                  - Você tem o direito de postar Comentários em nosso site e tem
                  todas as licenças e consentimentos para tal;
                </span>
                <br className="termos-de-uso-text048"></br>
                <span className="termos-de-uso-text049">
                  - Seus Comentários não invadem qualquer propriedade intelectual,
                  incluindo direitos autorais, patentes ou marcas registradas de
                  terceiros;
                </span>
                <br className="termos-de-uso-text050"></br>
                <span className="termos-de-uso-text051">
                  - Seus Comentários não contém material difamatório, injurioso,
                  ofensivo, indecente ou de alguma forma ilícito, que é invasão de
                  privacidade.
                </span>
                <br className="termos-de-uso-text052"></br>
                <span className="termos-de-uso-text053">
                  - Seus Comentários não serão usados para solicitar ou promover
                  negócios ou apresentar atividades comerciais ou atividades
                  ilícitas.
                </span>
                <br className="termos-de-uso-text054"></br>
                <br className="termos-de-uso-text055"></br>
                <span className="termos-de-uso-text056">
                  Você por meio deste concede a Simbium a licença não-exclusiva de
                  usar, reproduzir, editar e autorizar outros a usar, reproduzir
                  ou editar qualquer um de seus Comentários em qualquer e todas as
                  formas, formatos e mídias.
                </span>
                <br className="termos-de-uso-text057"></br>
                <br className="termos-de-uso-text058"></br>
                <span className="termos-de-uso-text059">
                  Criação de links para nosso conteúdo
                </span>
                <br className="termos-de-uso-text060"></br>
                <br className="termos-de-uso-text061"></br>
                <span className="termos-de-uso-text062">
                  As seguintes organizações podem criar links para nosso Site sem
                  a necessidade de aprovação prévia por escrito:
                </span>
                <br className="termos-de-uso-text063"></br>
                <br className="termos-de-uso-text064"></br>
                <span className="termos-de-uso-text065">
                  - Agências governamentais;
                </span>
                <br className="termos-de-uso-text066"></br>
                <span className="termos-de-uso-text067">
                  - Mecanismos de busca;
                </span>
                <br className="termos-de-uso-text068"></br>
                <span className="termos-de-uso-text069">
                  - Organizações de mídia;
                </span>
                <br className="termos-de-uso-text070"></br>
                <span className="termos-de-uso-text071">
                  - Distribuidores de diretórios online podem inserir links para
                  nosso Site na mesma maneira que inserem hiperlinks para Sites de
                  outras empresas listadas; e
                </span>
                <br className="termos-de-uso-text072"></br>
                <span className="termos-de-uso-text073">
                  Empresas credenciadas, exceto organizações angariantes sem fins
                  lucrativos e grupos de arrecadação de fundos para instituições
                  de caridade, que não podem inserir links para nosso Site sem
                  aprovação prévia.
                </span>
                <br className="termos-de-uso-text074"></br>
                <br className="termos-de-uso-text075"></br>
                <span className="termos-de-uso-text076">
                  Essas organizações podem postar links para nossa página inicial,
                  nossas publicações ou outras informações do Site, contanto que o
                  link: (a) não seja, de forma alguma, enganoso; (b) não insinue
                  falsamente a existência de uma relação de patrocínio, endosso,
                  ou aprovação nossa a produtos e/ou serviços; e (c) seja
                  apropriado ao contexto em que está sendo inserido.
                </span>
                <br className="termos-de-uso-text077"></br>
                <span className="termos-de-uso-text078">
                  Nós podemos considerar e aprovar solicitações de link feitas
                  pelos seguintes tipos de organizações:
                </span>
                <br className="termos-de-uso-text079"></br>
                <br className="termos-de-uso-text080"></br>
                <span className="termos-de-uso-text081">
                  - fontes de informação bem conhecidas sobre produtos e negócios;
                </span>
                <br className="termos-de-uso-text082"></br>
                <span className="termos-de-uso-text083">
                  - sites comunitários ponto.com;
                </span>
                <br className="termos-de-uso-text084"></br>
                <span className="termos-de-uso-text085">
                  - associações e outros grupos que representem instituições de
                  caridade;
                </span>
                <br className="termos-de-uso-text086"></br>
                <span className="termos-de-uso-text087">
                  - distribuidores de diretórios online;
                </span>
                <br className="termos-de-uso-text088"></br>
                <span className="termos-de-uso-text089">
                  - portais de internet;
                </span>
                <br className="termos-de-uso-text090"></br>
                <span className="termos-de-uso-text091">
                  - firmas de contabilidade, advocacia e consultoria; e
                </span>
                <br className="termos-de-uso-text092"></br>
                <span className="termos-de-uso-text093">
                  - instituições educacionais e associações de classe.
                </span>
                <br className="termos-de-uso-text094"></br>
                <br className="termos-de-uso-text095"></br>
                <span className="termos-de-uso-text096">
                  Nós vamos aprovar solicitações de link feitas pelos tipos de
                  organização listados acima se julgarmos que: (a) o link não é
                  desfavorável para nossa imagem e/ou para a imagem de empresas
                  credenciadas; (b) a organização solicitante não possui histórico
                  negativo conosco; (c) o benefício que ganhamos com a
                  visibilidade do link compensa a ausência do Simbium; e (d) o
                  link será inserido em um contexto geral informativo.
                </span>
                <br className="termos-de-uso-text097"></br>
                <span className="termos-de-uso-text098">
                  Essas organizações podem postar links para nossa página inicial
                  contanto que o link: (a) não seja, de forma alguma, enganoso;
                  (b) não insinue falsamente a existência de uma relação de
                  patrocínio, endosso, ou aprovação de produtos e/ou serviços da
                  nossa parte; e (c) seja apropriado ao contexto em que está sendo
                  inserido.
                </span>
                <br className="termos-de-uso-text099"></br>
                <span className="termos-de-uso-text100">
                  Se você representa uma das organizações listadas no parágrafo 2
                  acima e está interessado em postar um link para nosso site, você
                  deve nos informar de seu interesse enviando um e-mail para
                  Simbium. Por favor, inclua seu nome, o nome da sua organização e
                  suas informações de contato, assim como a URL do seu site, uma
                  lista das URLs do nosso site que você pretende usar como links,
                  e uma lista dos sites nos quais você pretende publicar nossas
                  URLs. Aguarde entre 2 e 3 semanas para receber uma resposta.
                </span>
                <br className="termos-de-uso-text101"></br>
                <span className="termos-de-uso-text102">
                  Organizações aprovadas poderão publicar links para nosso Site
                  das seguintes formas:
                </span>
                <br className="termos-de-uso-text103"></br>
                <br className="termos-de-uso-text104"></br>
                <span className="termos-de-uso-text105">
                  - Usando nosso nome corporativo; ou
                </span>
                <br className="termos-de-uso-text106"></br>
                <span className="termos-de-uso-text107">
                  - Usando a URL para onde o link redireciona; ou
                </span>
                <br className="termos-de-uso-text108"></br>
                <span className="termos-de-uso-text109">
                  - Usando qualquer outra descrição do nosso Site que faça sentido
                  dentro do contexto e do formato do conteúdo onde o link está
                  sendo inserido.
                </span>
                <br className="termos-de-uso-text110"></br>
                <br className="termos-de-uso-text111"></br>
                <span className="termos-de-uso-text112">
                  Não será permitido o uso ou a publicação de links para o logo
                  e/ou outros elementos visuais do Simbium sem um acordo de
                  licença para o uso da marca registrada.
                </span>
                <br className="termos-de-uso-text113"></br>
                <br className="termos-de-uso-text114"></br>
                <span className="termos-de-uso-text115">
                  Responsabilização pelo Conteúdo
                </span>
                <br className="termos-de-uso-text116"></br>
                <br className="termos-de-uso-text117"></br>
                <span className="termos-de-uso-text118">
                  Não seremos responsabilizados legalmente por qualquer conteúdo
                  que apareça no seu Site. Você concorda em nos proteger e nos
                  defender contra todas as acusações que forem levantadas no seu
                  Site. Nenhum link deve aparecer em qualquer Site que possa ser
                  interpretado como difamatório, obsceno, criminoso, ou que
                  infrinja, viole ou defenda a violação dos direitos de terceiros.
                </span>
                <br className="termos-de-uso-text119"></br>
                <br className="termos-de-uso-text120"></br>
                <span className="termos-de-uso-text121">Reserva de Direitos</span>
                <br className="termos-de-uso-text122"></br>
                <br className="termos-de-uso-text123"></br>
                <span className="termos-de-uso-text124">
                  Reservamos nosso direito de solicitar que você remova todos os
                  links ou quaisquer links que redirecionem para nosso site. Você
                  concorda em remover imediatamente todos os links para nosso site
                  assim que a remoção for solicitada. Também reservamos nosso
                  direito de corrigir e alterar estes termos e condições a
                  qualquer momento. Ao publicar continuadamente links para nosso
                  site, você concorda a seguir estes termos e condições sobre
                  links.
                </span>
                <br className="termos-de-uso-text125"></br>
                <br className="termos-de-uso-text126"></br>
                <span className="termos-de-uso-text127">
                  Remoção de links postados em nosso site
                </span>
                <br className="termos-de-uso-text128"></br>
                <br className="termos-de-uso-text129"></br>
                <span className="termos-de-uso-text130">Se</span>
                <span className="termos-de-uso-text131">
                  {' '}
                  você encontrar qualquer link em nosso Site que seja de qualquer
                  forma ofensivo, você tem a liberdade de entrar em contato
                  conosco e nos informar do problema a qualquer momento. Vamos
                  considerar as solicitações de remoção de links, mas não somos
                  obrigados a remover quaisquer links do nosso site nem a
                  responder diretamente à sua solicitação.
                </span>
                <br className="termos-de-uso-text132"></br>
                <span className="termos-de-uso-text133">
                  Nós não garantimos que as informações contidas neste site são
                  corretas. Nós não garantimos integralidade ou exatidão do
                  conteúdo. Não garantimos que o site se manterá disponível ou que
                  o material do site se manterá atualizado.
                </span>
                <br className="termos-de-uso-text134"></br>
                <br className="termos-de-uso-text135"></br>
                <span className="termos-de-uso-text136">
                  Declaração de Isenção de Responsabilidade
                </span>
                <br className="termos-de-uso-text137"></br>
                <br className="termos-de-uso-text138"></br>
                <span className="termos-de-uso-text139">
                  No máximo possível permitido por lei, nós excluímos todas as
                  representações, garantias e condições relativas ao nosso site e
                  ao uso deste site. Nada nesta declaração de isenção de
                  responsabilidade vai:
                </span>
                <br className="termos-de-uso-text140"></br>
                <br className="termos-de-uso-text141"></br>
                <span className="termos-de-uso-text142">
                  - limitar ou excluir nossa responsabilidade ou sua
                  responsabilidade por mortes ou danos pessoais;
                </span>
                <br className="termos-de-uso-text143"></br>
                <span className="termos-de-uso-text144">
                  - limitar ou excluir nossa responsabilidade ou sua
                  responsabilidade por fraudes ou deturpações fraudulentas;
                </span>
                <br className="termos-de-uso-text145"></br>
                <span className="termos-de-uso-text146">
                  - limitar nossa responsabilidade ou sua responsabilidade de
                  quaisquer maneiras que não forem permitidas sob a lei;
                </span>
                <br className="termos-de-uso-text147"></br>
                <span className="termos-de-uso-text148">
                  - excluir quaisquer responsabilidades suas ou nossas que não
                  podem ser excluídas de acordo com a lei aplicável.
                </span>
                <br className="termos-de-uso-text149"></br>
                <br className="termos-de-uso-text150"></br>
                <span className="termos-de-uso-text151">
                  As limitações e proibições de responsabilização listadas nesta
                  Seção e em outras partes desta declaração: (a) estão sujeitas ao
                  parágrafo anterior; e (b) regem todas as responsabilizações que
                  surgirem sob a declaração, incluindo responsabilizações surgidas
                  em contrato, em delitos e em quebra de obrigações legais.
                </span>
                <br className="termos-de-uso-text152"></br>
                <span className="termos-de-uso-text153">
                  Enquanto o site e as informações e serviços do site forem
                  oferecidos gratuitamente, nós não seremos responsáveis por
                  perdas e danos de qualquer natureza.
                </span>
                <br className="termos-de-uso-text154"></br>
                <br className="termos-de-uso-text155"></br>
                <span className="termos-de-uso-text156">
                  Agradecemos por escolher o Simbium como sua fonte de informações
                  e serviços. Se você tiver alguma dúvida ou precisar de mais
                  esclarecimentos sobre nossos termos e condições, por favor,
                  entre em contato conosco. Esperamos que sua experiência em nosso
                  site seja agradável e enriquecedora.
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  )
}

export default TermosDeUso
