import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setUsuario } from '../../features/usuario/usuarioAtual';

import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './add_consultoria.css';

import axios from 'axios'
import { toast } from 'react-toastify';

import ModalAgendamentos from '../../views/home-official/consultoria/modal-agendamentos';

const PopupAgendarConsultoria = (props) => {
  const dispatch = useDispatch();
  const usuarioAtual = useSelector(state => state.usuarioAtual);

  const [stepAgendamento, setStepAgendamento] = useState(1);
  const [tituloAgendamento, setTituloAgendamento] = useState('');
  const [descricaoAgendamento, setDescricaoAgendamento] = useState('');
  const [professores, setProfessores] = useState([]);
  const [selectedProfessor, setSelectedProfessor] = useState('');
  const [horariosDisponiveis, setHorariosDisponiveis] = useState([]);
  const [horarioSelecionado, setHorarioSelecionado] = useState('');
  const [agendamentoLoading, setAgendamentoLoading] = useState('');
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [pacotesVisible, setPacotesVisible] = useState(usuarioAtual.consultorias && (!usuarioAtual.consultorias.cota || usuarioAtual.consultorias.cota === 0 || new Date(usuarioAtual.consultorias?.expiracao) < new Date()) && !usuarioAtual.consultorias.adesao);
  const [mostrarConsultorias, setMostrarConsultorias] = useState(false)


  const onboardingConsultoria = async () => {
    try {

      const response = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/usuarios/onboarding`, {
        etapa: 'agendar-consultoria'
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      })

      if (response.data && response.data.usuario) {
        dispatch(setUsuario(response.data.usuario));
      }

    } catch (err) {
    }

  }


  const handleProfessorChange = (professorId) => {
    if (selectedProfessor === professorId) {
      setSelectedProfessor('')
    } else {
      setSelectedProfessor(professorId);
      setStepAgendamento(2)
    }

    if (professorId === '') {
      setHorariosDisponiveis([], '')
    } else {
      getHorariosDisponiveis(selectedDate, professorId)
    }
  };

  const handleDateChange = date => {
    setSelectedDate(date);
    setHorarioSelecionado('')
    if (selectedProfessor !== '') {
      getHorariosDisponiveis(date, selectedProfessor)
    }
  };

  const handleHorarioChange = (newHorario) => {
    if (newHorario !== '') {
      setHorarioSelecionado(newHorario);
      setStepAgendamento(3)
    }
  }

  const formatSelectedDate = date => {
    const daysOfWeek = [
      'Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira',
      'Quinta-feira', 'Sexta-feira', 'Sábado'
    ];
    const months = [
      'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
      'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
    ];

    const day = daysOfWeek[date.getDay()];
    const dayOfMonth = date.getDate();
    const month = months[date.getMonth()];

    return `${day}, ${dayOfMonth} de ${month}`;
  };

  const formattedDate = formatSelectedDate(selectedDate);

  const professorTemHorarioDisponivel = date => {
    if (!selectedProfessor) return false;
    const professor = professores.find(p => p._id === selectedProfessor);
    if (!professor) return false;
    const dayOfWeek = date.getDay();
    const weekdays = ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado'];
    const disponibilidade = professor.horariosDisponiveis.find(h => h.diaSemana === weekdays[dayOfWeek]);
    return !!disponibilidade && disponibilidade.horarios.length > 0;
  };


  const getHorariosDisponiveis = async (date, professorId) => {
    const data = new Date(date)
    const dia = data.getDate();
    const mes = data.getMonth() + 1;
    const ano = data.getFullYear();

    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/usuarios/horarios-disponiveis?dia=${dia}&mes=${mes}&ano=${ano}&professorId=${professorId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      });
      setHorariosDisponiveis(response.data)
    } catch (err) {
    }
  }

  const agendarConsultoria = async () => {
    const data = new Date(selectedDate)
    const dia = data.getDate();
    const mes = data.getMonth();
    const ano = data.getFullYear();
    const hora = horarioSelecionado.split(":")[0]
    const minuto = horarioSelecionado.split(":")[1]
    const professorId = selectedProfessor
    const titulo = tituloAgendamento;
    const descricao = descricaoAgendamento;

    try {
      setAgendamentoLoading(true)
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/agendamentos`, {
        data: new Date(ano, mes, dia, hora, minuto, 0, 0).toISOString(),
        professor: professorId,
        titulo,
        descricao
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      })

      setAgendamentoLoading(false)
      props.fecharPopup()
      props.sucesso()
      dispatch(setUsuario({
        ...usuarioAtual,
        consultorias: {
          ...usuarioAtual.consultorias,
          cota: usuarioAtual.consultorias.cota - 1
        }
      }));

      if (!usuarioAtual.onboarding || !usuarioAtual.onboarding.etapas || !usuarioAtual.onboarding.etapas.includes("agendar-consultoria")) {
        onboardingConsultoria()
      }

    } catch (err) {
      setAgendamentoLoading(false)
      props.fecharPopup()
      if (err && err.response && err.response.data && err.response.data.error) {
        props.erro(err.response.data.error)
      } else {
        props.erro("Algo deu errado")
      }
    }
  }



  useEffect(() => {
    let isMounted = true;

    const fetchProfessores = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_BACKEND_BASE_URL + '/usuarios/professores?consultores=true', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        });
        if (isMounted && response.data) {
          console.log("PROFESSORES: ", response.data)
          setProfessores(response.data);
        }
      } catch (error) {
      }
    };

    fetchProfessores();

    return () => {
      isMounted = false;
    }

  }, [])


  return (
    <div className="home-popup-agendamento">
      {mostrarConsultorias && <ModalAgendamentos usuarioAtualId={usuarioAtual._id} consultores={professores} setConsultores={setProfessores} setMostrarConsultorias={setMostrarConsultorias} />}
      <div className="home-conteudo">
        <div className="btn-cancelar" onClick={props.fecharPopup}>
          <svg
            viewBox="0 0 804.5714285714286 1024"
          >
            <path d="M741.714 755.429c0 14.286-5.714 28.571-16 38.857l-77.714 77.714c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-168-168-168 168c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-77.714-77.714c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l168-168-168-168c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l77.714-77.714c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l168 168 168-168c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l77.714 77.714c10.286 10.286 16 24.571 16 38.857s-5.714 28.571-16 38.857l-168 168 168 168c10.286 10.286 16 24.571 16 38.857z"></path>
          </svg>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
          <h2>{(!usuarioAtual.consultorias || !usuarioAtual.consultorias.cota || usuarioAtual.consultorias.cota === 0) ? 'Sua cota esgotou!' :
            (!usuarioAtual.consultorias || !usuarioAtual.consultorias.expiracao || new Date(usuarioAtual.consultorias.expiracao) < new Date()) ? 'Sua cota expirou!' : 'Agendamento de Consultoria'}</h2>
          {usuarioAtual?.consultorias?.cota > 0 && new Date(usuarioAtual?.consultorias?.expiracao) > new Date() && stepAgendamento === 1 && (
            <p style={{ color: "#777", fontSize: '14px' }}>Você tem {usuarioAtual?.consultorias?.cota || 0} consultorias {usuarioAtual && usuarioAtual.consultorias && usuarioAtual.consultorias.expiracao ? `para usar até ${new Date(usuarioAtual.consultorias.expiracao).toLocaleDateString("pt-br")}` : ''}</p>
          )}
          {usuarioAtual.consultorias && !usuarioAtual.consultorias.adesao && usuarioAtual.consultorias?.cota > 0 && (
            <button onClick={() => setPacotesVisible(!pacotesVisible)} className="pacotes-personalizados-btn">{!pacotesVisible ? 'Ver pacotes personalizados' : 'Voltar para agendamento'}</button>
          )}
        </div>

        {usuarioAtual.consultorias && usuarioAtual.consultorias.cota && usuarioAtual.consultorias.cota > 0
          && usuarioAtual.consultorias.expiracao && new Date(usuarioAtual.consultorias.expiracao) > new Date() && !pacotesVisible ? (
          <div id="agendamento" style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '30px' }}>
            <div className="steps">
              <div className="line">
                <div className="progress"
                  style={{ width: `${stepAgendamento === 2 ? '52%' : stepAgendamento === 3 ? '100%' : '0%'}`, height: '100%', background: '#E86817' }}
                ></div>
              </div>
              <div className="step"
                onClick={() => {
                  setStepAgendamento(1)
                }}
                style={stepAgendamento === 1 || stepAgendamento === 2 || stepAgendamento === 3 ? { background: '#E86817', color: '#fff' } : {}}>1</div>
              <div className="step"
                onClick={() => {
                  if (selectedProfessor !== '') {
                    setStepAgendamento(2)
                  }
                }}
                style={stepAgendamento === 2 || stepAgendamento === 3 ? { background: '#E86817', color: '#fff' } : {}}>2</div>
              <div className="step"
                onClick={() => {
                  if (horarioSelecionado !== '' && selectedProfessor !== '') {
                    setStepAgendamento(3)
                  }
                }}
                style={stepAgendamento === 3 ? { background: '#E86817', color: '#fff' } : {}}>3</div>
            </div>
            {stepAgendamento === 1 && (
              <div className="home-container43">
                <h3 className="text-neutral-300 text-center"><span className='font-bold'>Agende sua consultoria:</span> Acelere seu crescimento e conte com apoio especializado!</h3>
                <div className="professores">
                  {professores?.length > 0 ? (
                    professores.map((professor, index) => (
                      <div key={index} className="professor"
                        style={selectedProfessor === professor._id ? { border: '5px solid #E86817' } : {}}
                        onClick={() => { handleProfessorChange(professor._id) }}
                      >
                        <img src={professor.foto_perfil ? professor.foto_perfil : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png'} />
                        <div className="dados">
                          <h4>{professor.nome}</h4>
                          {/*{professor.area && (
                            <h5>{professor.area}</h5>
                          )}*/}
                        </div>
                      </div>
                    ))
                  ) :
                    <p className='text-gray-500'>Não há consultores cadastrados no momento!</p>
                  }
                </div>
              </div>
            )}
            {stepAgendamento === 2 && (
              <div className="">
                <span className="home-text87">Escolha a melhor Data e Horário</span>
                <div className="home-container52 m-5">
                  <Calendar
                    value={selectedDate}
                    onChange={handleDateChange}
                    minDate={new Date()}
                    tileClassName={({ date, view }) => {
                      if (view === 'month' && professorTemHorarioDisponivel(date)) {
                        return 'day-with-availability';
                      }
                    }}
                  />
                  <div className="home-data">
                    <span className="home-text88">{formattedDate}</span>
                    <div className="home-horrios">
                      {horariosDisponiveis.length > 0 ? (
                        horariosDisponiveis.map((h, index) => (
                          <span
                            key={index}
                            className={`home-text89 ${horarioSelecionado === h ? 'active' : ''}`}
                            onClick={() => handleHorarioChange(h)}
                          >
                            {h}
                          </span>
                        ))
                      ) : (
                        selectedProfessor ? (
                          <span className="text-less">Nenhum horário disponível para a data selecionada</span>
                        ) : (
                          <span className="text-less">Selecione um professor para ver os horários</span>)
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {stepAgendamento === 3 && (
              <div style={{ width: '100%' }}>
                <div className="agendamento-detalhes">
                  <h3>Agendamento:</h3>
                  <div className="data-agendamento">
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-calendar-time" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path d="M11.795 21h-6.795a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v4"></path>
                      <path d="M18 18m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0"></path>
                      <path d="M15 3v4"></path>
                      <path d="M7 3v4"></path>
                      <path d="M3 11h16"></path>
                      <path d="M18 16.496v1.504l1 1"></path>
                    </svg>
                    <div className="data-selecionada">
                      <span>{selectedDate.toLocaleDateString('pt-br')}</span>
                      <span>às {horarioSelecionado}h</span>
                    </div>
                    <svg style={{ cursor: 'pointer' }} onClick={() => setStepAgendamento(2)} xmlns="http://www.w3.org/2000/svg" className="ionicon" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M364.13 125.25L87 403l-23 45 44.99-23 277.76-277.13-22.62-22.62zM420.69 68.69l-22.62 22.62 22.62 22.63 22.62-22.63a16 16 0 000-22.62h0a16 16 0 00-22.62 0z" /></svg>
                  </div>

                  <div className="info-adicional">
                    <h4>Você quer ajuda sobre:</h4>
                    <input type="text" value={tituloAgendamento} onChange={(e) => setTituloAgendamento(e.target.value)} />
                    <textarea value={descricaoAgendamento} onChange={(e) => setDescricaoAgendamento(e.target.value)}></textarea>
                  </div>
                </div>
                {selectedDate && selectedProfessor && horarioSelecionado && (
                  <button className={`btn-agendar ${agendamentoLoading ? 'loading' : ''}`} onClick={agendarConsultoria}>{agendamentoLoading ? 'Aguarde...' : 'Concluir Agendamento'}</button>
                )}
              </div>
            )}

            {professores.length ?
              <button className='w-full p-3 rounded-lg bg-none text-orange-500 hover:text-white active:text-neutral-400' onClick={() => setMostrarConsultorias(!mostrarConsultorias)}>Mostrar meus agendamentos</button>
              :
              <p className='text-xs text-white text-center'>Você ainda não possui nenhuma consultoria</p>
            }

          </div>
        ) : (<div style={{ display: 'none' }}></div>)}
        {usuarioAtual.consultorias && (!usuarioAtual.consultorias.cota || usuarioAtual.consultorias.cota === 0 || new Date(usuarioAtual.consultorias?.expiracao) < new Date()) && usuarioAtual.consultorias.adesao && (
          <div id="adesao">
            <p>Aprenda com os melhores e esteja um passo à frente - Conheça nossos planos de adesão:</p>
            <div className="planos">
              <a target="_blank" href={`https://clkdmg.site/pay/kit-6-consultorias?source=${usuarioAtual._id}`} className="plano">
                <h4>Assinatura Semestral</h4>
                <p>6 meses de acesso + 6 consultorias grátis</p>
                <p>R$ 1.499,00</p>
              </a>

              <a target="_blank" href={`https://clkdmg.site/pay/kit-12-consultorias?source=${usuarioAtual._id}`} className="plano">
                <h4>Assinatura Anual</h4>
                <p>12 meses de acesso + 12 consultorias grátis</p>
                <p>R$ 2.997,00</p>
              </a>
            </div>
          </div>
        )}
        {pacotesVisible && (
          <div id="pacotes">
            <p>Continue evoluindo! Compre um de nossos pacotes de consultoria:</p>
            <div className="planos">
              <a target="_blank" href={`https://clkdmg.site/pay/consultoria-de-30d-kit-4-encontros?source=${usuarioAtual._id}`} className="plano">
                <h4>Desafios de Rotina (4 Consultorias)</h4>
                <p>30 dias</p>
                <p>R$ 250,00</p>
              </a>
              <a target="_blank" href={`https://clkdmg.site/pay/playbook-de-marketing?source=${usuarioAtual._id}`} className="plano">
                <h4>Processo de Marketing (16 Consultorias)</h4>
                <p>16 semanas</p>
                <p>R$ 799,00</p>
              </a>
              <a target="_blank" href={`https://clkdmg.site/pay/playbook-de-vendas?source=${usuarioAtual._id}`} className="plano">
                <h4>Processo de Vendas (24 Consultorias)</h4>
                <p>24 semanas</p>
                <p>R$ 1.199,00</p>
              </a>
              <a target="_blank" href={`https://clkdmg.site/pay/playbook-completo-marketing-e-vendas?source=${usuarioAtual._id}`} className="plano">
                <h4>Processo Completo (48 Consultorias)</h4>
                <p>48 semanas</p>
                <p>R$ 1.929,00</p>
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default PopupAgendarConsultoria
