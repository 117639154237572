import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setUsuario } from '../features/usuario/usuarioAtual';
import { setCurso } from '../features/curso/cursoAtual';
import { setModulo } from '../features/modulo/moduloAtual';

import { useParams, Link } from 'react-router-dom';
import axios from 'axios'
import { Helmet } from 'react-helmet'
import { toast } from 'react-toastify';

import Header from '../components/header'
import Menu from '../components/menu';
import Footer from '../components/footer'
import Aside from '../components/aside'
import ListaModulos from '../components/lista-modulos'
import ListaOutrosCursos from '../components/lista-outros-cursos'
import PopupAnotacoesAula from '../components/popups/anotacoes_aula';
import PopupAgendamento from '../components/popups/add_consultoria'
import './aula.css'


const Aula = (props) => {
  const dispatch = useDispatch();
  const usuarioAtual = useSelector(state => state.usuarioAtual);
  let cursoAtual = useSelector(state => state.cursoAtual);
  let moduloAtual = useSelector(state => state.moduloAtual);

  const { slug } = useParams();
  const [curso, setCursoAula] = useState(null);
  const [modulo, setModuloAula] = useState(null);
  const [aula, setAula] = useState({})
  const [loading, setLoading] = useState(false);
  const [aulasModulo, setAulasModulo] = useState([])
  const [statsAula, setStatsAula] = useState({});
  const [selectedRating, setSelectedRating] = useState(null)
  const [aulaConcluida, setAulaConcluida] = useState(false)
  const [aulaFavorita, setAulaFavorita] = useState(null)
  const [popupVisible, setPopupVisible] = useState(false)
  const [agendamentoPopupVisible, setAgendamentoPopupVisible] = useState(false);
  const [started, setStarted] = useState(false);
  const [playing, setPlaying] = useState(false);

  const [showMenu, setShowMenu] = useState(window.innerWidth >= 1024);
  function baixarMaterial(material) {
    const anchor = document.createElement('a');
    anchor.href = material.url;
    anchor.download = material.nome;
    anchor.target = '_blank';
    anchor.click();
  }

  const onboardingInspiracional = async () => {
    try {

      const response = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/usuarios/onboarding`, {
        etapa: 'aula-inspiracional'
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      })

      if (response.data && response.data.usuario) {
        dispatch(setUsuario(response.data.usuario));
      }

    } catch (err) {

    }

  }

  const fecharPopup = () => {
    setPopupVisible(false)
  }

  const abrirPopup = () => {
    setPopupVisible(true)
  }

  const handleRatingChange = async (event) => {
    setSelectedRating(Number(event.target.value))

    try {
      const result = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/aulas/avaliar-aula`, {
        nota: event.target.value,
        aulaID: aula._id,
        cursoID: aula.curso?._id
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      })
      if (result.data && result.data.message) toast.success(result.data.message)

    } catch (err) {
    }
  };

  const updateUser = async () => {
    try {
      const userResponse = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/usuarios/usuario_atual`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      dispatch(setUsuario(userResponse.data))
    } catch (err) {
    }
  }

  const concluirAula = async () => {
    try {
      const result = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/aulas/concluir-aula`, {
        aulaID: aula._id,
        cursoID: curso?._id
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      })


      if (result.data && result.data.hasOwnProperty("concluida")) {
        setAulaConcluida(result.data.concluida)
        let newAulasModulo = aulasModulo;
        const aulaTarget = aulasModulo.aulas.findIndex(a => a._id === aula._id);
        if (aulaTarget !== -1) {
          newAulasModulo.aulas[aulaTarget].concluida = result.data.concluida;
          setAulasModulo([])
          setAulasModulo(newAulasModulo)
        }
        await updateUser()
      }

      if (result.data && result.data.concluida) {
        toast.success('Aula concluída')
      } else {
        toast.success('Aula desmarcada como concluída')
      }


      if ((!usuarioAtual.onboarding || !usuarioAtual.onboarding.etapas || !usuarioAtual.onboarding.etapas.includes("aula-inspiracional")) && (!aula.modulo || aula.modulo.length === 0) && (!aula.curso || aula.curso.length === 0)
        && result.data && result.data.concluida) {
        onboardingInspiracional()
      }

    } catch (err) {
    }
  }

  const favoritarAula = async () => {
    try {
      const result = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/aulas/favoritar-aula`, {
        aulaID: aula._id,
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      })

      if (result.data && result.data.hasOwnProperty("favorita")) {
        setAulaFavorita(result.data.favorita)
        await updateUser()
      }

      if (!aulaFavorita) {
        toast.success('Aula favoritada')
      } else {
        toast.success('Aula desmarcada como favorita')
      }

    } catch (err) {
    }
  }

  const handlePlayClick = () => {
    setPlaying(true);
    setStarted(true);
  };

  const copyToClipboard = () => {
    const textArea = document.createElement('textarea');
    textArea.value = aula.curso.lp;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    toast.success("Link de afiliação copiado!")
  };

  const fetchDadosAula = async () => {
    const response3 = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/aulas/stats-aula?aulaID=${aula._id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });

    setStatsAula(response3.data)
  };

  async function fetchAula() {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/aulas?slug=${slug}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      });
      if (response.data.aulas && response.data.aulas.length > 0) {
        setAula(response.data.aulas[0])
      }
    } catch (error) {
    }
  }


  useEffect(() => {
    fetchAula();
    setPlaying(false)
    setStarted(false)
    setAulaConcluida(null)
    setAulaFavorita(null)
    setSelectedRating(null)

  }, [slug]);

  useEffect(() => {
    let isMounted = true

    if (aula._id && isMounted) {
      let c = aula.curso.find(c => c._id === cursoAtual)
      if (c) {
        setCursoAula(c)
      } else {
        if (aula.curso && aula.curso.length > 0) {
          setCursoAula(aula.curso[0])
          dispatch(setCurso(aula.curso[0]._id))
        }
      }

      let m = aula.modulo.find(m => m._id === moduloAtual)
      if (m) {
        setModuloAula(m)
      } else {
        if (aula.modulo && aula.modulo.length > 0) {
          setModuloAula(aula.modulo[0])
          dispatch(setModulo(aula.modulo[0]._id))
        }
      }

      fetchDadosAula()
    }

    return () => {
      isMounted = false
    }

  }, [aula])

  useEffect(() => {
    let isMounted = true

    const fetchAulasModulo = async () => {
      if (aula.modulo && aula.modulo.length > 0) {
        try {
          const response2 = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/aulas?modulo=${modulo._id ? modulo._id : ''}&curso=${curso._id ? curso._id : ''}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          });

          if (isMounted) {
            setAulasModulo(response2.data)
          }
        } catch (err) {
        }
      } else {
        try {
          const response2 = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/aulas?inspiracionais=${true}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          });
          if (isMounted) {
            setAulasModulo(response2.data)
          }
        } catch (err) {
        }
      }

      setLoading(false);
    };

    fetchAulasModulo()

    return () => {
      isMounted = false
    }
  }, [curso, modulo])

  useEffect(() => {

    if (statsAula) {
      if (statsAula.nota) {
        setSelectedRating(statsAula.nota)
      }

      if (statsAula.hasOwnProperty("concluida")) {
        setAulaConcluida(statsAula.concluida)
      }

      if (statsAula.hasOwnProperty("favorita")) {
        setAulaFavorita(statsAula.favorita)
      }
    }

  }, [statsAula])

  return (
    <div>
      <Header usuarioAtual={usuarioAtual} showMenu={showMenu} setShowMenu={setShowMenu} />
      <div className="aula-container">
        <Helmet>
          <title>{aula ? aula.nome + ' - Simbium' : 'Aula - Simbium'}</title>
          <meta
            property="og:title"
            content={`${aula ? aula.nome : 'Aula'} - Simbium`}
          />
        </Helmet>
        <div className="aula-container01">
          {/*{window.outerWidth > 991 && (
            <Aside agendarConsultoria={() => setPopupAgendamentoVisible(true)}></Aside>
          )}*/}
          {popupVisible && <PopupAnotacoesAula updateUser={updateUser} fetchAula={fetchAula} fecharPopup={fecharPopup} anotacao={statsAula.anotacao} item={aula} sucesso={() => { toast.success('Anotação salva com sucesso!') }} />}
          {!loading && aula ? (
            <div className="aula-contedo l-cont">
              <div className="aula-container02">
                <div className="aula-container03">
                  <div className="aula-video" style={(aula.modulo && aula.modulo.length === 0) || (aula.curso && aula.curso.length === 0) ? { width: '100%' } : {}}>
                    {!started ? (
                      <div className="img" onClick={handlePlayClick}>
                        <img src={aula.capa} alt="Capa do vídeo" />
                        <div className="button">
                          <button>
                            <svg
                              fill="rgb(255, 91, 28)"
                              height="64px"
                              width="64px"
                              version="1.1"
                              id="Layer_1"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              viewBox="0 0 472.615 472.615"
                              xmlSpace="preserve"
                            >
                              <g id="SVGRepo_bgCarrier" strokeWidth="0" />
                              <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
                              <g id="SVGRepo_iconCarrier">
                                <g>
                                  <g>
                                    <path d="M236.308,0C105.799,0,0,105.798,0,236.308c0,130.507,105.799,236.308,236.308,236.308s236.308-105.801,236.308-236.308 C472.615,105.798,366.816,0,236.308,0z M139.346,347.733V124.88l229.37,111.428L139.346,347.733z" />
                                  </g>
                                </g>
                              </g>
                            </svg>
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className="videoplayer" style={{ display: playing ? 'flex' : 'none' }}>
                        <div dangerouslySetInnerHTML={{ __html: aula.video }} />
                      </div>
                    )}
                  </div>

                  {aula.curso && aula.curso.length > 0 && aula.modulo && aula.modulo.length > 0 ?
                    (<div className="aula-container04">
                      <div className="aula-dados-modulo">
                        <span className="aula-text">{modulo?.nome}</span>
                        <Link to={`/curso/${curso?.slug}`} className="aula-text01">
                          <span>{curso?.nome}</span>
                        </Link>
                      </div>
                      <div className="aula-aulas-modulo">
                        {aulasModulo && aulasModulo.aulas && aulasModulo.aulas.length > 0 && aulasModulo.aulas.map((aulaModulo, index) => (
                          <Link to={`/aula/${aulaModulo.slug}`} key={index}
                            className={`aula-aula ${aula._id === aulaModulo._id ? 'current' : ''}`}
                          >
                            <div style={{ position: 'relative' }}>
                              <img
                                alt="image"
                                src={aulaModulo.capa}
                                className="aula-capa-da-aula"
                              />
                              {aulaModulo.concluida && (
                                <div style={{ position: 'absolute', top: '0', left: '0', right: '0', bottom: '0', background: 'rgb(0 63 0 / 81%)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                  <svg viewBox="0 0 1024 1024" fill="#0cda07" width="24">
                                    <path d="M954.857 323.429c0 14.286-5.714 28.571-16 38.857l-491.429 491.429c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-284.571-284.571c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l77.714-77.714c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l168 168.571 374.857-375.429c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l77.714 77.714c10.286 10.286 16 24.571 16 38.857z"></path>
                                  </svg>
                                </div>
                              )}
                            </div>
                            <span className="aula-nome-da-aula">
                              <span>{aulaModulo.nome}</span>
                            </span>
                          </Link>
                        ))}
                      </div>
                    </div>) :
                    (
                      <div className="aula-container04">
                        <div className="aula-dados-modulo">
                          <span className="aula-text">Chave Mestra</span>
                          <span className="aula-text02">Recurso exclusivo para assinantes</span>
                        </div>
                        {usuarioAtual.ref.pago ? (
                          <div className="aula-aulas-modulo">
                            {aulasModulo && aulasModulo.aulas && aulasModulo.aulas.length > 0 && aulasModulo.aulas.map((aulaModulo, index) => (
                              <Link to={`/aula/${aulaModulo.slug}`} key={index}
                                className={`aula-aula ${aula._id === aulaModulo._id ? 'current' : ''}`}
                              >
                                <div style={{ position: 'relative' }}>
                                  <img
                                    alt="image"
                                    src={aulaModulo.capa}
                                    className="aula-capa-da-aula"
                                  />
                                  {aulaModulo.concluida && (
                                    <div style={{ position: 'absolute', top: '0', left: '0', right: '0', bottom: '0', background: 'rgb(0 63 0 / 81%)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                      <svg viewBox="0 0 1024 1024" fill="#0cda07" width="24">
                                        <path d="M954.857 323.429c0 14.286-5.714 28.571-16 38.857l-491.429 491.429c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-284.571-284.571c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l77.714-77.714c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l168 168.571 374.857-375.429c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l77.714 77.714c10.286 10.286 16 24.571 16 38.857z"></path>
                                      </svg>
                                    </div>
                                  )}
                                </div>
                                <span className="aula-nome-da-aula">
                                  <span>{aulaModulo.nome}</span>
                                </span>
                              </Link>
                            ))}
                          </div>
                        ) : (
                          <div className="aula-aulas-modulo">
                            {aulasModulo && aulasModulo.aulas && aulasModulo.aulas.length > 0 && aulasModulo.aulas.map((aulaModulo, index) => (
                              <div key={index}
                                className={`aula-aula ${aula._id === aulaModulo._id ? 'current' : ''} ${aulaModulo.slug === "bem-vindo-a-chave-mestra-2" ? "master-key-free-class" : "master-key-paid-class"}`}
                              >
                                <div style={{ position: 'relative' }}>
                                  <img
                                    alt="image"
                                    src={aulaModulo.capa}
                                    className="aula-capa-da-aula"
                                  />
                                  {aulaModulo.concluida && (
                                    <div style={{ position: 'absolute', top: '0', left: '0', right: '0', bottom: '0', background: 'rgb(0 63 0 / 81%)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                      <svg viewBox="0 0 1024 1024" fill="#0cda07" width="24">
                                        <path d="M954.857 323.429c0 14.286-5.714 28.571-16 38.857l-491.429 491.429c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-284.571-284.571c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l77.714-77.714c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l168 168.571 374.857-375.429c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l77.714 77.714c10.286 10.286 16 24.571 16 38.857z"></path>
                                      </svg>
                                    </div>
                                  )}
                                </div>
                                <span className="aula-nome-da-aula">
                                  <span>{aulaModulo.nome}</span>
                                </span>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    )
                  }
                </div>
              </div>
              <div className="aula-acoes-aula">
                <div className="aula-avaliacao">
                  <div className="aula-container05">
                    <span className="aula-text06">Avaliar aula:</span>
                    <div className="rate">
                      <input type="radio" id="star5" name="rate" value="5" checked={selectedRating === 5} onChange={handleRatingChange} />
                      <label htmlFor="star5" title="text">5 stars</label>
                      <input type="radio" id="star4" name="rate" value="4" checked={selectedRating === 4} onChange={handleRatingChange} />
                      <label htmlFor="star4" title="text">4 stars</label>
                      <input type="radio" id="star3" name="rate" value="3" checked={selectedRating === 3} onChange={handleRatingChange} />
                      <label htmlFor="star3" title="text">3 stars</label>
                      <input type="radio" id="star2" name="rate" value="2" checked={selectedRating === 2} onChange={handleRatingChange} />
                      <label htmlFor="star2" title="text">2 stars</label>
                      <input type="radio" id="star1" name="rate" value="1" checked={selectedRating === 1} onChange={handleRatingChange} />
                      <label htmlFor="star1" title="text">1 star</label>
                    </div>
                  </div>
                </div>
                <div className="aula-anotacoes">
                  <div className="aula-container07">
                    <span className="aula-text07">
                      <span>Anotações pessoais</span>
                      <br></br>
                    </span>
                    <div className="aula-container08" onClick={abrirPopup}
                      style={statsAula && statsAula.anotacao && statsAula.anotacao !== '' ? { background: '#E86811' } : {}}
                    >
                      <svg
                        viewBox="0 0 804.5714285714286 1024"
                        className="aula-icon10"
                      >
                        <path d="M804.571 420.571v109.714c0 30.286-24.571 54.857-54.857 54.857h-237.714v237.714c0 30.286-24.571 54.857-54.857 54.857h-109.714c-30.286 0-54.857-24.571-54.857-54.857v-237.714h-237.714c-30.286 0-54.857-24.571-54.857-54.857v-109.714c0-30.286 24.571-54.857 54.857-54.857h237.714v-237.714c0-30.286 24.571-54.857 54.857-54.857h109.714c30.286 0 54.857 24.571 54.857 54.857v237.714h237.714c30.286 0 54.857 24.571 54.857 54.857z"></path>
                      </svg>
                      <span className="aula-text10">{statsAula && statsAula.anotacao && statsAula.anotacao !== '' ? 'Ver nota' : 'Adicionar nota'}</span>
                    </div>
                  </div>
                </div>
                <div className="aula-concluida">
                  <div className="aula-container09">
                    <span className="aula-text11">Marcar como concluída</span>
                  </div>
                  <div className="aula-container10"
                    onClick={concluirAula}
                    style={aulaConcluida ? { backgroundColor: "#E86811" } : {}}
                  >
                    {aulaConcluida ? (
                      <svg
                        style={{ alignSelf: 'center' }}
                        fill="none" xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-square-check-filled" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#1b1b1b" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" />
                        <path d="M18.333 2c1.96 0 3.56 1.537 3.662 3.472l.005 .195v12.666c0 1.96 -1.537 3.56 -3.472 3.662l-.195 .005h-12.666a3.667 3.667 0 0 1 -3.662 -3.472l-.005 -.195v-12.666c0 -1.96 1.537 -3.56 3.472 -3.662l.195 -.005h12.666zm-2.626 7.293a1 1 0 0 0 -1.414 0l-3.293 3.292l-1.293 -1.292l-.094 -.083a1 1 0 0 0 -1.32 1.497l2 2l.094 .083a1 1 0 0 0 1.32 -.083l4 -4l.083 -.094a1 1 0 0 0 -.083 -1.32z" strokeWidth="0" fill="#1b1b1b" />
                      </svg>
                    ) : (
                      <svg
                        style={{ alignSelf: 'center' }}
                        fill="none" xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-square" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#1b1b1b" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" />
                        <path d="M3 3m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z" />
                      </svg>
                    )}
                    <span className="aula-text12"
                    >{aulaConcluida ? 'Aula Concluída' : 'Concluir Aula'}</span>
                  </div>
                </div>
                <div className="aula-favoritar">
                  <div className="aula-container11">
                    <span className="aula-text13">{aula.curso && aula.curso.lp ? 'Favoritar/Compartilhar' : 'Favoritar'}</span>
                  </div>
                  <div className="aula-container12"
                  >
                    <div className="aula-container13"
                      style={aulaFavorita ? { backgroundColor: "#E86811" } : {}}
                      onClick={favoritarAula}
                    >
                      <svg viewBox="0 0 1024 1024" className="aula-icon14">
                        <path d="M512 950.857c-9.143 0-18.286-3.429-25.143-10.286l-356.571-344c-4.571-4-130.286-118.857-130.286-256 0-167.429 102.286-267.429 273.143-267.429 100 0 193.714 78.857 238.857 123.429 45.143-44.571 138.857-123.429 238.857-123.429 170.857 0 273.143 100 273.143 267.429 0 137.143-125.714 252-130.857 257.143l-356 342.857c-6.857 6.857-16 10.286-25.143 10.286z"></path>
                      </svg>
                    </div>
                    {aula.curso && aula.curso.lp && (
                      <div className="aula-container14" onClick={copyToClipboard}>
                        <svg
                          viewBox="0 0 954.2948571428572 1024"
                          className="aula-icon16"
                        >
                          <path d="M804.571 565.143v148c0 90.857-73.714 164.571-164.571 164.571h-475.429c-90.857 0-164.571-73.714-164.571-164.571v-475.429c0-90.857 73.714-164.571 164.571-164.571h145.714c9.714 0 18.286 8 18.286 18.286 0 9.143-6.286 16.571-14.857 18.286-28.571 9.714-54.286 21.143-76 34.286-2.857 1.143-5.714 2.286-9.143 2.286h-64c-50.286 0-91.429 41.143-91.429 91.429v475.429c0 50.286 41.143 91.429 91.429 91.429h475.429c50.286 0 91.429-41.143 91.429-91.429v-122.286c0-6.857 4-13.143 10.286-16.571 11.429-5.143 21.714-12.571 30.857-21.143 5.143-5.143 13.143-7.429 20-4.571s12 9.143 12 16.571zM940 281.714l-219.429 219.429c-6.857 7.429-16 10.857-25.714 10.857-4.571 0-9.714-1.143-14.286-2.857-13.143-5.714-22.286-18.857-22.286-33.714v-109.714h-91.429c-125.714 0-205.714 24-250.286 74.857-46.286 53.143-60 138.857-42.286 270.286 1.143 8-4 16-11.429 19.429-2.286 0.571-4.571 1.143-6.857 1.143-5.714 0-11.429-2.857-14.857-7.429-4-5.714-94.857-134.286-94.857-248.571 0-153.143 48-329.143 420.571-329.143h91.429v-109.714c0-14.857 9.143-28 22.286-33.714 4.571-1.714 9.714-2.857 14.286-2.857 9.714 0 18.857 4 25.714 10.857l219.429 219.429c14.286 14.286 14.286 37.143 0 51.429z"></path>
                        </svg>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="aula-descricao">
                <div className="aula-container15">
                  <span className="aula-text14">
                    <span>
                      Aula:
                    </span>
                  </span>
                  <span className="aula-text17">
                    <span>{aula.nome}</span>
                    <br></br>
                  </span>
                </div>
                <span className="aula-text20">
                  <div dangerouslySetInnerHTML={{ __html: aula.descricao }} />
                </span>
              </div>
              {aula.materiais && aula.materiais.length > 0 && (
                <div className="materiais-aula">
                  <div className="head-mat-aulas">
                    <svg viewBox="0 0 1024 1024" className="lista-materiais-icon">
                      <path
                        d="M766 726l-34-142 110-96-144-12-58-134-58 134-144 12 110 96-34 142 126-74zM854 256q34 0 59 26t25 60v426q0 34-25 60t-59 26h-684q-34 0-59-26t-25-60v-512q0-34 25-60t59-26h256l86 86h342z"
                        className=""
                      ></path>
                    </svg>
                    <span className="lista-materiais-text">Materiais</span>
                  </div>

                  <div className="lista-materiais">
                    {aula.materiais.map((material, index) => (
                      <div className="material" key={index} onClick={() => baixarMaterial(material)}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-file-download" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                          <path d="M14 3v4a1 1 0 0 0 1 1h4"></path>
                          <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z"></path>
                          <path d="M12 17v-6"></path>
                          <path d="M9.5 14.5l2.5 2.5l2.5 -2.5"></path>
                        </svg>
                        <span>{material.nome}</span>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {aula.modulo && aula.modulo.length > 0 && (
                <ListaModulos cursoId={cursoAtual}></ListaModulos>
              )}

              <ListaOutrosCursos cursoId={cursoAtual}></ListaOutrosCursos>
              {agendamentoPopupVisible && (
                <PopupAgendamento erro={(e) => { toast.error(e) }} sucesso={() => toast.success("Consultoria agendada com sucesso!")} fecharPopup={() => setAgendamentoPopupVisible(false)} />
              )}
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </div>
      <Footer></Footer>
    </div>
  )
}

export default Aula