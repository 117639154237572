import React from 'react';

const DocumentInputPayment = ({ card, cardErrors, setCard, setCardErrors, disabled }) => {

    const formatcpf = (cpf) => {
        return cpf.replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d{1,2})$/, '$1-$2');
    };

    const formatcnpj = (cnpj) => {
        return cnpj.replace(/(\d{2})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d)/, '$1/$2')
            .replace(/(\d{4})(\d{1,2})$/, '$1-$2');
    };

    const validatecpf = (cpf) => {
        cpf = cpf.replace(/\D/g, ''); // Remove non-numeric characters

        if (cpf.length !== 11) {
            return false;
        }

        // Eliminate known invalid cpfs
        if (/^(\d)\1*$/.test(cpf)) {
            return false;
        }

        let sum = 0;
        let remainder;

        for (let i = 1; i <= 9; i++) {
            sum += parseInt(cpf.substring(i - 1, i)) * (11 - i);
        }

        remainder = (sum * 10) % 11;

        if ((remainder === 10) || (remainder === 11)) {
            remainder = 0;
        }
        if (remainder !== parseInt(cpf.substring(9, 10))) {
            return false;
        }

        sum = 0;
        for (let i = 1; i <= 10; i++) {
            sum += parseInt(cpf.substring(i - 1, i)) * (12 - i);
        }
        remainder = (sum * 10) % 11;

        if ((remainder === 10) || (remainder === 11)) {
            remainder = 0;
        }
        if (remainder !== parseInt(cpf.substring(10, 11))) {
            return false;
        }

        return true;
    };

    const validatecnpj = (cnpj) => {
        cnpj = cnpj.replace(/\D/g, ''); // Remove non-numeric characters

        if (cnpj.length !== 14) {
            return false;
        }

        // Eliminate known invalid cnpjs
        if (/^(\d)\1*$/.test(cnpj)) {
            return false;
        }

        let length = cnpj.length - 2;
        let numbers = cnpj.substring(0, length);
        let digits = cnpj.substring(length);
        let sum = 0;
        let pos = length - 7;

        for (let i = length; i >= 1; i--) {
            sum += numbers.charAt(length - i) * pos--;
            if (pos < 2) {
                pos = 9;
            }
        }

        let result = sum % 11 < 2 ? 0 : 11 - sum % 11;
        if (result !== parseInt(digits.charAt(0))) {
            return false;
        }

        length = length + 1;
        numbers = cnpj.substring(0, length);
        sum = 0;
        pos = length - 7;

        for (let i = length; i >= 1; i--) {
            sum += numbers.charAt(length - i) * pos--;
            if (pos < 2) {
                pos = 9;
            }
        }

        result = sum % 11 < 2 ? 0 : 11 - sum % 11;
        if (result !== parseInt(digits.charAt(1))) {
            return false;
        }

        return true;
    };

    const detectDocumentType = (document) => {
        return document.length < 12 ? 'cpf' : 'cnpj';
    };

    const handleDocumentChange = (e) => {
        let { value } = e.target;

        // Remove non-numeric characters for storage
        const rawDocument = value.replace(/\D/g, '');

        const documentType = detectDocumentType(rawDocument);

        // Validate document
        let isValid = false;
        if (documentType === 'cpf') {
            isValid = validatecpf(rawDocument);
        } else {
            isValid = validatecnpj(rawDocument);
        }

        setCard((prevCard) => ({
            ...prevCard,
            holder_document: rawDocument,
        }));

        setCardErrors((prevErrors) => ({
            ...prevErrors,
            holder_document: isValid ? '' : `${documentType.toUpperCase()} inválido`
        }));
    };

    const formatDocument = (document, documentType) => {
        if (documentType === 'cpf') return formatcpf(document);
        return formatcnpj(document);
    };

    return (
        <input
            type="text"
            id="document"
            name="document"
            value={formatDocument(card.holder_document, detectDocumentType(card.holder_document))}
            onChange={handleDocumentChange}
            required
            disabled={disabled}
            minLength={14}
            maxLength={18}
            className='w-full text-sm p-2 rounded-md bg-neutral-700 focus:bg-neutral-700 focus:outline-none focus:border-none focus:shadow-none focus:ring-1 focus:ring-orange-500'
        />
    );
};

export default DocumentInputPayment;
