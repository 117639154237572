import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import Menu from '../../components/menu';
import Footer from '../../components/footer';
import Header from '../../components/header';
import axios from 'axios';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { PlusIcon } from '@heroicons/react/24/solid'
import { toast } from 'react-toastify';
import { RectangleGroupIcon } from '@heroicons/react/24/solid'

const FornecedoresCatalogados = () => {
    const usuarioAtual = useSelector(state => state.usuarioAtual);
    const [showMenu, setShowMenu] = useState(false);
    const [fornecedores, setFornecedores] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [modalMode, setModalMode] = useState('create')
    const [processing, setProcessing] = useState(false)

    const [fornecedor, setFornecedor] = useState({
        nome: '',
        descricao: '',
        categoria: {
            main: '',
            others: [],
        },
        localizacao: '',
        link: '',
    })
    const [fornecedorErros, setFornecedorErros] = useState({
        nome: '',
        descricao: '',
        categoria: {
            main: '',
            others: '',
        },
        localizacao: '',
        link: '',
    })

    useEffect(() => {
        // Função para buscar a lista de fornecedores
        const fetchFornecedores = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/fornecedores-catalogados`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                });

                if (response.data) {
                    setFornecedores(response.data);
                } else {
                    toast.error("Erro ao capturar os fornecedores. Recarregue a página.");
                }
            } catch (error) {
                console.error('Erro ao buscar fornecedores:', error);
                toast.error("Erro ao capturar os fornecedores. Recarregue a página.");
            }
        };

        fetchFornecedores();
    }, []);

    const closeModal = () => {
        setFornecedor({
            nome: '',
            descricao: '',
            categoria: {
                main: '',
                others: [],
            },
            localizacao: '',
            link: '',
        })
        setModalMode('create')
        setShowModal(false)
    }

    const [newOtherCategory, setNewOtherCategory] = useState('');

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFornecedor(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleCategoryChange = (e) => {
        const { name, value } = e.target;
        setFornecedor(prev => ({
            ...prev,
            categoria: {
                ...prev.categoria,
                [name]: value
            }
        }));
    };

    const addOtherCategory = () => {
        if (newOtherCategory.trim()) {
            setFornecedor(prev => ({
                ...prev,
                categoria: {
                    ...prev.categoria,
                    others: [...prev.categoria.others, newOtherCategory]
                }
            }));
            setNewOtherCategory('');
        }
    };

    const removeOtherCategory = (index) => {
        setFornecedor(prev => ({
            ...prev,
            categoria: {
                ...prev.categoria,
                others: prev.categoria.others.filter((_, i) => i !== index)
            }
        }));
    };

    const createFornecedor = async () => {
        setProcessing(true)
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/fornecedores-catalogados`, fornecedor, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            });
            if (response.data) {
                setFornecedores(prevFornecedores => [...prevFornecedores, response.data]);
                toast.success("Fornecedor cadastrado com sucesso!");
                closeModal();
                setProcessing(false)
            } else {
                console.log(response)
                toast.error("Erro ao cadastrar fornecedor. Tente novamente.");
                setProcessing(false)
            }
        } catch (error) {
            console.error('Erro ao cadastrar fornecedor:', error);
            toast.error("Erro ao cadastrar fornecedor. Tente novamente.");
            setProcessing(false)
        }
    }

    const updateFornecedor = async (fornecedor_id) => {
        setProcessing(true)
        try {
            const response = await axios.put(`${process.env.REACT_APP_BACKEND_BASE_URL}/fornecedores-catalogados/${fornecedor_id}`, fornecedor, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            });
            if (response.data) {
                const updatedFornecedor = response.data;

                // Atualize a lista de fornecedores
                setFornecedores(prevFornecedores =>
                    prevFornecedores.map(f =>
                        f.id === updatedFornecedor.id ? updatedFornecedor : f
                    )
                );
                toast.success("Fornecedor atualizado com sucesso!");
                closeModal();
                setProcessing(false)
            } else {
                console.log(response)
                toast.error("Erro ao atualizar fornecedor. Tente novamente.");
                setProcessing(false)
            }
        } catch (error) {
            console.error('Erro ao atualizar fornecedor:', error);
            toast.error("Erro ao atualizar fornecedor. Tente novamente.");
            setProcessing(false)
        }
    }

    const handleSave = async (fornecedor_id = null) => {
        if (modalMode === 'create') createFornecedor();
        else if (modalMode === 'update') updateFornecedor(fornecedor_id);
        else toast.error('Modal está em modo desconhecido!')
    };

    const [disableModal, setDisableModal] = useState(true);
    useEffect(() => {
        setDisableModal(true)
        if (!fornecedor.nome) {
            fornecedorErros.nome = 'O nome deve ser um texto não vazio'
            return true;
        } else {
            fornecedorErros.nome = ''
        }

        if (!fornecedor.descricao) {
            fornecedorErros.descricao = 'A descrição deve ser um texto não vazio'
            return true;
        } else {
            fornecedorErros.descricao = ''
        }

        if (!fornecedor.categoria.main) {
            fornecedorErros.categoria.main = 'A categoria principal deve ser um texto não vazio'
            return true;
        } else {
            fornecedorErros.categoria.main = ''
        }

        if (!fornecedor.categoria.others.length) {
            fornecedorErros.categoria.others = 'Você deve incluir pelo menos 1 categoria extra'
            return true;
        } else {
            fornecedorErros.categoria.others = ''
        }

        if (!fornecedor.localizacao) {
            fornecedorErros.localizacao = 'A localização deve ser um texto não vazio'
            return true;
        } else {
            fornecedorErros.localizacao = ''
        }

        if (!fornecedor.link) {
            fornecedorErros.link = 'O link deve ser um texto não vazio'
            return true;
        } else {
            fornecedorErros.link = ''
        }
        setDisableModal(false)
    }, [fornecedor, fornecedor.categoria, newOtherCategory])

    const filteredFornecedores = fornecedores.filter(fornecedor =>
        fornecedor.nome.toLowerCase().includes(searchTerm.toLowerCase()) ||
        fornecedor.descricao.toLowerCase().includes(searchTerm.toLowerCase()) ||
        fornecedor.categoria.main.toLowerCase().includes(searchTerm.toLowerCase()) ||
        fornecedor.categoria.others.some(el => el.toLowerCase().includes(searchTerm.toLowerCase())) ||
        fornecedor.localizacao.toLowerCase().includes(searchTerm.toLowerCase()) ||
        fornecedor.link.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const deletarFornecedor = async (fornecedor_id, fornecedor_nome) => {
        if (!confirm(`Você tem certeja que deseja excluir o fornecedor ${fornecedor_nome}?`)) return;
        setProcessing(true)
        console.log("ID: ", fornecedor_id);
        try {
            const response = await axios.delete(`${process.env.REACT_APP_BACKEND_BASE_URL}/fornecedores-catalogados/${fornecedor_id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            });
            if (response.data) {
                setFornecedores(fornecedores.filter(el => el._id !== fornecedor_id));
                toast.success("Fornecedor deletado com sucesso!");
                setProcessing(false)
            } else {
                console.log(response)
                toast.error("Erro ao deletar fornecedor. Tente novamente.");
                setProcessing(false)
            }
        } catch (error) {
            console.error('Erro ao deletar fornecedor:', error);
            toast.error("Erro ao deletar fornecedor. Tente novamente.");
            setProcessing(false)
        }
    }

    const editarFornecedor = (fornecedor_id) => {
        setFornecedor(fornecedores.find(el => el._id === fornecedor_id));
        setModalMode('update')
        setShowModal(true);
    }

    const mixPanelAcesso = () => {
        if (!usuarioAtual || !usuarioAtual._id) {
            console.error('usuarioAtual or usuarioAtual._id is missing');
            return;
        }
        mixpanel.track("Fornecedores | Acesso à página", {
            distinct_id: usuarioAtual._id,
        });
    }

    useEffect(() => {
        mixPanelAcesso();
    }, []);

    return (
        <div className='w-full'>
            {showModal &&
                <div className="fixed top-0 left-0 w-screen min-h-screen flex justify-center align-middle items-center z-40">
                    <div className="w-full min-h-screen bg-neutral-950 opacity-95" onClick={() => closeModal()}></div>
                    <div className="absolute w-11/12 md:w-1/2 rounded-lg bg-neutral-800 p-5 text-neutral-300 overflow-y-auto max-h-screen">
                        <div className="border-b border-neutral-700 py-5 space-y-2 flex justify-between items-center">
                            <img src='/main_logo.png' className='h-7' />
                            <button className="text-4xl hover:text-neutral-200 active:text-neutral-50" onClick={() => closeModal()}>&times;</button>
                        </div>
                        <div className="py-5 flex flex-col gap-5 w-2/3 m-auto">
                            <div className='flex flex-col w-full gap-2'>
                                <label htmlFor="nome" className='text-xs md:text-sm'>Nome</label>
                                <input type="text" id="nome" name="nome" value={fornecedor.nome} onChange={handleInputChange} required placeholder="Indique o nome do fornecedor" autoFocus className='w-full text-sm p-2 rounded-md bg-neutral-700 focus:bg-neutral-700 focus:outline-none focus:border-none focus:shadow-none focus:ring-1 focus:ring-orange-500' />
                                {fornecedorErros.nome && <p className='text-red-500 text-xs md:text-sm pt-1 pb-2'>{fornecedorErros.nome}</p>}
                            </div>
                            <div className='flex flex-col w-full gap-2'>
                                <label htmlFor="descricao" className='text-xs md:text-sm'>Descrição</label>
                                <input type="text" id="descricao" name="descricao" value={fornecedor.descricao} onChange={handleInputChange} required placeholder="Indique a descrição do fornecedor" className='w-full text-sm p-2 rounded-md bg-neutral-700 focus:bg-neutral-700 focus:outline-none focus:border-none focus:shadow-none focus:ring-1 focus:ring-orange-500' />
                                {fornecedorErros.descricao && <p className='text-red-500 text-xs md:text-sm pt-1 pb-2'>{fornecedorErros.descricao}</p>}
                            </div>
                            <div className='flex flex-col w-full gap-2'>
                                <label htmlFor="main" className='text-xs md:text-sm'>Categoria Principal</label>
                                <input type="text" id="main" name="main" value={fornecedor.categoria.main} onChange={handleCategoryChange} required placeholder="Indique a categoria principal" className='w-full text-sm p-2 rounded-md bg-neutral-700 focus:bg-neutral-700 focus:outline-none focus:border-none focus:shadow-none focus:ring-1 focus:ring-orange-500' />
                                {fornecedorErros.categoria.main && <p className='text-red-500 text-xs md:text-sm pt-1 pb-2'>{fornecedorErros.categoria.main}</p>}
                            </div>
                            <div className='flex flex-col w-full gap-2'>
                                <label className='text-xs md:text-sm'>Categorias Secundárias</label>
                                <div className='flex gap-2'>
                                    <input type="text" value={newOtherCategory} onChange={(e) => setNewOtherCategory(e.target.value)} placeholder="Adicione uma categoria" className='w-full text-sm p-2 rounded-md bg-neutral-700 focus:bg-neutral-700 focus:outline-none focus:border-none focus:shadow-none focus:ring-1 focus:ring-orange-500' />
                                    <button type="button" onClick={addOtherCategory} className='py-2 px-3 rounded-md text-xs bg-orange-600 hover:bg-orange-700 active:bg-orange-500'>Adicionar</button>
                                </div>
                                {fornecedorErros.categoria.others && <p className='text-red-500 text-xs md:text-sm pt-1 pb-2'>{fornecedorErros.categoria.others}</p>}
                                <ul>
                                    {fornecedor.categoria.others.map((cat, index) => (
                                        <li key={index} className='flex justify-between items-center'>
                                            {cat}
                                            <button type="button" onClick={() => removeOtherCategory(index)} className='text-red-500 hover:text-red-700 text-xs'>Remover</button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className='flex flex-col w-full gap-2'>
                                <label htmlFor="localizacao" className='text-xs md:text-sm'>Localização</label>
                                <input type="text" id="localizacao" name="localizacao" value={fornecedor.localizacao} onChange={handleInputChange} required placeholder="Indique a localização do fornecedor" className='w-full text-sm p-2 rounded-md bg-neutral-700 focus:bg-neutral-700 focus:outline-none focus:border-none focus:shadow-none focus:ring-1 focus:ring-orange-500' />
                                {fornecedorErros.localizacao && <p className='text-red-500 text-xs md:text-sm pt-1 pb-2'>{fornecedorErros.localizacao}</p>}
                            </div>
                            <div className='flex flex-col w-full gap-2'>
                                <label htmlFor="link" className='text-xs md:text-sm'>Link {'('}Endereço Url{')'}</label>
                                <input type="text" id="link" name="link" value={fornecedor.link} onChange={handleInputChange} required placeholder="https://www.exemplo.com.br" className='w-full text-sm p-2 rounded-md bg-neutral-700 focus:bg-neutral-700 focus:outline-none focus:border-none focus:shadow-none focus:ring-1 focus:ring-orange-500' />
                                {fornecedorErros.link && <p className='text-red-500 text-xs md:text-sm pt-1 pb-2'>{fornecedorErros.localizacao}</p>}
                            </div>
                        </div>
                        <div className="border-t border-neutral-700 py-5 flex flex-row justify-end items-center align-middle gap-x-3 select-none">
                            <button className="py-2 px-3 md:py-3 md:px-4 rounded-md text-xs md:text-sm bg-neutral-600 hover:bg-neutral-700 active:bg-neutral-800" onClick={() => closeModal()}>Cancelar</button>
                            <button className="py-2 px-3 md:py-3 md:px-4  rounded-md text-xs font-semibold md:text-sm bg-orange-600 hover:bg-orange-700 active:bg-orange-500" onClick={() => handleSave(fornecedor._id)} disabled={disableModal || processing}>Salvar</button>
                        </div>
                    </div>
                </div>
            }

            <div className='w-full'>
                <Helmet>
                    <title>Simbium</title>
                    <meta property="og:title" content="Simbium" />
                </Helmet>
                <Header usuarioAtual={usuarioAtual} showMenu={showMenu} setShowMenu={setShowMenu} />
                <div className='bg-neutral-900 flex flex-col font-poppins'>
                    <div className='w-full flex flex-row lg:mt-14 lg:pt-10 pt-5 md:p-5 gap-5'>
                        {showMenu && (
                            <div className='w-3/12 mb-5'>
                                <Menu showMenu={showMenu} setShowMenu={setShowMenu} />
                            </div>
                        )}
                        <div className={`${showMenu ? 'w-9/12' : 'w-11/12'} mb-5 mx-auto text-neutral-50`}>
                            <div className="flex flex-col w-full bg-neutral-700 rounded-lg text-white overflow-hidden">
                                <div className="bg-bottom bg-no-repeat bg-cover w-full flex flex-col gap-7 p-5 sm:p-10 md:p-14 lg:p-20 items-center" style={{ backgroundImage: "url('/landing-page-header.png')" }}>
                                    <h1 className="text-4xl md:text-5xl text-center font-poppins font-semibold">Fornecedores Catalogados</h1>
                                    <p className='text-xs md:text-lg text-center text-white'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, b</p>
                                    <div className='flex flex-row gap-2 w-full sm:w-2/3 mx-auto items-center align-middle justify-center'>
                                        <input
                                            type="text"
                                            placeholder="Pesquisar fornecedores..."
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                            className="w-full p-2 md:p-5 rounded-md text-orange-700 focus:border-orange-400 focus:ring-orange-400"
                                        />
                                        {usuarioAtual.tipo === 'admin' &&
                                            <button className='w-14 h-14 bg-white text-orange-500 text-center hover:bg-orange-50 active:bg-orange-100 p-3 rounded-lg flex items-center justify-center' title={"Adicionar favorecido"} onClick={() => setShowModal(true)}>
                                                <PlusIcon className='w-5 h-5 p-0 m-0' />
                                            </button>
                                        }
                                    </div>

                                </div>
                                <div className='w-11/12 mx-auto my-10'>
                                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
                                        {filteredFornecedores.length ? (
                                            filteredFornecedores.map(fornecedor => (
                                                <div key={fornecedor.id} className="bg-neutral-800 p-4 rounded-lg space-y-5">
                                                    <h2 className="text-3xl font-medium">{fornecedor.nome}</h2>
                                                    <p className='text-wrap text-lg text-justify'>{fornecedor.descricao}</p>
                                                    <div className='text-sm flex flex-col gap-1'>
                                                        <p className='text-neutral-400'>Categoria principal: <span className='text-white'>{fornecedor.categoria?.main}</span></p>
                                                        <p className='text-neutral-400'>Outras categorias:</p>
                                                        {fornecedor.categoria?.others?.length > 0 && (
                                                            <ul className='ml-5'>
                                                                {fornecedor.categoria.others.map((categoria, index) => (
                                                                    <li key={index} className='flex items-center gap-2'>
                                                                        <RectangleGroupIcon className='w-3 h-3' />
                                                                        <span>{categoria}</span>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        )}
                                                    </div>
                                                    <p className='text-neutral-500 text-sm'>Localização: <span className='text-white'>{fornecedor.localizacao}</span></p>
                                                    <a href={fornecedor.link || '/'} target='_blank' className="text-white text-center mt-2 inline-block bg-orange-600 hover:bg-orange-700 active:bg-orange-800 p-2 rounded-md w-full">
                                                        Ver Detalhes
                                                    </a>
                                                    <div className='w-full flex justify-between'>
                                                        {usuarioAtual.tipo === 'admin' && (
                                                            <>
                                                                <button className='w-full text-xs text-yellow-500 text-center' onClick={() => editarFornecedor(fornecedor._id, fornecedor.nome)}>Editar fornecedor</button>
                                                                <button className='w-full text-xs text-red-500 text-center' onClick={() => deletarFornecedor(fornecedor._id, fornecedor.nome)}>Excluir fornecedor</button>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            ))
                                        ) : (
                                            <div className='w-full col-span-3 text-neutral-300 text-center'>
                                                Não há fornecedores cadastrados no momento!
                                            </div>
                                        )}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
};

export default FornecedoresCatalogados;
