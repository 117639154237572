import React, { useState } from 'react'
import axios from 'axios'
import RichTextEditor from '../richtext'

import styles from './anotacoes_aula.module.css'

const PopupAnotacoesAula = (props) => {
  const [anotacao, setAnotacao] = useState(props.anotacao ? props.anotacao : '')

  const salvarAnotacao = async () => {
    if (!props.item || (props.item && !props.item._id)) props.fecharPopup()

    try {

      const result = await axios.post(process.env.REACT_APP_BACKEND_BASE_URL + '/aulas/anotacao-aula', {
        anotacao,
        aulaId: props.item._id
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      })

      props.fetchAula()
      props.updateUser()
      props.sucesso()
      props.fecharPopup()

    } catch (err) {
    }

  };

  return (
    <div className={styles['container']}>
      <div className={styles['container1']}>
        <div className={styles['head']}>
          <span className={styles['text']}>Anotações da Aula</span>
          <div className={styles['fechar-popup']} onClick={props.fecharPopup}>
            <svg
              viewBox="0 0 804.5714285714286 1024"
              className={styles['icon']}
            >
              <path d="M741.714 755.429c0 14.286-5.714 28.571-16 38.857l-77.714 77.714c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-168-168-168 168c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-77.714-77.714c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l168-168-168-168c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l77.714-77.714c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l168 168 168-168c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l77.714 77.714c10.286 10.286 16 24.571 16 38.857s-5.714 28.571-16 38.857l-168 168 168 168c10.286 10.286 16 24.571 16 38.857z"></path>
            </svg>
          </div>
        </div>
        <div className={styles['form']}>
          <div className={styles['container2']}>
            <RichTextEditor value={anotacao} onChange={(newValue) => setAnotacao(newValue)} />
          </div>
        </div>
        <div className={styles['container3']}>
          <button type="button" className={styles['button']} onClick={props.fecharPopup}>
            Cancelar
          </button>
          <button type="button" className={styles['button1']} onClick={salvarAnotacao}>
            Salvar Anotação
          </button>
        </div>
      </div>
    </div>
  )
}

export default PopupAnotacoesAula
